// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customDataPicker .custom-month-picker .ant-picker-header-view {
  display: none;
}
.customDataPicker .custom-month-picker .ant-picker-header {
  justify-content: center;
}
.customDataPicker .custom-month-picker .ant-picker-body {
  padding-top: 0;
}

.section-button {
  border: 1px solid #B2B2B2;
}

.section-notif {
  border: 1px solid #B2B2B2;
}`, "",{"version":3,"sources":["webpack://./src/component/molecules/SelectComponentRunPayroll/styles.scss"],"names":[],"mappings":"AACI;EACI,aAAA;AAAR;AAEI;EACI,uBAAA;AAAR;AAGI;EACI,cAAA;AADR;;AAKA;EACI,yBAAA;AAFJ;;AAIA;EACI,yBAAA;AADJ","sourcesContent":[".customDataPicker {\n    .custom-month-picker .ant-picker-header-view {\n        display: none;\n    }\n    .custom-month-picker .ant-picker-header {\n        justify-content: center;\n    }\n    \n    .custom-month-picker .ant-picker-body {\n        padding-top: 0;\n    }\n}\n\n.section-button{\n    border: 1px solid #B2B2B2;\n}\n.section-notif{\n    border: 1px solid #B2B2B2;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
