import { Collapse } from "antd";
import { Result } from "../../../assets/icons";
import "./styles.scss";
import ScheduleCard from "../ScheduleCard";
import { Link } from "react-router-dom";


const DetailAssessmentActivityReport = ({ detailData }) => {
  const assessmentData = [
    { label: "Result Assessment", value: detailData?.result_status },
    { label: "Final Decision", value: detailData?.result_effective_date },
    { label: "Attachment ", value: 
      detailData?.result_attachment ? (
        <Link
          to={process.env.REACT_APP_HRIS_URL+`personal-assessment/file/${detailData?.result_attachment}`}
          target="_blank"
          rel="noopener noreferrer"
          className="block overflow-x-auto text-gray-500"
          style={{
            wordWrap: "break-word",
            width: "100%",
          }}
        >
          {"Result Assessment Attachment"}
        </Link>
        ) : (
        <div className="subtitle">
          -
        </div>
      )
     },
    { label: "Remarks ", value: detailData?.result_remarks },
  ];

  return (
    <>
      <div className="mb-[-10px]">Assessment Activity</div>
      <ul>
        {detailData?.process.map(item => {
          return <li>{item?.activity}</li>
        })}
      </ul>
      <>
        {detailData?.process.map((schedule, index) => (
          <ScheduleCard key={index} schedule={schedule} />
        ))}
        <div className="mt-12">
          <Collapse expandIcon={() => null} defaultActiveKey={["1"]}>
            <Collapse.Panel
              header={
                <div className="flex gap-2 items-center">
                  <Result size={20} />
                  Result
                </div>
              }
              key="1">
              <>
                <div className="flex flex-col gap-4 px-4 py-4">
                  {assessmentData.map((item, index) => (
                    <div key={index} className="flex flex-col gap-2">
                      <div>{item.label}</div>
                      <div className="px-4">{item.value}</div>
                    </div>
                  ))}
                </div>
              </>
            </Collapse.Panel>
          </Collapse>
        </div>
      </>
    </>
  );
};

export default DetailAssessmentActivityReport;
