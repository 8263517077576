import {
  Button,
  Collapse,
  DatePicker,
  Form as FormAntd,
  message,
  Modal,
  TimePicker,
} from "antd";
import { useEffect, useState } from "react";
import {
  CheckboxOptionWithLabel,
  InputWithLabel,
  SelectMultipleWithLabel,
} from "../../Form/Input";
import {
  AddPersonalAssessmentProcessActivity,
  DeletePersonalAssessmentProcessActivity,
  getDetailPersonalAssessmentProcess,
} from "../../../api/personalAssessment";
import { useFormik } from "formik";
import { DeleteIcon, EditIcon, Result, Send } from "../../../assets/icons";
import dayjs from "dayjs";
import "./styles.scss";
import ScheduleCard from "../ScheduleCard";
import { Link } from "react-router-dom";
import AttachmentIcon from "../../../assets/img/icons/attachment-ico.png";
import ResultPA from "../ResultPA";
import { decryptData } from "../../../helper/cryptojs";
const DetailAssessmentActivity = ({ fetchAsessment, detailData }) => {
  const location = window.location.href;
  let n = location.lastIndexOf("/");
  let result = location.substring(n + 1);
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const employeeNumber = decryptData(localStorage.getItem("EmployeeNumber"));
  const RoleaccessPriviledgeProcess =
    localStorage.getItem("RoleaccessPriviledgePersonalAssessmentCreate") ||
    localStorage.getItem("RoleaccessPriviledgePersonalAssessmentProcess");
  const RoleaccessPriviledgeProcessDetail = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgePersonalAssessmentProcess")
  );

  const [fetching, setFetching] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditForm, setIsEditForm] = useState(true);
  const [modalText, setModalText] = useState("");
  const [actionType, setActionType] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    setActionType(actionType);
  }, [actionType]);

  const fetchDataPersonalAssessmentProcess = async () => {
    setFetching(true);
    try {
      const res = await getDetailPersonalAssessmentProcess(id);
      setData(res?.data?.data);
    } catch (error) {
      console.error(error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    fetchDataPersonalAssessmentProcess();
  }, []);

  const activitiesManipulation = new Set(
    data?.process?.map((item) => item?.activity)
  );
  const uniqueActivities = Array.from(activitiesManipulation);
  const resultData = data?.process?.length ? uniqueActivities : [];

  const handleButtonClick = (type, typeValue) => {
    sessionStorage.setItem("schedule", JSON.stringify(typeValue));
    switch (type) {
      case "send":
        setModalText("Are you sure to send FGD Schedule?");
        break;
      case "delete":
        setModalText("Are you sure to delete FGD Schedule?");
        setActionType("delete");
        break;
      case "send_psikotest":
        setModalText("Are you sure to send Psikotes Schedule?");
        break;
      case "delete_psikotest":
        setModalText("Are you sure to delete Psikotes Schedule?");
        setActionType("delete");
        break;
      case "send_interview":
        setModalText("Are you sure to send Interview Schedule?");
        break;
      case "delete_interview":
        setModalText("Are you sure to delete Interview Schedule?");
        setActionType("delete_interview");
        break;
      case "send_presentasi":
        setModalText("Are you sure to send Presentasi Schedule?");
        break;
      case "delete_presentasi":
        setModalText("Are you sure to delete Presentasi Schedule?");
        setActionType("delete_presentasi");
        break;
      case "send_role_play":
        setModalText("Are you sure to send Role Play Schedule?");
        break;
      case "delete_role_play":
        setModalText("Are you sure to delete Role Play Schedule?");
        setActionType("delete_role_play");
        break;
      default:
        break;
    }

    setIsModalVisible(true);
  };

  const handleModalSubmit = async () => {
    form.handleSubmit();
    setIsModalVisible(false);
  };

  const handleModalNo = () => {
    setIsModalVisible(false);
  };

  const activityDate = (activity) => {
    const process = data?.process?.find((item) => item?.activity === activity);
    return process ? process?.date : null;
  };
  const activityStartTime = (activity) => {
    const process = data?.process?.find((item) => item?.activity === activity);
    return process
      ? dayjs(process?.start_time, "HH:mm:ss").format("HH:mm")
      : null;
  };
  const activityEndTime = (activity) => {
    const process = data?.process?.find((item) => item?.activity === activity);
    return process
      ? dayjs(process?.end_time, "HH:mm:ss").format("HH:mm")
      : null;
  };

  const activityUrl = (activity) => {
    const process = data?.process?.find((item) => item?.activity === activity);
    return process ? process?.url : null;
  };

  const form = useFormik({
    initialValues: {
      assessment_activity: resultData ? resultData : "",
      employeeNumber: employeeNumber,
      process_id: null,
      request_id: id,
      activity: null,
      date: activityDate("FGD"),
      date_psikotest: activityDate("Psikotest"),
      date_interview: activityDate("Interview"),
      date_presentasi: activityDate("Presentasi"),
      date_role_play: activityDate("Role Play"),
      start_time: activityStartTime("FGD"),
      end_time: activityEndTime("FGD"),
      start_time_psikotest: activityStartTime("Psikotest"),
      end_time_psikotest: activityEndTime("Psikotest"),
      start_time_interview: activityStartTime("Interview"),
      end_time_interview: activityEndTime("Interview"),
      start_time_presentasi: activityStartTime("Presentasi"),
      end_time_presentasi: activityEndTime("Presentasi"),
      start_time_role_play: activityStartTime("Role Play"),
      end_time_role_play: activityEndTime("Role Play"),
      url: activityUrl("FGD"),
      url_psikotest: activityUrl("Psikotest"),
      url_interview: activityUrl("Interview"),
      url_presentasi: activityUrl("Presentasi"),
      url_role_play: activityUrl("Role Play"),
    },
    enableReinitialize: true,

    onSubmit: async (values) => {
      setFetching(true);
      const activity = JSON.parse(sessionStorage.getItem("schedule"));
      const filteredValues = {
        request_id: values.request_id,
        activity: activity.activity,
      };
      const filteredValuesUpdate = {
        ...values,
        request_id: values.request_id,
        employeeNumber: employeeNumber,
        process_id: activity.id,
      };

      try {
        let res;
        if (
          actionType == "delete" ||
          actionType == "delete_psikotest" ||
          actionType == "delete_interview" ||
          actionType == "delete_presentasi" ||
          actionType == "delete_role_play"
        ) {
          res = await DeletePersonalAssessmentProcessActivity(filteredValues);
        } else if (activity.id) {
          res = await AddPersonalAssessmentProcessActivity(
            filteredValuesUpdate
          );
        } else {
          res = await AddPersonalAssessmentProcessActivity(values);
        }
        if (!res?.data?.success) {
          message.error({
            content: res?.data?.error,
          });
        } else if (res?.data?.success) {
          if (res?.data?.message) {
            message.success({
              content: res?.data?.message,
            });
          }
        }
        setIsEditForm(false);
        form.resetForm();
        fetchDataPersonalAssessmentProcess();
        fetchAsessment();
        setFetching(false);
      } catch (error) {
        message.error({
          content: error?.response?.data?.error,
        });
        setFetching(false);
      }
    },
  });

  const assessmentData = [
    { label: "Result Assessment", value: data?.result_status },
    { label: "Effective Date", value: data?.result_effective_date },
    {
      label: "Attachment ",
      value: (
        <div className="text-sm text-gray-500 flex items-center gap-2">
          <img src={AttachmentIcon} className="w-4" alt="icon" />
          {data?.result_attachment ? (
            <Link
              to={
                process.env.REACT_APP_HRIS_URL +
                `personal-assessment/file/${data?.result_attachment}`
              }
              target="_blank"
              rel="noopener noreferrer"
              className="block overflow-x-auto text-gray-500"
              style={{ wordWrap: "break-word", width: "100%" }}>
              {data?.result_attachment}
            </Link>
          ) : (
            <span className="text-gray-500">No File</span>
          )}
        </div>
      ),
    },
    { label: "Remarks ", value: data?.result_remarks },
  ];

  return (
    <>
      {isEditForm && data?.process?.length > 0 ? (
        <>
          {detailData?.status !== "Done" &&
            RoleaccessPriviledgeProcessDetail && (
              <div
                className="flex justify-end cursor-pointer"
                onClick={() => setIsEditForm(!isEditForm)}>
                <EditIcon />
              </div>
            )}
          <div className="mb-[-10px]">Assessment Activity</div>
          <ul>
            {data?.process?.map((index) => (
              <li key={index}>{index?.activity}</li>
            ))}
          </ul>
          <>
            {data?.process?.map((index) => (
              <ScheduleCard key={index} schedule={index} />
            ))}
            <div className="mt-12">
              {RoleaccessPriviledgeProcessDetail &&
              !data?.result_effective_date ? (
                <ResultPA
                  isPrivillege={RoleaccessPriviledgeProcess}
                  valuesProcess={data}
                  fetchAsessment={fetchAsessment}
                  fetchDataPersonalAssessmentProcess={
                    fetchDataPersonalAssessmentProcess
                  }
                />
              ) : (
                <Collapse expandIcon={() => null} defaultActiveKey={["1"]}>
                  <Collapse.Panel
                    header={
                      <div className="flex gap-2 items-center">
                        <Result size={20} />
                        Result
                      </div>
                    }
                    key="1">
                    <>
                      <div className="flex flex-col gap-4 px-4 py-4">
                        {assessmentData.map((item, index) => (
                          <div key={index} className="flex flex-col gap-2">
                            <div>{item.label}</div>
                            <div className="px-4">{item.value || "-"}</div>
                          </div>
                        ))}
                      </div>
                    </>
                  </Collapse.Panel>
                </Collapse>
              )}
            </div>
          </>
        </>
      ) : isEditForm || detailData?.status !== "Done" ? (
        <>
          <Modal
            visible={isModalVisible}
            onCancel={handleModalNo}
            footer={[
              <Button key="no" onClick={handleModalNo}>
                Cancel
              </Button>,
              <Button
                loading={fetching}
                key="submit"
                type="primary"
                onClick={handleModalSubmit}>
                OK
              </Button>,
            ]}>
            <p>{modalText}</p>
          </Modal>
          <FormAntd onFinish={form.handleSubmit}>
            <FormAntd.Item>
              <SelectMultipleWithLabel
                name="assessment_activity"
                label={
                  <div>
                    Assessment Activity
                    <span className="text-red-500">*</span>
                  </div>
                }
                placeholder="Select one"
                value={form.values.assessment_activity || null}
                items={[
                  {
                    value: "FGD",
                    title: "FGD",
                  },
                  {
                    value: "Psikotest",
                    title: "Psikotest",
                  },
                  {
                    value: "Interview",
                    title: "Interview",
                  },
                  {
                    value: "Presentasi",
                    title: "Presentasi",
                  },
                  {
                    value: "Role Play",
                    title: "Role Play",
                  },
                ]}
                onChange={(val) =>
                  form.setValues({
                    ...form.values,
                    assessment_activity: val,
                  })
                }
                onBlur={form.handleBlur}
              />
              {form.touched.assessment_activity &&
                form.errors.assessment_activity && (
                  <div className="relative">
                    <span className="text-error absolute">
                      {form.errors.assessment_activity}
                    </span>
                  </div>
                )}
            </FormAntd.Item>

            {["FGD", "Psikotest", "Interview", "Presentasi", "Role Play"].map(
              (activity, _) => {
                const isFGD = activity === "FGD";
                const isPesikotest = activity === "Psikotest";
                const isInterview = activity === "Interview";
                const isPresentasi = activity === "Presentasi";
                const isRolePlay = activity === "Role Play";

                const isFirstActivity =
                  form.values.assessment_activity[0] === activity;

                const scheduleTitle = isFGD
                  ? "FGD Schedule"
                  : isPesikotest
                  ? "Psikotest Schedule"
                  : isInterview
                  ? "Interview Schedule"
                  : isPresentasi
                  ? "Presentasi Schedule"
                  : isRolePlay
                  ? "Role Play Schedule"
                  : null;
                const dateFieldName = isFGD
                  ? "date"
                  : isPesikotest
                  ? "date_psikotest"
                  : isInterview
                  ? "date_interview"
                  : isPresentasi
                  ? "date_presentasi"
                  : isRolePlay
                  ? "date_role_play"
                  : null;
                const startTimeFieldName = isFGD
                  ? "start_time"
                  : isPesikotest
                  ? "start_time_psikotest"
                  : isInterview
                  ? "start_time_interview"
                  : isPresentasi
                  ? "start_time_presentasi"
                  : isRolePlay
                  ? "start_time_role_play"
                  : null;
                const endTimeFieldName = isFGD
                  ? "end_time"
                  : isPesikotest
                  ? "end_time_psikotest"
                  : isInterview
                  ? "end_time_interview"
                  : isPresentasi
                  ? "end_time_presentasi"
                  : isRolePlay
                  ? "end_time_role_play"
                  : null;
                const urlFieldName = isFGD
                  ? "url"
                  : isPesikotest
                  ? "url_psikotest"
                  : isInterview
                  ? "url_interview"
                  : isPresentasi
                  ? "url_presentasi"
                  : isRolePlay
                  ? "url_role_play"
                  : null;
                const sendAction = isFGD
                  ? "send"
                  : isPesikotest
                  ? "send_psikotest"
                  : isInterview
                  ? "send_interview"
                  : isPresentasi
                  ? "send_presentasi"
                  : isRolePlay
                  ? "send_role_play"
                  : null;
                const deleteAction = isFGD
                  ? "delete"
                  : isPesikotest
                  ? "delete_psikotest"
                  : isInterview
                  ? "delete_interview"
                  : isPresentasi
                  ? "delete_presentasi"
                  : isRolePlay
                  ? "delete_role_play"
                  : null;
                const activityKey = isFGD
                  ? "FGD"
                  : isPesikotest
                  ? "Psikotest"
                  : isInterview
                  ? "Interview"
                  : isPresentasi
                  ? "Presentasi"
                  : isRolePlay
                  ? "Role Play"
                  : null;

                if (!form.values.assessment_activity.includes(activity))
                  return null;

                const firstActivity = form?.values?.assessment_activity?.find(
                  (value) => value
                );

                let urlFieldNameURL;
                if (firstActivity) {
                  switch (firstActivity) {
                    case "FGD":
                      urlFieldNameURL = form.values.url;
                      break;
                    case "Psikotest":
                      urlFieldNameURL = form.values.url_psikotest;
                      break;
                    case "Interview":
                      urlFieldNameURL = form.values.url_interview;
                      break;
                    case "Presentasi":
                      urlFieldNameURL = form.values.url_presentasi;
                      break;
                    case "Role Play":
                      urlFieldNameURL = form.values.url_role_play;
                      break;
                    default:
                      urlFieldNameURL = "";
                      break;
                  }
                }

                const dynamicUrl = form.values.applies_to_all_activity
                  ? urlFieldNameURL
                  : form.values[urlFieldName] || "";

                if (form.values.applies_to_all_activity) {
                  sessionStorage.setItem("url", JSON.stringify(dynamicUrl));
                } else {
                  sessionStorage.removeItem("url");
                }

                return (
                  <div className="flex flex-col" key={activityKey}>
                    <div className="text-slate-500 mb-1">
                      {scheduleTitle}
                      <span className="text-red-500">*</span>
                    </div>
                    <div className="flex flex-wrap justify-between gap-6">
                      <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                        <FormAntd.Item className="m-0">
                          <DatePicker
                            name={dateFieldName}
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            allowClear={true}
                            value={
                              form.values[dateFieldName]
                                ? dayjs(
                                    form.values[dateFieldName],
                                    "YYYY-MM-DD"
                                  )
                                : null
                            }
                            onChange={(date) => {
                              form.setFieldValue(
                                dateFieldName,
                                date ? dayjs(date).format("YYYY-MM-DD") : null
                              );
                            }}
                            disabledDate={(current) => {
                              return current < dayjs().startOf("day");
                            }}
                            className="w-full h-10 bg-gray-100 border border-gray-300 rounded"
                          />
                          {form.touched[dateFieldName] &&
                            form.errors[dateFieldName] && (
                              <span className="text-error">
                                {form.errors[dateFieldName]}
                              </span>
                            )}
                        </FormAntd.Item>
                      </div>

                      {/* Start Time Field */}
                      <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                        <FormAntd.Item className="m-0">
                          <TimePicker
                            allowClear={true}
                            name={startTimeFieldName}
                            format="HH:mm"
                            placeholder="Start Time"
                            defaultValue={
                              form.values[startTimeFieldName]
                                ? dayjs(
                                    form.values[startTimeFieldName],
                                    "HH:mm"
                                  )
                                : null
                            }
                            onChange={(_, timeString) => {
                              form.setFieldValue(
                                startTimeFieldName,
                                timeString
                              );
                            }}
                            size="large"
                            className="w-full h-10 bg-gray-100 border border-gray-300 rounded"
                          />
                          {form.touched[startTimeFieldName] &&
                            form.errors[startTimeFieldName] && (
                              <span className="text-error">
                                {form.errors[startTimeFieldName]}
                              </span>
                            )}
                        </FormAntd.Item>
                      </div>

                      {/* End Time Field */}
                      <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                        <FormAntd.Item className="m-0">
                          <TimePicker
                            allowClear={true}
                            name={endTimeFieldName}
                            format="HH:mm"
                            placeholder="End Time"
                            defaultValue={
                              form.values[endTimeFieldName]
                                ? dayjs(form.values[endTimeFieldName], "HH:mm")
                                : null
                            }
                            onChange={(_, timeString) => {
                              form.setFieldValue(endTimeFieldName, timeString);
                            }}
                            size="large"
                            className="w-full h-10 bg-gray-100 border border-gray-300 rounded"
                          />
                          {form.touched[endTimeFieldName] &&
                            form.errors[endTimeFieldName] && (
                              <span className="text-error">
                                {form.errors[endTimeFieldName]}
                              </span>
                            )}
                        </FormAntd.Item>
                      </div>

                      {/* Send Button */}
                      {RoleaccessPriviledgeProcess && (
                        <div className="flex items-center justify-end mx-12">
                          <div
                            className={`${
                              data?.process?.find(
                                (process) => process.activity === activityKey
                              )?.enable_button
                                ? !form.values[dateFieldName] ||
                                  form.values[dateFieldName].trim() === "" ||
                                  !form.values[startTimeFieldName] ||
                                  form.values[startTimeFieldName].trim() ===
                                    "" ||
                                  !form.values[endTimeFieldName] ||
                                  form.values[endTimeFieldName].trim() === "" ||
                                  dynamicUrl.length === 0
                                  ? "cursor-not-allowed"
                                  : form.values[dateFieldName] !== "" &&
                                    form.values[startTimeFieldName] !== "" &&
                                    form.values[endTimeFieldName] !== "" &&
                                    dynamicUrl.length > 0 &&
                                    (form.values[dateFieldName] !==
                                      form.initialValues[dateFieldName] ||
                                      form.values[startTimeFieldName] !==
                                        form.initialValues[
                                          startTimeFieldName
                                        ] ||
                                      form.values[endTimeFieldName] !==
                                        form.initialValues[endTimeFieldName])
                                  ? "cursor-pointer"
                                  : "cursor-not-allowed"
                                : !form.values[dateFieldName] ||
                                  form.values[dateFieldName].trim() === "" ||
                                  !form.values[startTimeFieldName] ||
                                  form.values[startTimeFieldName].trim() ===
                                    "" ||
                                  !form.values[endTimeFieldName] ||
                                  form.values[endTimeFieldName].trim() === "" ||
                                  dynamicUrl.length === 0
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                            onClick={() => {
                              const processSend = data?.process?.find(
                                (process) => process.activity === activityKey
                              )?.enable_button;

                              const isFormValid = processSend
                                ? !form.values[dateFieldName] ||
                                  form.values[dateFieldName].trim() === "" ||
                                  !form.values[startTimeFieldName] ||
                                  form.values[startTimeFieldName].trim() ===
                                    "" ||
                                  !form.values[endTimeFieldName] ||
                                  form.values[endTimeFieldName].trim() === "" ||
                                  dynamicUrl.length === 0
                                  ? false
                                  : form.values[dateFieldName] !==
                                      form.initialValues[dateFieldName] ||
                                    form.values[startTimeFieldName] !==
                                      form.initialValues[startTimeFieldName] ||
                                    form.values[endTimeFieldName] !==
                                      form.initialValues[endTimeFieldName]
                                : form.values[dateFieldName] &&
                                  form.values[dateFieldName].trim() !== "" &&
                                  form.values[startTimeFieldName] &&
                                  form.values[startTimeFieldName].trim() !==
                                    "" &&
                                  form.values[endTimeFieldName] &&
                                  form.values[endTimeFieldName].trim() !== "" &&
                                  dynamicUrl.length > 0;

                              if (isFormValid) {
                                handleButtonClick(
                                  sendAction,
                                  data?.process?.find(
                                    (process) =>
                                      process.activity === activityKey
                                  ) ?? { activity: activityKey }
                                );
                              }
                              setActionType(null);
                            }}>
                            <Send
                              fill={
                                data?.process?.find(
                                  (process) => process.activity === activityKey
                                )?.enable_button
                                  ? !form.values[dateFieldName] ||
                                    form.values[dateFieldName].trim() === "" ||
                                    !form.values[startTimeFieldName] ||
                                    form.values[startTimeFieldName].trim() ===
                                      "" ||
                                    !form.values[endTimeFieldName] ||
                                    form.values[endTimeFieldName].trim() ===
                                      "" ||
                                    dynamicUrl.length === 0
                                    ? "#D6D6D6"
                                    : form.values[dateFieldName] !==
                                        form.initialValues[dateFieldName] ||
                                      form.values[startTimeFieldName] !==
                                        form.initialValues[
                                          startTimeFieldName
                                        ] ||
                                      form.values[endTimeFieldName] !==
                                        form.initialValues[endTimeFieldName]
                                    ? "#0057FF"
                                    : "#D6D6D6"
                                  : !form.values[dateFieldName] ||
                                    form.values[dateFieldName].trim() === "" ||
                                    !form.values[startTimeFieldName] ||
                                    form.values[startTimeFieldName].trim() ===
                                      "" ||
                                    !form.values[endTimeFieldName] ||
                                    form.values[endTimeFieldName].trim() ===
                                      "" ||
                                    dynamicUrl.length === 0
                                  ? "#D6D6D6"
                                  : "#0057FF"
                              }
                            />
                          </div>

                          {/* Delete Button */}
                          <div
                            className={`${
                              data?.process?.find(
                                (process) => process.activity === activityKey
                              )?.enable_button
                                ? !form.values[dateFieldName] ||
                                  form.values[dateFieldName].trim() === "" ||
                                  !form.values[startTimeFieldName] ||
                                  form.values[startTimeFieldName].trim() ===
                                    "" ||
                                  !form.values[endTimeFieldName] ||
                                  form.values[endTimeFieldName].trim() === "" ||
                                  dynamicUrl.length === 0
                                  ? "cursor-not-allowed"
                                  : form.values[dateFieldName] !== "" &&
                                    form.values[startTimeFieldName] !== "" &&
                                    form.values[endTimeFieldName] !== "" &&
                                    dynamicUrl.length > 0 &&
                                    (form.values[dateFieldName] !==
                                      form.initialValues[dateFieldName] ||
                                      form.values[startTimeFieldName] !==
                                        form.initialValues[
                                          startTimeFieldName
                                        ] ||
                                      form.values[endTimeFieldName] !==
                                        form.initialValues[endTimeFieldName])
                                  ? "cursor-pointer"
                                  : "cursor-not-allowed"
                                : !form.values[dateFieldName] ||
                                  form.values[dateFieldName].trim() === "" ||
                                  !form.values[startTimeFieldName] ||
                                  form.values[startTimeFieldName].trim() ===
                                    "" ||
                                  !form.values[endTimeFieldName] ||
                                  form.values[endTimeFieldName].trim() === "" ||
                                  dynamicUrl.length === 0
                                ? "cursor-not-allowed"
                                : "cursor-pointer"
                            }`}
                            onClick={() => {
                              const processSend = data?.process?.find(
                                (process) => process.activity === activityKey
                              )?.enable_button;

                              const isFormValid = processSend
                                ? !form.values[dateFieldName] ||
                                  form.values[dateFieldName].trim() === "" ||
                                  !form.values[startTimeFieldName] ||
                                  form.values[startTimeFieldName].trim() ===
                                    "" ||
                                  !form.values[endTimeFieldName] ||
                                  form.values[endTimeFieldName].trim() === "" ||
                                  dynamicUrl.length === 0
                                  ? false
                                  : form.values[dateFieldName] !==
                                      form.initialValues[dateFieldName] ||
                                    form.values[startTimeFieldName] !==
                                      form.initialValues[startTimeFieldName] ||
                                    form.values[endTimeFieldName] !==
                                      form.initialValues[endTimeFieldName]
                                : form.values[dateFieldName] &&
                                  form.values[dateFieldName].trim() !== "" &&
                                  form.values[startTimeFieldName] &&
                                  form.values[startTimeFieldName].trim() !==
                                    "" &&
                                  form.values[endTimeFieldName] &&
                                  form.values[endTimeFieldName].trim() !== "" &&
                                  dynamicUrl.length > 0;

                              if (isFormValid) {
                                handleButtonClick(
                                  deleteAction,
                                  data?.process?.find(
                                    (process) =>
                                      process.activity === activityKey
                                  ) ?? { activity: activityKey }
                                );
                              }
                            }}>
                            <DeleteIcon
                              fill={
                                data?.process?.find(
                                  (process) => process.activity === activityKey
                                )?.enable_button
                                  ? !form.values[dateFieldName] ||
                                    form.values[dateFieldName].trim() === "" ||
                                    !form.values[startTimeFieldName] ||
                                    form.values[startTimeFieldName].trim() ===
                                      "" ||
                                    !form.values[endTimeFieldName] ||
                                    form.values[endTimeFieldName].trim() ===
                                      "" ||
                                    dynamicUrl.length === 0
                                    ? "#D6D6D6"
                                    : form.values[dateFieldName] !==
                                        form.initialValues[dateFieldName] ||
                                      form.values[startTimeFieldName] !==
                                        form.initialValues[
                                          startTimeFieldName
                                        ] ||
                                      form.values[endTimeFieldName] !==
                                        form.initialValues[endTimeFieldName]
                                    ? "#FF0000"
                                    : "#D6D6D6"
                                  : !form.values[dateFieldName] ||
                                    form.values[dateFieldName].trim() === "" ||
                                    !form.values[startTimeFieldName] ||
                                    form.values[startTimeFieldName].trim() ===
                                      "" ||
                                    !form.values[endTimeFieldName] ||
                                    form.values[endTimeFieldName].trim() ===
                                      "" ||
                                    dynamicUrl.length === 0
                                  ? "#D6D6D6"
                                  : "#FF0000"
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    {/* URL Input */}
                    <FormAntd.Item>
                      <div className="w-[81%]">
                        <InputWithLabel
                          className="w-full"
                          name={urlFieldName}
                          placeholder="Attach your link"
                          value={dynamicUrl}
                          onChange={form.handleChange}
                          addonBefore="URL"
                        />
                      </div>

                      {/* Applies to all activity checkbox */}
                      {isFirstActivity && (
                        <div className="flex items-center gap-2">
                          <CheckboxOptionWithLabel
                            name="applies_to_all_activity"
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              form.setFieldValue(
                                "applies_to_all_activity",
                                isChecked
                              );
                              if (!isChecked) {
                                sessionStorage.removeItem("url");
                              }
                            }}
                            checked={form.values?.applies_to_all_activity}
                            value={form.values?.applies_to_all_activity}
                          />
                          <div className="text-slate-500 mt-2">
                            Applies to all activity{" "}
                            <span className="text-red-500">*</span>
                          </div>
                        </div>
                      )}
                    </FormAntd.Item>
                  </div>
                );
              }
            )}
          </FormAntd>

          <div className="flex gap-3 justify-end mt-4">
            <Button
              key="submit"
              type="primary"
              className="btn btn-sec"
              loading={fetching}
              onClick={() => {
                setIsEditForm(true);
                fetchDataPersonalAssessmentProcess();
                form.resetForm();
              }}>
              Back
            </Button>
          </div>
        </>
      ) : null}
    </>
  );
};

export default DetailAssessmentActivity;