/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { decryptData } from "../../../helper/cryptojs";
import { getPtkpStatusDetail } from "../../../api/ptkpStatus";
import { formatCurrency } from "../../../helper/formatCurrency";

const PtkpStatusDetail = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  let n = location.lastIndexOf("/");
  let result = location.substring(n + 1);
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const BestpraticeUseEffect = useRef(false);
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const [detail, setDetail] = useState([]);

  const getDetailPtkpStatus = async () => {
    try {
      const res = await getPtkpStatusDetail(id);
      setDetail(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      getDetailPtkpStatus();
    }
  }, [company]);

  const dataBreadcrumb = [
    {
      title: "Payroll Management - PTKP Status",
      url: "/ptkp-status",
    },
    {
      title: "PTKP Status Detail",
      url: ``
    },
  ];

  return (
    <>
      <div className="title-section">
        <Button
          className="btn-sh-p"
          type="primary"
          shape="circle"
          style={{ marginRight: "14px" }}
          onClick={() => navigate(-1)}
          icon={<ArrowLeftOutlined />}
          size={"large"}
        />
        PTKP Status Detail
      </div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="detail-wrapper">
        <div className="border-dashed border-2 border-sky-500 w-auto bg-white p-6 rounded-lg shadow-lg mt-3">
          <Row>
            <Col span={12}>
              <div className="detail-card">
                <div className="title">PTKP Type</div>
                <div className="subtitle">{detail?.ptkp_type || "-"}</div>
              </div>
            </Col>
            <Col span={12}>
              <div className="detail-card">
                <div className="title">PTKP Code</div>
                <div className="subtitle">{detail?.ptkp_status || "-"}</div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={12}>
              <div className="detail-card">
                <div className="title">PTKP Value</div>
                <div className="subtitle">{formatCurrency(detail?.ptkp_value) || "-"}</div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PtkpStatusDetail;
