/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Row, Table, Tabs, Button, Collapse } from "antd";
import { useEffect, useState } from "react";
import TabPane from "antd/es/tabs/TabPane";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { formatCurrency } from "../../../../helper/formatCurrency";
import Breadcrumb from "../../../../component/Breadcrumb";
import {
  getPAHistoryDetailAssessment,
  getPAHistoryDetailProcess,
} from "../../../../api/employee";
import "../../styles.scss";
import moment from "moment";

const AssessmentDetailEmployee = () => {
  const location = useLocation()
  const navigate = useNavigate();

  const [detail, setDetail] = useState({});
  const [detailResult, setDetailResult] = useState({});
  const [employeeId, setEmployeeId] = useState(null);
  const [loading, setLoading] = useState(false);

  const detailAssessment = async () => {
    const {pa_id, employee_number} = location?.state
    try {
      const res = await getPAHistoryDetailAssessment(employee_number, pa_id);
      setDetail(res?.data?.data);
      setEmployeeId(res?.data?.data?.employee_id);
    } catch (err) {
      console.log(err);
    }
  };

  const detailProcess = async () => {
    const {pa_id} = location?.state
    try {
      const res = await getPAHistoryDetailProcess(pa_id);
      setDetailResult(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };
  

  useEffect(() => {
    detailAssessment();
    detailProcess()
  }, []);

  const dataBreadcrumb = [
    {
      title: "Master Data - Employee Management",
      url: "/employee-management",
    },
    {
      title: "Employee Management Detail",
      url: `/employee-management/detail/id%3F${employeeId}`,
    },
    {
      title: "Assessment Detail",
      url: `/employee-management/detail/detail-assessment}`,
    },
  ];


  return (
    <>
      <div className="title-section">
        <Button
          className="btn-sh-p"
          type="primary"
          shape="circle"
          style={{ marginRight: "14px" }}
          onClick={() =>
            navigate(`/employee-management/detail/id%3F${employeeId}`)
          }
          icon={<ArrowLeftOutlined />}
          size={"large"}
        />
        Assessment Detail
      </div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="detail-wrapper">
        <Row>
          <Col span={12}>
            <div className="detail-card">
              <div className="title"> NIK</div>
              <div className="subtitle">{detail?.employee_number || "-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Employee Name</div>
              <div className="subtitle">{detail?.employee_name || "-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Branch</div>
              <div className="subtitle">{detail?.from_branch_name || "-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Department</div>
              <div className="subtitle">{detail?.from_department_name || "-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Division</div>
              <div className="subtitle">{detail?.from_division_name || "-"}</div>
            </div>
          </Col>

          <Col span={12}>
            <div className="detail-card">
              <div className="title">Position</div>
              <div className="subtitle">{detail?.from_position_name || "-"}</div>
            </div>
          </Col>
          <div className="mt-7 border-dashed border-2 border-sky-500 w-full bg-white p-6 rounded-lg shadow-lg">
            <div className="detail-wrapper">
              <div className="border-dashed border-2 border-sky-500 w-auto bg-white p-6 rounded-lg shadow-lg mt-3">
                <Row>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Personal Assessment Number</div>
                      <div className="subtitle">
                        {detail?.personal_assessment_number || "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Request Date</div>
                      <div className="subtitle">
                        {detail?.request_date || "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Period</div>
                      <div className="subtitle">
                        {detail?.period_month || "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Type</div>
                      <div className="subtitle">
                        {detail?.type || "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Current Branch</div>
                      <div className="subtitle">
                        {detail?.from_branch_name || "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Recommended Branch</div>
                      <div className="subtitle">
                        {detail?.recommended_branch_name || "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Current Department</div>
                      <div className="subtitle">
                        {detail?.from_department_name || "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Recommended Department</div>
                      <div className="subtitle">
                        {detail?.recommended_department_name || "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Current Division</div>
                      <div className="subtitle">
                        {detail?.from_division_name || "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Recommended Division</div>
                      <div className="subtitle">
                        {detail?.recommended_division_name || "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Current Position</div>
                      <div className="subtitle">
                        {detail?.from_position_name || "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Recommended Position</div>
                      <div className="subtitle">
                        {detail?.recommended_position_name || "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Current Level</div>
                      <div className="subtitle">
                        {detail?.from_level_name || "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Recommended Level</div>
                      <div className="subtitle">
                        {detail?.recommended_level_name || "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Current Grade</div>
                      <div className="subtitle">
                        {detail?.from_grade_value || "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Recommended Grade</div>
                      <div className="subtitle">
                        {detail?.recommended_grade_value || "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Current Direct SPV</div>
                      <div className="subtitle">
                        {detail?.from_direct_upline_name || "-"}
                      </div>
                    </div>
                  </Col>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Recommended Direct SPV</div>
                      <div className="subtitle">
                        {detail?.recommended_direct_upline_name || "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
                {detail?.status === 'Cancel' || detail?.status === 'Rejected' ? 
                  <Row>
                    <Col span={12}>
                      <div className="detail-card">
                        <div className="title">Reason</div>
                        <div className="subtitle">
                      <span className="block overflow-x-auto" style={{ wordWrap: 'break-word', width:'90%' }}>
                        {detail?.cancel_reason || "-"}
                      </span>
                        </div>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="detail-card">
                        <div className="title">Attachment</div>
                        <div className="subtitle">
                          {detail?.cancel_attachment ?  (
                              <a
                              href={`${process.env.REACT_APP_HRIS_URL}personal-assessment/file/${detail?.cancel_attachment}`}
                              target="_blank"
                              rel="noopener noreferrer">
                            View Attachment
                          </a>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                :
                  null
                }
                <Row>
                  <Col span={12}>
                    <div className="detail-card">
                      <div className="title">Status</div>
                      <div className="subtitle">
                        {detail?.status || "-"}
                      </div>
                    </div>
                  </Col>
                </Row>
                <div className="mt-5 mb-5">
                  <Collapse>
                    <Collapse.Panel header={<div>Result</div>} key="1">
                      <Row>
                        <Col span={24}>
                          <div className="detail-card">
                            <div className="title">Result Assessment</div>
                            <div className="subtitle">
                              {detailResult?.result_status || "-"}
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className="detail-card">
                            <div className="title">Effective Date</div>
                            <div className="subtitle">
                              {detailResult?.result_effective_date || "-"}
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className="detail-card">
                            <div className="title">Attachment</div>
                            <div className="text-sm text-gray-500 flex items-center">
                              <div>
                                <svg
                                  className="w-4 h-4 mr-1 fill-current"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                >
                                  <path d="M4 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1H2V6zM9 12v5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z"></path>
                                </svg>
                              </div>
                              <>
                                {detailResult?.result_attachment ? (
                                <Link
                                  to={process.env.REACT_APP_HRIS_URL+`personal-assessment/file/${detailResult?.result_attachment}`}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="block overflow-x-auto text-gray-500"
                                  style={{
                                    wordWrap: "break-word",
                                    width: "100%",
                                  }}
                                >
                                  {"Result Assessment Attachment"}
                                </Link>
                                ) : (
                                <div className="subtitle">
                                  -
                                </div>
                              )}
                              </>
                            </div>
                          </div>
                        </Col>
                        <Col span={24}>
                          <div className="detail-card">
                            <div className="title">Remarks</div>
                            <div className="subtitle">
                              {detailResult?.result_remarks || "-"}
                            </div>
                          </div>
                        </Col>
                        {/* <Col span={24}>
                          <div className="detail-card">
                            <div className="title">Final Decision</div>
                            <div className="subtitle">
                              {"Approved"}
                            </div>
                          </div>
                        </Col> */}
                      </Row>
                    </Collapse.Panel>
                  </Collapse>
                </div>
              </div>
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default AssessmentDetailEmployee;