import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getHoliday = async (search,month, startDate, endDate, limit, offset, page,company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    // startDate: startDate,
    // endDate: endDate,
    filterColumn: "holiday_name",
    month: month,
    filter: search?.length > 0 ? search : undefined,
    start:  offset,
    length: limit,
    draw: page,
  };
  return master.post("/mholiday", payload);
};

export const getHolidayDetail = async (id) => {
  let payload = {
    mholidayCode: id,
  };
  return master.post("/mholiday-detail", payload);
};


export const AddHoliday = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    name: data.name,
    companyCode : data.companyCode,
    description : data.description,
    holiday_type: data.holiday_type,
    date_from: data.date_from,
    date_to: data.date_to,
    recurring: data.recurring,
    cut_leave: data.cut_leave,
    created_by: user

  };

  return master.post("/mholiday-create", payload);
};

export const EditHoliday = async (data,id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    companyCode : data.companyCode,
    mholidayCode: id,
    name: data.name,
    description : data.description,
    holiday_type: data.holiday_type,
    date_from: data.date_from,
    date_to: data.date_to,
    recurring: data.recurring,
    cut_leave: data.cut_leave,
    modified_by: user
  };

  return master.post("/mholiday-update", payload);
};


export const DeleteHoliday = async (id, companyCode) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        companyCode,
        mholidayCode: id,
        modified_by: user
    }
    return master.post("/mholiday-delete", payload);
}