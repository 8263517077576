import { master } from "./hris";
import { decryptData } from "../helper/cryptojs";

const getListReportPayroll = async (
  company_id,
  page,
  offset,
  limit,
  startDate
) => {
  return master.get(`/run-payroll/list-data`, {
    params: {
      company_id: company_id,
      draw: page,
      start: offset,
      length: limit,
      period: startDate,
    },
  });
};

const getDetailReport = async (id) => {
  return master.get(`/run-payroll/detail/${id}`);
};

const generatePayslip = async (id) => {
  const user = decryptData(localStorage.getItem("Username"));
  return master.post(`/run-payroll/generate-payslip/${id}`, {created_by: user });
};

export { getListReportPayroll, getDetailReport, generatePayslip };
