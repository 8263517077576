/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { decryptData } from "../../../helper/cryptojs";
import { getTaxMethodDetail } from "../../../api/taxMethod";

const TaxMethodDetail = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  let n = location.lastIndexOf("/");
  let result = location.substring(n + 1);
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const BestpraticeUseEffect = useRef(false);
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const [detail, setDetail] = useState([]);

  const getDetailTaxMethod = async () => {
    try {
      const res = await getTaxMethodDetail(id);
      setDetail(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      getDetailTaxMethod();
    }
  }, [company]);

  const dataBreadcrumb = [
    {
      title: "Payroll Management",
      url: "/tax-methods",
    },
    {
      title: "Tax Method Detail",
      url: ``
    },
  ];

  return (
    <>
      <div className="title-section">
        <Button
          className="btn-sh-p"
          type="primary"
          shape="circle"
          style={{ marginRight: "14px" }}
          onClick={() => navigate(-1)}
          icon={<ArrowLeftOutlined />}
          size={"large"}
        />
        Tax Method Detail
      </div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="detail-wrapper">
        <div className="border-dashed border-2 border-sky-500 w-auto h-auto bg-white p-6 rounded-lg shadow-lg mt-3">
          <Row>
            <Col span={12}>
              <div className="detail-card">
                <div className="title">Tax Method</div>
                <div className="subtitle">{detail?.tax_method || "-"}</div>
              </div>
            </Col>
            <Col span={12}>
              <div className="detail-card">
                <div className="title">
                  Applies to <span className="text-red-500">*</span>
                </div>
                <div className="subtitle">
                  {detail?.applies_to == "all"
                    ? "All Employees"
                    : "Based on Level"}
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            {detail?.applies_to != "all" ? (
              <>
                <Col span={12}></Col>
                <Col span={12}>
                  <div className="detail-card">
                    <div className="title">Level</div>
                    {detail?.level?.length > 0 ? (
                      detail?.level.map((level, index) => (
                        <div
                          className="subtitle"
                          key={index}>{`- ${level}`}</div>
                      ))
                    ) : (
                      <div className="subtitle">-</div>
                    )}
                  </div>
                </Col>
              </>
            ) : null}
          </Row>
        </div>
      </div>
    </>
  );
};

export default TaxMethodDetail;
