import { Button, Col, DatePicker, Pagination, Row, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Table from "../../component/Table";
import "./styles.scss";
import { ReloadOutlined, EditFilled, StopOutlined } from "@ant-design/icons";
import { BarChart } from "../../component/Bar";
import { Bar, Doughnut } from "react-chartjs-2";
import { ArcElement, Chart } from "chart.js";
import "chart.js/auto";
import {
  getEmployeeNearExpired,
  getEmployeePerBranch,
  getEmployeeStatus,
  getGenderDiversity,
  getListmountAttendance,
  getListmountAttendanceGrafik,
} from "../../api/dashboard";
import { decryptData, encryptData } from "../../helper/cryptojs";
import EditExpiredEmployee from "./EditExpiredEmployee";
import BulkUpdateExpiredEmployee from "./BulkUpdateExpiredEmployee";
Chart.register(ArcElement);
const { RangePicker } = DatePicker;
export default function Dashboard() {
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [search, setSearch] = useState("");
  const [offset, setOffset] = useState(0)
  const [employeeExpired, setEmployeeExpired] = useState([]);
  const [employeePerBranch, setEmployeePerBranch] = useState([]);
  const role = decryptData(localStorage.getItem("Role"));
  const authRoleBased = localStorage.getItem('RoleaccessPriviledgeDashbord');
  const mastermenu = role;
  const [startDate, setStartDate] = useState(null);
  const [startDate1, setStartDate1] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endDate1, setEndDate1] = useState(null);
  const [dataAttendanceMount, setDataAttendaceMount] = useState({});
  const [dataAttendanceMountBar, setDataAttendaceMountBar] = useState([]);
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const BestpraticeUseEffect = useRef(false);
  const [currentDate, setCurrentDate] = useState("");

  useEffect(() => {
    const date = new Date();
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Ingat, Januari dimulai dari 0
    const year = date.getFullYear();

    setCurrentDate(`${day}/${month}/${year}`);
  }, []);

  // const Master = mastermenu ? authRoleBased  : authRoleBased;
  const shouldDisplayContent = authRoleBased === 'true';
  const [employeeByStatus, setEmployeeByStatus] = useState([
    {
      color: "#2bc4bd",
      total: 0,
      percent: 0,
      name: "Permanent",
    },
    {
      color: "#7f58a3",
      total: 0,
      percent: 0,
      name: "Contract",
    },
    {
      color: "#ebb860",
      total: 0,
      percent: 0,
      name: "Probation",
    },
    {
      color: "#297fd4",
      total: 0,
      percent: 0,
      name: "Intern",
    },
  ]);
  const [genderDiversity, setGenderDiversity] = useState({
    labels: ["Male", "Female"],
    datasets: [
      {
        label: "# of Votes",
        data: [0, 0],
        backgroundColor: ["#297fd4", "#e55959"],
        borderWidth: 1,
      },
    ],
  });

  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const branch = decryptData(sessionStorage.getItem("selectBranch"))
    ? decryptData(sessionStorage.getItem("selectBranch"))
    : decryptData(localStorage.getItem("DefaultBranchCode"));
  const [loading, setLoading] = useState(false);
  const [totalEmployee, setTotalEmployee] = useState(0);
  const [totalEmployeeGender, setTotalEmployeeGender] = useState(0);
  const [totalEmployeePerBranch, setTotalEmployeePerbranch] = useState(0);

  const getRandomColor = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return "#" + randomColor;
  };

  const getEmployeeExpiredList = async () => {
    setLoading(true);
    try {
      const res = await getEmployeeNearExpired(company, limit, offset, page);
      setEmployeeExpired(res?.data?.data);
      setTotalData(res?.data?.recordsTotal);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
		if (!BestpraticeUseEffect.current) {
			BestpraticeUseEffect.current = true;
		} else {
			getEmployeeExpiredList()
		}
	}, [limit, offset, page]);

  useEffect(() => {
		if(page === 1){
			setOffset(0)
		}else {
			setOffset(page*limit - 10)
		}
	},[page])

  useEffect(() => {
    if (handleApiButtonClick === true) {
      getEmployeeExpiredList();
    }
  }, [branch]);

  const getEmployeeByStausList = async () => {
    setLoading(true);
    try {
      const res = await getEmployeeStatus(company);
      const total = res?.data?.data.reduce(
        (accum, item) => accum + item.total_employee,
        0
      );
      //console.log(res?.data?.data)
      const permanent = res?.data?.data.find(
        (datum) => datum.employee_type === "Permanent"
      );
      const contract = res?.data?.data.find(
        (datum) => datum.employee_type === "Contract"
      );
      const probation = res?.data?.data.find(
        (datum) => datum.employee_type === "Probation"
      );
      const intern = res?.data?.data.find(
        (datum) => datum.employee_type === "Intern"
      );
      const data = [
        {
          color: "#2bc4bd",
          total: permanent.total_employee ? permanent.total_employee : 0,
          percent: permanent.total_employee
            ? percentNumber(permanent.total_employee, total)
            : "0%",
          name: "Permanent",
        },
        {
          color: "#7f58a3",
          total: contract.total_employee ? contract.total_employee : 0,
          percent: contract.total_employee
            ? percentNumber(contract.total_employee, total)
            : "0%",
          name: "Contract",
        },
        {
          color: "#ebb860",
          total: probation.total_employee ? probation.total_employee : 0,
          percent: probation.total_employee
            ? percentNumber(probation.total_employee, total)
            : "0%",
          name: "Probation",
        },
        {
          color: "#297fd4",
          total: intern.total_employee ? intern.total_employee : 0,
          percent: intern.total_employee
            ? percentNumber(intern.total_employee, total)
            : "0%",
          name: "Intern",
        },
      ];
      // const data = res?.data?.data.map((datum, index) => ({
      //     color: dataEmployee[index % 4].color,
      //     total: datum.total_employee,
      //     percent: percentNumber(datum.total_employee, total),
      //     name: datum.employee_type
      // }))
      const total1 = data.reduce((accum, item) => accum + item.total, 0);
      setEmployeeByStatus(data);
      setTotalEmployee(total1);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (handleApiButtonClick === true) {
      getEmployeeByStausList();
    }
  }, [branch]);

  const getEmployeePerBranchList = async () => {
    setLoading(true);
    try {
      const res = await getEmployeePerBranch(company);
      let data = res?.data?.data.data_branch
        ? res.data.data.data_branch.map((datum) => ({
            color: getRandomColor(),
            total: datum.total_per_branch,
            percent: Math.round(datum.percentage) + "%",
            name: datum.branch_name,
          }))
        : [];
      setEmployeePerBranch(data);
      setTotalEmployeePerbranch(res?.data?.data.total_employee);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (handleApiButtonClick === true) {
      getEmployeePerBranchList();
    }
  }, [branch]);

  const getGenderDiversityList = async () => {
    setLoading(true);
    try {
      const res = await getGenderDiversity(company);
      const male = res?.data?.data.data_gender.find(
        (datum) => datum.gender === "MALE"
      );
      const female = res?.data?.data.data_gender.find(
        (datum) => datum.gender === "FEMALE"
      );

      const dataGender = {
        labels: ["Male", "Female"],
        datasets: [
          {
            label: "# of Votes",
            data: [male.total_per_gender, female.total_per_gender],
            backgroundColor: ["#297fd4", "#e55959"],
            borderWidth: 1,
          },
        ],
      };
      setGenderDiversity(dataGender);
      setTotalEmployeeGender(res?.data?.data.total_employee);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (handleApiButtonClick === true) {
      getGenderDiversityList();
    }
  }, [branch]);

  const handleApiButtonClick = () => {
    getEmployeeExpiredList();
    getGenderDiversityList();
    getEmployeePerBranchList();
    getEmployeeByStausList();
  };

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "NIK",
      dataIndex: "employee_number",
      key: "employee_number",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Branch",
      dataIndex: "branch_name",
      key: "branch_name",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Employee Name",
      dataIndex: "full_name",
      key: "full_name",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Phone No",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Position",
      dataIndex: "position_name",
      key: "position_name",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Join Date",
      dataIndex: "join_date",
      key: "join_date",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: "25px",
      render: (_,record,index) => {
        // console.log(record)
        return RoleAccessEmployeeUpdate ? (
            <Row justify={"space-between"}>
              <div className="icon-action" onClick={() => setEditEmployee(record)}>
                <EditFilled />
              </div>
            </Row>
          ) : (
            <Row justify={"space-between"}>
              <Tooltip title="No Access">
                <StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
              </Tooltip>
            </Row>
          )
      }
    },
  ];

  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return employeeExpired?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }

  const onChangeTable = (pageNumber, type) => {
    setPage(pageNumber);
    setOffset(pageNumber*limit- 10);
  };

  const percentNumber = (data, total) => {
    if (total > 0) {
      const jumlah = Math.round((data * 100) / total) + "%";
      return jumlah;
    }
    return "-";
  };

  const getListMountAttendance = async () => {
    try {
      const res = await getListmountAttendance(startDate1, endDate, company);
      // console.log("apakah ada list atendance", res?.data?.data);
      setDataAttendaceMount(res?.data.data[0]);
    } catch (err) {
      console.log(err);
    }
  };
  const getListMountAttendanceBar = async () => {
    try {
      const res = await getListmountAttendanceGrafik(
        startDate,
        endDate,
        company
      );
      // console.log("apakah ada list atendance", res?.data?.data);
      setDataAttendaceMountBar(res?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      getListMountAttendanceBar();
      getListMountAttendance();
    }
  }, [startDate, endDate, company]);

  const labels = dataAttendanceMountBar?.data?.map(
    (data) => data.attendance_date
  );
  const totalAbsentData = dataAttendanceMountBar?.data?.map(
    (data) => data.total_absent
  );

  const DummyBarChartData = {
    labels: labels || [],
    datasets: [
      {
        label: "Total Present",
        data: totalAbsentData || [],
        backgroundColor: "#185ADB",
      },
    ],
  };

  const options = {
    scales: {
      y: {
        type: "linear",
        beginAtZero: true,
      },
    },
  };

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const start = dates[0].startOf("day").format("YYYY-MM-DD");
      const end = dates[1].endOf("day").format("YYYY-MM-DD");
      setStartDate(start);
      setEndDate(end);
    } else {
      sessionStorage.removeItem("start_date");
      sessionStorage.removeItem("end_date");
      sessionStorage.removeItem("picker_selected");
      setStartDate(null);
      setEndDate(null);
      setStartDate1(null);
      setEndDate1(null);
    }
    setSelectedDateRange(dates);
  };

  const handleDownload = () => {
    window.location.href = `${process.env.REACT_APP_HRIS_URL}employee-almost-end-report?companyCode=${company}&branchCode=${branch}`;
  };
  const RoleAccessEmployeeUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeEmployeeUpdate'));

  const [editEmployee, setEditEmployee] = useState(null)
  const [editEmployeeBulk, setEditEmployeeBulk] = useState(false)
  const closeEditEmployee = (e, reload = false) => {
    setEditEmployee(null)
    if(reload) {
      setPage(1);
      setOffset(0);
      getEmployeeExpiredList();
    }
  }
  const closeBulkEditEmployee = (e, reload = false) => {
    setEditEmployeeBulk(false)
    if(reload) {
      setPage(1);
      setOffset(0);
      getEmployeeExpiredList();
    }
  }
  return (
    <>
      {shouldDisplayContent && (
        <>
          <div className="border bg-white p-6 rounded-lg mt-2 shadow-lg">
            <Row justify={"end"}>
              <Col style={{ marginBottom: 20 }}>
                <Button
                  className="btn-sh-p"
                  size="large"
                  loading={loading}
                  type="primary"
                  shape="circle"
                  icon={<ReloadOutlined />}
                  onClick={handleApiButtonClick}
                />
              </Col>
            </Row>
            <Row className="flex flex-col md:flex-row gap-2 justify-center md:justify-between">
              <Col xs={8} md={7} xl={7} className=" w-80 md:w-0">
                <div className="card-dashboard-wp">
                  <div className="title-card-dashboard">Employement Status</div>
                  <BarChart data={employeeByStatus} />
                  <div
                    style={{
                      marginTop: "24px",
                      width: "100%",
                      overflow: "auto",
                      height: "200px",
                    }}>
                    <div className="content-wp">
                      <div className="title-wp">
                        <div className="title-content">Total</div>
                      </div>
                      <div className="number-content">{totalEmployee}</div>
                      <div className="number-content">
                        {totalEmployee > 0
                          ? percentNumber(totalEmployee, totalEmployeeGender)
                          : "-"}
                      </div>
                    </div>
                    {employeeByStatus.map((data) => {
                      return (
                        <div className="content-wp">
                          <div className="title-wp">
                            <div
                              className="color-pic"
                              style={{
                                backgroundColor: `${data.color}`,
                              }}></div>
                            <div className="title-content">{data.name}</div>
                          </div>
                          <div className="number-content">{data.total}</div>
                          <div className="number-content">{data.percent}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>
              <Col xs={8} md={7} xl={7}>
                <div className="card-dashboard-wp">
                  <div className="title-card-dashboard">
                    Employee Per Branch
                  </div>
                  <BarChart data={employeePerBranch} />
                  <div
                    style={{
                      marginTop: "24px",
                      width: "100%",
                      overflow: "auto",
                      height: "200px",
                    }}>
                    <div className="content-wp">
                      <div className="title-wp">
                        <div className="title-content">Total</div>
                      </div>
                      <div className="number-content">
                        {totalEmployeePerBranch}
                      </div>
                      <div className="number-content">
                        {totalEmployeePerBranch > 0 ? "100%" : "-"}
                      </div>
                    </div>
                    {employeePerBranch.map((data) => {
                      return (
                        <div className="content-wp">
                          <div className="title-wp">
                            <div
                              className="color-pic"
                              style={{
                                backgroundColor: `${data.color}`,
                              }}></div>
                            <div className="title-content">{data.name}</div>
                          </div>
                          <div className="number-content">{data.total}</div>
                          <div className="number-content">{data.percent}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Col>
              <Col xs={8} md={7} xl={7}>
                <div className="card-dashboard-wp">
                  <div className="title-card-dashboard">Gender Diversity</div>
                  <Doughnut data={genderDiversity} />
                  <div
                    style={{
                      marginTop: "24px",
                      width: "100%",
                      overflow: "auto",
                      height: "200px",
                    }}>
                    <div className="content-wp">
                      <div className="title-wp">
                        <div className="title-content">Total</div>
                      </div>
                      <div className="number-content">
                        {totalEmployeeGender}
                      </div>
                      <div className="number-content">
                        {totalEmployeeGender > 0 ? "100%" : "-"}
                      </div>
                    </div>
                    <div className="content-wp">
                      <div className="title-wp">
                        <div
                          className="color-pic"
                          style={{
                            backgroundColor: `${genderDiversity?.datasets[0]?.backgroundColor[0]}`,
                          }}></div>
                        <div className="title-content">
                          {genderDiversity?.labels[0]}
                        </div>
                      </div>
                      <div className="number-content">
                        {genderDiversity?.datasets[0]?.data[0]}
                      </div>
                      <div className="number-content">
                        {percentNumber(
                          genderDiversity?.datasets[0]?.data[0],
                          totalEmployeeGender
                        )}
                      </div>
                    </div>
                    <div className="content-wp">
                      <div className="title-wp">
                        <div
                          className="color-pic"
                          style={{
                            backgroundColor: `${genderDiversity?.datasets[0]?.backgroundColor[1]}`,
                          }}></div>
                        <div className="title-content">
                          {genderDiversity?.labels[1]}
                        </div>
                      </div>
                      <div className="number-content">
                        {genderDiversity?.datasets[0]?.data[1]}
                      </div>
                      <div className="number-content">
                        {percentNumber(
                          genderDiversity?.datasets[0]?.data[1],
                          totalEmployeeGender
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="border bg-white p-6 rounded-lg mt-2 shadow-lg">
            <div className="title-card-attendance">Attendance</div>
            <div className="border-dashed border-2 border-sky-500 mt-3 bg-white p-6 rounded-lg shadow-lg overflow-auto">
              <div className="border-dashed border-2 border-sky-500 bg-white p-6 rounded-lg shadow-lg overflow-auto">
                <p className="text-sm font-bold text-zinc-700 text-center">
                  {currentDate}
                </p>
                <div>
                  <div className="border-dashed border-2 border-sky-500 bg-white p-2 md:p-6 rounded-lg shadow-lg">
                    <div className="flex flex-col justify-between gap-0 md:gap-6 md:flex-row text-center md:text-start">
                      <div>
                        <p className=" text-sm font-bold text-zinc-700  ">
                          Total Employee
                        </p>
                        <p className="text-sm">
                          {dataAttendanceMount?.total_active_employees}
                        </p>
                      </div>
                      <div>
                        <p className=" text-sm font-bold text-zinc-700  ">
                          Present
                        </p>
                        <p className="text-sm">
                          {dataAttendanceMount?.total_present}
                        </p>
                      </div>
                      <div>
                        <p className=" text-sm font-bold text-zinc-700  ">
                          Absent
                        </p>
                        <p className="text-sm">
                          {dataAttendanceMount?.no_absent}
                        </p>
                      </div>
                      <div>
                        <p className=" text-sm font-bold text-zinc-700  ">
                          Time Off
                        </p>
                        <p className="text-sm">
                          {dataAttendanceMount?.total_time_off}
                        </p>
                      </div>
                      <div>
                        <p className=" text-sm font-bold text-zinc-700  ">
                          Day Off
                        </p>
                        <p className="text-sm">
                          {dataAttendanceMount?.total_day_off}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Row className="border-dashed border-2 border-sky-500 bg-white p-6 rounded-lg shadow-lg mt-3 hidden md:block">
                {/* <div className="border-dashed border-2 border-sky-500 rounded p-2 ">
                  <p className="text-sm font-bold text-zinc-700 text-center">
                    Total Employee : {dataAttendanceMount?.total_present}
                  </p>
                </div> */}
                <Col span={24}>
                  <div className="text-sm mt-5 font-bold text-zinc-700 text-start">
                    <RangePicker size="large" onChange={handleDateChange} />
                  </div>
                  <div className="mt-7">
                    <Bar
                      className="flex justify-center"
                      data={DummyBarChartData}
                      options={options}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </>
      )}

      {shouldDisplayContent && (
        <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
          <div className="table-wrapper" style={{ marginTop: "50px" }}>
            <div className="title-table">
              List of Employees Whose Contracts Will Expired
            </div>
            <div className="flex justify-end mb-2">
              <Button size={"large"} type="primary" onClick={handleDownload}>
                Download
              </Button>
              <Button className={"ml-2"} size={"large"} type="primary" onClick={() => setEditEmployeeBulk(true)}>
                Update
              </Button>
            </div>
            <Table
              dataSource={dataTableWithNumber()}
              columns={columns}
              loading={false}
            />
            <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
              <Pagination
                defaultCurrent={page}
                current={page}
                total={totalData}
                onChange={onChangeTable}
                showSizeChanger={false}
              />
            </div>
          </div>
          {editEmployee &&
            <EditExpiredEmployee editEmployee={editEmployee} closeModal={closeEditEmployee} />
          }
          {editEmployeeBulk &&
            <BulkUpdateExpiredEmployee openModal={editEmployeeBulk} closeModal={closeBulkEditEmployee} />
          }
        </div>
      )}
    </>
  );
}
