import { Button, Col, message, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getLevelDetail } from "../../../api/level";

const LevelDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [loading, setLoading] = useState(false);
	const [detail, setDetail] = useState([]);
    const BestpraticeUseEffect = useRef(false)
    const levelList = async () => {
		setLoading(true);
		try {
		  const res = await getLevelDetail(id)
		  setDetail(res?.data?.data);
		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            levelList();
          }
	}, []);

    const dataBreadcrumb = [
        {
			title: "Master Data - Level",
			url: "/level"
		},
        {
			title: "Level Detail",
			url: `/level/detail/${result}`
		},
	];
    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/level')} icon={<ArrowLeftOutlined />} size={"large"} />
				Level Detail
			</div>
           <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Level Name</div>
                            <div className="subtitle">{detail?.name}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Sequence</div>
                            <div className="subtitle">{detail?.sequence}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default LevelDetail