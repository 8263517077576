import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";


const getPayrollComponentMaster = async (
    company,
    page,
    offset,
    limit,
    search_value,
) => {
    return master.get(`/payroll-component`,{
        params: {
            company_id: company,
            start : offset,
            length : limit,
            draw : page,
            search_by: 'name', // not required, only for filtering
            search_value // not required, only for filtering
        }
    })
}

const getPayrollComponentMasterDetail = async (id) => {
    return master.get(`/payroll-component/${id}`)
}

const getComponentCode = async (params) => {
    return master.get(`payroll-component/generate-code`, {
        params: params,
    })
}

const getPayrollComponentCustomSalary = async (
    company,
    page,
    offset,
    limit,
    search_value,
) => {
    return master.get(`/custom-salary-item`,{
        params: {
            company_id: company,
            start : offset,
            length : limit,
            draw : page,
            search_by: 'name', // not required, only for filtering
            search_value // not required, only for filtering
        }
    })
}

const getListLevel = async (companyCode) => {
    let payload = {
        companyCode: companyCode,
    }
    return master.post("/list-level-input", payload);
}

const getListPosition = async (companyCode) => {
    let payload = {
        companyCode: companyCode,
    }
    return master.post("/list-position-input", payload);
}

const getBaseCalc = async (companyId, useWages) => {
    return master.get(`/payroll-component/base-calculation?company_id=${companyId}&use_wages=${useWages}`)
}

const getWagesSetting = async (companyId) => {
    return master.get(`/wages-setting?company_id=${companyId}`)
}

const postWagesSetting = async(payload) => {
    return master.post('/wages-setting', payload, {
        headers :{
            "Content-Type" : "application/json"    
          }
    })
}

const editPayrollComponent = async (payload, id) => {
    return master.post(`/payroll-component/${id}`, payload, {
        headers :{
            "Content-Type" : "application/json"    
          }
    })
}

const addPayrollComponent = async (payload) => {
    return master.post('/payroll-component', payload, {
        headers :{
            "Content-Type" : "application/json"    
          }
    })
}

const deletePayrollComponent = async (payload) => {
    return master.delete(`/payroll-component/delete/${payload}`)
}


export {
    getPayrollComponentMaster,
    getPayrollComponentMasterDetail,
    getComponentCode,
    getListLevel,
    getListPosition,
    getBaseCalc,
    editPayrollComponent,
    addPayrollComponent,
    deletePayrollComponent,
    getPayrollComponentCustomSalary,
    getWagesSetting,
    postWagesSetting,
}