import { Col, Row } from "antd"
import React from "react"
import { formatCurrency } from "../../../helper/formatCurrency"

const PayrollDetail = ({data}) => {
    const mappingFieldBpjs = {
        JKK: "Jaminan Kecelakaan Kerja",
        JHT: "Jaminan Hari Tua",
        JP: "Jaminan Pensiun",
        JKM: "Jaminan Kematian"
    };
      
    return (
        <div className="detail-wrapper">
            <Row>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Basic Salary</div>
                        <div className="subtitle">{formatCurrency(data.basic_salary || 0)}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Salary Type</div>
                        <div className="subtitle">{data.salary_type ? (data.salary_type.charAt(0).toUpperCase() + data.salary_type.slice(1)) : "-" || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Payment Schedule</div>
                        <div className="subtitle">{data.payment_schedule ? (data.payment_schedule.charAt(0).toUpperCase() + data.payment_schedule.slice(1)) : "-" || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Bank Name</div>
                        <div className="subtitle">{data.bank_name || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Account Number</div>
                        <div className="subtitle">{data.account_number || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Account Name</div>
                        <div className="subtitle">{data.account_name || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Prorate Setting</div>
                        <div className="subtitle">{data.prorate_setting || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Allowed for Overtime</div>
                        <div className="subtitle">{data.allowed_overtime ? 'Yes' : 'No' || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Overtime Working Day</div>
                        <div className="subtitle">{data.overtime_working_day || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Overtime Day Off</div>
                        <div className="subtitle">{data.overtime_dayoff || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Overtime National Holiday</div>
                        <div className="subtitle">{data.overtime_national_holiday || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">NPWP</div>
                        <div className="subtitle">{data.taxpayer_identification_number || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">PTKP Status</div>
                        <div className="subtitle">{data.ptkp_status || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Tax Method</div>
                        <div className="subtitle">{data.tax_method || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Taxable Date</div>
                        <div className="subtitle">{data.taxable_date || "-"}</div>
                    </div>
                </Col>
                
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Employee Tax Status</div>
                        <div className="subtitle">{data.employee_tax_status || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Beginning Netto</div>
                        <div className="subtitle">{data.begining_netto || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">PPh 21 Paid</div>
                        <div className="subtitle">{formatCurrency(data.pph21_paid) || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Tax Salary</div>
                        <div className="subtitle">{data.tax_salary || "-"}</div>
                    </div>
                </Col>
                </Row>
                <div className="text-slate-500 text-[16px] mt-4">
                Allowance
                </div>
                <div className="border-solid border-1 w-[80px]"></div>
                <Row className="mb-4">
                {data.allowance_data && data.allowance_data.length > 0 ? (
                    data.allowance_data.map((allowance, index) => (
                    <Col span={12} key={index}>
                        <div className="detail-card">
                        <div className="title">{allowance.name}</div>
                      <div className="subtitle">{allowance.amount === 0 ? 'Rp 0' : formatCurrency(allowance.amount || "-")}</div>
                        </div>
                    </Col>
                    ))
                ) : (
                    <Col span={24}>
                    <div className="detail-card">
                        <div className="title">No Allowance Data</div>
                    </div>
                    </Col>
                )}
                </Row>

                <div className="text-slate-500 text-[16px]">
                BPJS Configuration
                </div>
                <div className="border-solid border-1 w-[140px]"></div>
                <Row>
                {data?.insurance === 1 && data?.bpjsKesehatan === 1 ? (
                    <>
                    <Col span={12}>
                <div className="detail-card">
                    <div className="title">BPJS Kesehatan</div>
                    <div className="subtitle">{data.bpjsKesehatan === 1 ? 'Yes' : 'No' || "-"}</div>
                </div>
                </Col>
                <Col span={12}></Col>
                
                    </>
                ):null}
                {data?.insurance === 2 ? (
                <>
                <Col span={12}>
                <div className="detail-card">
                    <div className="title">BPJS Kesehatan</div>
                    <div className="subtitle">{data.bpjsKesehatan === 1 ? 'Yes' : 'No' || "-"}</div>
                </div>
                </Col>
                <Col span={12}></Col>
                </>
                ):null}

                {data?.bpjsKesehatan === 2 ? (
                    <>
                    
                        <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Insurance</div>
                            <div className="subtitle">{data.insurance === 1 ? 'Yes' : data?.insurance === 2 ?  'No' : "-" || "-"}</div>
                        </div>
                        </Col>
                        <Col span={12}></Col>
                    </>
                ):null}
                
                {data?.insurance === 1 ? (
                <>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Insurance Name</div>
                        <div className="subtitle">{data.insurance_name || "-"}</div>
                    </div>
                </Col>
                <Col span={12}></Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Insurance Number</div>
                        <div className="subtitle">{data.insurance_number || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Insurance Date</div>
                            <div className="subtitle">{data.insurance_date || "-"}</div>
                        </div>
                        </Col>
               
              
                </>
                ):null}
                {data?.bpjsKesehatan === 1 ? (
                    <>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">BPJS Kesehatan Number</div>
                        <div className="subtitle">{data.bpjskes_number || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">BPJS Kesehatan Date</div>
                        <div className="subtitle">{data.bpjskes_date || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">BPJS Kesehatan Cost</div>
                        <div className="subtitle">{data.bpjskes_type || "-"}</div>
                    </div>
                </Col>
                <Col span={18}>
                    <div className="detail-card">
                        <div className="title">BPJS Kesehatan Family</div>
                        <div className="subtitle">{data.bpjskes_family || "0"}</div>
                    </div>
                </Col>
                </>
                    ):null}
                
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">BPJS Ketenagakerjaan Type</div>
                        <div className="subtitle">{data.bpjstk_type || "-"}</div>
                    </div>
                </Col>
                 <Col span={12}></Col>
                {data?.bpjstk_type != "No" ? (
                    <>
                   
             <Col span={12}>
                 <div className="detail-card">
                     <div className="title">BPJS Ketenagakerjaan Number</div>
                     <div className="subtitle">{data.bpjstk_number || "-"}</div>
                 </div>
             </Col>
                 <Col span={12}>
                 <div className="detail-card">
                     <div className="title">BPJS Ketenagakerjaan Date</div>
                     <div className="subtitle">{data.bpjstk_date || "-"}</div>
                 </div>
             </Col>
             </>
                ): null}
               
                
                </Row>
                {data?.bpjstk_type === "PU" ? (
                    <>
                <Row>
                <Col span={12} >
                <div className="detail-card">
                    <div className="title">BPJS Ketenagakerjaan Item</div>   
                    {Object.entries(data?.mapping_field_bpjs || {}).map(([key, value], index) => (
                        <div className={`subtitle ${key === "JP" || key === "JKK" || key === "JKM" && index !== 0 ? "mt-0" : ""}`} key={value}>{value ? mappingFieldBpjs[key] : null}</div>
                    ))}
                </div>
                </Col>
                </Row>
                <Row className="mb-[50px]">
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">JHT Cost Type</div>
                        <div className="subtitle">{data?.jht_type || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">JP Cost Type</div>
                        <div className="subtitle">{data?.pension_cost || "-"}</div>
                    </div>
                </Col>
                </Row>
                </>
                ):null}
               
                <Row className={data?.bpjstk_type !== "PU" ? "mt-0" : "mt-[-50px]"}>
                {data?.component_overtime === 1 ? (
                    <>
                     <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Tunjangan Jabatan</div>
                        <div className="subtitle">{formatCurrency(data?.tunjangan_jabatan) || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Tunjangan Kehadiran</div>
                        <div className="subtitle">{formatCurrency(data?.tunjangan_kehadiran )|| "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Tunjangan Profesi</div>
                        <div className="subtitle">{formatCurrency(data?.tunjangan_profesi) || "-"}</div>
                    </div>
                </Col>
                <Col span={12}>
                    <div className="detail-card">
                        <div className="title">Tunjangan Makan</div>
                        <div className="subtitle">{formatCurrency(data?.tunjangan_makan) || "-"}</div>
                    </div>
                </Col>
                    </>
                ):null}
            </Row>
        </div>
    )
}

export default PayrollDetail