export default function clock({ size = 28, fill = "black" }) {
  return (
    <div>
      <svg
        width={size}
        height={size}
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.9997 23.333C16.475 23.333 18.849 22.3497 20.5993 20.5993C22.3497 18.849 23.333 16.475 23.333 13.9997C23.333 11.5243 22.3497 9.15035 20.5993 7.40001C18.849 5.64967 16.475 4.66634 13.9997 4.66634C11.5243 4.66634 9.15035 5.64967 7.40001 7.40001C5.64967 9.15035 4.66634 11.5243 4.66634 13.9997C4.66634 16.475 5.64967 18.849 7.40001 20.5993C9.15035 22.3497 11.5243 23.333 13.9997 23.333ZM13.9997 2.33301C15.5318 2.33301 17.0489 2.63478 18.4643 3.22108C19.8798 3.80738 21.1659 4.66675 22.2493 5.7501C23.3326 6.83345 24.192 8.11957 24.7783 9.53503C25.3646 10.9505 25.6663 12.4676 25.6663 13.9997C25.6663 17.0939 24.4372 20.0613 22.2493 22.2493C20.0613 24.4372 17.0939 25.6663 13.9997 25.6663C7.54801 25.6663 2.33301 20.4163 2.33301 13.9997C2.33301 10.9055 3.56217 7.93802 5.7501 5.7501C7.93802 3.56217 10.9055 2.33301 13.9997 2.33301ZM14.583 8.16634V14.2913L19.833 17.4063L18.958 18.8413L12.833 15.1663V8.16634H14.583Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}
