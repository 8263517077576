// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type=date]::-webkit-datetime-edit-text,
input[type=date]::-webkit-inner-spin-button,
input[type=date]:before {
  content: attr(placeholder) !important;
  margin-right: 0.5em;
}

input[type=date]::placeholder {
  /* Set the placeholder content to "dd/mm" */
  content: "dd/mm";
}`, "",{"version":3,"sources":["webpack://./src/views/Leave/styles.scss"],"names":[],"mappings":"AAAA;;;EAaE,qCAAA;EAEA,mBAAA;AAVF;;AAcA;EACI,2CAAA;EACA,gBAAA;AAXJ","sourcesContent":["input[type=\"date\"]::-webkit-datetime-edit-text,\ninput[type=\"date\"]::-webkit-inner-spin-button,\n// input[type=\"date\"]::-webkit-calendar-picker-indicator {\n//   display: none;\n// }\n\n// input[type=\"date\"] {\n//     color: transparent;\n// }\n\n/* Menampilkan teks di atas input */\ninput[type=\"date\"]:before {\n\n  content: attr(placeholder) !important;\n//   color: #555; /* Warna teks placeholder */\n  margin-right: 0.5em;\n}\n\n//remove placehoder dari dd/mm/yyyy to dd/mm\ninput[type=\"date\"]::placeholder {\n    /* Set the placeholder content to \"dd/mm\" */\n    content: \"dd/mm\";\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
