/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row,Timeline } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { decryptData } from "../../../helper/cryptojs";
import { getLeaveDetailTransaction } from "../../../api/leaveTransaction";
import moment from "moment";

const LeaveTransactionDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    let n = location.lastIndexOf('/');
    let result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    let dec = decode.lastIndexOf('?');
    let id = decode.substring(dec + 1);
    const BestpraticeUseEffect = useRef(false)
    const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const[detail,setDetail] = useState([])

    const getListLeaveDetailTransaction = async () => {
        try {
          const res = await getLeaveDetailTransaction(
            id,company
          );
          setDetail(res?.data?.data);

        } catch (err) {
          console.log(err);
        }
      };

	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            getListLeaveDetailTransaction()
          }
	}, [company]);

    const dataBreadcrumb = [
        {
			title: "Attendance Management - Leave Transaction",
			url: "/leave-transaction"
		},
        {
			title: "Leave Transaction Detail",
			url: `/leave-transaction/detail/${result}`
		},
	];

    const Duration = detail?.data?.days_submitted !== null
    ? `${detail?.data?.days_submitted} ${detail?.data?.days_submitted === "1" ? 'Day' : 'Days'}`
    : '-';
    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/leave-transaction')} icon={<ArrowLeftOutlined />} size={"large"} />
				Leave Transaction Detail
			</div>
           <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Employee Name</div>
                            <div className="subtitle">{`${detail?.data?.full_name} - ${detail?.data?.employee_number}` || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Branch</div>
                            <div className="subtitle">{detail?.data?.branch_name || "-" }</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Position</div>
                            <div className="subtitle">{detail?.data?.position_name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Department</div>
                            <div className="subtitle">{detail?.data?.department_name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Division</div>
                            <div className="subtitle">{detail?.data?.division_name || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Request Date</div>
                            <div className="subtitle">{detail?.data?.created_at || "-"}</div>
                        </div>
                    </Col>
                    
                   
                </Row>
               
                <div className="border-dashed border-2 border-sky-500 w-auto bg-white p-6 rounded-lg shadow-lg mt-3">
                    <div className="text-lg font-semibold underline">
                    Request Detail
                    </div> 
                    <Row>

                   
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Leave Type</div>
                            <div className="subtitle">{detail?.data?.leave_type || "-"}</div>
                        </div>
                    </Col>
                 
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Regulation</div>
                            <div className="subtitle">{detail?.data?.regulation || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Start Date</div>
                            <div className="subtitle">
                            {detail?.data?.date_from || '-'}
                            </div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">End Date</div>
                            <div className="subtitle">
                            {detail?.data?.date_to || '-'}
                            </div>
                        </div>
                    </Col>
                   
                    
                     <Col span={12}>
                        <div className="detail-card">
                            <div className="title"> Duration</div>
                            <div className="subtitle">{Duration || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Allowance</div>
                            <div className="subtitle">{detail?.data?.allowance ? "Yes" : "No" }</div>
                        </div>
                    </Col>
                    <Col span={12}>
                <div className="detail-card">
                  <div className="subtitle"><Col span={23}>
                    <div className="detail-card">
                    <div className="title">Description</div>
                    <div className="subtitle flex">
                    <span className="block overflow-x-auto" style={{ wordWrap: 'break-word', width:'100%' }}>
                    {detail?.data?.reason || "-"}
                    </span>
                    </div>
                    </div>
                    </Col></div>
                </div>
              </Col>
              <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Request Type</div>
                            <div className="subtitle">{detail?.data?.leave_time}</div>
                        </div>
                    </Col>
                   
                  
                    <Col span={12}>
                              <div className="detail-card overflow-x-auto">
                                <div className="title">Attachment</div>
                                <div className="text-sm text-gray-500 flex items-center">
                                  <div>
                                    <svg
                                      className="w-4 h-4 mr-1 fill-current"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20">
                                      <path d="M4 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1H2V6zM9 12v5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z"></path>
                                    </svg>
                                  </div>
                                
                                    {detail?.data?.document_url ? (
                                  <Link
                                    to={process.env.REACT_APP_HRIS_URL + `leaves-transaction/file/${detail?.data?.document_url}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="block overflow-x-auto text-gray-500" style={{ wordWrap: 'break-word', width:'100%' }}
                                  >
                                    {detail?.data?.document_url
                                      .replace(
                                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/attendancerequest/Phase02/`,
                                        ""
                                      )
                                      .replace(
                                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/attendancerequest/`,
                                        ""
                                      )
                                      .replace(
                                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/storage/attendancerequest/Phase 02/`,
                                        ""
                                      )}
                                  </Link>
                                ) : (
                                  <span key={detail?.data?.change_shift_id} className="text-gray-500">
                                    No File
                                  </span>
                                )}
                                  
                                  
                                
                                </div>
                              </div>
                            </Col>
                            {detail?.data?.status  == "REJECTED"  && (
                        <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Reason</div>
                            <div className="subtitle">{detail?.detail_status?.reject_reason|| "-"}</div>
                        </div>
                        </Col>                    
                    )}
                      <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Status</div>
                            <div className="subtitle">
                            {detail?.data?.status === "WAITING" ? "PENDING" : detail?.data?.status}
                            </div>
                        </div>
                    </Col>
                    <Col span={24}>
                <div className="detail-card">
                  <div className="title">Status Detail</div>
                  <div className="subtitle mt-5">
                  <Timeline
                        items={detail?.detail_status?.tracking_data?.map((item) => ({
                          children: (
                            <div
                            dangerouslySetInnerHTML={{
                              __html: ` ${item.web_wording}  <br />${item.submitted_date ? item.submitted_date : "-"}`,
                            }}
                            />
                          ),
                        })).concat([{
                          children: (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: ` ${
                                " Time of requested"
                                }  <br />${detail?.request_datetime ? detail?.request_datetime : "-"}`
                              }}
                            />
                          ),
                        }])}
                      />
                  </div>
                </div>
              </Col>
                    
                    </Row>
                </div>
            </div>
        </>
    )
}

export default LeaveTransactionDetail