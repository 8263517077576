/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, message,  Row, Timeline } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getChangeRequestDetail, getTimeRequestDetail } from "../../../api/timeRequest";
import "./../styles.scss"
import { decryptData } from "../../../helper/cryptojs";
import moment from "moment";
const TimeRequestDetail = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  let n = location.lastIndexOf("/");
  let result = location.substring(n + 1);
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
	const filterValue  = decryptData(sessionStorage.getItem("FilterRequestDetail"))
  const [detail, setDetail] = useState([]);
  const [detailShift, setDetailShift] = useState([]);
  const BestpraticeUseEffect = useRef(false);

  const overtimeTransactionList = async () => {
    try {
      const res = await getTimeRequestDetail(id);
      setDetail(res?.data?.data);
    } catch (err) {
      message.error({
        content: "Request Not Found",
      });
      console.log(err);
    }
  };
  const getDetailChangeShift = async () => {
    try {
      const res = await getChangeRequestDetail(id);
      setDetailShift(res?.data?.data);
    } catch (err) {
      message.error({
        content: "Request Not Found",
      });
      console.log(err);
    }
  };


  useEffect(() => {
		if (!BestpraticeUseEffect.current) {
			BestpraticeUseEffect.current = true;
		} else {
			if (filterValue === "attendance") {
        overtimeTransactionList();
      }else {
				getDetailChangeShift();
			}
		}
	}, []);

  const dataBreadcrumb = [
    {
      title: "Attendance Management - Time Request",
      url: "/time-request",
    },
    {
      title: filterValue === "attendance" ? "Attendance Request Detail" : "Change Shift Request Detail",
      url: `/time-request/detail/${result}` 
    },
  ];

  return (
    <>
      <div className="title-section">
        <Button
          className="btn-sh-p"
          type="primary"
          shape="circle"
          style={{ marginRight: "14px" }}
          onClick={() => navigate("/time-request")}
          icon={<ArrowLeftOutlined />}
          size={"large"}
        />
       {filterValue === "attendance" ? "Attendance Request Detail" : "Change Shift Request Detail"}
      </div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="detail-wrapper">
        <Row>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Employee Name</div>
              <div className="subtitle">{filterValue === "attendance"  ? detail?.employee_name : detailShift?.data?.full_name || "-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Position</div>
              <div className="subtitle">
              {filterValue === "attendance"  ? detail?.position_name : detailShift?.data?.position_name || "-"}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Department</div>
              <div className="subtitle">
              {filterValue === "attendance"  ? detail?.department_name : detailShift?.data?.department_name || "-"}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Division</div>
              <div className="subtitle">
              {filterValue === "attendance"  ? detail?.division_name : detailShift?.data?.division_name || "-"}
                </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Request Date</div>
              <div className="subtitle">
              {filterValue === "attendance"  ? detail?.request_date : detailShift?.data?.created_at || "-"}
                </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Request Type</div>
              <div className="subtitle">
              {filterValue === "attendance"  ? detail?.request_type : detailShift?.data?.request_type || "-"}
                </div>
            </div>
          </Col>

        </Row>
        <div className="border-dashed border-2 border-sky-500 w-auto bg-white p-6 rounded-lg shadow-lg mt-3">
          <div className="text-lg font-semibold underline">
          Request Detail
          </div>
          <Row>
          <Col span={12}>
                <div className="detail-card">
                  <div className="title">{filterValue === "attendance"  ? "Attendance Date" : "Change Shift Date"}</div>
                  <div className="subtitle">{filterValue === "attendance"  ? detail?.attendance_date : detailShift?.data?.request_date || "-"}</div>
                </div>
              </Col>
             
                
                {filterValue !== "attendance" && (
                <Col span={12}>
                </Col>
                )}

            <Col span={12}>
                <div className="detail-card">
                  <div className="title">{filterValue === "attendance"  ? "Schedule" : "Original Schedule"}</div>
                  <div className="subtitle">{filterValue === "attendance"  ? detail?.schedule?.schedule : `${detailShift?.data?.original_shift_name} (${moment(detailShift?.data?.original_shift_schedule_in, 'HH:mm:ss').format('HH:mm')} - ${moment(detailShift?.data?.original_shift_schedule_out, 'HH:mm:ss').format('HH:mm')})`  || "-"}</div>
                  {detail?.time_off && (
                    <div className="title mt-1">
                      Time Off - {detail?.time_off || "-"}
                    </div>
                  )}
                </div>
              </Col>
              <Col span={12}>
                <div className="detail-card">
                  <div className="title">{filterValue === "attendance"  ? "Check In" : "New Schedule"}</div>
                  <div className="subtitle">{filterValue === "attendance"  ? detail?.check_in : `${detailShift?.data?.request_shift_name} (${moment(detailShift?.data?.request_shift_schedule_in, 'HH:mm:ss').format('HH:mm')} - ${moment(detailShift?.data?.request_shift_schedule_out, 'HH:mm:ss').format('HH:mm')})`  || "-"}</div>
                </div>
              </Col>

            {filterValue !== "attendance" && (
              <>
                <Col span={12}>
                <div className="detail-card">
                  <div className="subtitle"><Col span={23}>
                    <div className="detail-card">
                    <div className="title">Description</div>
                    <div className="subtitle flex">
                    <span className="block overflow-x-auto" style={{ wordWrap: 'break-word', width:'100%' }}>
                    {detailShift?.data?.description == null ? "-" : detailShift?.data?.description || "-"}
                    </span>
                    </div>
                    </div>
                    </Col></div>
                </div>
              </Col>
                  {filterValue !== "attendance" && (
                            <Col span={12}>
                              <div className="detail-card overflow-x-auto">
                                <div className="title">Attachment</div>
                                <div className="text-sm text-gray-500 flex items-center">
                                  <div>
                                    <svg
                                      className="w-4 h-4 mr-1 fill-current"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20">
                                      <path d="M4 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1H2V6zM9 12v5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z"></path>
                                    </svg>
                                  </div>
                                  {filterValue === "attendance" ? (
                                    <>
                                    {detail?.attachment_url ? (
                                  <Link
                                    to={detail?.attachment_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="block overflow-x-auto text-gray-500" style={{ wordWrap: 'break-word', width:'100%' }}
                                  >
                                    {detail?.attachment_url
                                      .replace(
                                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/attendancerequest/Phase02/`,
                                        ""
                                      )
                                      .replace(
                                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/attendancerequest/`,
                                        ""
                                      )
                                      .replace(
                                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/storage/attendancerequest/Phase 02/`,
                                        ""
                                      )}
                                  </Link>
                                ) : (
                                  <span key={detail?.id} className="text-gray-500">
                                    No File
                                  </span>
                                )}
                                    </>
                                  ):(
                                    <>
                                    {detailShift?.data?.attachment ? (
                                  <Link
                                    to={detailShift?.data?.attachment}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="block overflow-x-auto text-gray-500" style={{ wordWrap: 'break-word', width:'100%' }}
                                  >
                                    {detailShift?.data?.attachment
                                      .replace(
                                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/attendancerequest/Phase02/`,
                                        ""
                                      )
                                      .replace(
                                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/attendancerequest/`,
                                        ""
                                      )
                                      .replace(
                                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/storage/attendancerequest/Phase 02/`,
                                        ""
                                      )}
                                  </Link>
                                ) : (
                                  <span key={detailShift?.data?.change_shift_id} className="text-gray-500">
                                    No File
                                  </span>
                                )}
                                    </>
                                  )}
                                
                                </div>
                              </div>
                            </Col>
                      )}
                      </>
                        )}
                {filterValue !== "attendance" && (
                <Col span={12}>
                </Col>
                )}
              {filterValue !== "attendance" && (
                <Col span={12}>
                </Col>
                )}

              {filterValue === "attendance" && (
              <Col span={12}>
                <div className="detail-card">
                  <div className="title">Check Out</div>
                  <div className="subtitle">{detail?.check_out || "-"}</div>
                </div>
              </Col>
              )}

            {filterValue === "attendance" && (
              <Col span={12}>
                <div className="detail-card">
                  <div className="subtitle"><Col span={23}>
                    <div className="detail-card">
                    <div className="title">Description</div>
                    <div className="subtitle flex">
                    <span className="block overflow-x-auto" style={{ wordWrap: 'break-word', width:'100%' }}>
                    {detail?.description ?  detail?.description : "-"}
                    </span>
                    </div>
                    </div>
                    </Col></div>
                </div>
              </Col>
            )}
                {/*Attachment  */}
                    {filterValue === "attendance" && (
                            <Col span={12}>
                              <div className="detail-card overflow-x-auto">
                                <div className="title">Attachment</div>
                                <div className="text-sm text-gray-500 flex items-center">
                                  <div>
                                    <svg
                                      className="w-4 h-4 mr-1 fill-current"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20">
                                      <path d="M4 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1H2V6zM9 12v5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z"></path>
                                    </svg>
                                  </div>
                                  {filterValue === "attendance" ? (
                                    <>
                                    {detail?.attachment_url ? (
                                  <Link
                                    to={detail?.attachment_url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="block overflow-x-auto text-gray-500" style={{ wordWrap: 'break-word', width:'100%' }}
                                  >
                                    {detail?.attachment_url
                                      .replace(
                                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/attendancerequest/Phase02/`,
                                        ""
                                      )
                                      .replace(
                                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/attendancerequest/`,
                                        ""
                                      )
                                      .replace(
                                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/storage/attendancerequest/Phase 02/`,
                                        ""
                                      )}
                                  </Link>
                                ) : (
                                  <span key={detail?.id} className="text-gray-500">
                                    No File
                                  </span>
                                )}
                                    </>
                                  ):(
                                    <>
                                    {detailShift?.data?.attachment ? (
                                  <Link
                                    to={detailShift?.data?.attachment}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="block overflow-x-auto text-gray-500" style={{ wordWrap: 'break-word', width:'100%' }}
                                  >
                                    {detailShift?.data?.attachment
                                      .replace(
                                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/attendancerequest/Phase02/`,
                                        ""
                                      )
                                      .replace(
                                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/attendancerequest/`,
                                        ""
                                      )
                                      .replace(
                                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/storage/attendancerequest/Phase 02/`,
                                        ""
                                      )}
                                  </Link>
                                ) : (
                                  <span key={detailShift?.data?.change_shift_id} className="text-gray-500">
                                    No File
                                  </span>
                                )}
                                    </>
                                  )}
                                
                                </div>
                              </div>
                            </Col>
              )}
              
               <Col span={12}>
                <div className="detail-card">
                  <div className="title">Status</div>
                  <div className="subtitle">{filterValue === "attendance" ? detail?.request_status : detailShift?.data?.status || "-"}
                  </div>
                </div>
              </Col>

         
              {detail?.request_status  == "REJECTED"  && (
                        <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Reason</div>
                            <div className="subtitle">{detail?.detail_status?.reject_reason|| "-"}</div>
                        </div>
                        </Col>                    
                    )}
             
              {detailShift?.data?.status  == "REJECTED"  && (
                        <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Reason</div>
                            <div className="subtitle">{detailShift?.detail_status?.reject_reason|| "-"}</div>
                        </div>
                        </Col>                    
                    )}
              <Col span={24}>
                <div className="detail-card">
                  <div className="title">Status Detail</div>
                  <div className="subtitle mt-5">
                    {filterValue === "attendance" ? (
                        <Timeline
                        items={detail?.detail_status?.tracking_data?.map((item) => ({
                          children: (
                            <div
                            dangerouslySetInnerHTML={{
                              __html: ` ${item.web_wording} <br />${item.submitted_date ? item.submitted_date : "-"}`,
                            }}
                            />
                          ),
                        })).concat([{
                          children: (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: ` ${
                                " Time of requested"
                                }  <br />${detail?.request_datetime ? detail?.request_datetime : "-"}`
                              }}
                            />
                          ),
                        }])}
                      />
                    ) : (
                      <Timeline
                        items={detailShift?.detail_status?.tracking_data?.map((item) => ({
                          children: (
                            <div
                            dangerouslySetInnerHTML={{
                              __html: ` ${item.web_wording} <br />${item.submitted_date ? item.submitted_date : "-"}`,
                            }}
                            />
                          ),
                        })).concat([
                          {
                            children: (
                              <div>
                                {detailShift?.request_datetime ? (
                                  <div>
                                    Time of requested
                                    <br />
                                    {detailShift.request_datetime}
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </div>
                            ),
                          },
                        ])}
                      />
                    )}
                
                  </div>
                </div>
              </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default TimeRequestDetail;
