import { AutoComplete, Checkbox, DatePicker, Input, Input as InputAntd, InputNumber, Radio, Select, Tooltip, Space, Flex, Col, Row, Button, Switch } from 'antd';
import { NumericFormat } from 'react-number-format';
import "./styles.scss"
import { InfoCircleOutlined, DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import {Form} from "react-bootstrap";
const { RangePicker } = DatePicker;
const { TextArea } = Input;
export const InputWithLabel = ({label, visibleCheckbox=false, disabledCheckbox=false, checked=false, checkboxFunc, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className="label-input">{label}</div>
            {visibleCheckbox ?
            <Checkbox 
            checked={checked}
            onChange={checkboxFunc}
            disabled={disabledCheckbox}
            style={{marginBottom: '1rem'}}
            >
                Use permanent address as current address
            </Checkbox>
            :
            null}
            <InputAntd {...props} />
        </div>
    )
}
export const InputNumberWithLabel = ({label, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className="label-input">{label}</div>
            <InputNumber {...props} size='large'/>
        </div>
    )
}
export const InputFormula = ({radioProps, selectProps, errorFlag, errorMes}) => {
    return (
        <>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={4}>
            <div className="percent-btn" style={{marginTop: '1rem'}} >
                <Radio.Group  
                    {...radioProps}
                />
            </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={4}>
                <div className="input-wrapper" style={{marginTop: '1rem'}}>
                <Select
                    {...selectProps}
                    placeholder="Select One"
                />
                </div>
                {errorFlag ? 
                (
                    <div style={{marginTop: '1rem'}}>
                        <span className="text-error">{errorMes}</span>
                    </div>
                )
                :
                null}
            </Col>
        </>

    )
}
export const InputFormulaWithButton = ({radioProps, selectProps, showDel, showAdd, deleteAction, addAction, errorFlag}) => {
    return (
        <Row 
        gutter={{ xs: 8, sm: 16, md: 24, lg: 4, xl: 12, xxl: 12}}
        >
            <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={4}>
            <div className="percent-btn" style={{marginTop: '1rem'}} >
                <Radio.Group  
                    {...radioProps}
                />
            </div>
            </Col>
            <Col xs={24} sm={24} md={24} lg={8} xl={6} xxl={4}>
            <div className="input-wrapper" style={{marginTop: '1rem'}}>
                <Select
                    {...selectProps}
                    placeholder="Select One"
                />
                {errorFlag && selectProps.noData ? 
                (
                    <div style={{marginTop: '1rem'}}>
                        <span className="text-error">Field is required</span>
                    </div>
                )
                :
                null}
            </div>
            </Col>
            <>
                {showDel && (
                    <Col xs={12} sm={12} md={12} lg={4} xl={2} xxl={1}>
                        <div style={{
                            marginTop: '1rem',
                            // marginLeft:'1rem'
                        }}>
                            <Button size='large' onClick={deleteAction}>
                                <DeleteOutlined style={{color: '#F02020'}}/>
                            </Button>
                        </div>
                    </Col>
                )}
                {showAdd && (
                    <Col xs={12} sm={12} md={12} lg={4} xl={2} xxl={1}>
                        <div style={{
                            marginTop: '1rem',
                            // marginLeft:'1rem'
                        }}>
                            <Button type='dashed' size='large' onClick={addAction}>
                                {/* <PlusOutlined /> */}
                                Add more
                            </Button>
                        </div>
                    </Col>
                )}
            </>
        </Row>

    )
}
export const InputGroupWithLabel = ({label, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className="label-input">{label}</div>
            <Input.Group {...props}  />
        </div>
    )
}
export const RadioGroupWithLabel = ({label, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className="label-input">{label}</div>
            <Radio.Group {...props}  />
        </div>
    )
}
export const TextAreaWithLabel = ({label, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className="label-input">{label}</div>
            <TextArea  {...props} />
        </div>
    )
}

export const NumberFormat = ({label, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className="label-input">{label}</div>
            <NumericFormat  {...props}
            customInput={Input}
            //thousandSeparator={true} prefix={'$'} 
            />
        </div>
    )
}

export const DatePickerWithLabel = ({label, value, placeholderDate, classsNameLabel,...props}) => {
    return (
        <div className={`input-wrapper ${classsNameLabel}`}>
            <div className="label-input">{label}</div>
            <DatePicker {...props}  />
        </div>
    )
}
export const RangeDatePickerWithLabel = ({label, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className="label-input">{label}</div>
            <RangePicker  {...props} />
        </div>
    )
}

export const TimeWithLabel = ({label,value,placeholderTime, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className="label-input">{label}</div>
            <div style={{position: "relative"}}>
                <Form.Control
                    type="time"
                    value={value}
                    {...props}
                />
                {value?.length <= 0 && (
                    <div className='placeholder-date'>{placeholderTime}</div>
                )}
            </div>
            
        </div>
    )
}

export const DatePicker3WithLabel = ({label, value, placeholderDate, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className="label-input">{label}</div>
            <DatePicker {...props} />
        </div>
    )
}

export const DatePicker2WithLabel = ({label, value, placeholderDate, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className="label-input">{label}</div>
            <div style={{position: "relative"}}>
                <Form.Control
                    type="date"
                    format={"DD-MM"}
                    value={value}
                    {...props}
                />
                {value?.length <= 0 && (
                    <div className='placeholder-date'>{placeholderDate}</div>
                )}
            </div>
        </div>
    )
}

export const SelectOptionWithLabel = ({label, items, classsNameLabel, placeholder,...props}) => {
    return (
        <div className={`input-wrapper ${classsNameLabel}`}>
            <div className="label-input">{label}</div>
            <Select
                {...props}
                placeholder={placeholder || 'Select One'}
            >
                {items?.map((data, index) => {
                    return (
                        <Select.Option key={index} value={data?.value}>{data?.title}</Select.Option>
                    )
                })}
            </Select>
        </div>
    )
}
export const SelectOptionWithLabelPeriode = ({label, items, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className="label-input">{label}</div>
            <Select
                {...props}
            >
                {items?.map((data, index) => {
                    return (
                        <Select.Option key={index} value={data?.value}>{data?.title}</Select.Option>
                    )
                })}
            </Select>
        </div>
    )
}
export const SelectOptionWithLabelApplies = ({label, items, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className="label-input">{label}</div>
            <Select
                {...props}
            >
                {items?.map((data, index) => {
                    return (
                        <Select.Option key={index} value={data.value}>{data.title}</Select.Option>
                    )
                })}
            </Select>
        </div>
    )
}
export const SelectOptionWithLabelAppliesAllvalue = ({label,...props}) => {
    return (
        <div className="input-wrapper-multiple">
            <div className="label-input">{label}</div>
            <Select size="large" {...props}/>
        </div>
    )
}
export const SelectSearchWithLabel = ({labelname, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className="label-input">{labelname}</div>
            <Select size='large'
                {...props}
            >     
            </Select>
        </div>
    )
}
export const SelectSearchWithLabelSwitch = ({labelname, switchProps, ...props}) => {
    return (
        <div className="input-wrapper">
            <Row justify={'space-between'} align={'middle'}>                            
                <div className="label-input">{labelname}</div>
                <Tooltip placement="top" color='white' title={switchProps.tooltipMessage}>
                    {switchProps.multiCompanyAccess === 'all' || switchProps.multiCompanyAccess === 'company' ?
                        <Row>
                                <div className="label-input-custom mb-0" style={{cursor: 'pointer'}}>Multi Company</div>
                                <Switch {...switchProps}/>
                        </Row>
                    :null}
                    </Tooltip>
            </Row>
            <Select size='large'
                {...props}
            >     
            </Select>
        </div>
    )
}
export const SwitchLabel = ({labelname, tooltipMessage, ...props}) => {
    return (
        <div className="input-wrapper">
            <Tooltip placement="right" color='white' title={tooltipMessage}>
                <Row>
                    <div className="label-input-custom mb-0">{labelname}</div>
                    <Switch
                    {...props}/>
                </Row>
            </Tooltip>
        </div>
    )
}
export const SelectOptionAttendanceWithLabel = ({label, items, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className="label-input">{label}</div>
            <Select
                {...props}
            >
                {items.map((data, index) => {
                    return (
                        <Select.Option key={index} value={data.value}>{data.title}</Select.Option>
                    )
                })}
            </Select>
        </div>
    )
}
export const SelectOptionAttendanceNoLabel = ({label, items, ...props}) => {
    return (
        <div className="input-wrapper">
            <Select
                {...props}
            >
                {items.map((data, index) => {
                    return (
                        <Select.Option key={index} value={data.value}>{data.title}</Select.Option>
                    )
                })}
            </Select>
        </div>
    )
}

export const CheckboxOptionWithLabel = ({label,value, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className="label-input">{label}</div>
            <Checkbox {...props}/>
        </div>
    )
}
export const CheckboxOptionWithLabelrow = ({label,value, ...props}) => {
    return (
        <div className="input-wrapper">
            <Row justify={'start'} align={'middle'}>
                <Checkbox {...props}>
                    <div className="label-input" style={{marginBottom: 0}}>{label}</div>
                </Checkbox>
            </Row>
        </div>
    )
}
export const CheckboxOptionWithLabelLeave = ({label,value, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className='flex flex-row gap-2 items-center'>
                <Checkbox {...props}/>
                <div className="text-lg text-slate-500">{label}</div>
            </div>
            <div className="ml-6 text-sm text-slate-400">{'This setting allows employee carry unused leave days from one period tothe next '}</div>
        </div>
    )
}

export const RadioOptionWithLabelAnnualLeave = ({label,setSelectedData,selected,setSelected}) => {
    
    const changeHandler = e => {
      setSelected(e.target.value);
      setSelectedData(e.target.value)
      console.log('radio checked', selected);
    };
    return (
        <div className="input-wrapper">
            <div className='flex flex-row gap-2 items-center'>
                <div className="text-lg text-slate-500">{label}</div>
            </div>
        <div className="ml-0 text-sm text-slate-400">{'This setting allows employee carry unused leave days from one period tothe next '}</div>
                <div className="text-md mt-3 text-slate-500">New Employee Proration</div>
            <Radio.Group onChange={changeHandler} value={selected}>
                <Radio value="0">On First Entitlement</Radio>
                <Radio value="1">No Proration</Radio>
            </Radio.Group>
        </div>
    )
}
export const CheckboxOptionWithLabel1 = ({label,value, ...props}) => {
    return (
        <div className="input-wrapper">
            {/* <div className='flex flex-row justify-center items-center gap-2'> */}
            <Row>
                <Col gap={2}>
                    <Checkbox {...props}>
                        <div className="label-input-nomb">{label}</div>
                    </Checkbox>
                    <Tooltip placement="right" color='white' title="Checklist if the schedule is the default schedule" >
                        <InfoCircleOutlined size={'large'} className='mt-1 label-input-nomb'/>
                    </Tooltip>
                </Col>
            </Row>
            {/* </div> */}
           
        </div>
    )
}

export const SelectMultipleWithLabel = ({label, items, ...props}) => {
    return (
        <div className='input-wrapper-multiple'>
            <div className="label-input">{label}</div>
            <Space
                style={{
                    width: '100%',backgroundColor: '#94a3b2 !important'
                  }}
                direction="vertical"
            >
            <Select
                size='large'
                {...props}
                mode="multiple"
                placeholder="Please Select"
                defaultValue={[]}
                showSearch
                style={{
                    width: '100%',backgroundColor: '#94a3b2 !important'
                  }}
            >
                {items.map((data, index) => {
                    return (
                        <Select.Option key={index} value={data.value}>{data.title}</Select.Option>
                    )
                })}
            </Select>
            </Space>
        </div>
    )
}

export const AutocompleteWithLabel = ({label, items, ...props}) => {
    return (
        <div className="input-wrapper">
            <div className="label-input">{label}</div>
            <AutoComplete
                {...props}
                options={items}
                filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                }
            />
        </div>
    )
}