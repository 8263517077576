// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payroll {
  border-right: #979393 2px solid;
  padding-right: 40px;
  height: 25px !important;
  margin-top: 10px;
}

.cut-off-period {
  border-right: #979393 2px solid;
  padding-right: 40px;
  height: 25px !important;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/component/molecules/ReviewRunPayroll/styles.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AACJ;;AACA;EACI,+BAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAEJ","sourcesContent":[".payroll {\n    border-right: #979393 2px solid;\n    padding-right: 40px;\n    height: 25px !important;\n    margin-top: 10px;\n}\n.cut-off-period {\n    border-right: #979393 2px solid;\n    padding-right: 40px;\n    height: 25px !important;\n    margin-top: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
