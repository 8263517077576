import { Button, Col, Collapse, message, Row, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, SyncOutlined } from "@ant-design/icons";
import { generatePayslip, getDetailReport } from "../../../api/reportPayroll";
import { SettingOutlined } from "@ant-design/icons";
import moment from "moment";

const PayrollReportDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState([]);

    const reportDetail = async () => {
		setLoading(true);
		try {
		  const res = await getDetailReport(id)
        //   console.log(res)
		  setDetail(res?.data);
		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };

    const handleGeneratePayslip = async () => {
		setLoading(true);
        try {
		  const res = await generatePayslip(id)
          if (res.data.status !== 400) {
            Modal.success({
              title: "",
              content: (
                <div className="text-center">
                  <h2>Generate Payslip Success</h2>
                  <p>Please check the results in the table</p>
                </div>
              ),
              onOk: navigate("/generate-payslip"),
            });
            navigate("/generate-payslip");
          } else if (res.data.status !== 200) {
            message.error({
              content: res.data.message ?? "Generate payslip failed",
            });
          }
        } catch (err) {
            message.error({
				content: "Request Not Found",
			});
		  console.log(err);
		} finally {
		  setLoading(false);
		}
    }
	
	useEffect(() => {
        reportDetail();
	}, []);

    const dataBreadcrumb = [
        {
			title: "Report - Payroll",
			url: "/payroll-report"
		},
        {
			title: "Payroll Report Detail",
			url: ``
		},
	];
    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/payroll-report')} icon={<ArrowLeftOutlined />} size={"large"} />
				 Payroll Report Detail
			</div>
           <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Period</div>
                            <div className="subtitle">{detail?.period}</div>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="detail-card">
                            <div className="title">Run Payroll Date</div>
                            <div className="subtitle">{moment(detail?.created_at).format('DD-MM-YYYY')}</div>
                        </div>
                    </Col>
                    <Col span={6}>
                        <Button
                            className="btn-sh-p"
                            type="primary"
                            onClick={() =>
                                handleGeneratePayslip()
                            }
                            icon={<SyncOutlined />}
                            size={"large"}
                        >
                            Generate Payslip
                        </Button>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Total Data</div>
                            <div className="subtitle">{detail?.total_data}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Status</div>
                            <div className="subtitle">{detail?.status}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default PayrollReportDetail