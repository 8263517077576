import React, { useState } from "react";
import { Button, Col, Form as FormAntd, Row, message } from "antd";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  CloudUploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import "../styles.scss";
import IconExel from "../../../assets/IconExel";
import { addOImport0vertimeTransaction } from "../../../api/overtimeTransaction";
import { decryptData } from "../../../helper/cryptojs";
const FormImportOvertimTransaction = () => {
  const navigate = useNavigate();
  const [fileName, setFileName] = useState(null);
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"))? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const branch = decryptData(sessionStorage.getItem("selectBranch"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"))
  const branchCode = branchSession || branch !== null ? branch  : branchDefault    
    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      if (file && file.size <= 2 * 1024 * 1024) {
        setFileName(file.name);
        form.setValues({
          ...form.values,
          Overtime: e.currentTarget.files[0],
        });
      } else {
        setFileName(null);
        let alesrt = message.error(
          "Please upload a XLSX file with a maximum size of 2 MB"
        );
        setTimeout(() => {
          if (alesrt) {
            window.location.reload(true);
          }
        }, 3000);
      }
    };

    const handleFileDelete = () => {
      setFileName(null);
    };


    const form = useFormik({
        initialValues: {
        company : companyStore,
        Overtime :  null,
      },
      enableReinitialize: true,
      // validationSchema: validationUser,
      onSubmit: async(values) => {
          try {
              const res = await addOImport0vertimeTransaction(values);
              if(!res?.data?.success){
                  message.error({
                      content: res?.data?.error,
                  });
              } else {
                window.location.href = `${res.data.data}`;
                  message.success({
                      content:  "Import data success",
                  });
                  navigate("/overtime-transaction")
                  form.resetForm();
              }
          } catch (error) {
              message.error({
                  content: "Request Not Found",
              });
          }
      },
  });


  const handleDownload = () => {
    window.location.href = `${process.env.REACT_APP_HRIS_URL}dash-overtime-template-download?branchCode=${branchCode}`;
  };

  return (
    <div className="add-wrapper">
      <div className="title-section">Import Overtime Transaction</div>
      <FormAntd onFinish={form.handleSubmit}>
        <Col>
          <Row className="grid mt-5  grid-cols-1 md:grid-cols-2 gap-8"></Row>
          <Row className="grid  grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <Col>
                <p className="font-semibold text-lg">Download Template</p>
              </Col>
              <div className="flex flex-row gap-7 items-center mt-2">
                <IconExel />
                <div className="flex flex-col justify-start">
                  <p className="ml-4">download template.xlsx</p>
                  <Button type="link" onClick={handleDownload}>
                    Download the following template and please fill in the data
                  </Button>
                </div>
              </div>
            </div>
          </Row>
          <Row >
            <div className="mt-5">
            <div>
                <p className="font-semibold text-sm"><div>Upload Attachment<span style={{color:"#FF0000"}}> *</span></div></p>
            </div>
              <div className="mt-1">
                <div>
                  <label className="file-upload">
                    <input
                      type="file"
                      onChange={handleFileUpload}
                      accept=".xlsx"
                    />
                    <CloudUploadOutlined /> Upload Attachment
                  </label>
                </div>
                <div>
                  {fileName && (
                    <div>
                      <p>
                        {fileName}{" "}
                        <Button
                          shape="circle"
                          onClick={handleFileDelete}
                          icon={<DeleteOutlined style={{ color: "red" }} />}
                          size={"small"}
                        />{" "}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Row>
          <div className="flex justify-end">
            <Button
              key="back"
              className="btn btn-sec"
              style={{ marginRight: "14px" }}
              onClick={() => navigate("/overtime-transaction")}
            >
              Cancel
            </Button>
            <Button
              key="submit"
              type="primary"
              className="btn btn-primary btn-sh-p"
              onClick={form.handleSubmit}
              disabled={form.isSubmitting}
            >
              Submit
            </Button>
          </div>
        </Col>
      </FormAntd>
    </div>
  );
};

export default FormImportOvertimTransaction;