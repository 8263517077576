/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Pagination } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { decryptData } from "../../helper/cryptojs";
import { PersonalAssessmentMaster } from "../../api/personalAssessment";
import {
  DatePickerWithLabel,
  SelectOptionWithLabel,
} from "../../component/Form/Input";
import "./styles.scss";

export default function PersonalAssessment() {
  const navigate = useNavigate();
  const previousPage = useRef(1);
  const locationPath = useLocation();
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const RoleAccessPersonalAssessmentCreate = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgePersonalAssessmentCreate")
  );
  const RoleaccessPriviledgeProcess = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgePersonalAssessmentProcess")
  );
  const limitSession = sessionStorage.getItem("sessionPagePersonalAssessment");
  const offsetSession = sessionStorage.getItem(
    "sessionOffsetPersonalAssessment"
  );
  const sessionSearch = sessionStorage.getItem(
    "sessionSearchPersonalAssessment"
  );
  const typeSession = sessionStorage.getItem("sessionPageType");
  const statusSession = sessionStorage.getItem("sessionPageStatus");
  const sessionDate = decryptData(
    sessionStorage.getItem("sessionDatePersonalAssessment")
  );
  const employeeNumber = decryptData(localStorage.getItem("EmployeeNumber"));
  const branch = decryptData(sessionStorage.getItem("selectBranch"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"));
  const branchCode = branchSession || branch !== null ? branch : branchDefault;

  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(limitSession ? parseInt(limitSession) : 1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(offsetSession ? offsetSession : 0);
  const [search, setSearch] = useState(sessionSearch || "");
  const [type, setType] = useState(typeSession ? typeSession : null);
  const [status, setStatus] = useState(statusSession ? statusSession : null);
  const [period, setPeriod] = useState(sessionDate || null);

  const fetchPersonalAssessmentMaster = async () => {
    setLoading(true);
    try {
      const res =
        locationPath.pathname !== "/personal-assessment"
          ? null
          : await PersonalAssessmentMaster(
              RoleAccessPersonalAssessmentCreate,
              RoleaccessPriviledgeProcess,
              company,
              branchCode,
              employeeNumber,
              type,
              status,
              period,
              page,
              offset,
              limit,
              search
            );
      setListTable(res?.data?.data);
      setTotalData(res?.data?.recordsTotal);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("sessionPagePersonalAssessment", page);
  }, [page]);

  useEffect(() => {
    const limitSession = sessionStorage.getItem(
      "sessionPagePersonalAssessment"
    );
    if (limitSession) {
      setPage(parseInt(limitSession));
    }
  }, []);

  useEffect(() => {
    fetchPersonalAssessmentMaster();
    const beforeUnloadHandler = () => {
      sessionStorage.removeItem("sessionPagePersonalAssessment");
      sessionStorage.removeItem("sessionOffsetPersonalAssessment");
      sessionStorage.removeItem("sessionSearchPersonalAssessment");
      setSearch("");
    };
    window.addEventListener("beforeunload", beforeUnloadHandler);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [type, status, period, page, offset, limit, search]);

  useEffect(() => {
    if (page === 1) {
      setOffset(0);
    } else {
      setOffset(page * limit - 10);
    }
  }, [page]);

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (_, record, index) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`
              )
            }>
            {index + 1 + (page - 1) * limit}
          </div>
        );
      },
    },
    {
      title: "Branch",
      dataIndex: "branch_name",
      key: "branch_name",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "NIK",
      dataIndex: "employee_number",
      key: "employee_number",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "Employee Name",
      dataIndex: "full_name",
      key: "full_name",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`
              )
            }>
            {text.replace(/&#039;/g, "'") || "-"}
          </div>
        );
      },
    },

    {
      title: "Request Date",
      dataIndex: "request_date",
      key: "request_date",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: "Period",
      dataIndex: "period_month",
      key: "period_month",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/personal-assessment/detail/${encodeURIComponent(
                  "id?" + record.id
                )}`
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    previousPage.current = page;
  }, [page]);

  const onChangeTable = (pageNumber, type) => {
    const offsetSession = pageNumber * limit - 10;
    setPage(pageNumber);
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPagePersonalAssessment", pageNumber);
    sessionStorage.setItem("sessionOffsetPersonalAssessment", offsetSession);
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };
  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }
  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setLimit(10);
    setPage(1);
    sessionStorage.setItem("sessionSearchPersonalAssessment", e.target.value);
  };

  const handleDatePickerChange = (_, dateString) => {
    setPeriod(dateString || null);
    setPage(1);
    setOffset(0);
    sessionStorage.setItem("sessionDatePersonalAssessment", dateString);
  };

  const dataBreadcrumb = [
    {
      title: "Performance Management",
      url: "/personal-assessment",
    },
    {
      title: "Personal Assessment",
      url: "",
    },
  ];

  return (
    <>
      <div className="title-section">Personal Assessment</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
        <div className="flex flex-wrap gap-2 justify-end items-center mb-10">
          <SelectOptionWithLabel
            allowClear
            classsNameLabel="input-type"
            placeholder="By Status"
            name="byStatus"
            className="select-company w-20 md:w-32 text-xs mt-0"
            items={[
              {
                value: "Pending",
                title: "PENDING",
              },
              {
                value: "On Process",
                title: "ON PROCESS",
              },
              {
                value: "Waiting Process",
                title: "WAITING PROCESS",
              },
              {
                value: "Done",
                title: "DONE",
              },
              {
                value: "Rejected",
                title: "REJECTED",
              },
              {
                value: "Hold",
                title: "HOLD",
              },
              {
                value: "Cancel",
                title: "CANCELLED",
              },
            ]}
            value={status}
            onChange={(value) => {
              setStatus(value);
              if (value) {
                sessionStorage.setItem("sessionPageStatus", value);
              } else {
                sessionStorage.removeItem("sessionPageStatus");
              }
              setPage(1);
            }}
          />
          <SelectOptionWithLabel
            allowClear
            classsNameLabel="input-type"
            placeholder="By Type"
            name="by_type"
            className="select-company w-20 md:w-32 text-xs"
            items={[
              {
                value: "Promotion",
                title: "Promotion",
              },
              {
                value: "Talent Mapping",
                title: "Talent Mapping ",
              },
            ]}
            value={type}
            onChange={(value) => {
              setType(value);
              if (value) {
                sessionStorage.setItem("sessionPageType", value);
              } else {
                sessionStorage.removeItem("sessionPageType");
              }
              setPage(1);
            }}
          />
          <DatePickerWithLabel
            placeholder="Select Month"
            allowClear
            picker="month"
            value={period ? period : null}
            onChange={handleDatePickerChange}
            className="w-full md:w-48 h-10 bg-[#F8FAFB] border-none mt-2"
            classsNameLabel="input-type"
          />
          <InputWithIcon
            type="text"
            placeholder="Search Employee Name"
            img={iconSearch}
            className="search-input w-full md:w-[250px] btn-sh-sec"
            classImg="op-icon"
            onChange={onChangeSearch}
            value={search}
          />
          {RoleAccessPersonalAssessmentCreate && (
            <Button
              className="bg-[#0281FF] mt-2 md:mt-0"
              type="primary"
              shape="circle"
              onClick={() =>
                navigate("/personal-assessment/add-personal-assessment")
              }
              icon={<PlusOutlined />}
              size={"large"}
            />
          )}
        </div>
        <div className="table-wrapper">
          {loading ? (
            <>
              <Table id={"my-table"} columns={columns} loading={loading} />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          ) : (
            <>
              <Table
                id={"my-table"}
                dataSource={dataTableWithNumber()}
                columns={columns}
                loading={loading}
              />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}