import { Button, Col, Form as FormAntd, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import {
  InputWithLabel,
  SelectSearchWithLabel,
} from "../../component/Form/Input";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { addGrade, editGrade, getLevelList, getGradeDetail } from "../../api/grade";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";

const FormAddGrade = () => {
  const navigate = useNavigate();
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const [fetching, setFetching] = useState(false);
  const location = window.location.href;
  var n = location.split("/");
  var result = n[5];
  const decode = decodeURIComponent(result);
  var dec = decode.lastIndexOf("?");
  var id = decode.substring(dec + 1);
  const [detail, setDetail] = useState([]);
  const [flag, setFlag] = useState(0);

  const [listLevel, setListLevel] = useState([]);
  const { Option } = Select;

  const gradeList = async () => {
    setFetching(true);
    try {
        const res = await getGradeDetail(id)
        const {data} = res?.data
        setDetail(data);
    } catch (err) {
      console.log(err);
    } finally {
      setFetching(false);
    }
  };

  const levelList = async () => {
    setFetching(true);
    try {
        const res = await getLevelList(company)
        setListLevel(res?.data?.data);
    } catch (err) {
      console.log(err);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    levelList();
    if (result) {
      gradeList();
    }
  }, [flag]);

  const validationUser = yup.object().shape({
    level: yup.string().required("Field is required"),
    grade: yup.number().required("Field is required").min(1, 'Must be between 1 - 10').max(10, 'Must be between 1 - 10'),
  });
  const form = useFormik({
    initialValues: {
      level: result ? detail?.level_id : null,
      grade: result ? detail?.grade : "",
    },
    enableReinitialize: true,
    validationSchema: validationUser,
    onSubmit: async (values) => {
      setFetching(true);
      try {
        const res = result ? await editGrade(values, id) : await addGrade(values);
        if(!res?.data?.success){
            message.error({
                content: res?.data?.error,
            });
        } else {
            message.success({
                content: result ? "Grade successfully updated" : "Grade successfully saved",
            });
            navigate("/grade");
            form.resetForm();
            setFlag((prev) => prev + 1)
        }
        setFetching(false);
      } catch (error) {
        message.error({
          content: "Request Not Found",
        });
        setFetching(false);
      }finally {
        setFetching(false);
      }
    },
  });

  const dataBreadcrumb = [
    {
      title: "Master Data - Grade",
      url: "/grade",
    },
    {
      title: `${result ? "Edit Grade" : "Add Grade"}`,
      url: `${result ? `/grade/edit-grade/${result}` : "/grade/add-grade"}`,
    },
  ];

  return (
    <>
      <div className="title-section">{result ? "Edit Grade" : "Add Grade"}</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="add-wrapper">
        <FormAntd onFinish={form.handleSubmit}>
          <FormAntd.Item>
            <SelectSearchWithLabel
              defaultValue={null}
              name="level"
              labelname={"Level"}
              showSearch
              placeholder="Select Level"
              optionFilterProp="children"
              filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }  
              value={form?.values?.level}
              onChange={(e) => {
                form.setValues({
                  ...form.values,
                  level: e,
                  })
               }}
              onBlur={form.handleBlur}
            >
              {listLevel.map((option) => (
                <Option key={option.level_id} value={option.level_id}>
                  {option.name}
                </Option>
              ))}
            </SelectSearchWithLabel>
            <div style={{ marginTop: "1rem" }}>
              {form.touched.level && form.errors.level ? (
                <span className="text-error">{form.errors.level}</span>
              ) : null}
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <InputWithLabel
              name="grade"
              label={"Grade"}
              placeholder="Grade"
              value={form?.values?.grade}
              onChange={(e) => {
                const telNo = e.target.value;
                const re = /^(10|[1-9])$/;
                if (telNo === "" || re.test(telNo)) {
                  form.handleChange(e);
                }
              }}
              onBlur={form.handleBlur}
              min={1}
              max={10}
              maxLength={2}
            />
            <div style={{ marginTop: "1rem" }}>
              {form.touched.grade && form.errors.grade ? (
                <span className="text-error">{form.errors.grade}</span>
              ) : null}
            </div>
          </FormAntd.Item>
        </FormAntd>
        <div className="footer-add">
          <Button
            key="back"
            className="btn btn-sec"
            style={{ marginRight: "14px" }}
            onClick={() => navigate("/grade")}
          >
            Cancel
          </Button>
          <Button
            key="submit"
            type="primary"
            className="btn btn-primary btn-sh-p"
            loading={fetching}
            onClick={form.handleSubmit}
            disabled={form.isSubmitting}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormAddGrade;
