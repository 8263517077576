// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.section-card {
  border: #B2B2B2 1px solid;
}
.section-card .select-employee {
  border-bottom: #D8D8D8 1px solid;
}
.section-card .check-employee {
  border-bottom: #D8D8D8 1px solid;
}

.section-footer {
  border: #B2B2B2 1px solid;
}

.ant-pagination-options-size-changer .ant-select-selector {
  cursor: pointer !important;
}

.ant-pagination-options-size-changer .ant-select-selection-search {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/component/Organisms/EmployeeSelector/styles.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;AACJ;AAAI;EACI,gCAAA;AAER;AAAI;EACI,gCAAA;AAER;;AACA;EACI,yBAAA;AAEJ;;AAEA;EACI,0BAAA;AACJ;;AAEA;EACI,aAAA;AACJ","sourcesContent":[".section-card {\n    border: #B2B2B2 1px solid;\n    .select-employee {\n        border-bottom: #D8D8D8 1px solid;\n    }\n    .check-employee {\n        border-bottom: #D8D8D8 1px solid;\n    }\n}\n.section-footer {\n    border: #B2B2B2 1px solid;\n}\n\n\n.ant-pagination-options-size-changer .ant-select-selector {\n    cursor: pointer !important;\n  }\n\n.ant-pagination-options-size-changer .ant-select-selection-search {\n    display: none;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
