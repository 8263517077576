import { Button, Col, Form as FormAntd, message, Row, DatePicker, Checkbox } from "antd";
import React, { useEffect, useRef, useState } from "react";
import  { DatePicker2WithLabel, InputWithLabel, InputNumberWithLabel, SelectOptionWithLabel, NumberFormat, SelectOptionWithLabelAppliesAllvalue } from "../../component/Form/Input";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { editCompanyAbsenceSetting, getCompany, getCompanyAbsenceSetting } from "../../api/company";
import { getBaseCalc } from "../../api/payrollComponent";
import './styles.scss'
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
const AbsenceSetting = () => {
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);
    const location = window.location.href;
    var n = location.split("/");
    var result =  n[5];;
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [detail, setDetail] = useState();
    const deCompanyCode = decryptData(localStorage.getItem("DefaultCompanyCode"));
    const [baseCalc, setBaseCalc] = useState([])
    const [isAmountAbs, setIsAmountAbs] = useState(false)
    const [isCustomAbs, setIsCustomAbs] = useState(false)
    const [isNoneAbs, setIsNoneAbs] = useState(false)

    const companyDetail = async () => {
		setFetching(true);
		try {
		  const res = await getCompanyAbsenceSetting(id)
          const {data} = res?.data
          data?.absence_cal_ref === 'amount' ? setIsAmountAbs(true) : setIsAmountAbs(false)
          data?.absence_cal_ref === 'custom' ? setIsCustomAbs(true) : setIsCustomAbs(false)
          data?.absence_cal_ref === 'none' || data?.absence_cal_ref === 'amount'? setIsNoneAbs(true) : setIsNoneAbs(false)
		  setDetail(data);
		} catch (err) {
		  console.log(err);
		} finally {
		  setFetching(false);
		}
	  };
    const checkValidity = (flag, arrayTarget = []) => {
        const found = arrayTarget.find((e) => e.id === flag)
        if(!found){
            return false
        }else{
            return true
        }
    }	
	useEffect(() => {
        companyDetail();    
	}, []);

    useEffect(() => {
        const populateOpts = async () => {
            const resBasecal = await getBaseCalc(id, 0)
            const data = resBasecal?.data?.data
            setBaseCalc(data)
        }
        populateOpts()
    }, [])


    const validationUser = yup.object().shape({
        absence_cal_ref: yup.string().required("Field is required"),
        absence_cal_ref_amount: isAmountAbs ? yup.string().required("Field is required") : '',
        absence_cal_ref_custom: isCustomAbs ? yup.string().required("Field is required") : '',
        calculation_base: isNoneAbs ? '' : yup.string().required("Field is required"),
    });
    
    const form = useFormik({
        initialValues: {
            absence_cal_ref : result ? detail?.absence_cal_ref : null,
            absence_cal_ref_amount: result ? detail?.absence_cal_ref_amount : null,
            absence_cal_ref_custom: result ? detail?.absence_cal_ref_custom : null,
            calculation_base: result ? (checkValidity(detail?.calculation_base, baseCalc) ? detail?.calculation_base: null)  : null,
        },
        enableReinitialize: true,
        validationSchema: validationUser,
        onSubmit: async(values) => {
            if(result){
                setFetching(true)
                try {
                    const res = await editCompanyAbsenceSetting(values, id)
                    if(!res?.data?.success){
                        message.error({
                            content: res?.data?.error,
                        });
                    } else {
                        message.success({
                            content: "Settings successfully updated",
                        });
                        setTimeout(() => {
                            // window.location.reload();
                        }, 1000);
                        navigate('/company')
                        form.resetForm();
                    }
                    setFetching(false)
                } catch (error) {
                    message.error({
                        content: "Request Not Found",
                    });
                    setFetching(false)
                }
            }
        },
    });

    const dataBreadcrumb = [
		{
			title: "Master Data - Company",
			url: "/company"
		},
		{
			title: `Absence Setting` ,
			url: ``
		},
	]
  
    return (
        <>
          <div className="title-section">
				{"Absence Setting"}
			</div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="add-wrapper">
           
          <FormAntd
              onFinish={form.handleSubmit}
          >
              <div className="title-divider">Absence Settings</div>
              <FormAntd.Item>
                  <SelectOptionWithLabel
                      name="absence_cal_ref"
                      label={<div>Absence Calculation References<span style={{color:"#FF0000"}}> *</span></div>}
                      items={[
                        {
                            value: 'none',
                            title: "None"
                        },
                          {
                              value: 'based_on_working_days',
                              title: "Based on Working Day"
                          },
                          {
                              value: 'based_on_calendar_days',
                              title: "Based on Calendar Day"
                          },
                          {
                              value: 'custom',
                              title: "Custom"
                          },
                          {
                              value: 'amount',
                              title: "Amount"
                          },
                      ]}
                      value={form?.values?.absence_cal_ref}
                      onSelect={(val) =>{
                        if(val === 'none' || val === 'amount'){
                            setIsNoneAbs(true)
                        }else{
                            setIsNoneAbs(false)
                        }
                        if(val === 'amount'){
                            setIsAmountAbs(true)
                        }else{
                            setIsAmountAbs(false)
                        }
                        if(val === 'custom'){
                            setIsCustomAbs(true)
                        }else{
                            setIsCustomAbs(false)
                        }
                        form.setValues({
                        ...form.values,
                        absence_cal_ref: val,
                        absence_cal_ref_amount: null,
                        absence_cal_ref_custom: null,
                        calculation_base: null
                        })
                      }}
                      onBlur={form.handleBlur}
                  />
                  <div style={{marginTop: '1rem'}}>
                  {form.touched.absence_cal_ref && form.errors.absence_cal_ref ? (
                      <span className="text-error">{form.errors.absence_cal_ref}</span>
                    ) : null}
                  </div>
              </FormAntd.Item>
              {isAmountAbs ?
                  <FormAntd.Item>
                      <NumberFormat
                        label={
                          <div>
                            Custom Number<span className="text-red-500 ml-1">*</span>
                          </div>
                        }
                        name="absence_cal_ref_amount"
                        type="text"
                        allowNegative={false}
                        placeholder="Rp."
                        thousandSeparator={true}
                        value={
                          form?.values?.absence_cal_ref_amount ? form?.values?.absence_cal_ref_amount : ""
                        }
                        onValueChange={(values) => {
                          const { floatValue } = values;
                          form.setValues({
                            ...form.values,
                            absence_cal_ref_amount: floatValue, // Menetapkan nilai yang sudah diformat
                          });
                        }} 
                      />
                      <div style={{marginTop: '1rem'}}>
                      {form.touched.absence_cal_ref_amount && form.errors.absence_cal_ref_amount ? (
                          <span className="text-error">{form.errors.absence_cal_ref_amount}</span>
                      ) : null}
                        </div>
                  </FormAntd.Item>
                :
                  isCustomAbs ?
                  <FormAntd.Item>
                      <InputNumberWithLabel
                          name="absence_cal_ref_custom"
                          label={<div>Custom Number<span style={{color:"#FF0000"}}> *</span></div>}
                          placeholder=""
                          value={form.values.absence_cal_ref_custom ? form.values.absence_cal_ref_custom : null }
                          onChange={(value) =>{
                            const re = /^[0-9]*$/;
                            if (value === '' || re.test(value)) {
                                form.setValues({
                                    ...form.values,
                                    absence_cal_ref_custom: value
                                })
                            }
                          }}
                          stringMode
                          step={1}
                          max={31}
                          min={1}
                          style={{ width: "100%" }}
                      />
                      <div style={{marginTop: '1rem'}}>
                      {form.touched.absence_cal_ref_custom && form.errors.absence_cal_ref_custom ? (
                          <span className="text-error">{form.errors.absence_cal_ref_custom}</span>
                      ) : null}
                      </div>
                  </FormAntd.Item>
                  :
                    null
                }
                {!isNoneAbs ?
                    <FormAntd.Item>
                        <SelectOptionWithLabelAppliesAllvalue
                            name="calculation_base"
                            label={<div>Calculations Base<span style={{color:"#FF0000"}}> *</span></div>}
                            options={baseCalc}
                            placeholder="Select One"
                            fieldNames={{label:"name", value:"id"}}
                            value={form?.values?.calculation_base}
                            onChange={(val) =>
                                form.setValues({
                                ...form.values,
                                calculation_base: val,
                                })
                            }
                            onBlur={form.handleBlur}
                        />
                        <div style={{marginTop: '1rem'}}>
                        {form.touched.calculation_base && form.errors.calculation_base ? (
                            <span className="text-error">{form.errors.calculation_base}</span>
                        ) : null}
                        </div>
                    </FormAntd.Item>
                :
                    null                    
                }
          </FormAntd>
          <div className="footer-add">
              <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/company')}>
                  Cancel
              </Button>
              <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                  Submit
              </Button>
          </div>
      </div>
        </>
        
      
    )
}

export default AbsenceSetting;
