export default function Resubmit({ size = 15, fill = "white" }) {
  return (
    <div>
      <svg
        width={size}
        height={size}
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M16.625 9.50044C16.6255 11.1112 16.0951 12.6734 15.1224 13.9256C14.1497 15.1779 12.7937 16.0442 11.2813 16.3798C9.76881 16.7154 8.1916 16.4999 6.81343 15.7693C5.43526 15.0387 4.3397 13.8373 3.71 12.3661L1.6235 13.2489C2.45063 15.223 3.90505 16.8403 5.74223 17.8289C7.5794 18.8175 9.68731 19.1171 11.7116 18.6772C13.7358 18.2374 15.553 17.085 16.8575 15.4138C18.1621 13.7426 18.8745 11.6544 18.875 9.50044C18.8751 7.54377 18.2874 5.63611 17.1944 4.04481C16.1014 2.45351 14.5578 1.25833 12.7799 0.626777C11.0021 -0.00477864 9.07906 -0.0410565 7.28041 0.523027C5.48176 1.08711 3.89762 2.22328 2.75 3.77231V0.906688H0.5V7.15669L1.625 8.32856H6.875V5.98481H4.028C4.77066 4.64367 5.91751 3.59537 7.29058 3.00258C8.66365 2.40978 10.1862 2.30565 11.6219 2.70633C13.0576 3.10701 14.3261 3.9901 15.2308 5.21856C16.1354 6.44703 16.6255 7.95218 16.625 9.50044Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}
