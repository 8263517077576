import {
  SET_CUSTOM_SALARIES,
  ADD_CUSTOM_SALARY,
  REMOVE_CUSTOM_SALARY,
  UPDATE_CUSTOM_SALARY,
} from "../actionTypes";
export const setCustomSalaries = (salaries) => ({
  type: SET_CUSTOM_SALARIES,
  payload: salaries,
});

export const addCustomSalary = (salary) => ({
  type: ADD_CUSTOM_SALARY,
  payload: salary,
});

export const removeCustomSalary = (index) => ({
  type: REMOVE_CUSTOM_SALARY,
  payload: index,
});

export const updateCustomSalary = (index, newSalary) => ({
  type: UPDATE_CUSTOM_SALARY,
  payload: { index, newSalary },
});
