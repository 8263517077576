import { Button, Col, Form as FormAntd, Radio, Row, message, DatePicker, Flex, Input as InputAntd, Tooltip, Select } from "antd";
import {DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from "react";
import  { InputWithLabel, RadioGroupWithLabel, TextAreaWithLabel, SelectOptionWithLabel, SelectOptionWithLabelAppliesAllvalue, InputFormulaWithButton } from "../../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
// import { AddHoliday, EditHoliday, getHolidayDetail } from "../../api/holiday";
import { decryptData } from "../../../helper/cryptojs";
import Breadcrumb from "../../../component/Breadcrumb";
import dayjs from "dayjs";
import { getBaseCalc, getWagesSetting, postWagesSetting } from "../../../api/payrollComponent";
import * as yup from "yup";


const WagesSetting = () => {
    const navigate = useNavigate();
    const deCompanyId = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompanyId"))
    : decryptData(localStorage.getItem("DefaultCompanyId"));
    const [fetching, setFetching] = useState(false);
    const location = window.location.href;
    var n = location.split("/");
    var result =  n[5];
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [formula, setFormula] = useState([{operation: '+', showDel: false, showAdd: true}]);
    const [detail, setDetail] = useState([]);
    const [errorFlag, setErrorFlag] = useState(false);
    const [flag, setFlag] = useState(0);

	const options = [
        {
          label: '+',
          value: '+',
        },
        {
          label: '-',
          value: '-',
        },
        {
          label: '*',
          value: '*',
        },
        {
            label: '/',
            value: '/',
          },
      ];

    const addAction = () => {
        let temp = [...formula]
        temp.forEach((e,i) => {
            e.showDel = true
            e.showAdd = false
            if(i === 0) {
                e.showDel = false
                e.showAdd = false
            }
        });
        if(formula.length === 4){
            temp.push({operation: '+', showDel: true, showAdd: false})
        }else if (formula.length < 5){
            temp.push({operation: '+', showDel: true, showAdd: true})
        }
        setFormula(temp)
    }
    const deleteAction = (index) => {
        const temp = [...formula];
        let res = temp.filter((_, i) => i !== index);
        res[res.length-1].showAdd = true
        if(res.length === 1){
            res[res.length-1].showDel = false    
        }
        setFormula(res);
        setErrorFlag(false)
    }

    const populatePage = (formulas, baseCalc) => {
        form.setValues({
            ...form.values,
            base_calculation_id: baseCalc,
        })
        if(formulas.length > 0){
        let temp = JSON.parse(JSON.stringify(formulas));
        temp.forEach((e,i) => {
            e.showDel = true
            e.showAdd = true
            if(i === 0 && temp.length > 1){
                e.showDel = false
                e.showAdd = false
            }else if (i === 0 && temp.length === 1) {
                e.showDel = false
                e.showAdd = true
            }else if (i === temp.length-1 && temp.length === 5) {
                e.showDel = true
                e.showAdd = false
            }else if(i === temp.length-1 && temp.length !== 5){
                e.showDel = true
                e.showAdd = true
            }else {
                e.showDel = true
                e.showAdd = false
            }     
        })
        
        setFormula(temp)}
    }

    const WagesSettingFetch = async () => {
		setFetching(true);
		try {
          const res = await getBaseCalc(deCompanyId, 0)
          const data = res?.data?.data
          setDetail(data)
          const populate = await getWagesSetting(deCompanyId)
          const {formulas, base_calculation_id} = populate?.data?.data
          populatePage(formulas, base_calculation_id)
		} catch (err) {
		  console.log(err);
		} finally {
        setFetching(false);
		}
	  };

	useEffect(() => {
        WagesSettingFetch();
	}, []);

    const validationForm = yup.object().shape({
        base_calculation_id: yup.string().required('Field is required'),
    })

    const checkUnique = (id) => {
        const found = formula.find(e => e.payroll_component_id === id)
        if(found){
            return false
        }  
        if(!found){
            if(id === form.values.base_calculation_id) {
                return false
            }else{
                return true
            }
        }
    }

    const form = useFormik({
        initialValues: {
            base_calculation_id: detail ? detail.base_calculation_id : null,
        },
        enableReinitialize: true,
        validationSchema: validationForm,
        onSubmit: async(values) => {
            setFetching(true)
            const errorChecker = false
            const user = decryptData(localStorage.getItem("Username"));
            let temp = JSON.parse(JSON.stringify(formula));
            temp.forEach(e => {
                delete e.showDel
                delete e.showAdd
                if(!(e.payroll_component_id)){
                    errorChecker = true
                }
            })
            if(!errorFlag && !errorChecker){
                values.formulas = temp
                values.created_by = user
                values.company_id = deCompanyId
                const res =  await postWagesSetting(values)
                if(!res?.data?.success){
                    message.error({
                        content: res?.data?.error,
                    });
                }else{
                    message.success("Setting Data Success");
                    navigate("/payroll-component")
                    form.resetForm();
                    setFlag((prev) => prev + 1)
                }
            }
        },
    });
    

    const dataBreadcrumb = [
		{
			title: "Payroll Component - Master Component",
			url: "/payroll-component"
		},
		{
			title: `Wages Setting` ,
			url: ``
		},
	]

    return (
       <>
        <div className="title-section">
				Wages Setting
		</div>
        <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
        <div className="add-wrapper">
            <FormAntd
                onFinish={form.handleSubmit}
            >
                    <div className="label-input">Formula<span style={{color:"#FF0000"}}> *</span></div>
                        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 24, xxl: 12}}>
                            <Col 
                            xs={24} sm={24} md={24} lg={24} xl={4} xxl={4}
                            >
                                <div className="input-wrapper" style={{marginTop: '1rem'}}>
                                    <Select
                                        name={"base_calculation_id"}
                                        value={form?.values?.base_calculation_id}
                                        fieldNames={{label: 'name', value: 'id'}}
                                        onSelect={(val) =>{
                                            if(checkUnique(val)){
                                                form.setValues({
                                                ...form.values,
                                                base_calculation_id: val,
                                                })
                                            }else{
                                                message.error({
                                                    content: 'Payroll component can only be selected once',
                                                });
                                                form.setValues({
                                                ...form.values,
                                                base_calculation_id: null,
                                                })
                                            }
                                        }}
                                        onBlur={form.handleBlur}
                                        placeholder="Select One"
                                        options={detail}
                                        status={form.touched.base_calculation_id && form.errors.base_calculation_id ? "error" : null}
                                    />
                                    <div style={{marginTop: '1rem'}}>
                                        {form.touched.base_calculation_id && form.errors.base_calculation_id ? (
                                        <span className="text-error">{form.errors.base_calculation_id}</span>
                                        ) : null}
                                    </div>
                                </div>
                            </Col>

                            <Col xs={24} sm={24} md={24} lg={24} xl={20} xxl={20}>
                                {formula.map((data, i) => {
                                    return (
                                    <InputFormulaWithButton
                                    key={i} 
                                    radioProps={{
                                        defaultValue: '+',
                                        buttonStyle:"solid",
                                        optionType:"button",
                                        size:'large',
                                        options: options,
                                        value: data.operation,
                                        onChange: ({target: {value}})=>{
                                            let temp = [...formula]
                                            temp[i].operation = value
                                            setFormula(temp)
                                        }
                                        }}

                                    selectProps={{
                                            // allowClear: true,
                                            options: detail || [],
                                            value: data.payroll_component_id,
                                            onSelect: (value) => {
                                                if(checkUnique(value)){
                                                    let temp = [...formula]
                                                    temp[i].payroll_component_id = value
                                                    setErrorFlag(false)
                                                    setFormula(temp)
                                                }else{
                                                    message.error({
                                                        content: 'Payroll component can only be selected once',
                                                    });
                                                    let temp = [...formula]
                                                    temp[i].payroll_component_id = null
                                                    setErrorFlag(true)
                                                    setFormula(temp)
                                                }
                                            },
                                            onBlur: form.handleBlur,
                                            fieldNames: {label: 'name', value: 'id'},
                                            status: errorFlag && !data.payroll_component_id ? 'error' : null, 
                                            noData: !data.payroll_component_id ? true : false
                                        }}
                                    errorFlag={errorFlag}
                                    showDel={data.showDel}
                                    showAdd={data.showAdd}
                                    deleteAction={()=>deleteAction(i)}
                                    addAction={()=>addAction()}
                                    />
                                )
                                })}
                            </Col>
                        </Row>
                            
            </FormAntd>
            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate(-1)}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p"  onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </div>
       </>
    )
}

export default WagesSetting;