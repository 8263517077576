import { Button, Col, message, Modal, Pagination, Row, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { PlusOutlined, DeleteFilled, EditFilled ,StopOutlined} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../helper/cryptojs";
import { deleteShift, getShift } from "../../api/shift";
import {decode} from 'html-entities';

export default function ShiftingShift() {
	const navigate = useNavigate();
	const sessionPage = sessionStorage.getItem("sessionPageShift");
	const sessionOffset = sessionStorage.getItem("sessionOffsetShift");
	const sessionSearch = sessionStorage.getItem("sessionSearchShift");
	const [listTable, setListTable] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(sessionPage ? parseInt(sessionPage) : 1)
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(sessionOffset ? sessionOffset : 0)
	const previousPage = useRef(1);
	const [search, setSearch] = useState(sessionSearch ? sessionSearch : "")
	const [flag, setFlag] = useState(0)
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [deleteId, setDeleteId] = useState()
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const BestpraticeUseEffect = useRef(false)
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
	

	const shiftList = async () => {
		setLoading(true);
		try {
		  const res = await getShift(company, search, limit, offset,page)
		  setListTable(res?.data?.data);
		  setTotalData(res?.data?.recordsTotal)
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	useEffect(() => {
		if (!BestpraticeUseEffect.current) {
			BestpraticeUseEffect.current = true;
		  } else {
			shiftList();
			const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageShift");
				sessionStorage.removeItem("sessionSearchShift");
				sessionStorage.removeItem("sessionOffsetShift");
				setPage(1)
				setOffset(0)
				setSearch("")
			};
			window.addEventListener("beforeunload", beforeUnloadHandler);
			return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			};
		  }
	}, [search,startDate, endDate,  limit, offset, page,company]);


	useEffect(() => {
		let offsetSession = 0;
		if(page > 1){
			offsetSession = page*limit - 10
		}
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPageShift", page);
		sessionStorage.setItem("sessionOffsetShift", offsetSession);
	},[page])

	const handleDelete = async (id) =>{
		setLoading(true)
		try {
			const res = await deleteShift(id)
			message.success({
				content: "Delete Data Success",
				onClose: () => {
					window.location.reload();
				}
			});
			setLoading(false)
			setFlag((prev) => prev + 1)
		} catch (error) {
			message.error({
				content:  "Response Failed" 
			});
			setLoading(false)
		}
	}
	


	const columns = [
	{
		title: 'No',
		dataIndex: 'no',
		key: 'no',
		width: '25px',
        render: (text,record) =>{
			return (
				<div className="detail-btn-wp" onClick={() => navigate(`/shifting/shift/detail-shift/${encodeURIComponent("id?" + record?.m_shifting_id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
	{
		
		title: 'Shift Name',
		dataIndex: 'name',
		key: 'name',
		render: (text,record) =>{
			return (
				
				<div className="detail-btn-wp" onClick={() => navigate(`/shifting/shift/detail-shift/${encodeURIComponent("id?" + record?.m_shifting_id)}`)}>
					{decode(text) || '-'}
				</div>
			)
		}
	},
	{
		
		title: 'Schedule In',
		dataIndex: 'schedule_in',
		key: 'schedule_in',
		render: (text,record) =>{
			return (
				
				<div className="detail-btn-wp" onClick={() => navigate(`/shifting/shift/detail-shift/${encodeURIComponent("id?" + record?.m_shifting_id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
	{
		
		title: 'Schedule Out',
		dataIndex: 'schedule_out',
		key: 'schedule_out',
		render: (text,record) =>{
			return (
				
				<div className="detail-btn-wp" onClick={() => navigate(`/shifting/shift/detail-shift/${encodeURIComponent("id?" + record?.m_shifting_id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
	{
		title: 'Action',
		key: 'action',
		width: "50px",
		render: (_,record,index) => {
            return RoleAccessShiftUpdate && RoleAccessShiftDelete ? (
                <Row justify={"space-between"}>
					<div className="icon-action" >
					  <DeleteFilled onClick={() => showModal(record?.m_shifting_id)}/>
				  	</div>
					 <div className="icon-action" onClick={() => navigate(`/shifting/shift/edit-shift/${encodeURIComponent("id?" + record?.m_shifting_id)}`)}>
					 <EditFilled />
					 </div>
                </Row>
            ) : (
				<Row justify={"space-between"}>
					<Tooltip title="No Access">
						<StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
					</Tooltip>
				</Row>
			)
        }
	},
	];
	const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/shifting/shift"
		},
		{
			title: "Shift",
			url: "/shifting/shift"
		},
	];


	useEffect(() => {
		previousPage.current = page;
	}, [page]);


	const onChangeTable = (pageNumber, type) => {
		setPage(pageNumber)
		setOffset(pageNumber*limit- 10)
		//
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		setLimit(10)
		setPage(1)
		sessionStorage.setItem("sessionSearchShift", e.target.value);
	}


	const showModal = (id) => {
		setIsModalOpen(true);
		setDeleteId(id)
	  };

	const handleOk = () => {
		setIsModalOpen(false);
		handleDelete(deleteId)
	};
	
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const RoleAccessShiftCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeShiftCreate'));
	const RoleAccessShiftUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeShiftUpdate'));
	const RoleAccessShiftDelete = JSON.parse(localStorage.getItem('RoleaccessPriviledgeShiftDelete'));

    return (
        <>
		  <>
		  		<div className="title-section">
					Shift
				</div>
				<Modal title="Delete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
						<p>Are you sure to delete this data?</p>
				</Modal>
				<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
					<Col span={24}>
						<Breadcrumb items={dataBreadcrumb}/>
					</Col>
				</div>
				<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
					<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"} >
						<Col md={{top:20}} className="mt-5 md:mt-0">
							<Row justify={"end"} style={{gap:15}} md={14}>
									<InputWithIcon
										type="text"
										placeholder="Search Shift"
										img={iconSearch}
										className="search-input btn-sh-sec"
										classImg="op-icon"
										value={search}
										onChange={onChangeSearch} />
									{RoleAccessShiftCreate && (
										<Col style={{ display: "flex", justifyContent: "flex-end" }}>
											<Button className="btn-sh-p" type="primary" shape="circle" onClick={() => navigate('/shifting/shift/add-shift')} icon={<PlusOutlined />} size={"large"} />
										</Col>
									)}
									
							</Row>
						</Col>
					</Row>
					<div className="table-wrapper">
					{loading ? (
						<>
						<Table id={'my-table'}  columns={columns}  loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					) : (
						<>
						<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					)}	
					</div>
				</div>
					</>
          
        </>
    )
}