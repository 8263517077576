/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Pagination, Row, Modal, message } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { PlusOutlined, EditFilled } from "@ant-design/icons";
import { decryptData } from "../../helper/cryptojs";
import { formatCurrency } from "../../helper/formatCurrency";
import { DeleteBpjsMaster, getBpjsKesehatan } from "../../api/bpjs";

export default function BpjsKesehatan() {
  const navigate = useNavigate();
  const previousPage = useRef(1);
  const locationPath = useLocation();
  const BestpraticeUseEffect = useRef(false);
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const RoleAccessBpjsKesehatanUpdate = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeBpjsKesehatanUpdate")
  );
  const RoleAccessBpjsKesehatanCreate = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeBpjsKesehatanCreate")
  );
  const limitSession = sessionStorage.getItem("sessionPageBpjsMaster");
  const offsetSession = sessionStorage.getItem("sessionOffsetBpjsMaster");
  const sessionSearch = sessionStorage.getItem("sessionSearchBpjsMaster");
  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(limitSession ? parseInt(limitSession) : 1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(offsetSession ? offsetSession : 0);
  const [search, setSearch] = useState(sessionSearch ?? "");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState();

  const BpjsKesehatanMaster = async () => {
    setLoading(true);
    try {
      const res =
        locationPath.pathname !== "/bpjs-kesehatan"
          ? null
          : await getBpjsKesehatan(company, 1, page, offset, limit, search);
      setListTable(res?.data?.data);
      setTotalData(res?.data?.recordsTotal);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("sessionPageBpjsMaster", page);
  }, [page]);

  useEffect(() => {
    const limitSession = sessionStorage.getItem("sessionPageBpjsMaster");
    if (limitSession) {
      setPage(parseInt(limitSession));
    }
  }, []);

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      BpjsKesehatanMaster();

      const beforeUnloadHandler = () => {
        sessionStorage.removeItem("sessionPageBpjsMaster");
        sessionStorage.removeItem("sessionOffsetBpjsMaster");
        sessionStorage.removeItem("sessionSearchBpjsMaster");

        setSearch("");
      };
      window.addEventListener("beforeunload", beforeUnloadHandler);
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, [company, page, offset, limit, search]);

  useEffect(() => {
    if (page === 1) {
      setOffset(0);
    } else {
      setOffset(page * limit - 10);
    }
  }, [page]);

  const columns = [
    {
      title: "No",
      dataIndex: "index",
      key: "index",
      render: (text, record, index) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/bpjs-kesehatan/detail/${encodeURIComponent(
                  "id?" + record.m_bpjs_kesehatan_id
                )}`
              )
            }>
            {index + 1 + (page - 1) * limit}
          </div>
        );
      },
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/bpjs-kesehatan/detail/${encodeURIComponent(
                  "id?" + record.m_bpjs_kesehatan_id
                )}`
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "BPJS Kesehatan Item",
      dataIndex: "item",
      key: "item",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/bpjs-kesehatan/detail/${encodeURIComponent(
                  "id?" + record.m_bpjs_kesehatan_id
                )}`
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/bpjs-kesehatan/detail/${encodeURIComponent(
                  "id?" + record.m_bpjs_kesehatan_id
                )}`
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },

    {
      title: "Percentage Default",
      dataIndex: "presentase_default",
      key: "presentase_default",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/bpjs-kesehatan/detail/${encodeURIComponent(
                  "id?" + record.m_bpjs_kesehatan_id
                )}`
              )
            }>
            {`${text}%` || "-"}
          </div>
        );
      },
    },
    {
      title: "Minimum Basic Premium Value",
      dataIndex: "min_basic_premium",
      key: "min_basic_premium",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/bpjs-kesehatan/detail/${encodeURIComponent(
                  "id?" + record.m_bpjs_kesehatan_id
                )}`
              )
            }>
            {formatCurrency(text) || "-"}
          </div>
        );
      },
    },
    {
      title: "Maximum Basic Premium Value",
      dataIndex: "max_basic_premium",
      key: "max_basic_premium",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/bpjs-kesehatan/detail/${encodeURIComponent(
                  "id?" + record.m_bpjs_kesehatan_id
                )}`
              )
            }>
            {formatCurrency(text) || "-"}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "50px",
      render: (_, record, index) => {
        return RoleAccessBpjsKesehatanUpdate ? (
          <Row justify={"space-between"}>
            <div
              className="icon-action"
              onClick={() =>
                navigate(
                  `/bpjs-kesehatan/edit-bpjs-kesehatan/${encodeURIComponent(
                    "id?" + record?.m_bpjs_kesehatan_id
                  )}`
                )
              }>
              <EditFilled />
            </div>
          </Row>
        ) : null;
      },
    },
  ];
  const dataBreadcrumb = [
    {
      title: "Payroll Management",
      url: "/bpjs-kesehatan",
    },
    {
      title: "BPJS Kesehatan",
      url: `/bpjs-kesehatan`,
    },
  ];

  useEffect(() => {
    previousPage.current = page;
  }, [page]);

  const onChangeTable = (pageNumber, type) => {
    const offsetSession = pageNumber * limit - 10;
    setPage(pageNumber);
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPageBpjsMaster", pageNumber);
    sessionStorage.setItem("sessionOffsetBpjsMaster", offsetSession);
    //
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };
  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }
  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setLimit(10);
    setPage(1);
    sessionStorage.setItem("sessionSearchBpjsMaster", e.target.value);
  };

  const handleDelete = async (id, company) => {
    setLoading(true);
    try {
      const res = await DeleteBpjsMaster(id, company);
      message.success({
        content: "BPJS Kesehatan item has been successfully deleted",
      });
      window.location.reload("/");
      setLoading(false);
      console.log(res);
    } catch (error) {
      message.error({
        content: "Data failed to delete",
      });
      setLoading(false);
    }
  };

  const showModal = (id) => {
    setIsModalOpen(true);
    setDeleteId(id);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    handleDelete(deleteId, company);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Delete"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}>
        <p>Are you sure want to delete this data?</p>
      </Modal>
      <div className="title-section">BPJS Kesehatan</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
        {" "}
        <Row
          style={{ marginBottom: "24px", justifyContent: "end" }}
          align={"middle"}>
          <Col md={{ top: 20 }} className="mt-5 md:mt-0">
            <Row justify={"end"} style={{ gap: 15 }} md={14}>
              <InputWithIcon
                type="text"
                placeholder="Search BPJS Kesehatan"
                img={iconSearch}
                className="search-input w-[250px] btn-sh-sec"
                classImg="op-icon"
                onChange={onChangeSearch}
                value={search}
              />
              {RoleAccessBpjsKesehatanCreate && (
                <Button
                  className="bg-[#0281FF]"
                  type="primary"
                  shape="circle"
                  onClick={() => navigate("/bpjs-kesehatan/add-bpjs-kesehatan")}
                  icon={<PlusOutlined />}
                  size={"large"}
                />
              )}
            </Row>
          </Col>
        </Row>
        <div className="table-wrapper">
          {loading ? (
            <>
              <Table id={"my-table"} columns={columns} loading={loading} />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          ) : (
            <>
              <Table
                id={"my-table"}
                dataSource={dataTableWithNumber()}
                columns={columns}
                loading={loading}
              />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
