import { Button, Col, Form as FormAntd, message, Modal } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { InputWithLabel, NumberFormat } from "../../component/Form/Input";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { addInsurance, editInsurance, getInsuranceDetail } from "../../api/insurance";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";

const FormAddInsurance = () => {
    const navigate = useNavigate();
    const deCompanyId = decryptData(localStorage.getItem("DefaultCompanyId"));
    const companyStoreId = decryptData(sessionStorage.getItem("selectCompanyId"));
    const [fetching, setFetching] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const location = window.location.href;
    var n = location.split("/");
    var result = n[5];
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [detail, setDetail] = useState([]);
    const [flag, setFlag] = useState(0);
    const BestpraticeUseEffect = useRef(false)

    const insuranceDetail = async () => {
        setFetching(true);
        try {
            const res = await getInsuranceDetail(id)
            setDetail(res?.data?.data);
        } catch (err) {
            console.log(err);
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
        } else {
            if (result) {
                insuranceDetail();
            }
        }
    }, [flag]);

    const validationUser = yup.object().shape({
        code: yup.string().required("Field is required").matches(/^[a-z0-9A-Z]+$/, "Only number and characters are allowed"),
        name: yup.string().required("Field is required"),
        premiumValue: yup.string().required("Field is required"),
        companyId: yup.string().required("Field is required"),
    });
    const form = useFormik({
        initialValues: {
            code: result ? detail?.code : "",
            name: result ? detail?.name : "",
            premiumValue: result ? detail?.premium_value : "",
            companyId: result ? detail?.company_id : companyStoreId ? companyStoreId : deCompanyId,
        },
        enableReinitialize: true,
        validationSchema: validationUser,
        onSubmit: async (values) => {
            setFetching(true)
            try {
                const res = result ? await editInsurance(values, id) : await addInsurance(values);
                if (!res?.data?.success) {
                    message.error({
                        content: res?.data?.error,
                    });
                } else {
                    message.success({
                        content: result ? "Insurance successfully updated" : "Insurance successfully saved",
                    });
                    navigate('/insurance')
                    form.resetForm();
                    setFlag((prev) => prev + 1)
                }
                setFetching(false)
            } catch (error) {
                message.error({
                    content: "Request Not Found",
                });
                setFetching(false)
            }
        },
    });

    const dataBreadcrumb = [
        {
            title: "Payroll Management - Insurance",
            url: "/insurance"
        },
        {
            title: `${result ? 'Edit Insurance' : 'Add Insurance'}`,
            url: ``
        },
    ]

    const showModal = () => {
        if(form.isValid) {
            if (result) {
                setIsModalOpen(true);
            } else {
                form.handleSubmit()
            }
        }
    };

    const handleOk = () => {
        setIsModalOpen(false);
        form.handleSubmit()
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <>
            <div className="title-section">
                {result ? "Edit Insurance" : "Add Insurance"}
            </div>
            <Modal title="Edit" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <p>Are you sure want to change the data?</p>
            </Modal>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
                <Col span={24}>
                    <Breadcrumb items={dataBreadcrumb} />
                </Col>
            </div>
            <div className="add-wrapper">
                <FormAntd
                    onFinish={form.handleSubmit}
                >
                    <FormAntd.Item>
                        <InputWithLabel
                            name="code"
                            label={<div>Code<span style={{ color: "#FF0000" }}> *</span></div>}
                            placeholder="Code"
                            value={form?.values?.code}
                            onChange={(e) => {
                                const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                                if (!regex.test(e.target.value)) {
                                    form.handleChange(e);
                                }
                            }}
                            onBlur={form.handleBlur}
                            maxLength={10}
                        />
                        {form.touched.code && form.errors.code ? (
                            <span className="text-error">{form.errors.code}</span>
                        ) : null}
                    </FormAntd.Item>
                    <FormAntd.Item>
                        <InputWithLabel
                            name="name"
                            label={<div>Insurance Name<span style={{ color: "#FF0000" }}> *</span></div>}
                            placeholder="Name"
                            value={form?.values?.name}
                            onChange={(e) => {
                                const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                                if (!regex.test(e.target.value)) {
                                    form.handleChange(e);
                                }
                            }}
                            onBlur={form.handleBlur}
                            maxLength={50}
                        />
                        {form.touched.name && form.errors.name ? (
                            <span className="text-error">{form.errors.name}</span>
                        ) : null}
                    </FormAntd.Item>
                    <FormAntd.Item>
                        <NumberFormat
                            name="premiumValue"
                            thousandSeparator={true}
                            label={<div>Premium Value<span style={{ color: "#FF0000" }}> *</span></div>}
                            placeholder="Rp."
                            value={form?.values?.premiumValue}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            allowNegative={false}
                        />
                        {form.touched.premiumValue && form.errors.premiumValue ? (
                            <span className="text-error">{form.errors.premiumValue}</span>
                        ) : null}
                    </FormAntd.Item>
                    <FormAntd.Item>
                        <InputWithLabel
                            name="companyCode"
                            type="hidden"
                            placeholder="Name"
                            value={form?.values?.companyCode}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                        />
                        {form.touched.companyCode && form.errors.companyCode ? (
                            <span className="text-error">{form.errors.companyCode}</span>
                        ) : null}
                    </FormAntd.Item>
                </FormAntd>
                <div className="footer-add">
                    <Button key="back" className="btn btn-sec" style={{ marginRight: "14px" }} onClick={() => navigate('/insurance')}>
                        Cancel
                    </Button>,
                    <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={showModal} disabled={form.isSubmitting}>
                        Submit
                    </Button>
                </div>
            </div>
        </>
    )
}

export default FormAddInsurance;