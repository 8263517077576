
import { Button, Col, Form as FormAntd, Row, Select, message,Popconfirm } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { SelectSearchWithLabel } from "../../../component/Form/Input";
import { decryptData } from "../../../helper/cryptojs";
import moment from "moment";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import '../styles.scss'
import { DeleteOutlined } from '@ant-design/icons'; 
import { addSetSchedule, deleteDatesetSchedule, editSetSchedule, getListInputNikSetSchedule, getListShiftingInputSetSchedule, getSetScheduleDetail } from "../../../api/setSchedule";
import Breadcrumb from "../../../component/Breadcrumb";
const FormAddSetSceduleShift = () => {
    const navigate = useNavigate();
    const deCompanyCode = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const [fetching, setFetching] = useState(false);
    const location = window.location.href;
    var n = location.split("/");
    var result =  n[6];
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [detail, setDetail] = useState([]);
    const [flag, setFlag] = useState(0);
    const [selectedOption, setSelectedOption] = useState('');
    const [options, setOptions] = useState([]);
    const [options1, setOptions1] = useState([]);
    const { Option } = Select;
    const BestpraticeUseEffect = useRef(false)
    const [activeDates, setActiveDates] = useState([]);
    const [activeShifts, setActiveShifts] = useState({});
    const [selectedDates, setSelectedDates] = useState([]);
    const [dateOptions, setDateOptions] = useState({});
   
      const SetScheduleDetail = async () => {
      try {
        const res = await getSetScheduleDetail(id);
        const data = res?.data?.data;
        setDetail(data);

        // Mendapatkan data shift name dan tanggal aktif dari API
        const activeDates = data?.schedule?.map((item) => moment(item.date).toDate());
        setActiveDates(activeDates || []);

        // Memperbarui state activeShifts dengan data nama shift dari API
        const shiftNames = data?.schedule?.reduce(
          (acc, item) => ({ ...acc, [moment(item.date).format('YYYY-MM-DD')]: item.name }),
          {}
        );
        setActiveShifts(shiftNames || {});
      } catch (err) {
        console.log(err);
      } finally {
        setFetching(false);
      }
    };


    // useEffect(() => {
    //   if (detail && detail.schedule) {
    //     const dates = detail.schedule.map((item) => moment(item.date).toDate());
    //     setSelectedDates(dates);
    
    //     // Memperbarui state dateOptions dengan data m_shifting_id dari API
    //     const shiftNames = detail?.schedule?.reduce(
    //       (acc, item) => ({ ...acc, [moment(item.date).format('YYYY-MM-DD')]: item.m_shifting_id }),
    //       {}
    //     );
    //     setDateOptions(shiftNames);
    
    //     // Memperbarui state activeShifts dengan data m_shifting_id dari API
    //     const activeShiftsData = detail?.schedule?.reduce(
    //       (acc, item) => ({ ...acc, [moment(item.date).format('YYYY-MM-DD')]: item.m_shifting_id }),
    //       {}
    //     );
    //     setActiveShifts(activeShiftsData);
    
    //     setActiveDates(dates);
    //   }
    // }, [detail]);
    

    const ShiftingInputSetScheduleList = async () => {
      try {
          const res = await getListShiftingInputSetSchedule(deCompanyCode)
          const data = res?.data?.data;
          // console.log('testing',data)
          setOptions(data)
        } catch (err) {
          console.log(err);
        }finally {
          setFetching(false);
          
        };
  };

    const SetscheduleListNik = async () => {
        try {
            const res = await getListInputNikSetSchedule(deCompanyCode)
            const data = res?.data?.data;
            // console.log('testing',data)
            setOptions1(data)
          } catch (err) {
            console.log(err);
          }
	  };


    useEffect(() => {
          if (!BestpraticeUseEffect.current) {
              BestpraticeUseEffect.current = true;
            } else {
              SetScheduleDetail();
              SetscheduleListNik();
              ShiftingInputSetScheduleList();
            }
    }, [flag]);



    const handleSelectChange = (value) => {
        setSelectedOption(value);
        form.setValues({
            ...form.values,
            employeeNumber: value,
        })
        // Mendapatkan nilai full_name terkait dari data API
        const selectedData = options1.find(option => option.employee_number === value);
        if (selectedData) {
        form.setFieldValue('EmployeeName',selectedData ? selectedData.full_name : '');
        form.setFieldValue('DepartmentName',selectedData ? selectedData.department_name : '');
        form.setFieldValue('DivisionName',selectedData ? selectedData.division_name : '');
        form.setFieldValue('PositionName',selectedData ? selectedData.position_name : '');
        } else {
        form.setFieldValue('EmployeeName');
        form.setFieldValue('DepartmentName');
        form.setFieldValue('DivisionName');
        form.setFieldValue('PositionName');
        }
    };

    
      const form = useFormik({
          initialValues: {
            employeeNumber: result ? detail?.employee_number : "",
            scheduleData: result ? detail?.schedule : [{}],
          },
          enableReinitialize: true,
          // validationSchema: validationUser,
           onSubmit: async(values) => {
            
              const scheduleData = Object.entries(dateOptions).map(([date, mshiftingId]) => ({
                  mshiftingId: mshiftingId,
                  date: date,
               }));
              // const activeScheduleData = selectedDates.map((date) => {
              //   const mshiftingId = activeShifts[moment(date).format("YYYY-MM-DD")];
              //   if (!mshiftingId && result) {
              //     throw new message.error("Please select a shift for the active dates.");
              //   }
              //   return {
              //     mshiftingId: mshiftingId,
              //     date: moment(date).format("YYYY-MM-DD"),
              //   };
              // });   
              values.scheduleData = scheduleData ;
              // values.scheduleData = result ?  activeScheduleData  : scheduleData ;
              setFetching(true)
              try {
                  const res = result ? await editSetSchedule(values, id): await addSetSchedule(values);
                  if(!res?.data?.success){
                      message.error({
                          content: res?.data?.error,
                      });
                  } else {
                      message.success({
                          content: result ? "Schedule successfully updated" : "Schedule successfully saved",
                      });
                      navigate("/shifting/set-schedule")
                      form.resetForm();
                      setFlag((prev) => prev + 1)
                  

                  }
                  setFetching(false)
              } catch (error) {
                  message.error({
                      content: "Request Not Found",
                  });
                  setFetching(false)
              }
          },
      });
   
   
   

    const handleDateClick = (date) => {
      if (date) {
        const dateIndex = selectedDates.findIndex((selectedDate) =>
          isSameDay(selectedDate, date)
        );
    
        if (dateIndex > -1) {
          // Date already selected, remove it from the array
          setSelectedDates((prevSelectedDates) =>
            prevSelectedDates.filter((_, index) => index !== dateIndex)
          );
        } else {
          // Date not selected yet, add it to the array
          setSelectedDates((prevSelectedDates) => [...prevSelectedDates, date]);
        }
      }
    };
    

    const tomorrow = moment().add(1, 'day').startOf('day');
    const isPastDate = (date) => {
      const today = result ?  moment().startOf('yesterday') :  moment().startOf('tommorow') ;
      return moment(date).isBefore(today);
    };
    
 
   
    const handleOptionChange = (date, value) => {
      const dateKey = moment(date).format('YYYY-MM-DD');
      setDateOptions((prevDateOptions) => ({
        ...prevDateOptions,
        [dateKey]: value,
      }));
    
    
      setActiveShifts((prevActiveShifts) => ({
        ...prevActiveShifts,
        [dateKey]: value,
      }));      
    };
    
    
    const isSameDay = (dateA, dateB) => {
      return (
        dateA.getDate() === dateB.getDate() &&
        dateA.getMonth() === dateB.getMonth() &&
        dateA.getFullYear() === dateB.getFullYear()
      );
    };
    
    
    const tileClassName = ({ date }) => {
      const isSelected = selectedDates.some((selectedDate) => isSameDay(selectedDate, date));
      const isActive = activeDates.some((activeDate) => isSameDay(activeDate, date));
      return isSelected ? 'selected' : isActive ? 'active' : null;
    };
    
    const tileContent = ({ date }) => {
      const isSelected = selectedDates.some((selectedDate) => isSameDay(selectedDate, date));
      const isActive = activeDates.some((activeDate) => isSameDay(activeDate, date));
      const isDisabled = isPastDate(date);
   
      return (
        <div className="tile-content-wrapper">

          {isSelected && <div className="selected-marker"></div>}

          <Select
            onChange={(value) => handleOptionChange(date, value)}
            style={{ width: '100%'}}
            placeholder="Select Shift"
            disabled={isDisabled}
            value={activeShifts[moment(date).format('YYYY-MM-DD')] || undefined}
          >
            {options.map((option) => (
              <Option key={option.m_shifting_id} value={option.m_shifting_id}>
                {option.name}
              </Option>
            ))}
          </Select>
     
          {result && isActive   && !isDisabled && (
          <Popconfirm
            title="Are you sure to delete this schedule?"
            onConfirm={() => handleDeleteSchedule(date)}
            okText="Yes"
            cancelText="No"
            className="mt-2"
          >
            <DeleteOutlined  className="delete-icon" />
          </Popconfirm>
        )}
        </div>
      );
    };
  
   
    const handleDeleteSchedule = async (date) => {
      try {
        const dateFormatted = moment(date).format('YYYY-MM-DD');
        const dataToDelete = {
          employeeNumber: form.values.employeeNumber,
          date: dateFormatted,
        };
        await deleteDatesetSchedule(dataToDelete);
        message.success({
          content: "Schedule successfully deleted",
        });
        setFlag((prev) => prev + 1);
      } catch (error) {
        message.error({
          content: "Failed to delete schedule",
        });
      }
    };
    
    

    const getDefaultSelectedDates = () => {
      const currentDate = moment();
      const firstDayOfMonth = currentDate.clone().startOf('month');
      const lastDayOfMonth = currentDate.clone().endOf('month');
      const defaultSelectedDates = [];
    
      for (let i = firstDayOfMonth.date(); i <= lastDayOfMonth.date(); i++) {
        const date = firstDayOfMonth.clone().date(i);
        if (!isPastDate(date)) {
          defaultSelectedDates.push(date.toDate());
        }
      }
    
      return defaultSelectedDates;
    };
    
   
    const defaultSelectedDates = getDefaultSelectedDates();
    useState(() => {
      setSelectedDates(defaultSelectedDates)
    }, []);

    const dataBreadcrumb = [
      {
        title: "Master Data - Set Schedule",
        url: "/shifting/set-schedule"
      },
      {
        title: `${result ?  'Edit Schedule' : 'Add Schedule'}` ,
        url: `${ result ?  `/shifting/set-schedule/edit-set-schedule/${result}` : '/shifting/set-schedule/add-set-schedule'}`
      },
    ];

    return (
        <>
          <div className="title-section">
				      {result ? "Edit Schedule" : "Add Schedule"}
		  	    </div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
              <Col span={24}>
                <Breadcrumb items={dataBreadcrumb}/>
              </Col>
            </div>
        <div className="add-wrapper">
            <FormAntd
                onFinish={form.handleSubmit}
            >
                <Row justify={"space-between"}>
                    <Col span={24}>
                        <FormAntd.Item>
                            <SelectSearchWithLabel
                                name="employeeNumber"
                                labelname={<div>Employee<span style={{color:"#FF0000"}}> *</span></div>}
                                showSearch
                                allowClear
                                placeholder="Select Employee"
                                optionFilterProp="children"
                                onChange={handleSelectChange}
                                disabled={result ? true : false}
                                value={selectedOption ? selectedOption : detail?.employee_number}
                                filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {options1.map(option => (
                                <Option key={option.employee_number} value={option.approver_id}>{option.employee_option}</Option>
                                ))}
                            </SelectSearchWithLabel>
                        </FormAntd.Item>
                    </Col>
                </Row>
              
                <FormAntd.Item>
                    <div>
                        <Calendar
                          tileClassName={tileClassName}
                          tileContent={tileContent}
                          onClickDay={handleDateClick}
                          className="custom-calendar"
                          minDate={tomorrow.toDate()}
                        />
                    </div>
                </FormAntd.Item>
               
          
            </FormAntd>
            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/shifting/set-schedule/')}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </div>
        </>
    )
}

export default FormAddSetSceduleShift;