import { Button, Col, message, Modal, Steps } from "antd";
import { useEffect, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import { decryptData, encryptData } from "../../helper/cryptojs";
import "./styles.scss";
import ContentReview from "../../component/molecules/ReviewRunPayroll";
import EmployeeSelector from "../../component/Organisms/EmployeeSelector";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  CutOffCompany,
  PreviewRunPayroll,
  Reviews,
} from "../../api/runPayroll";
import SetUpPeriodRunPayroll from "../../component/molecules/SetUpPeriodRunPayroll";
import SelectComponentRunPayroll from "../../component/molecules/SelectComponentRunPayroll";
import dayjs from "dayjs";
import { InfoCircleOutlined } from "@ant-design/icons";
import RunPayrollSuccess from "../../component/molecules/RunPayrollSuccess";
import { formatArrayToObject } from "../../helper/formatArrayToObject";
import { useNavigate, useSearchParams } from "react-router-dom";

export default function RunPayroll() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const idParams = searchParams.get("id");
  const id = decryptData(sessionStorage.getItem("storeIdValues"));
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const companyId = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompanyId"))
    : decryptData(localStorage.getItem("DefaultCompanyId"));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fetching, setFetching] = useState(false);
  const [current, setCurrent] = useState(0);
  const [isSelectedEmployee, setIsSelectedEmployee] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [totalData, setTotalData] = useState([]);
  const [isPreview, setIsPreview] = useState([]);
  const [isTotalPreview, setIsTotalPreview] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [initialValues, setInitialValues] = useState("");
  const [isErrorsCustomSalaries, setIsErrorsCustomSalaries] = useState({});

  const validationSchemaSetUpPeriode = yup.object().shape({
    period: yup.string().required("Field is required"),
    start_date: yup.string().required("Field is required"),
  });
  const validationUser = yup.object().shape({
    allowances: yup
      .number()
      .typeError("Field is required")
      .required("Field is required"),
    deductions: yup
      .number()
      .typeError("Field is required")
      .required("Field is required"),
  });

  const form = useFormik({
    initialValues: {
      company_id: companyId,
      cut_off_type: totalData?.cut_off_type,
      period: "",
      start_date: "",
      end_date: "",
      allowances: "",
      deductions: "",
      select_employees: "",
      all_employee: "",
      custom_salaries: "",
      draw: "",
      start: "",
      length: "",
    },
    enableReinitialize: true,
    validationSchema:
      current === 0 ? validationSchemaSetUpPeriode : validationUser,

    validate: (values) => {
      const errors = {};

      if (current === 1) {
        if (values.allowances && values.allowances.length === 0) {
          errors.allowances = "Field is required";
        } else if (values.deductions && values.deductions.length === 0) {
          errors.deductions = "Field is required";
        }
      }

      return errors;
    },

    onSubmit: async (values) => {
      const submitValues = {
        ...values,
        allowances: formatArrayToObject(values.allowances),
        deductions: formatArrayToObject(values.deductions),
        custom_salaries: formatArrayToObject(values.custom_salaries),
        select_employees: formatArrayToObject(values.select_employees),
      };

      setInitialValues(submitValues);
      sessionStorage.setItem('storeValues', encryptData(submitValues))

      setFetching(true);
      try {
        if (current == 1) {
          const res = await PreviewRunPayroll(submitValues);
          setIsPreview(res?.data?.data);
          setIsTotalPreview(res?.data?.recordsTotal);
          message.success({
            content: "Select Component saved",
          });
          setCurrent(current + 1);
        } else if (current == 2) {
          const response = await Reviews(submitValues);
          sessionStorage.setItem('storeIdValues', encryptData(response?.data?.data?.id))
          sessionStorage.removeItem("selectedEmployees");
          setCurrent(current + 1);
          navigate(`/run-payroll?id=${response?.data?.data?.id}`);
        }
      } catch (error) {
        message.error({
          content: error?.response?.data?.error,
        });
      } finally {
        setFetching(false);
      }
    },
  });

  const fetchPreviewRunPayroll = async () => {
    try {
      const res =
        current === 2
          ? await PreviewRunPayroll(initialValues, page, offset, limit)
          : null;

      setIsPreview(res?.data?.data);
      setIsTotalPreview(res?.data?.recordsTotal);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPreviewRunPayroll();
  }, [initialValues, page]);

  useEffect(() => {
    form.setFieldValue("select_employees", isSelectedEmployee);
    let startDate, endDate;
    if (selectedDateRange) {
      const startRange = dayjs(selectedDateRange[0]);
      const endRange = dayjs(selectedDateRange[1]);
      if (startRange.isValid() && endRange.isValid()) {
        startDate = startRange.format("YYYY-MM-DD");
        endDate = endRange.format("YYYY-MM-DD");
      }
    } else {
      const periodMonth = dayjs(form.values.period, "MMMM").month();
      if (isNaN(periodMonth)) {
        return;
      } else {
        startDate = dayjs()
          .set("month", periodMonth - 1)
          .set("date", totalData?.cut_off_date + 1)
          .format("YYYY-MM-DD");
        const cutOffDate = totalData?.cut_off_date;
        if (cutOffDate && !isNaN(cutOffDate)) {
          endDate = dayjs()
            .set("month", periodMonth)
            .set("date", cutOffDate)
            .format("YYYY-MM-DD");
        } else {
          endDate = dayjs()
            .set("month", periodMonth)
            .endOf("month")
            .format("YYYY-MM-DD");
        }
      }
    }

    if (startDate && endDate) {
      form.setFieldValue("start_date", startDate);
      form.setFieldValue("end_date", endDate);
    }
  }, [
    isSelectedEmployee,
    selectedDateRange,
    form.values.period,
    totalData?.cut_off_date,
  ]);

  const fetchGetRunPayroll = async () => {
    setFetching(true);
    try {
      const res = await CutOffCompany(company);
      setTotalData(res?.data?.data);
    } catch (err) {
      console.error(err);
    }
    setFetching(false);
  };

  useEffect(() => {
    fetchGetRunPayroll();
    return () => {
      sessionStorage.removeItem("sessionPageRunPayroll");
      sessionStorage.removeItem("sessionOffsetRunPayroll");
      sessionStorage.removeItem("sessionSearchRunPayroll");
      sessionStorage.removeItem("selectStoreBranch");
      sessionStorage.removeItem("selectedEmployees");
    };
  }, [company]);

  const dataBreadcrumb = [
    {
      title: "Payroll Management - Run Payroll",
      url: "/run-payroll",
    },
    {
      title: "Run Payroll",
      url: `/run-payroll`,
    },
  ];

  const steps = [
    {
      title: <div className="text-sm 2xl:text-base">Set Up Period</div>,
      content: isModalOpen ? (
        <div className="flex justify-center">
          <EmployeeSelector
            setIsModalOpen={setIsModalOpen}
            isModalOpen={isModalOpen}
            setIsSelectedEmployee={setIsSelectedEmployee}
            isSelectedEmployee={isSelectedEmployee}
            form={form}
            period={form.values?.start_date}
          />
        </div>
      ) : (
        <SetUpPeriodRunPayroll
          isSelectedEmployee={isSelectedEmployee}
          setIsSelectedEmployee={setIsSelectedEmployee}
          setIsModalOpen={setIsModalOpen}
          setSelectedDateRange={setSelectedDateRange}
          selectedDateRange={selectedDateRange}
          form={form}
          totalData={totalData}
          loading={fetching}
        />
      ),
    },
    {
      title: <div className="text-sm 2xl:text-base ">Select Component</div>,
      content: (
        <SelectComponentRunPayroll
          setIsModalOpen={setIsModalOpen}
          form={form}
          isErrorField={isErrorsCustomSalaries}
        />
      ),
    },
    {
      title: (
        <div className="text-sm 2xl:text-base relative right-2 md:right-0 2xl:right-1">
          Review
        </div>
      ),
      content: (
        <ContentReview
          form={form}
          IsViewtotalData={totalData}
          isTotalPreview={isTotalPreview}
          isPreview={isPreview}
          page={page}
          setPage={setPage}
          setOffset={setOffset}
          limit={limit}
          loading={fetching}
          setLimit={setLimit}
        />
      ),
    },
  ];

  let next;

  if (current === 0) {
    next = async () => {
      form.setTouched({
        period: true,
        start_date: true,
      });
      if (
        !form.values.period ||
        (form.values.period &&
          (form?.values?.select_employees
            ? !form.values?.select_employees
            : !form.values?.all_employee)) ||
        !form.values.start_date
      ) {
        return;
      }
      setCurrent(current + 1);
      message.success({
        content: "Set Up Periode saved",
      });
    };
  } else if (current === 1) {
    next = async () => {
      form.setTouched({
        allowances: true,
        deductions: true,
      });

      const errors = await form.validateForm();

      const customSalariesErrors = {};
      form.values.custom_salaries.forEach((salary, index) => {
        if (salary.id && !salary.file) {
          customSalariesErrors[`custom_salaries${index}`] = "File is required";
        } else if (!salary.id && salary.file) {
          customSalariesErrors[`custom_salaries${index}`] = "Field is required";
        }
      });

      const allErrors = { ...errors, ...customSalariesErrors };

      if (!Object.keys(allErrors).length) {
        await form.submitForm();
        setCurrent(current + 1);
      }
      setIsErrorsCustomSalaries(customSalariesErrors);
    };
  }

  const prev = () => {
    setCurrent(current - 1);
  };

  const items = steps.map((item) => ({
    key: item.title,
    title: item.title,
  }));

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      await form.submitForm();
      setIsModalVisible(false);
    } catch (error) {
      error(error.message);
    }
  };

  const handleIsPrivillege = () => {
    window.location.replace("/company");
    localStorage.setItem("SelectedKeys", JSON.stringify(["employee34"]));
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <div className="title-section">Run Payroll</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      {current === 3 || idParams === id?.toString() ? (
        <>
          <div className="border bg-white rounded-lg mt-2 shadow-lg flex justify-center text-center items-center">
            <div className="table-wrapper">
              <RunPayrollSuccess form={form} IsViewtotalData={totalData} />
            </div>
          </div>
        </>
      ) : (
        <>
          <Modal
            title="Run Payroll"
            visible={isModalVisible}
            closeIcon={null}
            footer={[
              <div className="flex justify-end gap-2 py-2 items-center">
                <div
                  className="cursor-pointer py-2 px-6 section-modal  rounded-lg "
                  key="ok"
                  onClick={handleCancel}>
                  Close
                </div>
                <div
                  className="cursor-pointer py-2 px-6 bg-[#1890ff] text-white hover:bg-[#8bc9ff] rounded-lg"
                  key="ok"
                  onClick={handleOk}>
                  Run
                </div>
              </div>,
            ]}>
            <p>Are you sure to start payroll calculation?</p>
          </Modal>

          {totalData ? (
            <div className="border bg-white rounded-lg mt-2 shadow-lg">
              <div className="table-wrapper">
                <div className="iconWrapper w-full py-8 px-5 xl:w-1/2 2xl:w-1/2 mx-auto ">
                  <Steps
                    current={current}
                    items={items}
                    labelPlacement="vertical"
                  />
                </div>
                <div
                  className={`${
                    current === 2
                      ? "px-10"
                      : "flex flex-col justify-center px-6 w-[60%] mx-auto"
                  }`}>
                  {steps[current]?.content}
                  {isModalOpen ? null : (
                    <div className="flex justify-end items-center mt-[-30px] pb-10">
                      {current > 0 && (
                        <Button
                          className="py-6 font-semibold text-lg px-10 flex items-center"
                          style={{ margin: "0 8px" }}
                          onClick={() => prev()}>
                          Back
                        </Button>
                      )}

                      {current < steps.length - 1 && (
                        <Button
                          key="submit"
                          className="py-6 font-semibold text-lg px-10 flex items-center"
                          type="primary"
                          loading={fetching}
                          onClick={next}>
                          Next
                        </Button>
                      )}

                      {current === steps.length - 1 && (
                        <Button
                          className="py-6 font-semibold text-lg px-10 flex items-center"
                          type="primary"
                          onClick={showModal}
                          disabled={!form.isValid || fetching}>
                          Done
                        </Button>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <Modal
              visible={!isModalOpen}
              onOk={handleIsPrivillege}
              footer={[
                <div className="flex justify-center py-2">
                  <Button
                    type="primary"
                    className="btn"
                    key="ok"
                    onClick={handleIsPrivillege}>
                    OK
                  </Button>
                </div>,
              ]}
              width={600}
              closable={false}
              centered>
              <div className="text-center pt-2">
                <InfoCircleOutlined
                  style={{
                    fontSize: "100px",
                    color: "#1890ff",
                  }}
                />
                <h2>Announcement</h2>
                <p className="text-lg font-medium">
                  Please select cut off type first
                </p>
              </div>
            </Modal>
          )}
        </>
      )}
    </>
  );
}
