export default function deleteIcon({ size = 49, fill = "#FF0000" }) {
  return (
    <div>
      <svg
        width={size}
        height={size}
        viewBox="0 0 49 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M24.5003 8.16602C15.4782 8.16602 8.16699 15.4793 8.16699 24.4993C8.16699 33.5194 15.4782 40.8327 24.5003 40.8327C33.5225 40.8327 40.8337 33.5194 40.8337 24.4993C40.8337 15.4793 33.5225 8.16602 24.5003 8.16602ZM32.0688 29.1809C32.4516 29.5637 32.6667 30.0829 32.6667 30.6243C32.6667 31.1658 32.4516 31.685 32.0688 32.0678C31.686 32.4506 31.1667 32.6657 30.6253 32.6657C30.0839 32.6657 29.5647 32.4506 29.1819 32.0678L24.5003 27.3863L19.8188 32.0678C19.6296 32.2581 19.4047 32.409 19.157 32.5121C18.9093 32.6151 18.6436 32.6681 18.3753 32.6681C18.107 32.6681 17.8414 32.6151 17.5937 32.5121C17.3459 32.409 17.121 32.2581 16.9319 32.0678C16.7421 31.8784 16.5916 31.6534 16.4888 31.4057C16.3861 31.158 16.3332 30.8925 16.3332 30.6243C16.3332 30.3562 16.3861 30.0907 16.4888 29.843C16.5916 29.5953 16.7421 29.3703 16.9319 29.1809L21.6134 24.4993L16.9319 19.8178C16.549 19.435 16.334 18.9158 16.334 18.3743C16.334 17.8329 16.549 17.3137 16.9319 16.9309C17.3147 16.5481 17.8339 16.333 18.3753 16.333C18.9167 16.333 19.436 16.5481 19.8188 16.9309L24.5003 21.6124L29.1819 16.9309C29.5647 16.5481 30.0839 16.333 30.6253 16.333C31.1667 16.333 31.686 16.5481 32.0688 16.9309C32.4516 17.3137 32.6667 17.8329 32.6667 18.3743C32.6667 18.9158 32.4516 19.435 32.0688 19.8178L27.3872 24.4993L32.0688 29.1809Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}
