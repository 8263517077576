import { Button, Collapse, Form as FormAntd } from "antd";
import "./styles.scss";
import {
  SelectMultipleWithLabel,
  SelectOptionWithLabelPeriode,
} from "../../Form/Input";
import {
  SettingOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { Plus } from "../../../assets/icons";
import {
  getCustomSalaryItem,
  getSelectAllowance,
  getSelectDeduction,
} from "../../../api/runPayroll";
import { decryptData } from "../../../helper/cryptojs";

import { useSelector, useDispatch } from "react-redux";
import {
  addCustomSalary,
  updateCustomSalary,
  removeCustomSalary,
} from "../../../store/actions";

const SelectComponentRunPayroll = (props) => {
  const { form, isErrorField } = props;
  const companyId = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompanyId"))
    : decryptData(localStorage.getItem("DefaultCompanyId"));
  const inputRefs = useRef([]);
  const [data, setData] = useState([]);
  const [isDeduction, setIsDeduction] = useState([]);
  const [isCustomItem, setIsCustomItem] = useState([]);
  const dispatch = useDispatch();
  const customSalaries = useSelector((state) => state.customSalaries);

  const fetchGetSelectAllowance = async () => {
    try {
      const res = await getSelectAllowance(companyId);
      setData(res?.data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchGetSelectDeduction = async () => {
    try {
      const res = await getSelectDeduction(companyId);
      setIsDeduction(res?.data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  const fetchGetCustomSalaryItem = async () => {
    try {
      const res = await getCustomSalaryItem(companyId);
      setIsCustomItem(res?.data?.data);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchGetSelectAllowance();
    fetchGetCustomSalaryItem();
    fetchGetSelectDeduction();
  }, []);

  useEffect(() => {
    form.setFieldValue("custom_salaries", customSalaries);
  }, [customSalaries]);

  const handleAddSalaryUpload = () => {
    if (customSalaries.length < 5) {
      dispatch(addCustomSalary({ id: null, file: null }));
    }
  };

  const handleFileUpload = (e, index) => {
    const file = e.target.files[0];
    if (file) {
      dispatch(updateCustomSalary(index, { ...customSalaries[index], file }));
    }

    setIsRequired((prevIsRequired) => {
      const newIsRequired = { ...prevIsRequired };
      newIsRequired[index] = true;
      return newIsRequired;
    });
  };

  const handleFileDelete = (index) => {
    dispatch(
      updateCustomSalary(index, { ...customSalaries[index], file: null })
    );

    if (inputRefs.current[index]) {
      inputRefs.current[index].value = null;
    }

    setIsRequired((prevIsRequired) => {
      const newIsRequired = { ...prevIsRequired };
      newIsRequired[index] = false;
      return newIsRequired;
    });
    setIsRequiredFile((prevIsRequired) => {
      const newIsRequired = { ...prevIsRequired };
      newIsRequired[index] = true;
      return newIsRequired;
    });
  };

const handleRemoveSalaryUpload = (index) => {
  form.setFieldValue(`custom_salaries${index}`, undefined);
  dispatch(removeCustomSalary(index));
    if (inputRefs.current[index]) {
      inputRefs.current[index].value = null;
    }
  
};

  const handleComponentPayrollChange = (val, index) => {
    dispatch(updateCustomSalary(index, { ...customSalaries[index], id: val }));
    form.setFieldValue(`custom_salaries${index}`, val);
  };

  const [isRequired, setIsRequired] = useState({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
  });
  const [isRequiredFile, setIsRequiredFile] = useState({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
  });

  const handleIndexChange = (val, index) => {
    setIsRequired((prevIsRequired) => {
      const newIsRequired = { ...prevIsRequired };
      newIsRequired[index] = val === undefined ? true : false;
      return newIsRequired;
    });
    setIsRequiredFile((prevIsRequired) => {
      const newIsRequired = { ...prevIsRequired };
      newIsRequired[index] = val === undefined ? false : true;
      return newIsRequired;
    });
  };
  const handleIndexChangeFile = (e, index) => {
    setIsRequiredFile((prevIsRequired) => {
      const newIsRequired = { ...prevIsRequired };
      newIsRequired[index] = e === undefined ? true : false;
      return newIsRequired;
    });
  };

  return (
    <>
      <div>
        <p className="font-semibold text-xl text-[#505050] mb-2">
          Select Component
        </p>
        <div className="text-sm text-[#505050] leading-5">
          You can select component for run payroll
        </div>
      </div>
      <div className="py-5">
        <FormAntd onFinish={form.handleSubmit}>
          <FormAntd.Item>
            <SelectMultipleWithLabel
              name="allowances"
              label={
                <div>
                  Select Allowance
                  <span className="text-red-500">*</span>
                </div>
              }
              placeholder="Select Allowance"
              value={form.values.allowances || []}
              items={data.map((item) => ({
                value: item.id,
                title: item.name,
              }))}
              onChange={(val) =>
                form.setValues({
                  ...form.values,
                  allowances: val,
                })
              }
              onBlur={form.handleBlur}
            />
            {form.touched.allowances && form.errors.allowances && (
              <div className="relative">
                <span className="text-error absolute">
                  {form.errors.allowances}
                </span>
              </div>
            )}
          </FormAntd.Item>
          <FormAntd.Item>
            <SelectMultipleWithLabel
              name="deductions"
              label={
                <div>
                  Select Deduction
                  <span className="text-red-500">*</span>
                </div>
              }
              placeholder="Select Deduction"
              value={form.values.deductions || []}
              items={isDeduction.map((item) => ({
                value: item.id,
                title: item.name,
              }))}
              onChange={(val) =>
                form.setValues({
                  ...form.values,
                  deductions: val,
                })
              }
              onBlur={form.handleBlur}
            />
            {form.touched.deductions && form.errors.deductions && (
              <div className="relative">
                <span className="text-error absolute">
                  {form.errors.deductions}
                </span>
              </div>
            )}
          </FormAntd.Item>
          <FormAntd.Item>
            <p className="font-semibold text-xl text-[#505050] mb-2">
              Upload custom salary item
            </p>
            <div className="text-sm text-[#505050] leading-5">
              You can "also" upload custom salary item for run payroll
            </div>
            <div className="mt-10">
              <Collapse expandIcon={() => null}>
                <Collapse.Panel
                  header={
                    <div>
                      <SettingOutlined className="mr-2" />
                      Custom Salary
                    </div>
                  }
                  key="1">
                  {customSalaries.map((upload, index) => (
                    <div key={index} className="mb-5">
                      <div className="mb-10">
                        <FormAntd.Item>
                          <SelectOptionWithLabelPeriode
                            className="component_payroll"
                            placeholder="Component Payroll"
                            name={`custom_salaries${index}`}
                            label={<div>Component Payroll</div>}
                            items={isCustomItem.map((item) => ({
                              value: item.id,
                              title: item.name,
                            }))}
                            value={
                              form.values[`custom_salaries${index}`] ? form.values[`custom_salaries${index}`] : null
                            }
                            onChange={(val) => {
                              handleComponentPayrollChange(val, index);
                              handleIndexChange(val, index);
                            }}
                            onBlur={form.handleBlur}
                            allowClear={true}
                          />
                          {isErrorField?.[`custom_salaries${index}`] ===
                            "Field is required" &&
                            isRequired?.[index] && (
                              <div className="relative">
                                <span className="text-error absolute top-3">
                                  Field is required
                                </span>
                              </div>
                            )}
                        </FormAntd.Item>
                      </div>
                      <div className="mb-4">
                        <FormAntd.Item>
                          <div className="text-base mb-2 text-[#8697a8]">
                            File
                          </div>
                          <label className="file-upload hover:text-white">
                            <input
                              type="file"
                              name={`attachment_${index}`}
                              id={`attachment_${index}`}
                              onChange={(e) => {
                                handleFileUpload(e, index);
                                handleIndexChangeFile(e, index);
                              }}
                              accept=".xls, .xlsx"
                              ref={(el) => (inputRefs.current[index] = el)}
                            />
                            <CloudUploadOutlined /> Uploading Template Payroll
                          </label>
                          {upload.file && (
                            <div className="mt-2">
                              <p>
                                {upload.file.name}{" "}
                                <Button
                                  shape="circle"
                                  onClick={() => handleFileDelete(index)}
                                  icon={
                                    <DeleteOutlined style={{ color: "red" }} />
                                  }
                                  size={"small"}
                                />{" "}
                              </p>
                            </div>
                          )}
                          {isErrorField?.[`custom_salaries${index}`] ===
                            "File is required" &&
                            isRequiredFile?.[index] && (
                              <div className="relative">
                                <span className="text-error absolute">
                                  Field is required
                                </span>
                              </div>
                            )}
                        </FormAntd.Item>
                      </div>
                      {customSalaries.length > 1 && (
                        <div className="mb-4">
                          <Button
                            type="link"
                            icon={
                              <DeleteOutlined
                                style={{ color: "red", fontSize: "18px" }}
                              />
                            }
                            onClick={() => handleRemoveSalaryUpload(index)}>
                            Delete custom salary
                          </Button>
                        </div>
                      )}
                    </div>
                  ))}
                  {customSalaries.length < 5 && (
                    <div className="flex gap-1 items-center cursor-pointer">
                      <div className="mt-1">
                        <Plus />
                      </div>
                      <div
                        className="text-[#0081FF]"
                        onClick={handleAddSalaryUpload}>
                        Add more custom salary
                      </div>
                    </div>
                  )}
                </Collapse.Panel>
              </Collapse>
            </div>
          </FormAntd.Item>
        </FormAntd>
      </div>
    </>
  );
};

export default SelectComponentRunPayroll;
