import { Button, Checkbox, Col, DatePicker, message, Modal, Pagination, Row, Select ,Spin} from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
// import "./styles.scss"
import { useNavigate } from "react-router-dom";
import { Bulkcreate, getApprovallist } from "../../api/approvallist";
import { decryptData, encryptData } from "../../helper/cryptojs";
import { CloseCircleOutlined, CheckCircleOutlined} from "@ant-design/icons";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
export default function ApprovalList() {
	const navigate = useNavigate();
	const limitSession = sessionStorage.getItem("sessionPageApproval");
	const offsetSession = sessionStorage.getItem("sessionOffsetApproval");
	const startDateSession = decryptData(sessionStorage.getItem("sessionStartDateApprovalList"));
	const endDateSession = decryptData(sessionStorage.getItem("sessionEndDateApprovalList"));
	const approvalTypeSession = sessionStorage.getItem("sessionPageType");
	const sessionSearch = sessionStorage.getItem("sessionSearchApprovalList");
	const [loading, setLoading] = useState(false);
	const [listTable, setListTable] = useState([]);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(limitSession ? limitSession : 1);
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(offsetSession ? offsetSession : 0)
	const [search, setSearch] = useState(sessionSearch ? sessionSearch : "")
	const [startDate, setStartDate] = useState(startDateSession ? startDateSession : null)
	const [endDate, setEndDate] = useState(endDateSession ? endDateSession : null)
	const [selectedDateRange, setSelectedDateRange] = useState([]);
	const BestpraticeUseEffect = useRef(false)
	const previousPage = useRef(1);
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
	const employeeNumber = decryptData(localStorage.getItem("EmployeeNumber"));
	const [approvalType, setapprovalType] = useState(approvalTypeSession ? approvalTypeSession : null);
	const branch = decryptData(sessionStorage.getItem("selectBranch"));
	const Approvallist = async ()=>{
		setLoading(true);
		try {
		  const res = await getApprovallist(search, startDate, endDate,  limit, offset, page,company,branch,employeeNumber,approvalType)
		//   console.log('testing',res)
		  setListTable(res?.data?.data || []);
		  setTotalData(res?.data?.recordsTotal || 0)
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	}
	useEffect(() => {
		if (!BestpraticeUseEffect.current) {
			BestpraticeUseEffect.current = true;
		  } else {
			Approvallist();
			const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageApproval");
				sessionStorage.removeItem("sessionOffsetApproval");
				sessionStorage.removeItem('sessionStartDateApprovalList');
				sessionStorage.removeItem('sessionEndDateApprovalList');
				sessionStorage.removeItem('sessionPageType');
				sessionStorage.removeItem('picker_selected_approvel_list');
				sessionStorage.removeItem("sessionSearchApprovalList");
			  };
			  window.addEventListener("beforeunload", beforeUnloadHandler);
			  return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			  };
		  }
	}, [search, startDate, endDate, limit, offset, page,company,branch,employeeNumber,approvalType]);

	useEffect(() => {
		sessionStorage.setItem("sessionPageApproval", page);
	  }, [page]);

	  useEffect(() => {
		const limitSession = sessionStorage.getItem("sessionPageApproval");
		if (limitSession) {
		  setPage(parseInt(limitSession));
		}
	  }, []);

	  useEffect(() => {
		if (limitSession) {
			setPage(parseInt(limitSession));
		}
	}, []);

	useEffect(() => {
		if(page === 1){
			setOffset(0)
		}else {
			setOffset(page*limit - 10)
		}
	},[page])
   
	const checkAll = (isChecked) => {
		const updatedListTable = listTable.map((item) => ({
		  ...item,
		  checked: isChecked,
		}));
		setListTable(updatedListTable);
	  };
	  
	  const checkOne = (id,requestType) => {
		const updatedListTable = listTable.map((item) => {
		  if (item.row_id === id && item.request_type === requestType) {
			return {
			  ...item,
			  checked: !item.checked,
			};
		  }
		  return item;
		});
		setListTable(updatedListTable);
	  };

	const handleApprove = async () => {
		const selectedRows = listTable.filter((item) => item.checked);
	  
		if (selectedRows.length === 0) {
		  message.warning("Select at least one line to agree.");
		  return;
		}
		Modal.confirm({
		  title: "Confirmation",
		  content: "Are you sure you want to approve the selected items?",
		  onOk: async () => {
			try {
			  const bulk_data = selectedRows.map((row) => ({
				row_id: row.row_id,
				row_type: row.request_type,
				submit_type: "APPROVED",
			  }));
	  
			  const response = await Bulkcreate({
				companyCode: company,
				employeeNumber: employeeNumber,
				bulk_data: bulk_data,
			  });
	  
			  if (response.status === 200) {
				message.success("Request successfully approved");
				Approvallist();
			  } else {
				message.error("Failed to approve data.");
			  }
			} catch (error) {
			  console.error(error);
			  message.error("An error occurred while approving the data.");
			}
		  },
		});
	  };
	  
	  const handleReject = async () => {
		const selectedRows = listTable.filter((item) => item.checked);
	  
		if (selectedRows.length === 0) {
		  message.warning("Select at least one line to reject.");
		  return;
		}
		Modal.confirm({
		  title: "Confirmation",
		  content: "Are you sure you want to reject the selected items?",
		  onOk: async () => {
			try {
			  const bulk_data = selectedRows.map((row) => ({
				row_id: row.row_id,
				row_type: row.request_type,
				submit_type: "REJECTED",
			  }));
	  
			  const response = await Bulkcreate({
				companyCode: company,
				employeeNumber: employeeNumber,
				bulk_data: bulk_data,
			  });
	  
			  if (response.status === 200) {
				message.success("Request successfully rejected");
				Approvallist();
			  } else {
				message.error("Failed to reject data.");
			  }
			} catch (error) {
			  console.error(error);
			  message.error("An error occurred while rejecting the data.");
			}
		  },
		});
	  };

	const columns = [
		{
			title: (
			  <Checkbox
				onChange={(e) => checkAll(e.target.checked)}
				checked={
				  listTable.length > 0 &&
				  listTable.every((item) => item.checked)
				}
			  />
			),
			dataIndex: 'row_id',
			key: 'row_id',
			render: (_, record) => (
				<Checkbox
				onChange={() => checkOne(record.row_id, record.request_type)}
				checked={record.checked}
			  />
			),
		  },
		  
		{
			title: 'No',
			dataIndex: 'no',
			key: 'no',
			render: (text,record,index) =>{
				return (
					<div className="detail-btn-wp"   onClick={() => {
                        navigate(`/approval-list/approval-list-detail/${encodeURIComponent("id=" + record.row_id + "?row_type=" + record.request_type)}`);
                    }}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'Company',
			dataIndex: 'company_name',
			key: 'company_name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/approval-list/approval-list-detail/${encodeURIComponent("id=" + record.row_id+"?row_type="+record.request_type)}`)}>
						{text || "-"}
					</div>
				)
			}
		},
		{
			title: 'Branch',
			dataIndex: 'branch_name',
			key: 'branch_name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/approval-list/approval-list-detail/${encodeURIComponent("id=" + record.row_id+"?row_type="+record.request_type)}`)}>
						{text || "-"}
					</div>
				)
			}
		},
	
		{
			title: 'Request Date',
			dataIndex: 'request_date',
			key: 'request_date',
			sorter: (a, b) => new Date(a.request_date) - new Date(b.request_date),
			showSorterTooltip: {target: 'full-header', color: 'white' },
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/approval-list/approval-list-detail/${encodeURIComponent("id=" + record.row_id+"?row_type="+record.request_type)}`)}>
						{text}
					</div>
				)
			}
		},
        {
			title: 'NIK',
			dataIndex: 'employee_number',
			key: 'employee_number',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/approval-list/approval-list-detail/${encodeURIComponent("id=" + record.row_id+"?row_type="+record.request_type)}`)}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'Employee Name',
			dataIndex: 'full_name',
			key: 'full_name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/approval-list/approval-list-detail/${encodeURIComponent("id=" + record.row_id+"?row_type="+record.request_type)}`)}>
					{text.replace(/&#039;/g, "'") || "-"}
					</div>
				)
			}
		},
		{
			title: 'Approval Name',
			dataIndex: 'current_approval_name',
			key: 'current_approval_name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/approval-list/approval-list-detail/${encodeURIComponent("id=" + record.row_id+"?row_type="+record.request_type)}`)}>
						{text.replace(/&#039;/g, "'") || "-"}
					</div>
				)
			}
		},
		{
			title: 'Request Type',
			dataIndex: 'request_type',
			key: 'request_type',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/approval-list/approval-list-detail/${encodeURIComponent("id=" + record.row_id+"?row_type="+record.request_type)}`)}>
						{text}
					</div>
				)
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp"  onClick={() => navigate(`/approval-list/approval-list-detail/${encodeURIComponent("id=" + record.row_id+"?row_type="+record.request_type)}`)}>
						{text}
					</div>
				)
			}
		},
	];
	const dataBreadcrumb = [
		{
			title: "Approval List",
			url: "/approval-list"
		},
	];

	useEffect(() => {
		previousPage.current = page;
	  }, [page]);

	const onChangeTable = (pageNumber, type) => {
		const offsetSession = pageNumber*limit- 10
		setPage(pageNumber)
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPageApproval", pageNumber);
		sessionStorage.setItem("sessionOffsetApproval", offsetSession);
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		setLimit(10)
		setPage(1)
		sessionStorage.setItem("sessionSearchApprovalList", e.target.value);
	}

	useEffect(() => {
		const startDate = decryptData(sessionStorage.getItem("sessionStartDateApprovalList"));
		const endDate = decryptData(sessionStorage.getItem("sessionEndDateApprovalList"));
		if (startDate && endDate) {
		  setStartDate(startDate);
		  setEndDate(endDate);
		  setSelectedDateRange([dayjs(startDate),dayjs(endDate)])
		}
	  }, []);
	  
	
		const handleDateChange = (dates) => {
			if (dates && dates.length === 2) {
			  const start = dates[0].startOf('day').format('YYYY-MM-DD');
			  const end = dates[1].endOf('day').format('YYYY-MM-DD');
			  sessionStorage.setItem('sessionStartDateApprovalList', encryptData(start));
			  sessionStorage.setItem('sessionEndDateApprovalList', encryptData(end));
			  sessionStorage.setItem('picker_selected_approvel_list', 'true');
			  setStartDate(start);
			  setEndDate(end);
			  setPage(1)
			 
			} else {
			  sessionStorage.removeItem('sessionStartDateApprovalList');
			  sessionStorage.removeItem('sessionEndDateApprovalList');
			  sessionStorage.removeItem('picker_selected_approvel_list');
			  setStartDate(null);
			  setEndDate(null);
			  setPage(1);
			
			}
			setSelectedDateRange(dates);
		  };

		  const RoleAccessApprovalListApprove = JSON.parse(localStorage.getItem('RoleaccessPriviledgeApprovalListApprove'));
		  const RoleAccessApprovalListReject = JSON.parse(localStorage.getItem('RoleaccessPriviledgeApprovalListReject'));

    return (
        <>
            <div className="title-section">
				Approval List
			</div>
			<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
			<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
				<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"}>
					<Col md={{top:20}} className="mt-5 md:mt-0">
						<Row justify={"end"} style={{gap:15}} md={14}>
									<RangePicker
										size={"large"}
										onChange={handleDateChange}
										value={selectedDateRange}
										allowClear={true}
										format="YYYY-MM-DD"
									/>
								<Select
									allowClear
									placeholder="Request Type"
									className="select-company w-14 md:w-32 text-xs"
									onChange={(value) => {
										setapprovalType(value);
										if(value) {
											sessionStorage.setItem("sessionPageType",value);
										} else {
											sessionStorage.removeItem("sessionPageType");
										}
										setPage(1)
									  }}
									value={approvalType}  
									>
									<Select.Option value="Overtime">Overtime</Select.Option>
									<Select.Option value="Leave">Leave</Select.Option>
									<Select.Option value="Attendance">Attendance</Select.Option>
									<Select.Option value="Shifting">Shift</Select.Option>
								</Select>
								<InputWithIcon
									type="text"
									placeholder="Search Approval List"
									img={iconSearch}
									className="search-input btn-sh-sec"
									classImg="op-icon"
									value={search}
									onChange={onChangeSearch}
								/>
						</Row>
						<Row justify={"end"} style={{gap:15}} md={14} className="mt-5">
							{RoleAccessApprovalListReject && (
								<Button size={"large"}  type="primary" icon={<CloseCircleOutlined />} danger onClick={handleReject}  >Reject</Button>
							)}
							{RoleAccessApprovalListApprove && (
								<Button size={"large"}  icon={<CheckCircleOutlined />}  style={{ backgroundColor: "#34D399", color: "white",borderColor:"#34D399", marginRight: "14px" }}  onClick={handleApprove}>Approve</Button>
							)}
						</Row>
					</Col>
				</Row>
				<div className="table-wrapper">
					{loading ? (
						<>
						<Table id={'my-table'}  columns={columns}  loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					) : (
						<>
						<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					)}
				 </div>
			</div>
        </>
    )
}