import moment from "moment";
import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

const CutOffCompany = async (company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
  };
  return master.post("/company-cutoff", payload);
};

const getReviewPayroll = async (
  company,
  active,
  page,
  offset,
  limit,
  search
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    isActive: active,
    draw: page,
    start: offset,
    length: limit,
    search_by: "item",
    search_value: search?.length > 0 ? search : undefined,
  };
  return master.post("/review-payroll", payload);
};
const getEmployeeByLevelAccess = async (
  levelCode,
  company,
  branch,
  level,
  position,
  active,
  page,
  offset,
  limit,
  search,
  period
) => {
  const AllowedPayroll = decryptData(localStorage.getItem("AllowedPayroll"));
  const companyStore = decryptData(localStorage.getItem("DefaultCompanyId"));
  const branchStore = decryptData(localStorage.getItem("DefaultBranchId"));
  return master.get(
    `/employee-by-level-access?level_code_access=${
      AllowedPayroll || levelCode !== null ? levelCode : ""
    }&company_id=${companyStore || company !== null ? company : ""}&branch_id=${
      branchStore || branch !== null ? branch : ""
    }&level_id=${level}&position_id=${position}&employee_status=${active}&draw=${page}&start=${offset}&length=${limit}&search_by=full_name&search_value=${
      search?.length > 0 ? search : ""
    }&period=${period}`
  );
};
const getSelectAllowance = async (company) => {
  const companyStore = decryptData(sessionStorage.getItem("DefaultCompanyId"));
  return master.get(
    `/payroll-component?company_id=${
      companyStore || company !== null ? company : ""
    }&search_by=type&search_value=Allowance`
  );
};
const getSelectDeduction = async (company) => {
  const companyStore = decryptData(sessionStorage.getItem("DefaultCompanyId"));
  return master.get(
    `/payroll-component?company_id=${
      companyStore || company !== null ? company : ""
    }&search_by=type&search_value=Deduction`
  );
};
const getCustomSalaryItem = async (company) => {
  const companyStore = decryptData(localStorage.getItem("DefaultCompanyId"));
  return master.get(
    `/custom-salary-item?company_id=${
      companyStore || company !== null ? company : ""
    }`
  );
};

const PreviewRunPayroll = async (data, page, offset, limit) => {
  const user = decryptData(localStorage.getItem("Username"));
  const AllowedPayroll = decryptData(localStorage.getItem("AllowedPayroll"));
  let payload = {
    level_code_access: AllowedPayroll,
    company_id: data.company_id,
    cut_off_type: data.cut_off_type,
    period: data.end_date,
    start_date: data.start_date,
    end_date: data.end_date,
    allowances: data.allowances,
    deductions: data.deductions,
    all_employee: data.select_employees ? 0 : data.all_employee,
    custom_salaries: data.custom_salaries,
    select_employees:
      data.select_employees === null ? null : data.select_employees,
    draw: page,
    start: offset || 0,
    length: limit,
    created_by: user,
  };

  return master.post("/run-payroll/preview", payload);
};
const Reviews = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    company_id: data.company_id,
    cut_off_type: data.cut_off_type,
    period: data.end_date,
    start_date: data.start_date,
    end_date: data.end_date,
    allowances: data.allowances,
    deductions: data.deductions,
    all_employee: data.select_employees ? 0 : data.all_employee,
    custom_salaries: data.custom_salaries,
    select_employees:
      data.select_employees === null ? null : data.select_employees,
    start: 0,
    created_by: user,
  };

  return master.post("/run-payroll/store", payload);
};

const getLevelByLevelAccess = async (company, levelCode) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const AllowedPayroll = decryptData(localStorage.getItem("AllowedPayroll"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    level_code_access: AllowedPayroll || levelCode !== null ? levelCode : "",
    search_by: "name",
  };

  return master.post("/level", payload);
};
const getPositionList = async (company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    filterColumn: "name",
  };
  return master.post("/position", payload);
};
export {
  PreviewRunPayroll,
  Reviews,
  CutOffCompany,
  getReviewPayroll,
  getEmployeeByLevelAccess,
  getSelectAllowance,
  getSelectDeduction,
  getCustomSalaryItem,
  getLevelByLevelAccess,
  getPositionList,
};
