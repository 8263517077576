/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form as FormAntd, message, Modal } from "antd";
import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  InputWithLabel,
  NumberFormat,
  SelectOptionWithLabelApplies,
} from "../../component/Form/Input";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import "./styles.scss";
import { decryptData, encryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import {
  AddBpjsKesehatan,
  EditBpjsKesehatan,
  getLBpjsKesehatanDetail,
} from "../../api/bpjs";
const FormAddBpjsMaster = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  const locationPath = useLocation();
  let n = location.split("/");
  let result = n[5];
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const [fetching, setFetching] = useState(false);
  const [detail, setDetail] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleButtonClick = async () => {
    setIsModalVisible(true);
  };

  const handleModalSubmit = async () => {
    await form.handleSubmit();
    setIsModalVisible(false);
  };

  const handleModalNo = () => {
    setIsModalVisible(false);
  };

  const getDetailBpjsMaster = async () => {
    try {
      if (detail.length || locationPath.pathname === "/bpjs-kesehatan/add-bpjs-kesehatan") {
        return;
      }
  
      const res = await getLBpjsKesehatanDetail(id);
      setDetail(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDetailBpjsMaster();
    form.resetForm();
  }, []);

  const validationUser = yup.object().shape({
    item: yup.string().required("Field is required"),
    type: yup.string().required("Field is required"),
    presentase_default: yup.string().required("Field is required"),
    min_basic_premium: yup.string().required("Field is required"),
    max_basic_premium: yup.string().required("Field is required"),
  });

  const form = useFormik({
    initialValues: {
      companyCode: companyStore,
      code: result ? detail?.code : "",
      item: result ? detail?.item : "",
      type: result ? detail?.type : "",
      presentase_default: result ? detail?.presentase_default : "",
      min_basic_premium: result ? detail?.min_basic_premium : "",
      max_basic_premium: result ? detail?.max_basic_premium : "",
    },
    enableReinitialize: true,
    validationSchema: validationUser,

    onSubmit: async (values) => {
      setFetching(true);
      try {
        const res = result
          ? await EditBpjsKesehatan(values, id)
          : await AddBpjsKesehatan(values);
        if (!res?.data?.success) {
          message.error({
            content: res?.data?.error,
          });
        } else {
          message.success({
            content: result
              ? "BPJS Kesehatan item has been successfully updated"
              : "BPJS Kesehatan item has been successfully saved",
          });
          navigate("/bpjs-kesehatan");
          sessionStorage.removeItem("code");
          form.resetForm();
        }
        setFetching(false);
      } catch (error) {
        message.error({
          content: "BPJS Kesehatan code already exist",
        });
        setFetching(false);
      }
    },
  });

  const dataBreadcrumb = [
    {
      title: "Payroll Management - BPJS Kesehatan",
      url: "/bpjs-kesehatan",
    },
    {
      title: `${
        result ? "Edit BPJS Kesehatan Item" : "Add BPJS Kesehatan Item"
      }`,
      url: ``,
    },
  ];

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="submit" type="primary" onClick={handleModalSubmit}>
            Submit
          </Button>,
          <Button key="no" onClick={handleModalNo}>
            No
          </Button>,
        ]}>
        <p>Are you sure to change loan setting?</p>
      </Modal>
      <div className="title-section">
        {result ? "Edit BPJS Kesehatan Item" : "Add BPJS Kesehatan Item"}
      </div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="add-wrapper">
        <FormAntd onFinish={form.handleSubmit}>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-12 md:gap-[119px] mb-1">
                <div className="flex-grow">
                  <InputWithLabel
                    label={
                      <div>
                        Code<span className="text-red-500 ml-1">*</span>
                      </div>
                    }
                    disabled
                    maxWidth={10}
                    name="code"
                    placeholder="Code"
                    value={
                      form.values.type === "Company"
                        ? "BPJSKSC"
                        : form.values.type === "Employee"
                        ? "BPJSKSE"
                        : null || form.values.code
                    }
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    maxLength={20}
                  />
                  {sessionStorage.setItem(
                    "code",
                    encryptData(
                      form.values.type === "Company"
                        ? "BPJSKSC"
                        : form.values.type === "Employee"
                        ? "BPJSKSE"
                        : null
                    )
                  )}
                  {form.touched.type && form.errors.type ? (
                    <span className="text-error">{form.errors.type}</span>
                  ) : null}
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-12 md:gap-[119px] mb-1">
                <div className="flex-grow">
                  <InputWithLabel
                    label={
                      <div>
                        BPJS Kesehatan Item
                        <span className="text-red-500 ml-1">*</span>
                      </div>
                    }
                    maxWidth={10}
                    name="item"
                    placeholder="BPJS Kesehatan Item"
                    value={form.values.item}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    maxLength={50}
                  />
                  <div
                    className={
                      form.touched.item &&
                      form.errors.item === "Field is required"
                        ? "mb-[-22px] mt-0"
                        : "mt-3 mb-[-22px]"
                    }>
                    {form.touched.item && form.errors.item ? (
                      <span className="text-error">{form.errors.item}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-6 md:gap-[130px] mb-1">
                <div className="flex-grow">
                  <Col>
                    <SelectOptionWithLabelApplies
                      name="type"
                      label={
                        <div>
                          Type<span style={{ color: "#FF0000" }}> *</span>
                        </div>
                      }
                      items={[
                        {
                          value: "Company",
                          title: "Company",
                        },
                        {
                          value: "Employee",
                          title: "Employee",
                        },
                      ]}
                      value={form?.values?.type ? form?.values?.type : null}
                      onChange={(val) => {
                        form.setValues({
                          ...form.values,
                          type: val,
                        });
                      }}
                      placeholder="Type"
                      onBlur={form.handleBlur}
                    />
                    {form.touched.type && form.errors.type ? (
                      <span className="text-error">{form.errors.type}</span>
                    ) : null}
                  </Col>
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-12 md:gap-[119px] mb-1">
                <div className="flex-grow">
                  <InputWithLabel
                    label={
                      <div>
                        Percentage Default
                        <span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    addonAfter="%"
                    name="presentase_default"
                    type="text"
                    placeholder="Percentage Default"
                    value={form?.values?.presentase_default}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                  />

                  <div
                    className={
                      form.touched.presentase_default &&
                      form.errors.presentase_default === "Field is required"
                        ? "mb-[-22px] mt-0"
                        : "mt-3 mb-[-22px]"
                    }>
                    {form.touched.presentase_default &&
                    form.errors.presentase_default ? (
                      <span className="text-error">
                        {form.errors.presentase_default}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-12 md:gap-[119px] mb-1">
                <div className="flex-grow">
                  <NumberFormat
                    label={
                      <div>
                        Minimun Basic Premium Value
                        <span className="text-red-500 ml-1">*</span>
                      </div>
                    }
                    name="min_basic_premium"
                    type="text"
                    allowNegative={false}
                    placeholder="Rp."
                    thousandSeparator={true}
                    value={
                      form?.values?.min_basic_premium
                        ? form?.values?.min_basic_premium
                        : ""
                    }
                    onValueChange={(values) => {
                      const { formattedValue } = values;
                      form.setValues({
                        ...form.values,
                        min_basic_premium: formattedValue,
                      });
                    }}
                  />
                  <div
                    className={
                      form.touched.min_basic_premium &&
                      form.errors.min_basic_premium === "Field is required"
                        ? "mb-[-22px] mt-0"
                        : "mt-3 mb-[-22px]"
                    }>
                    {form.touched.min_basic_premium &&
                    form.errors.min_basic_premium ? (
                      <span className="text-error">
                        {form.errors.min_basic_premium}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-12 md:gap-[119px] mb-1">
                <div className="flex-grow">
                  <NumberFormat
                    label={
                      <div>
                        Maximum Basic Premium Value
                        <span className="text-red-500 ml-1">*</span>
                      </div>
                    }
                    name="max_basic_premium"
                    type="text"
                    allowNegative={false}
                    placeholder="Rp."
                    thousandSeparator={true}
                    value={
                      form?.values?.max_basic_premium
                        ? form?.values?.max_basic_premium
                        : ""
                    }
                    onValueChange={(values) => {
                      const { formattedValue } = values;
                      form.setValues({
                        ...form.values,
                        max_basic_premium: formattedValue,
                      });
                    }}
                  />
                  <div
                    className={
                      form.touched.max_basic_premium &&
                      form.errors.max_basic_premium === "Field is required"
                        ? "mb-[-22px] mt-0"
                        : "mt-3 mb-[-22px]"
                    }>
                    {form.touched.max_basic_premium &&
                    form.errors.max_basic_premium ? (
                      <span className="text-error">
                        {form.errors.max_basic_premium}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </FormAntd.Item>
        </FormAntd>

        <div className="footer-add">
          <Button
            key="back"
            className="btn btn-sec"
            style={{ marginRight: "14px" }}
            onClick={() => navigate("/bpjs-kesehatan")}>
            Cancel
          </Button>

          <Button
            key="submit"
            type="primary"
            className="btn btn-primary btn-sh-p"
            loading={fetching}
            onClick={detail?.is_active ? handleButtonClick : form.handleSubmit}
            disabled={form.isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormAddBpjsMaster;
