import { Button, Col, Collapse, message, Row, Skeleton } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { decryptData } from "../../../helper/cryptojs";
import { getPayrollComponentMasterDetail, getWagesSetting } from "../../../api/payrollComponent";
import { SettingOutlined } from "@ant-design/icons";
import Loader from "../../../component/common/Loader";
const PayrollComponentDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);

    const deCompanyId = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompanyId"))
    : decryptData(localStorage.getItem("DefaultCompanyId"));

    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState([]);
    const BestpraticeUseEffect = useRef(false)
    const [baseCalc, setBaseCalc] = useState('')

    const getPayrollDetails = async () => {
		setLoading(true);
		try {
		  const res = await getPayrollComponentMasterDetail(id)
          if(res?.data?.data?.base_calculation_id === 0){
              if(await checkWagesAvail()){
                  setBaseCalc('Wages')
                }else{
                    setBaseCalc('0')
                }
                setDetail(res?.data?.data)
            }else{
                if(res?.data?.data?.base_calculation_id){
                    const resBaseCalc = await getBaseCalc(res?.data?.data?.base_calculation_id)
                    setBaseCalc(resBaseCalc?.data?.data?.name)
                }
                setDetail(res?.data?.data)
            }
		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };

    const checkWagesAvail = async () => {
        try {
            const populate = await getWagesSetting(deCompanyId)
            const {data} = populate?.data
            if(data.length !== 0){
                return true
            }else{
                return false
            }
        } catch (err) {
            console.log(err);
		} finally {
		  setLoading(false);
		}
    }

    const getBaseCalc = async (baseId) => {
        try {
            return await getPayrollComponentMasterDetail(baseId)
        } catch (err) {
            message.error({
				content: "Request Not Found",
			});
		  console.log(err);
		} finally {
		  setLoading(false);
		}
    }
	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
        } else{
            getPayrollDetails();
        }
	}, []);

    const dataBreadcrumb = [
        {
			title: "Payroll Component - Master Component",
			url: "/payroll-component"
		},
        {
			title: "Master Component Detail",
			url: ``
		},
	];
    return (
        <>
    
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/payroll-component')} icon={<ArrowLeftOutlined />} size={"large"} />
                Payroll Component Detail
            </div>
        <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
                <Col span={24}>
                    <Breadcrumb items={dataBreadcrumb}/>
                </Col>
            </div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Component Code</div>
                            <div className="subtitle">{detail?.code}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Component Name</div>
                            <div className="subtitle">{detail?.name}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Type</div>
                            <div className="subtitle">{detail?.type}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Taxable</div>
                            <div className="subtitle">{detail?.taxable ? 'Yes' : 'No' || '-'}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Applies To</div>
                            <div className="subtitle">{detail?.applies_to}</div>
                        </div>
                    </Col>
                    {detail?.type === 'Deduction' ?
                    (
                        <Col span={12}>
                            <div className="detail-card">
                                <div className="title">Formula</div>
                                <div className="subtitle">{!detail?.formula ? "No" :
                                    `${detail?.ratio}${detail?.is_ratio_percentage ? '%': ''} ${detail?.operation} ${baseCalc}`
                                    }</div>
                            </div>
                        </Col>
                    )
                    : null
                    }
                    {detail?.applies_to === 'Custom' ?
                        (
                        <>
                            <Col span={12}>
                                <div className="detail-card">
                                    <div className="title">Level</div>
                                    {detail?.levels?.length > 0 ? detail?.levels.map((e, i) => {
                                    return (
                                        <div className="subtitle">{e !== 'null' ? (e.name || "-") : "-"}</div>
                                    )
                                })
                                :
                                <div className="subtitle">-</div>}
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className="detail-card">
                                    <div className="title">Position</div>
                                    {detail?.positions.length > 0 ? detail?.positions.map((e, i) => {
                                    return (
                                        <div className="subtitle">{e !== 'null' ? (e.name || "-") : "-"}</div>
                                    )
                                })
                                :
                                <div className="subtitle">-</div>}
                                </div>
                            </Col>
                        </>
                        )
                        :
                        null
                    }
                </Row>
            </div>
        </>
    )
}

export default PayrollComponentDetail