export default function info({ size = [21, 20], fill = "#0081FF" }) {
  return (
    <div>
      <svg
        width={size[0]}
        height={size[1]}
        viewBox="0 0 21 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_231_1209)">
          <path
            d="M10.3217 18.2889C14.8767 18.2889 18.5692 14.5964 18.5692 10.0414C18.5692 5.48648 14.8767 1.79395 10.3217 1.79395C5.76675 1.79395 2.07422 5.48648 2.07422 10.0414C2.07422 14.5964 5.76675 18.2889 10.3217 18.2889Z"
            stroke={fill}
            stroke-width="1.6495"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.3203 13.3404V10.0414"
            stroke={fill}
            stroke-width="1.6495"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M10.3203 6.74243H10.3286"
            stroke={fill}
            stroke-width="1.6495"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_231_1209">
            <rect
              width="19.794"
              height="19.794"
              fill="white"
              transform="translate(0.423828 0.144409)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
