/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row, Timeline } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { decryptData } from "../../../helper/cryptojs";
import { getLeaveDetailTransaction } from "../../../api/leaveTransaction";
import moment from "moment";

const EmployeeTransferDetail = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  let n = location.lastIndexOf("/");
  let result = location.substring(n + 1);
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const BestpraticeUseEffect = useRef(false);
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const [detail, setDetail] = useState([]);

  const getListLeaveDetailTransaction = async () => {
    try {
      const res = "";
      setDetail(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
    }
  }, [company]);

  const dataBreadcrumb = [
    {
      title: "Master Data - Employee",
      url: "/employee",
    },
    {
      title: "Employee Transfer",
      url: "/employee/employee-transfer",
    },
    {
      title: "Employee Transfer Detail",
      url: `/employee/employee-transfer/employee-transfer-detail/${result}`,
    },
  ];

  const Duration =
    detail?.data?.days_submitted !== null
      ? `${detail?.data?.days_submitted} ${detail?.data?.days_submitted === "1" ? 'Day' : 'Days'}`
      : "-";
  return (
    <>
      <div className="title-section">
        <Button
          className="btn-sh-p"
          type="primary"
          shape="circle"
          style={{ marginRight: "14px" }}
          onClick={() => navigate("/employee/employee-transfer")}
          icon={<ArrowLeftOutlined />}
          size={"large"}
        />
        Employee Transfer Detail
      </div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="detail-wrapper">
        <h3>Employee Detail</h3>
        <Row>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Employee Name</div>
              <div className="subtitle"> {"-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Branch</div>
              <div className="subtitle">{detail?.data?.branch_name || "-"}</div>
            </div>
          </Col>

          <Col span={12}>
            <div className="detail-card">
              <div className="title">Department</div>
              <div className="subtitle">
                {detail?.data?.department_name || "-"}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Division</div>
              <div className="subtitle">
                {detail?.data?.division_name || "-"}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Level</div>
              <div className="subtitle">{detail?.data?.level || "-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Position</div>
              <div className="subtitle">
                {detail?.data?.position_name || "-"}
              </div>
            </div>
          </Col>
        </Row>
        <h3>Transfer To</h3>
        <Row>
          <Col span={24}>
            <div className="detail-card">
              <div className="title">Transfer Type</div>
              <div className="subtitle"> {"-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Company</div>
              <div className="subtitle">
                {detail?.data?.company_name || "-"}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Branch</div>
              <div className="subtitle">{detail?.data?.branch_name || "-"}</div>
            </div>
          </Col>

          <Col span={12}>
            <div className="detail-card">
              <div className="title">Department</div>
              <div className="subtitle">
                {detail?.data?.department_name || "-"}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Division</div>
              <div className="subtitle">
                {detail?.data?.division_name || "-"}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Direct Supervisor</div>
              <div className="subtitle">
                {detail?.data?.direct_supervisor || "-"}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Level</div>
              <div className="subtitle">{detail?.data?.level || "-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Position</div>
              <div className="subtitle">
                {detail?.data?.position_name || "-"}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Employee Status</div>
              <div className="subtitle">
                {detail?.data?.employee_status || "-"}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Effective Date</div>
              <div className="subtitle">
                {detail?.data?.employee_status || "-"}
              </div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card overflow-x-auto">
              <div className="title">Attachment</div>
              <div className="text-sm text-gray-500 flex items-center">
                <div>
                  <svg
                    className="w-4 h-4 mr-1 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                  >
                    <path d="M4 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1H2V6zM9 12v5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z"></path>
                  </svg>
                </div>

                {detail?.data?.document_url ? (
                  <Link
                    to={
                      process.env.REACT_APP_HRIS_URL +
                      `leaves-transaction/file/${detail?.data?.document_url}`
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block overflow-x-auto text-gray-500"
                    style={{ wordWrap: "break-word", width: "100%" }}
                  >
                    {detail?.data?.document_url
                      .replace(
                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/attendancerequest/Phase02/`,
                        ""
                      )
                      .replace(
                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/attendancerequest/`,
                        ""
                      )
                      .replace(
                        `${process.env.REACT_APP_HRIS_URL}attendance-request/file/storage/attendancerequest/Phase 02/`,
                        ""
                      )}
                  </Link>
                ) : (
                  <span
                    key={detail?.data?.change_shift_id}
                    className="text-gray-500"
                  >
                    No File
                  </span>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default EmployeeTransferDetail;