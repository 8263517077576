/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Pagination, Row, DatePicker, message, Tabs, Tooltip, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import Table from "../../component/Table";
import { decryptData } from "../../helper/cryptojs";
import TabPane from "antd/es/tabs/TabPane";
import {
  deletePayrollComponent,
  getPayrollComponentCustomSalary,
  getPayrollComponentMaster,
} from "../../api/payrollComponent";
// import { formatCurrency } from "../../helper/formatCurrency";
import { PlusOutlined, DeleteFilled, EditFilled ,SettingOutlined, StopOutlined, DownloadOutlined} from "@ant-design/icons";
import iconSearch from "../../assets/img/search-icon.png";
import { useNavigate } from "react-router-dom";
import InputWithIcon from "../../component/Form/InputWithIcon";
import {decode} from 'html-entities';

// import dayjs from "dayjs";
import "./styles.scss";
export default function PayrollComponent() {
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompanyId"))
    : decryptData(localStorage.getItem("DefaultCompanyId"));
  const branch = decryptData(sessionStorage.getItem("selectBranch"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"));
  const branchCode = branchSession || branch !== null ? branch : branchDefault;

  const limitSessionMaster = sessionStorage.getItem("sessionPageMasterComponent");
  const limitSessionSalary = sessionStorage.getItem("sessionPageSalaryComponent");

  const offsetSessionMaster = sessionStorage.getItem("sessionOffsetMasterComponent");
  const offsetSessionSalary = sessionStorage.getItem("sessionOffsetSalaryComponent");


  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [listTableSalary, setListTableSalary] = useState([]);

  const [totalData, setTotalData] = useState(0);
  const [totalDataSalary, setTotalDataSalary] = useState(0);

  const [pageMaster, setPageMaster] = useState(limitSessionMaster ? limitSessionMaster : 1);
  const [pageSalary, setPageSalary] = useState(limitSessionSalary ? limitSessionSalary : 1);

  
  const [limitMaster, setLimitMaster] = useState(10);
  const [limitSalary, setLimitSalary] = useState(10);

  const [offsetMaster, setOffsetMaster] = useState(offsetSessionMaster ? offsetSessionMaster : 0);
  const [offsetSalary, setOffsetSalary] = useState(offsetSessionSalary ? offsetSessionSalary : 0);

  const navigate = useNavigate();
	const sessionPageMaster = sessionStorage.getItem("sessionPageMasterComponent");
	const sessionOffsetMaster = sessionStorage.getItem("sessionOffsetMasterComponent");
	const sessionSearchMaster = sessionStorage.getItem("sessionSearchMasterComponent");
  const sessionPageSalary = sessionStorage.getItem("sessionPageSalaryComponent");
	const sessionOffsetSalary = sessionStorage.getItem("sessionOffsetSalaryComponent");
	const sessionSearchSalary = sessionStorage.getItem("sessionSearchSalaryComponent");

  const [searchMaster, setSearchMaster] = useState(sessionSearchMaster ?? "")
  const [searchSalary, setSearchSalary] = useState(sessionSearchSalary ?? "")

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [deleteId, setDeleteId] = useState()

  const [isUsed, setIsUsed] = useState(false)

  const previousPageMaster = useRef(1);
  const previousPageSalary = useRef(1);

  const MasterComponent = async () => {
    setLoading(true);
    try {
      const res = await getPayrollComponentMaster(
        company,
        pageMaster,
        offsetMaster,
        limitMaster,
        searchMaster
      );
      setListTable(res?.data?.data);
      setTotalData(res?.data?.recordsTotal);
    } catch (err) {
      message.error({
        content: "Request Not Found",
      });
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const SalaryComponent = async () => {
    setLoading(true);
    try {
      const res = await getPayrollComponentCustomSalary(
        company,
        pageSalary,
        offsetSalary,
        limitSalary,
        searchSalary
      );
      setListTableSalary(res?.data?.data);
      setTotalDataSalary(res?.data?.recordsTotal);
    } catch (err) {
      message.error({
        content: "Request Not Found",
      });
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("sessionPageMasterComponent", pageMaster);
  }, [pageMaster]);
  useEffect(() => {
    sessionStorage.setItem("sessionPageSalaryComponent", pageSalary);
  }, [pageSalary]);

  useEffect(() => {
    MasterComponent();
    SalaryComponent();
    const beforeUnloadHandler = () => {
      sessionStorage.removeItem("sessionPageMasterComponent");
      sessionStorage.removeItem("sessionOffsetMasterComponent");
      sessionStorage.removeItem("sessionPageSalaryComponent");
      sessionStorage.removeItem("sessionOffsetSalaryComponent");
      setPageMaster(1);
      setPageSalary(1);
      setOffsetMaster(0);
      setOffsetSalary(0);

    };
    window.addEventListener("beforeunload", beforeUnloadHandler);
    return () => {
      window.removeEventListener("beforeunload", beforeUnloadHandler);
    };
  }, [company, offsetMaster, limitMaster, pageMaster, offsetSalary, limitSalary, pageSalary, searchMaster, searchSalary]);

  useEffect(() => {
    const limitSessionMaster = sessionStorage.getItem("sessionPageMasterComponent");
    if (limitSessionMaster) {
      setPageMaster(parseInt(limitSessionMaster));
    }
  }, []);

  useEffect(() => {
    const limitSessionSalary = sessionStorage.getItem("sessionPageSalaryComponent");
    if (limitSessionSalary) {
      setPageSalary(parseInt(limitSessionSalary));
    }
  }, []);

  useEffect(() => {
    let offsetSession = 0;
    if (pageMaster > 1) {
      offsetSession = pageMaster * limitMaster - 10;
    }
    setOffsetMaster(offsetSession);
    sessionStorage.setItem("sessionPageMasterComponent", pageMaster);
    sessionStorage.setItem("sessionOffsetMasterComponent", offsetMaster);
  }, [pageMaster]);

  useEffect(() => {
    let offsetSession = 0;
    if (pageSalary > 1) {
      offsetSession = pageSalary * limitSalary - 10;
    }
    setOffsetSalary(offsetSession);
    sessionStorage.setItem("sessionPageSalaryComponent", pageSalary);
    sessionStorage.setItem("sessionOffsetSalaryComponent", offsetSalary);
  }, [pageSalary]);

  const columnsMasterComponent = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record) => {
        return <div className="detail-btn-wp"
        onClick={()=>navigate(`/payroll-component/detail/${encodeURIComponent('id?'+record.id)}`)}
        >{text || "-"}</div>;
      },
    },
    {
      title: "Component Code",
      dataIndex: "code",
      key: "component_code",
      render: (text, record) => {
        return <div className="detail-btn-wp"
        onClick={()=>navigate(`/payroll-component/detail/${encodeURIComponent('id?'+record.id)}`)}
        >{decode(text) || "-"}</div>;
      },
    },
    {
      title: "Component Name",
      dataIndex: "name",
      key: "component_name",
      render: (text, record) => {
        return <div className="detail-btn-wp"
        onClick={()=>navigate(`/payroll-component/detail/${encodeURIComponent('id?'+record.id)}`)}
        >{decode(text) || "-"}</div>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => {
        return <div className="detail-btn-wp"
        onClick={()=>navigate(`/payroll-component/detail/${encodeURIComponent('id?'+record.id)}`)}
        >{decode(text) || "-"}</div>;
      },
    },
    {
      title: "Taxable",
      dataIndex: "taxable",
      key: "taxable",
      render: (text, record) => {
        return <div className="detail-btn-wp"
        onClick={()=>navigate(`/payroll-component/detail/${encodeURIComponent('id?'+record.id)}`)}
        >{text ? 'Yes' : 'No' || "-"}</div>;
      },
    },
    {
      title: "Applies To",
      dataIndex: "applies_to",
      key: "applies_to",
      render: (text, record) => {
        return (
          <div className="detail-btn-wp"
          onClick={()=>navigate(`/payroll-component/detail/${encodeURIComponent('id?'+record.id)}`)}
          >{decode(text) || "-"}</div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: "50px",
      render: (_, record, index) => {
        return RoleAccessPayrollComponentMasterUpdate && RoleAccessPayrollComponentMasterDelete ?  (
        <Row justify={"space-between"}>
          <div className="icon-action">
          <DeleteFilled onClick={() => {
            setIsUsed(record.is_used)
            showModal(record.id, record.is_used)
            }} />
          </div>
          <div className="icon-action" onClick={() => {
          navigate(`/payroll-component/edit-master-component/${encodeURIComponent("id?" + record.id)}`);
          // window.location.reload();
          }}>
          <EditFilled />
          </div>
        </Row>
        ) : (
            <Row justify={"space-between"}>
              <Tooltip title="No Access">
                <StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
              </Tooltip>
            </Row>
        )
      }
      },
  ];

  const columnsSalaryComponent = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, index) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Component Code",
      dataIndex: "code",
      key: "code",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Component Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Taxable",
      dataIndex: "taxable",
      key: "taxable",
      render: (text, record) => {
        return <div className="detail-btn-wp">{text ? 'Yes' : 'No' || "-"}</div>;
      },
    },
    // {
    //   title: "Applies To",
    //   dataIndex: "applies_to",
    //   key: "applies_to",
    //   render: (text, record) => {
    //     return (
    //       <div className="detail-btn-wp">{text || "-"}</div>
    //     );
    //   },
    // },
    {
      title: 'Action',
      key: 'action',
      width: "50px",
      render: (_, record, index) => {
        return RoleAccessPayrollComponentSalaryDownload ?  (
        <div className="detail-btn-wp">
          <Button 
          type="link" 
          // disabled={text !== "COMPLETED"} 
          onClick={() => {
            actDownloadResult(record.id)
          }} 
          target="_blank">
            <DownloadOutlined />
          </Button>
        </div>
        ) : (
            <Row justify={"space-between"}>
              <Tooltip title="No Access">
                <StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
              </Tooltip>
            </Row>
        )
      }
      },
  ];

  const dataBreadcrumb = [
    {
      title: "Payroll Management",
      url: "/payroll-component",
    },
    {
      title: "Payroll Component",
      url: `/payroll-component`
    },
  ];

  useEffect(() => {
    previousPageMaster.current = pageMaster;
  }, [pageMaster]);

  useEffect(() => {
    previousPageSalary.current = pageSalary;
  }, [pageSalary]);

  const actDownloadResult = (id) => {
    window.open(`${process.env.REACT_APP_HRIS_URL}custom-salary-item/download/${id}`, '_blank');
  }

  const onChangeTable = (pageNumber, type) => {
    const offsetSession = pageNumber * limitMaster - 10;
    setPageMaster(pageNumber);
    setOffsetMaster(offsetSession);
    sessionStorage.setItem("sessionPageMasterComponent", pageNumber);
    sessionStorage.setItem("sessionOffsetMasterComponent", offsetSession);
    //
  };

  const onChangeTableSalary = (pageNumber, type) => {
    const offsetSession = pageNumber * limitSalary - 10;
    setPageSalary(pageNumber);
    setOffsetSalary(offsetSession);
    sessionStorage.setItem("sessionPageSalaryComponent", pageNumber);
    sessionStorage.setItem("sessionOffsetSalaryComponent", offsetSession);
    //
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimitMaster(pageSize);
  };

  const onShowSizeChangeSalary = (_, pageSize) => {
    setLimitSalary(pageSize);
  };

  function dataTableWithNumber() {
    const showNumber = limitMaster * (pageMaster - 1);
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }
  function dataTableSalaryWithNumber() {
    const showNumber = limitSalary * (pageSalary - 1);
    return listTableSalary?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }

  const handleOk = () => {
		setIsModalOpen(false);
    setIsUsed(false)
		handleDelete(deleteId)
	};
	
	const handleCancel = () => {
    setIsUsed(false)
		setIsModalOpen(false);
	};

  const redirectToAddPage = () => {
		navigate('/payroll-component/add-master-component');
		// window.location.reload(); // Menambahkan reload setelah mengarahkan pengguna
	  };
  const redirectToWagesSetting = () => {
    navigate('/payroll-component/wages-setting');
    // window.location.reload(); // Menambahkan reload setelah mengarahkan pengguna
    };

  const onChangeSearch = (e) => {
    setSearchMaster(e.target.value)
    setLimitMaster(10)
    setPageMaster(1)
    sessionStorage.setItem("sessionSearchMasterComponent", e.target.value);
  }

  const onChangeSearchSalary = (e) => {
    setSearchSalary(e.target.value)
    setLimitSalary(10)
    setPageSalary(1)
    sessionStorage.setItem("sessionSearchSalaryComponent", e.target.value);
  }

  const showModal = (id) => {
		setIsModalOpen(true);
		setDeleteId(id)
	  };
  
  const handleDelete = async (id) =>{
    setLoading(true)
    try {
        const res = await deletePayrollComponent(id);
        message.success({
            content: "Delete Data Success",
        });
        setPageMaster(1)
        MasterComponent()
    } catch (error) {
        message.error({
            content: "Request Not Found",
        });
        setLoading(false)
    }
  }

  const RoleAccessPayrollComponentMasterCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgePayrollComponentMasterCreate'));
  const RoleAccessPayrollComponentMasterUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgePayrollComponentMasterUpdate'));
  const RoleAccessPayrollComponentMasterDelete = JSON.parse(localStorage.getItem('RoleaccessPriviledgePayrollComponentMasterDelete'));
  const RoleAccessPayrollComponentSalaryDownload = JSON.parse(localStorage.getItem('RoleaccessPriviledgePayrollComponentSalaryDownload'));



  return (
    <>
      <div className="title-section">Payroll Component</div>
      <Modal title="Delete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
						<p>Are you sure to delete this data{isUsed ? `, this component is being used in wages setting`: null}?</p>
      </Modal>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <Tabs defaultActiveKey="1" className="tab-wrapper" type="card">
        <TabPane tab="Master Component" key="1">
          <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
              <Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"} >
                <Col md={{top:20}} className="mt-5 md:mt-0">
                  <Row justify={"end"} style={{gap:15}} md={14}>
                    <InputWithIcon
                      type="text"
                      placeholder="Search Master Component"
                      img={iconSearch}
                      className="search-input btn-sh-sec"
                      classImg="op-icon"
                      value={searchMaster}
                      onChange={onChangeSearch}
                    />
                      <Button className="btn-sh-p" type="primary" shape="circle" onClick={redirectToWagesSetting} icon={<SettingOutlined />} size={"large"} />
                  {RoleAccessPayrollComponentMasterCreate && (
                    <>
                      <Button className="btn-sh-p" type="primary" shape="circle" onClick={redirectToAddPage} icon={<PlusOutlined />} size={"large"} />
                    </>
                  )}

                  </Row>
                </Col>
              </Row>
            {loading ? (
              <>
                <div className="table-wrapper-payroll-comp">
                  <Table
                    id={"my-table"}
                    columns={columnsMasterComponent}
                    loading={loading}
                  />
                </div>
                <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                  <Pagination
                    defaultCurrent={pageMaster}
                    current={pageMaster}
                    total={totalData}
                    onChange={onChangeTable}
                    showSizeChanger={false}
                    onShowSizeChange={onShowSizeChange}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="table-wrapper-payroll-comp">
                  <Table
                    id={"my-table"}
                    dataSource={dataTableWithNumber()}
                    columns={columnsMasterComponent}
                    loading={loading}
                  />
                </div>
                <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                  <Pagination
                    defaultCurrent={pageMaster}
                    current={pageMaster}
                    total={totalData}
                    onChange={onChangeTable}
                    onShowSizeChange={onShowSizeChange}
                    showSizeChanger={false}
                  />
                </div>
              </>
            )}
          </div>
        </TabPane>
        <TabPane tab="Custom Salary Item" key="2">
        <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
              <Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"} >
                <Col md={{top:20}} className="mt-5 md:mt-0">
                  <Row justify={"end"} style={{gap:15}} md={14}>
                    <InputWithIcon
                      type="text"
                      placeholder="Search Custom Salary Item"
                      img={iconSearch}
                      className="search-input btn-sh-sec"
                      classImg="op-icon"
                      value={searchSalary}
                      onChange={onChangeSearchSalary}
                    />
                  </Row>
                </Col>
              </Row>
            {loading ? (
              <>
                <div className="table-wrapper-payroll-comp">
                  <Table
                    id={"my-table"}
                    columns={columnsSalaryComponent}
                    loading={loading}
                  />
                </div>
                <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                  <Pagination
                    defaultCurrent={pageMaster}
                    current={pageMaster}
                    total={totalData}
                    onChange={onChangeTableSalary}
                    showSizeChanger={false}
                    onShowSizeChange={onShowSizeChangeSalary}
                  />
                </div>
              </>
            ) : (
              <>
                <div className="table-wrapper-payroll-comp">
                  <Table
                    id={"my-table"}
                    dataSource={dataTableSalaryWithNumber()}
                    columns={columnsSalaryComponent}
                    loading={loading}
                  />
                </div>
                <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                  <Pagination
                    defaultCurrent={pageSalary}
                    current={pageSalary}
                    total={totalDataSalary}
                    onChange={onChangeTableSalary}
                    onShowSizeChange={onShowSizeChangeSalary}
                    showSizeChanger={false}
                  />
                </div>
              </>
            )}
          </div>
        </TabPane>
      </Tabs>
    </>
  );
}
