// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file-upload input[type=file] {
  display: none;
}

.file-upload {
  border: 1px solid #ccc;
  border-radius: 10px;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}

.file-upload span {
  margin-right: 8px;
}

.file-upload:hover {
  background-color: #427ee5;
}

.file-upload:active {
  background-color: #427ee5;
}

.flex-container {
  display: flex;
  gap: 20px;
  text-align: center;
  justify-content: center;
  align-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/views/Company/styles.scss"],"names":[],"mappings":"AAAC;EACG,aAAA;AACJ;;AACA;EACI,sBAAA;EACA,mBAAA;EACA,qBAAA;EACA,iBAAA;EACA,eAAA;AAEJ;;AACE;EACE,iBAAA;AAEJ;;AACE;EACE,yBAAA;AAEJ;;AACE;EACE,yBAAA;AAEJ;;AAEE;EACE,aAAA;EACA,SAAA;EACA,kBAAA;EACA,uBAAA;EACA,qBAAA;EACA,mBAAA;AACJ","sourcesContent":[" .file-upload input[type=\"file\"] {\n    display: none;\n}\n.file-upload {\n    border: 1px solid #ccc;\n    border-radius: 10px;\n    display: inline-block;\n    padding: 6px 12px;\n    cursor: pointer;\n}\n\n  .file-upload span {\n    margin-right: 8px;\n  }\n  \n  .file-upload:hover {\n    background-color: #427ee5;\n  }\n  \n  .file-upload:active {\n    background-color: #427ee5;\n  }\n \n  \n  .flex-container {\n    display: flex;\n    gap: 20px;\n    text-align: center;\n    justify-content: center;\n    align-content: center;\n    align-items: center;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
