import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

const getTaxMethod = async (company, page, offset, limit, search) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    draw: page,
    start: offset,
    length: limit,
    search_by: "tax_method",
    search_value: search?.length > 0 ? search : undefined,
  };
  return master.post("/mtax-method", payload);
};

const getListDropdownListInputTaxMethod = async (company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
  };
  return master.post("/list-level-input", payload);
};
const getListDropdownListMasterTaxMethod = async (company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
  };
  return master.post("/mtax-method-list-input", payload);
};

const AddTaxMethod = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    companyCode: data.companyCode,
    tax_method: data.tax_method,
    applies_to: data.applies_to,
    applies_to_level_id: JSON.stringify(data.applies_to_level_id),
    created_by: user,
  };

  return master.post("/mtax-method-create", payload);
};

const EditTaxMethod = async (data, id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    mTaxMethodId: id,
    tax_method: data.tax_method,
    applies_to: data.applies_to,
    applies_to_level_id: JSON.stringify(data.applies_to_level_id),
    updated_by: user,
  };

  return master.post("/mtax-method-update", payload);
};

const getTaxMethodDetail = async (id) => {
  let payload = {
    mTaxMethodId: id,
  };
  return master.post("/mtax-method-detail", payload);
};

const DeleteTaxMethod = async (id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    mTaxMethodId: id,
    deleted_by: user,
  };
  return master.post("/mtax-method-delete", payload);
};

export {
  getTaxMethod,
  DeleteTaxMethod,
  AddTaxMethod,
  EditTaxMethod,
  getTaxMethodDetail,
  getListDropdownListInputTaxMethod,
  getListDropdownListMasterTaxMethod,
};
