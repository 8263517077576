/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Modal, Pagination, Row, Table, Tabs,message ,Form as FormAntd , Select} from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined, EditOutlined } from "@ant-design/icons";
import TabPane from "antd/es/tabs/TabPane";
import { InputNumberWithLabel } from "../../../component/Form/Input";
import { UpdateEmployeeManagement, getEmployeeManagementDetail, getEmployeeManagementDetailId, getEmployeeManagementDetailLoanId, getEmployeeManagementId, getEmployeeManagementOvertime, getPAHistoryListData } from "../../../api/employee";
import { useFormik } from "formik";
import { decryptData } from "../../../helper/cryptojs";
import moment from "moment";
import * as yup from "yup";
import { formatCurrency } from "../../../helper/formatCurrency";
const EmployeeManagementDetail = () => {
    const location = window.location.href;
    let n = location.lastIndexOf('/');
    let result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    let dec = decode.lastIndexOf('?');
    let id = decode.substring(dec + 1);
    const navigate = useNavigate()
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const BestpraticeUseEffect = useRef(false)
    const BestpraticeUseEffectOvertime = useRef(false)
	const [detail, setDetail] = useState([]);
	const [detailId, setDetailId] = useState([]);
	const [listTableLoan, setListTableLoan] = useState([]);
	const [listTableAssessment, setListTableAssessment] = useState([]);
    const [status, setStatus] = useState("active");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [fetching, setFetching] = useState(false);
    const [listTable, setListTable] = useState([]);
    const [totalData, setTotalData] = useState(0)
    const [listTableOverime, setListTableOvertime] = useState([]);
    const [totalDataOvertime, setTotalDataOvertime] = useState(0)
    const [month, setMonth] = useState(moment().format("MMMM").charAt(0).toUpperCase() + moment().format("MMMM").slice(1));
    const monthList = Array.from({length: 12}, (v, i) => {
        const monthName = moment().month(i).format("MMMM").charAt(0).toUpperCase() + moment().month(i).format("MMMM").slice(1);
        return { label: monthName, value: monthName };
    });
    const [monthOvertime, setMonthOvertime] = useState(moment().format("MMMM").charAt(0).toUpperCase() + moment().format("MMMM").slice(1));
    const monthListAssessment = Array.from({length: 12}, (v, i) => {
        const monthName = moment().month(i).format("MMMM").charAt(0).toUpperCase() + moment().month(i).format("MMMM").slice(1);
        const monthValue = moment().month(i).format("yyyy-MM")
        return { label: monthName, value: monthValue };
    });
    const [monthAssessment, setMonthAssessment] = useState(moment().format("yyyy-MM"));
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [limit, setLimit] = useState(10)
    const [offset, setOffset] = useState(0)
    const [pageOvertime, setPageOvertime] = useState(1)
    const [loadingOvertime, setLoadingOvertime] = useState(false)
    const [limitOvertime, setLimitOvertime] = useState(10)
    const [offsetOvertime, setOffsetOvertime] = useState(0)
    const idDetail = detail?.employee_leave_id

    const dataFilterStatus = [
        {
          label: "Active",
          value: "active",
        },
        {
          label: "Paid Off",
          value: "paid",
        },
        {
          label: "On Process",
          value: "on process",
        },
      ];

    const detailManagementList = async () => {
		try {
            setLoading(true);
		  const res = await getEmployeeManagementDetail(id)
		  setDetail(res?.data?.data);
		} catch (err) {
		  console.log(err);
		} finally {
            setLoading(false);
          }
	  };

    const ManagementList = async () => {
        try {
		  const res = await getEmployeeManagementId(id,month,offset,limit,page)
		  setListTable(res?.data?.data?.data);
          setTotalData(res?.data?.data?.recordsTotal)
		} catch (err) {
		  console.log(err);
		}
	  };

    const ManagementOvertimeList = async () => {
        try {
        setLoadingOvertime(true);
		  const res = await getEmployeeManagementOvertime(id,monthOvertime,offsetOvertime,limitOvertime,pageOvertime)
		  setListTableOvertime(res?.data?.data);
          setTotalDataOvertime(res?.data.recordsTotal)
		} catch (err) {
		  console.log(err);
		} finally {
            setLoadingOvertime(false);
        }
	  };
    const detailManagementListId = async () => {
		try {
            setLoading(true);
		  const res = await getEmployeeManagementDetailId(id)
          setDetailId(res?.data?.data);
		} catch (err) {
		  console.log(err);
        }finally {
            setLoading(false);
        }
	};


    const detailManagementLoanListId = async () => {
		try {
            setLoading(true);
		  const res = await getEmployeeManagementDetailLoanId(id,companyStore ,status)
          setListTableLoan(res?.data?.data?.data);
		} catch (err) {
		  console.log(err);
        }finally {
            setLoading(false);
        }
	};

    const detailManagementAssessmentListId = async (employee_number) => {
		try {
            setLoading(true);
		  const res = await getPAHistoryListData(employee_number, monthAssessment)
            setListTableAssessment(res?.data?.data)
		} catch (err) {
		  console.log(err);
        }finally {
            setLoading(false);
        }
	};

    useEffect(() => {
        if(detail){
            detailManagementAssessmentListId(detail?.employee_number)
        }
    }, [monthAssessment, detail])

	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            detailManagementList();
            detailManagementListId();
            ManagementList();
            detailManagementLoanListId();
          }
		
	}, [month,offset,limit,page,status]);

	useEffect(() => {
        if (!BestpraticeUseEffectOvertime.current) {
            BestpraticeUseEffectOvertime.current = true;
          } else {
            ManagementOvertimeList();
          }
		
	}, [monthOvertime,offsetOvertime,limitOvertime,pageOvertime]);

    useEffect(() => {
		if(page === 1){
			setOffset(0)
		}else {
			setOffset(page*limit - 10)
		}
	},[page])

    useEffect(() => {
		if(pageOvertime === 1){
			setOffsetOvertime(0)
		}else {
			setOffsetOvertime(pageOvertime*limitOvertime - 10)
		}
	},[page])

 
    const validationUser = yup.object().shape({
        leaveBalance: yup.number().required("Field is required"),
    });

    const form = useFormik({
        initialValues: {
          companyCode: companyStore ,
          employeeId: id,
          leaveBalance: null,
        },
        enableReinitialize: true,
        validationSchema: validationUser,
      onSubmit: async (values) => {
        try {
          const res = await UpdateEmployeeManagement(idDetail,values,companyStore);
          if (!res?.data?.success) {
            message.error({
              content: res?.data?.error,
            });
          } else {
            message.success({
                content: "Leave balance successfully updated",
            });
            
            form.resetForm();
            detailManagementList();
            handleCancel();
            setLoading(false);
          }
          setFetching(false);
        } catch (error) {
          message.error({
            content: "Request Not Found",
          });
          setFetching(false);
        }
      },
    });

    const dataBreadcrumb = [
        {
			title: "Master Data - Employee Management",
			url: "/employee-management"
		},
        {
			title: "Employee Management Detail",
            url: `/employee-management/detail/${result}`
		},
	];
    const columnLoan = [
		{
			title: 'No',
			dataIndex: 'no',
			key: 'no',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/employee-management/detail/detail-loan/${encodeURIComponent("id?" + record?.loan_id)}`)}>
						{text || '-'}
					</div>
				)
			}
		},
		{
			title: 'Loan Type',
			dataIndex: 'name',
			key: 'name',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/employee-management/detail/detail-loan/${encodeURIComponent("id?" + record?.loan_id)}`)}>
						{text || '-'}
					</div>
				)
			}
		},
		{
			title: 'Total Amount',
			dataIndex: 'loan_amount',
			key: 'loan_amount',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/employee-management/detail/detail-loan/${encodeURIComponent("id?" + record?.loan_id)}`)}>
						{formatCurrency(text) || "-"}
					</div>
				)
			}
		},
		{
			title: 'Tenor',
			dataIndex: 'tenor',
			key: 'tenor',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/employee-management/detail/detail-loan/${encodeURIComponent("id?" + record?.loan_id)}`)}>
						{text || '-'}
					</div>
				)
			}
		},
		{
			title: 'Total Installment',
			dataIndex: 'installment',
			key: 'installment',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/employee-management/detail/detail-loan/${encodeURIComponent("id?" + record?.loan_id)}`)}>
					{text !== 'NaN' ? formatCurrency(text) : 'Rp.0'}
					</div>
				)
			}
		},
		
		{
			title: 'Start Date',
			dataIndex: 'start_date',
			key: 'start_date',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/employee-management/detail/detail-loan/${encodeURIComponent("id?" + record?.loan_id)}`)}>
						{text || '-'}
					</div>
				)
			}
		},
		{
			title: 'End Date',
			dataIndex: 'end_date',
			key: 'end_date',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/employee-management/detail/detail-loan/${encodeURIComponent("id?" + record?.loan_id)}`)}>
						{text || '--:--'}
					</div>
				)
			}
		},
		{
			title: 'Remaining',
			dataIndex: 'remaining',
			key: 'remaining',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/employee-management/detail/detail-loan/${encodeURIComponent("id?" + record?.loan_id)}`)}>
						{formatCurrency(text) || '-'}
					</div>
				)
			}
		},
		{
			title: 'Status',
			dataIndex: 'active_status',
			key: 'active_status',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/employee-management/detail/detail-loan/${encodeURIComponent("id?" + record?.loan_id)}`)}>
						{text ? text.charAt(0).toUpperCase() + text.slice(1) : '-'}
					</div>
				)
			}
		},
	];
    const columnAssesment = [
		{
			title: 'No',
			dataIndex: 'no',
			key: 'no',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/employee-management/detail/detail-assessment`, {state:{pa_id:record.personal_assessment_request_id,employee_number:detail?.employee_number}})}>
						{text || '-'}
					</div>
				)
			}
		},
		{
			title: 'Request Date',
			dataIndex: 'request_date',
			key: 'request_date',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/employee-management/detail/detail-assessment`, {state:{pa_id:record.personal_assessment_request_id,employee_number:detail?.employee_number}})}>
						{text || '-'}
					</div>
				)
			}
		},
		{
			title: 'Period',
			dataIndex: 'period',
			key: 'period',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/employee-management/detail/detail-assessment`, {state:{pa_id:record.personal_assessment_request_id,employee_number:detail?.employee_number}})}>
						{text || "-"}
					</div>
				)
			}
		},
		{
			title: 'Assessment Type',
			dataIndex: 'type',
			key: 'type',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/employee-management/detail/detail-assessment`, {state:{pa_id:record.personal_assessment_request_id,employee_number:detail?.employee_number}})}>
						{text || '-'}
					</div>
				)
			}
		},
		{
			title: 'Result',
			dataIndex: 'result_status',
			key: 'result_status',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/employee-management/detail/detail-assessment`, {state:{pa_id:record.personal_assessment_request_id,employee_number:detail?.employee_number}})}>
					{text|| '-'}
					</div>
				)
			}
		},
        {
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp" onClick={() => navigate(`/employee-management/detail/detail-assessment`, {state:{pa_id:record.personal_assessment_request_id,employee_number:detail?.employee_number}})}>
					{text|| '-'}
					</div>
				)
			}
		},
	];

    const columnLeave = [
		{
			title: 'No',
			dataIndex: 'no',
			key: 'no',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{text || '-'}
					</div>
				)
			}
		},
		{
			title: 'Request Date',
			dataIndex: 'created_at',
			key: 'created_at',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{text || '-'}
					</div>
				)
			}
		},
		{
			title: 'Leave Type',
			dataIndex: 'leave_type',
			key: 'leave_type',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{text || "-"}
					</div>
				)
			}
		},
		{
			title: 'Start Date',
			dataIndex: 'date_from',
			key: 'date_from',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{text || '-'}
					</div>
				)
			}
		},
		{
			title: 'End Date',
			dataIndex: 'date_to',
			key: 'date_to',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{text || '-'}
					</div>
				)
			}
		},
		
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{text || '--:--'}
					</div>
				)
			}
		},
	];

    const columnOvertimeInfo = [
		{
			title: 'No',
			dataIndex: 'no',
			key: 'no',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{text}
					</div>
				)
			}
		},
		{
			title: 'Overtime Date',
			dataIndex: 'overtime_date',
			key: 'overtime_date',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{text || '-'}
					</div>
				)
			}
		},
		{
			title: 'Overtime Type',
			dataIndex: 'day_type',
			key: 'day_type',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{text || "-"}
					</div>
				)
			}
		},
		{
			title: 'Overtime Duration',
			dataIndex: 'duration',
			key: 'duration',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{text || "-"}
					</div>
				)
			}
		},
		{
			title: 'Total Payment',
			dataIndex: 'payment',
			key: 'payment',
			render: (text,record) =>{
				return (
					<div className="detail-btn-wp">
						{formatCurrency(text ? text : null) || "-"}
					</div>
				)
			}
		},
	
	
	];

    const onChangeTable = (pageNumber, type) => {
		setPage(pageNumber)
		setOffset(pageNumber*limit- 10)
	};

    const onChangeTableOvertime = (pageNumber, type) => {
		setPageOvertime(pageNumber)
		setOffsetOvertime(pageNumber*limit- 10)
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	const onShowSizeChangeOvertime = (_, pageSize) => {
		setLimitOvertime(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	function dataTableWithNumberOvertime() {
		const showNumber = limitOvertime * (pageOvertime - 1);
		return listTableOverime?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}
	function dataTableWithNumberLoan() {
		const showNumber = limit * (page - 1);
		return listTableLoan?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}
    function dataTableWithNumberAssessment() {
		const showNumber = limit * (page - 1);
		return listTableAssessment?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

    const showModal = () => {
    setIsModalVisible(true);
    };

    const handleCancel = () => {
    setIsModalVisible(false);
    form.resetForm();
    };

    return (
         <>
            <div className="title-section">
            <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/employee-management')} icon={<ArrowLeftOutlined />} size={"large"} />
                Employee Management Detail
			</div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title"> NIK</div>
                            <div className="subtitle">{detailId?.map((item) => item?.employee_number) ||"-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Employee Name</div>
                            <div className="subtitle">{detailId?.map((item) => item?.employee_name) ||"-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Branch</div>
                            <div className="subtitle">{detailId?.map((item) => item?.branch_name) ||"-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Department</div>
                            <div className="subtitle">{detailId?.map((item) => item?.department_name) ||"-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Division</div>
                            <div className="subtitle">{detailId?.map((item) => item?.division_name) ||"-"}</div>
                        </div>
                    </Col>
                   
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Position</div>
                            <div className="subtitle">{detailId?.map((item) => item?.position_name) ||"-"}</div>
                        </div>
                    </Col>
                    <div className="mt-7 border-dashed border-2 border-sky-500 w-full bg-white p-6 rounded-lg shadow-lg">
                        <Tabs defaultActiveKey="1"  className="tab-wrapper" type="card">
                            <TabPane tab="Leave Info" key="1">
                            <div className="mt-7  border-dashed flex flex-row border-sky-500 h-auto p-4 w-auto rounded-2xl justify-evenly items-center">
                                <div className="text-center">
                                    <div className="text-lg font-extralight">Leave {detail?.carry_over_year || 0}</div>
                                    <div className="text-2xl font-bold">{detail?.carry_over_leave || 0} <span style={{ fontSize: '0.4em' }}>Days</span></div>
                                </div>
                                <div className="text-center">
                                    <div className="text-lg font-extralight">Leave Balance</div>
                                    <div className="text-2xl font-bold">
                                        {detail?.leave_balance || 0} <span style={{ fontSize: '0.4em' }}>Days</span>
                                        <EditOutlined
                                            style={{ marginLeft: '10px', cursor: 'pointer' }}
                                            onClick={showModal}
                                        />
                                        </div>

                                </div>
                                <div className="text-center">
                                    <div className="text-lg font-extralight">Remaining Leave</div>
                                    <div className="text-2xl font-bold">{detail?.remaining_leave || 0} <span style={{ fontSize: '0.4em' }}>Days</span></div>
                                </div>
                                <Modal
                                title="Update Balance"
                                onCancel={handleCancel}
                                visible={isModalVisible}
                                footer={null}
                                >
                                <FormAntd  onFinish={form.handleSubmit} >
                                <FormAntd.Item>
                                <Row>
                                <Col flex={2}>
                                    <div className="label-input" style={{ marginTop:'15px' }}>Input Balance</div>
                                    </Col>
                                    <Col flex={5}>
                                    <FormAntd.Item>
                                    <InputNumberWithLabel
                                        name="leaveBalance"
                                        placeholder="Balance"
                                        value={form.values.leaveBalance ? form.values.leaveBalance : null }
                                        onChange={(value) =>{
                                            form.setValues({
                                                ...form.values,
                                                leaveBalance: value
                                            })
                                        }}
                                        max={99}
                                        min={-99}
                                        style={{ width: "100%" }}
                                    />
                                         <div className="text-[#C0C0C0] mt-3">Add (-) to reduce the leave balance</div>
                                         {form.touched.leaveBalance && form.errors.leaveBalance ? (
                                        <span className="text-error">{form.errors.leaveBalance}
                                        </span>
                                        ) : null}
                                         </FormAntd.Item>
                                        </Col>
                                        </Row>
                                    </FormAntd.Item>
                                </FormAntd>
                                    <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button
                                        key="back"
                                        style={{ marginRight: "14px" }}
                                        onClick={handleCancel}>
                                       
                                        Cancel
                                    </Button>
                                    
                                   <Button
                                       key="submit"
                                       type="primary"
                                       loading={fetching}
                                       onClick={form.handleSubmit}
                                       disabled={form.isSubmitting}
                                   >
                                       Update
                                   </Button>
                                    </div>
                                </Modal>
                            </div>
                                <div className="mt-7 border-dashed border-2  border-sky-500 w-auto bg-white p-6 rounded-lg shadow-lg md:overflow-y-scroll">
                                <Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"}  className="mt-4">
                                    <Col md={{top:20}} className="mt-5 md:mt-0">
                                        <Row justify={"end"} style={{gap:15}} md={14}>
                                        <div className="text-[#7B7F88] mt-2 mr-2">Month Period</div>
                                        <Select
                                            placeholder="Month Period"
                                            value={month}
                                            onChange={(val) => setMonth(val)}
                                            className="select-company w-40"
                                        >
                                            {monthList.map((month, index) => (
                                                <Select.Option style={{ height: "42px" }} key={index} value={month.value}>
                                                    {month.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                        </Row>
                                    </Col>
                                </Row>
                                <div className="table-wrapper">
                                {loading ? (
                                    <>
                                    <Table id={'my-table'} className="table-employee-loan"  columns={columnLeave}   pagination={false}   loading={loading} />
                                    <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                                        <Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
                                    </div>
                                    </>
                                ) : (
                                    <>
                                    <Table id={'my-table'} className="table-employee-loan" dataSource={dataTableWithNumber()} columns={columnLeave} loading={loading}   pagination={false}  />
                                    <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                                        <Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
                                    </div>
                                    </>
                                )}	
				                </div>
                                </div>
                            </TabPane>

                            <TabPane tab="Overtime Info" key="2">
                            <div className="mt-7 border-dashed border-2  border-sky-500 w-auto bg-white p-6 rounded-lg shadow-lg md:overflow-y-scroll">
                                <Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"}  className="mt-4">
                                    <Col md={{top:20}} className="mt-5 md:mt-0">
                                        <Row justify={"end"} style={{gap:15}} md={14}>

                                        <Select
                                            placeholder="Month Period"
                                            value={monthOvertime}
                                            onChange={(val) => setMonthOvertime(val)}
                                            className="select-company w-40"
                                        >
                                            {monthList.map((month, index) => (
                                                <Select.Option style={{ height: "42px" }} key={index} value={month.value}>
                                                    {month.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                        </Row>
                                    </Col>
                                </Row>
                                <div className="table-wrapper">
                                {loadingOvertime ? (
                                    <>
                                    <Table id={'my-table'} className="table-employee-loan"  columns={columnOvertimeInfo}   pagination={false}   loading={loadingOvertime} />
                                    <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                                        <Pagination defaultCurrent={pageOvertime} current={pageOvertime} total={totalDataOvertime} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChangeOvertime}/>
                                    </div>
                                    </>
                                ) : (
                                    <>
                                    <Table id={'my-table'} className="table-employee-loan" dataSource={dataTableWithNumberOvertime()} columns={columnOvertimeInfo} loading={loadingOvertime}   pagination={false}  />
                                    <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                                        <Pagination defaultCurrent={pageOvertime} current={pageOvertime} total={totalDataOvertime} onChange={onChangeTableOvertime} showSizeChanger={false} onShowSizeChange={onShowSizeChangeOvertime}/>
                                    </div>
                                    </>
                                )}
                                </div>
                                </div>
                            </TabPane>
                            <TabPane tab="Payroll" key="3">
                             
                            </TabPane>
                            <TabPane tab="History" key="4">
                            </TabPane>
                            <TabPane tab="Loan" key="5">
                            <div className="mt-7 border-dashed border-2  border-sky-500 w-auto bg-white p-6 rounded-lg shadow-lg md:overflow-y-scroll">
                                <Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"}  className="mt-4">
                                    <Col md={{top:20}} className="mt-5 md:mt-0">
                                        <Row justify={"end"} style={{gap:15}} md={14}>
                                        <div className="text-[#7B7F88] mt-2 mr-2">Status Type</div>
                                        <Select
                                            placeholder="Status Type"
                                            value={status ? status : undefined}
                                            onChange={(val) => {
                                                setStatus(val)
                                               
                                            }
                                            }
                                            className="select-company w-40"
                                        >
                                        {dataFilterStatus.map((data, index) => {
                                            return (
                                                <Select.Option style={{height: "42px"}} key={index} value={data.value}>{data.label}</Select.Option>
                                            )
                                        })}
								        </Select>
                                        </Row>
                                    </Col>
                                </Row>
                                <div className="table-wrapper">
                                {loading ? (
                                    <>
                                    <Table id={'my-table'}  columns={columnLoan}  loading={loading}  pagination={true}  />
                                    </>
                                ) : (
                                    <>
                                    <Table id={'my-table'} dataSource={dataTableWithNumberLoan()} columns={columnLoan} loading={loading}  pagination={true} />
                                    </>
                                )}	
				                </div>
                                </div>
                            </TabPane>
                            <TabPane tab="Assessment" key="6">
                            <div className="mt-7 border-dashed border-2  border-sky-500 w-auto bg-white p-6 rounded-lg shadow-lg md:overflow-y-scroll">
                                <Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"}  className="mt-4">
                                    <Col md={{top:20}} className="mt-5 md:mt-0">
                                        <Row justify={"end"} style={{gap:15}} md={14}>
                                        <div className="text-[#7B7F88] mt-2 mr-2">Status Type</div>
                                        <Select
                                            placeholder="Month Period"
                                            value={monthAssessment}
                                            onChange={(val) => setMonthAssessment(val)}
                                            className="select-company w-40"
                                        >
                                            {monthListAssessment.map((month, index) => (
                                                <Select.Option style={{ height: "42px" }} key={index} value={month.value}>
                                                    {month.label}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                        </Row>
                                    </Col>
                                </Row>
                                <div className="table-wrapper">
                                {loading ? (
                                    <>
                                    <Table id={'my-table'}  columns={columnAssesment}  loading={loading}  pagination={true}  />
                                    </>
                                ) : (
                                    <>
                                    <Table id={'my-table'} dataSource={dataTableWithNumberAssessment()} columns={columnAssesment} loading={loading}  pagination={true} />
                                    </>
                                )}	
				                </div>
                                </div>
                            </TabPane>
                        </Tabs>
                    </div>
                </Row>
            </div>
        </>
    )
}

export default EmployeeManagementDetail