import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

const getListReportTER = async (
  company,
  branch,
  page,
  offset,
  limit,
  startDate
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    branchCode: branchSession || branch !== null ? branch : branchDefault,
    draw: page,
    start: offset,
    length: limit,
    month: startDate,
  };

  return master.post("/report-list-ter", payload);
};
const getListReportProgressive = async (
  company,
  branch,
  page,
  offset,
  limit,
  startDate
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    branchCode: branchSession || branch !== null ? branch : branchDefault,
    draw: page,
    start: offset,
    length: limit,
    year: startDate,
  };

  return master.post("/report-list-progressive", payload);
};

export { getListReportTER ,getListReportProgressive};
