export default function filter({ size = 13, fill = "#111111" }) {
  return (
    <div>
      <svg
        width={size}
        height={size}
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_216_1498)">
          <path
            d="M11.7087 1.8125H1.29199L5.45866 6.73958V10.1458L7.54199 11.1875V6.73958L11.7087 1.8125Z"
            stroke={fill}
            stroke-width="1.04167"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </g>
        <defs>
          <clipPath id="clip0_216_1498">
            <rect
              width="12.5"
              height="12.5"
              fill="white"
              transform="translate(0.25 0.25)"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
}
