/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form as FormAntd, Row, Select, message,DatePicker } from "antd";
import  { useEffect, useRef, useState } from "react";
import {
  InputWithLabel,
  SelectSearchWithLabel,
  TextAreaWithLabel,
} from "../../../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { DeleteOutlined, CloudUploadOutlined } from "@ant-design/icons";
import "./styles.scss";
import { decryptData } from "../../../../helper/cryptojs";
import Breadcrumb from "../../../../component/Breadcrumb";
import {
  addChangeShiftRequest,
  getDropdownOriginalShift,
  getListEmployeeActive,
  getListScheduleNikDate,
} from "../../../../api/timeRequest";
import * as yup from "yup";
import dayjs from "dayjs";
const AddChangeShiftRequest = () => {
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(false);
  const inputRef = useRef(null);
  const [flag, setFlag] = useState(0);
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const { Option } = Select;
  const [fileName, setFileName] = useState(null);
  const [options, setOptions] = useState([]);
  const [dataSchedule, setDatSchedule] = useState([]);
  const [original, setOriginal] = useState([]);
  const BestpraticeUseEffect = useRef(false);


  const employeeNumberActive = async () => {
    try {
      const res = await getListEmployeeActive(companyStore);
      const data = res?.data?.data;
      setOptions(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getOriginalShiftData = async (date) => {
    try {
      const dedeEmployee = sessionStorage.getItem("EmployeeNumScheedule");
      const res = await getDropdownOriginalShift(companyStore, dedeEmployee, date);
      const data = res?.data?.data?.option_schedule;
      setOriginal(data);
    } catch (err) {
      console.log(err);
      setOriginal([]);
    }
  };
  const employeeSchedule = async () => {
    const employeeNumberSchedule = sessionStorage.getItem("EmployeeNumScheedule");
    const DateSchedule = sessionStorage.getItem("DateSchedule");
    try {
      const res = await getListScheduleNikDate(
        employeeNumberSchedule,
        DateSchedule
      );
      const data = res?.data?.data;
      setDatSchedule(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    sessionStorage.removeItem("DateSchedule");
    sessionStorage.removeItem("selectedIdShift");
    sessionStorage.removeItem("EmployeeNumScheedule");
  },[])

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      employeeNumberActive();
      employeeSchedule();
      getOriginalShiftData();
    
    }
}, [flag]);

  
  const handleSelectChange = (value) => {
    sessionStorage.setItem("EmployeeNumScheedule", value);
    form.setValues({
        ...form.values,
        employeeId: value,
        requestDate: sessionStorage.removeItem("DateSchedule") ,
        originalShiftId: sessionStorage.removeItem("selectedIdShift") ,
    })
    // Mendapatkan nilai full_name terkait dari data API
    const selectedData = options.find(option => option.employee_id === value);
    if (selectedData) {
    form.setFieldValue('DepartmentName',selectedData ? selectedData.department_name : '');
    form.setFieldValue('DivisionName',selectedData ? selectedData.division_name : '');
    form.setFieldValue('PositionName',selectedData ? selectedData.position_name : '');
    sessionStorage.setItem('EmployeeNumScheedule',selectedData ? selectedData.employee_number : '');
    sessionStorage.setItem('selectedIdShift',selectedData ? selectedData.employee_number : null);
    } else {
    form.setFieldValue('EmployeeName');
    form.setFieldValue('DepartmentName');
    form.setFieldValue('DivisionName');
    form.setFieldValue('PositionName');
    form.setFieldValue('requestDate');
    }
    employeeSchedule();
};
  const handleSelectChangeShiftId = (value) => {
    form.setValues({
        ...form.values,
        requestShiftId: value,
    })
};


const handleFileUpload = (e) => {
  const file = e.target.files[0];
  if (file && file.size <= 2 * 1024 * 1024) {
    setFileName(file.name);
    form.setValues({
      ...form.values,
      AttechmentUpload: e.currentTarget.files[0],
    });
  } else {
    setFileName(null);
    if (file) {
      message.error(
        "Please upload a PDF, JPG, PNG file with a maximum size of 2 MB"
      );
    }
  }
};

  const handleFileDelete = () => {
    setFileName(null);
  };

  const validationUser = yup.object().shape({
    employeeId: yup.string().required("Field is required"),
    requestDate: yup.string().required("Field is required"),
    requestShiftId: yup.string().required("Field is required"),
});

  const form = useFormik({
    initialValues: {
      companyCode: companyStore ,
      employeeId: null,
      originalShiftId: null,
      requestShiftId: null,
      requestDate:"",
      description: null,
      attachment: null,
    },
    enableReinitialize: true,
    validationSchema: validationUser,
    onSubmit: async (values) => {
      try {
        const decodeOriginalShiftId = sessionStorage.getItem("selectedIdShift") ? sessionStorage.getItem("selectedIdShift") : null;
        const res = await addChangeShiftRequest(values,companyStore,decodeOriginalShiftId);
        if (!res?.data?.success) {
          message.error({
            content: res?.data?.error,
          });
        } else {
          message.success({
            content: "Change shift successfully saved",
          });
          sessionStorage.removeItem("EmployeeNumScheedule");
          sessionStorage.removeItem("DateSchedule");
          sessionStorage.removeItem("selectedIdShift");
          navigate("/time-request");
          form.resetForm();
          setFlag((prev) => prev + 1);
        }
        setFetching(false);
      } catch (error) {
        message.error({
          content: "Change shift failed to saved",
        });
        setFetching(false);
      }
    },
  });

  // const today = new Date().toISOString().split('T')[0];
  const dataBreadcrumb = [
    {
      title: "Attendance Management - Time Request",
      url: "/time-request",
    },
    {
      title: "Change Shift Request",
      url: "/time-request/add-change-shift-request",
    },
  ];
  return (
    <>
      <div className="title-section">{"Add Change Shift Request"}</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="add-wrapper">
        <FormAntd onFinish={form.handleSubmit}>
          <Col>
            <Row justify={"space-between"}>
            <Col span={11}>
            <FormAntd.Item>
                <SelectSearchWithLabel
                    name="EmployeeNumber"
                    labelname={
                      <div>
                        Employee<span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    showSearch
                    allowClear
                    placeholder="Select Employee"
                    optionFilterProp="children"
                    onChange={handleSelectChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }>
                    {options.map((option) => (
                      <Option
                        key={option.employee_id}
                        value={option.employee_id}>
                       {`${option.employee_number} - ${option.full_name}`}
                      </Option>
                    ))}
                  </SelectSearchWithLabel>
                  {form.touched.employeeId && form.errors.employeeId ? (
                    <span className="text-error">{form.errors.employeeId}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
                <Col span={11}>
                            <FormAntd.Item>
                                <InputWithLabel
                                    disabled
                                    name="DepartmentName"
                                    label={"Department"} 
                                    placeholder="Department"
                                    value={form.values.DepartmentName}
                                    onChange={form.handleChange}
                                />
                            </FormAntd.Item>
                    </Col>
                </Row>
                <Row justify={"space-between"}>
                <Col span={11}>
                            <FormAntd.Item>
                                <InputWithLabel
                                    disabled
                                    name="DivisionName"
                                    label={"Division"} 
                                    value={form.values.DivisionName}
                                    placeholder="Division"
                                    onChange={form.handleChange}
                                />
                            </FormAntd.Item>
                    </Col>
                <Col span={11}>
                            <FormAntd.Item>
                                <InputWithLabel
                                    disabled
                                    name="PositionName"
                                    label={"Position"}
                                    value={form.values.PositionName} 
                                    placeholder="Position"
                                    onChange={form.handleChange}
                                />
                            </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                      <FormAntd.Item>
                          <div className="flex flex-col">
                          <div className="text-slate-500">{"Date"}<span className="text-red-500">*</span></div>
                          <DatePicker
                            name="requestDate"
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            
                            allowClear={true}
                            value={form.values.requestDate ? dayjs(form.values.requestDate, 'YYYY-MM-DD') : null}
                            onChange={date => {
                              const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
                              form.setFieldValue('requestDate', formattedDate);
                              sessionStorage.setItem("DateSchedule", formattedDate ? formattedDate : "");
                              employeeSchedule();
                              getOriginalShiftData(formattedDate); 
                             
                              form.setFieldValue('requestShiftId', ''); 
                              validationUser.fields.requestDate = yup.string();
                            }}
                            className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                          />
                          {form.touched.requestDate && form.errors.requestDate && (
                            <span className="text-error">{form.errors.requestDate}</span>
                          )}
                        </div>
                      </FormAntd.Item>
                    </Col>
                    <Col span={11}>
                <FormAntd.Item>
                  <div className="input-wrapper">
                    <div className="label-input items-center">{"Original Schedule"}</div>
                    {dataSchedule && dataSchedule.schedule ? dataSchedule.schedule : "-"}
                    {dataSchedule && sessionStorage.setItem("selectedIdShift", JSON.stringify(dataSchedule?.m_shifting_id ? dataSchedule.m_shifting_id : null))}
                   
                  </div>
                </FormAntd.Item>
              </Col>
                </Row>

            <Row justify={"space-between"}>
              <Col span={11}>
              <FormAntd.Item>
                <SelectSearchWithLabel
                  name="requestShiftId"
                  labelname={
                    <div>
                      New Schedule<span className="text-red-500">*</span>
                    </div>
                  }
                  showSearch
                  allowClear
                  placeholder="New Schedule"
                  optionFilterProp="children"
                  onChange={handleSelectChangeShiftId}
                  filterOption={(input, option) =>
                    option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0
                  }
                  value={original?.length === 0 ? null : form.values.requestShiftId  ? original?.length === 0 ? null : form.values.requestShiftId  : null}
                >
                  {original?.map((option) => (
                    <Option key={option?.m_shifting_id} value={option?.m_shifting_id}>
                      {`${option?.shift_name} ${option?.work_time}`}
                    </Option>
                  ))}
                </SelectSearchWithLabel>
                {form.touched.requestShiftId && form.errors.requestShiftId ? (
                  <span className="text-error">{form.errors.requestShiftId}</span>
                ) : null}
              </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <div className="mt-9">
                    <div>
                      <label className="file-upload">
                        <input
                          type="file"
                          name="AttechmentUpload"
                          id="AttechmentUpload"
                          onChange={handleFileUpload}
                          accept=".pdf, .jpg , .png"
                          ref={inputRef}
                        />
                        <CloudUploadOutlined /> Attachment
                      </label>
                    </div>
                    <div>
                      {fileName && (
                        <div>
                          <p>
                            {fileName}{" "}
                            <Button
                              shape="circle"
                              onClick={handleFileDelete}
                              icon={<DeleteOutlined style={{ color: "red" }} />}
                              size={"small"}
                            />{" "}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </FormAntd.Item>
              </Col>
            </Row>
            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <div>
                  <TextAreaWithLabel
                       name="description"
                       label={"Description"}
                       placeholder="......."
                       maxLength={200}
                       showCount
                       onChange={form.handleChange}
                       value={form.values.description}
                    />
                  </div>
                </FormAntd.Item>
              </Col>
              
            </Row>
          </Col>
        </FormAntd>

        <div className="footer-add">
          <Button
            key="back"
            className="btn btn-sec"
            style={{ marginRight: "14px" }}
            onClick={() => {navigate("/time-request");sessionStorage.removeItem("DateSchedule");}}>
            Cancel
          </Button>
          
          <Button
            key="submit"
            type="primary"
            className="btn btn-primary btn-sh-p"
            loading={fetching}
            onClick={form.handleSubmit}
            disabled={form.isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddChangeShiftRequest;
