import React, { memo } from "react";
import { Handle, Position } from "@xyflow/react";
import { decryptData } from "../../../helper/cryptojs";
import "./styles.scss";

export default memo(({ data, isConnectable }) => {
  const arrName = data?.full_name.split(" & ") || [];
  const companyCode = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  return (
    <>
      {data?.parent_id ? (
        <Handle
          type="target"
          position={Position.Top}
          // onConnect={(params) => console.log('handle onConnect', params)}
          // isConnectable={isConnectable}
        />
      ) : null}
      <div
        className="card-structur"
      >
        <div className="structur-position">{data?.position_name}</div>
        {arrName.length > 1 &&
          arrName.map((x, i) => {
            return (
              <div className="structur-name align-left">{`${i + 1}. ${x}`}</div>
            );
          })}
        {data?.company_code !== companyCode ? (
          <div className="structur-position">{data?.company_name}</div>
        ) : null}
        {arrName.length == 1 && (
          <div className="structur-name">{data?.full_name}</div>
        )}
      </div>
      {data?.children && data?.children.length > 0 ?
        <Handle
            type="source"
            position={Position.Bottom}
            // isConnectable={isConnectable}
        />
      : null}
    </>
  );
});
