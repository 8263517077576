/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, message, Modal, Tabs, Timeline } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { decryptData } from "../../../helper/cryptojs";
import {
  ApprovalPAVP,
  getDetailPersonalAssessment,
  ReasonPACancel,
} from "../../../api/personalAssessment";
import "../styles.scss";
import DetailCardList from "../../../component/molecules/DetailCardList";
import DetailAssessmentActivity from "../../../component/molecules/DetailAssessmentActivity";
import { ClockIcon, LoaderIcon, ResubmitIcon } from "../../../assets/icons";
import { useFormik } from "formik";
import * as yup from "yup";
import TextArea from "antd/es/input/TextArea";

const PersonalAssessmentDetail = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  let n = location.lastIndexOf("/");
  let result = location.substring(n + 1);
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const inputRef = useRef(null);
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const employeeNumber = decryptData(localStorage.getItem("EmployeeNumber"));
  const RoleaccessPriviledgeProcess = localStorage.getItem(
    "RoleaccessPriviledgePersonalAssessmentProcess"
  );

  const [data, setData] = useState([]);
  const [openReasonReject, setOpenopenReasonReject] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState("");

  const validationSchema = yup.object().shape({
    cancel_reason: yup.string().required("Field is required"),
  });

  const formik = useFormik({
    initialValues: {
      cancel_reason: null,
      cancel_attachment: null,
      status: null,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setLoading(true);
      if (data?.status == "Hold" && !data?.is_approver) {
        await Reject(values.cancel_reason, values.cancel_attachment);
      } else {
        await ApprovalRejectVP(values.cancel_reason, values.cancel_attachment);
      }
      setLoading(false);
    },
  });

  const Reject = async (cancel_reason, cancel_attachment) => {
    try {
      data.cancel_reason = cancel_reason;
      data.cancel_attachment = cancel_attachment;
      data.request_id = id;
      const res = await ReasonPACancel(data);
      if (!res?.data?.success) {
        message.error({
          content: res?.data?.error,
        });
      } else {
        message.success({
          content: "Personal Assessment Request successfully canceled",
        });
        navigate("/personal-assessment");
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  const ApprovalRejectVP = async (cancel_reason, cancel_attachment) => {
    try {
      data.employeeNumber = employeeNumber;
      data.cancel_reason = cancel_reason;
      data.cancel_attachment = cancel_attachment;
      data.status = "REJECTED";
      data.request_id = id;
      const res = await ApprovalPAVP(data);
      if (!res?.data?.success) {
        message.error({
          content: res?.data?.error,
        });
      } else {
        message.success({
          content: "Personal Assessment Request successfully rejected",
        });
        navigate("/personal-assessment");
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024) {
      const newFileName = file.name.replace(/['"]+/g, "");
      const renamedFile = new File([file], newFileName, { type: file.type });
      setFileName(renamedFile.name);
      formik.setValues({
        ...formik.values,
        cancel_attachment: renamedFile,
      });
    } else {
      setFileName(null);
      message.error(
        "Please upload a PDF,JPEG,JPG,PNG file with a maximum size of 2 MB"
      );
    }
  };

  const handleFileDelete = () => {
    inputRef.current.value = "";
    setFileName(null);
    formik.setValues({
      ...formik.values,
      cancel_attachment: null,
    });
  };

  const handleCancelReason = () => {
    setOpenopenReasonReject(false);
    formik.resetForm();
  };

  const handlerejects = () => {
    setOpenopenReasonReject(true);
  };
  const handleRejectsVP = () => {
    Modal.confirm({
      title: "Confirmation",
      icon: null,
      content: (
        <div className="flex justify-start gap-2 py-4">
          Are you sure to reject this assesment process ?
        </div>
      ),
      width: 500,
      footer: (
        <div className="flex justify-end gap-2 mt-2">
          <Button
            loading={loading}
            key="submit"
            type="primary"
            onClick={() => {
              Modal.destroyAll();
              setOpenopenReasonReject(true);
            }}>
            Ok
          </Button>
          <Button key="cancel" onClick={() => Modal.destroyAll()}>
            Cancel
          </Button>
        </div>
      ),
    });
  };

  const handleApprovalAction = async (action, successMessage) => {
    setLoading(true);
    try {
      const requestData = {
        employeeNumber,
        status: action,
        request_id: id,
      };

      const res = await ApprovalPAVP(requestData);

      if (!res?.data?.success) {
        message.error({
          content: res?.data?.error || "An error occurred. Please try again.",
        });
      } else {
        message.success({
          content: successMessage,
        });
        navigate("/personal-assessment");
      }
    } catch (err) {
      console.error(err);
      message.error({
        content: "An unexpected error occurred. Please try again later.",
      });
    } finally {
      setLoading(false);
      setIsModalVisible(false);
    }
  };

  const handleHoldVP = () => {
    setModalAction("HOLD");
    setIsModalVisible(true);
  };

  const handleApproveVP = () => {
    setModalAction("APPROVED");
    setIsModalVisible(true);
  };

  const handleConfirmAction = () => {
    if (modalAction === "HOLD") {
      handleApprovalAction(
        "HOLD",
        "Personal Assessment Request successfully held."
      );
    } else if (modalAction === "APPROVED") {
      handleApprovalAction(
        "APPROVED",
        "Personal Assessment Request successfully approved."
      );
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const fetchDetailPersonalAssessment = async () => {
    setLoading(true);
    try {
      const res = await getDetailPersonalAssessment(id, employeeNumber);
      setData(res?.data?.data);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDetailPersonalAssessment();
  }, [company]);

  const dataBreadcrumb = [
    {
      title: "Performance Management - Personal Assessment",
      url: "/personal-assessment",
    },
    {
      title: "Detail Personal Assessment",
      url: "",
    },
  ];

  const detailData = [
    [
      {
        title: "Personal Assessment Number",
        value: data?.personal_assessment_number || "-",
      },
      { title: "NIK", value: data?.employee_number || "-" },
    ],
    [
      { title: "Employee Name", value: data?.employee_name || "-" },
      { title: "Employment Status", value: data?.employee_status || "-" },
    ],
    [
      { title: "Direct SPV", value: data?.from_direct_upline_name || "-" },
      { title: "Request Date ", value: data?.request_date || "-" },
    ],
    [
      { title: "Period ", value: data?.period_month || "-" },
      { title: "Type ", value: data?.type || "-" },
    ],
    [
      { title: "Current Branch ", value: data?.from_branch_name || "-" },
      {
        title: "Recommended Branch ",
        value: data?.recommended_branch_name || "-",
      },
    ],
    [
      {
        title: "Current Department ",
        value: data?.from_department_name || "-",
      },
      {
        title: "Recommended Department ",
        value: data?.recommended_department_name || "-",
      },
    ],
    [
      {
        title: "Current Division ",
        value: data?.recommended_department_name || "-",
      },
      {
        title: "Recommended Division ",
        value: data?.recommended_division_name || "-",
      },
    ],
    [
      { title: "Current Position ", value: data?.from_position_name || "-" },
      {
        title: "Recommended Position ",
        value: data?.recommended_position_name || "-",
      },
    ],
    [
      { title: "Current Level ", value: data?.from_level_name || "-" },
      {
        title: "Recommended Level ",
        value: data?.recommended_level_name || "-",
      },
    ],
    [
      { title: "Current Grade ", value: data?.from_grade_value || "-" },
      {
        title: "Recommended Grade ",
        value: data?.recommended_grade_value || "-",
      },
    ],
    [
      {
        title: "Current Direct SPV ",
        value: data?.from_direct_upline_name || "-",
      },
      {
        title: "Recommended Direct SPV ",
        value: data?.recommended_direct_upline_name || "-",
      },
    ],
    [
      ["Rejected", "Cancel"].includes(data?.status)
        ? {
            title: "Reason ",
            value:  
            <span className="block overflow-x-auto" style={{ wordWrap: 'break-word', width:'90%' }}>
              {data?.cancel_reason || "-"}
            </span> 
          ,
          }
        : null,
      ["Rejected", "Cancel"].includes(data?.status)
        ? {
            title: "Attachment",
            value: data?.cancel_attachment ? (
              <Link
                to={
                  process.env.REACT_APP_HRIS_URL +
                  `personal-assessment/file/${data?.cancel_attachment}`
                }
                target="_blank">
                View Attachment
              </Link>
            ) : (
              "-"
            ),
          }
        : null,
    ],
    [{ title: "Status ", value: data?.status || "-" }],
  ];

  let items = [
    {
      key: "1",
      label: "Assessment Detail",
      children: (
        <div className="detail-wrapper">
          <div className="w-auto bg-white px-6  mt-3">
            <DetailCardList detailData={detailData} />
            <Col span={24}>
              <div className="detail-card">
                <div className="title">Status Detail</div>
                <div className="subtitle mt-5">
                  <Timeline
                    items={data?.tracking?.map((item) => ({
                      children: (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: `${item?.wording} <br />${
                              item?.formatted_date ? item?.formatted_date : "-"
                            }`,
                          }}
                        />
                      ),
                    }))}
                  />
                </div>
              </div>
            </Col>
            {data?.status == "Hold" && !data?.is_approver && (
              <div className="flex justify-end gap-5">
                <Button
                  loading={loading}
                  onClick={handlerejects}
                  size={"large"}
                  type="primary"
                  icon={<CloseCircleOutlined />}
                  danger>
                  Cancel
                </Button>
                <Button
                  loading={loading}
                  onClick={() =>
                    navigate(
                      `/personal-assessment/add-personal-assessment?${id}`
                    )
                  }
                  size={"large"}
                  icon={<ResubmitIcon />}
                  style={{
                    backgroundColor: "#34D399",
                    color: "white",
                    borderColor: "#34D399",
                    marginRight: "14px",
                  }}>
                  Resubmit
                </Button>
              </div>
            )}
            {data?.status == "Pending" &&
              data?.is_approve_turn &&
              data?.is_approver && (
                <div className="flex justify-end gap-2">
                  <Button
                    loading={loading}
                    onClick={handleRejectsVP}
                    size={"large"}
                    type="primary"
                    icon={<CloseCircleOutlined />}
                    danger>
                    Reject
                  </Button>
                  {data?.is_top_approver && (
                    <div
                      className={`${
                        loading
                          ? "bg-[#ffce84] flex items-center justify-center gap-2 px-4 text-white rounded-lg  hover:bg-[#ffce84]"
                          : "bg-[#FFC700] flex items-center justify-center gap-2 px-4 text-white rounded-lg cursor-pointer hover:bg-[#ffce84]"
                      } `}
                      onClick={handleHoldVP}>
                      <div className="mt-1">
                        {loading ? (
                          <LoaderIcon />
                        ) : (
                          <ClockIcon fill="#ffff" size={24} />
                        )}
                      </div>
                      Hold
                    </div>
                  )}
                  <Button
                    loading={loading}
                    onClick={handleApproveVP}
                    size={"large"}
                    icon={<CheckCircleOutlined />}
                    style={{
                      backgroundColor: "#34D399",
                      color: "white",
                      borderColor: "#34D399",
                      marginRight: "14px",
                    }}>
                    Approve
                  </Button>
                </div>
              )}
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Assessment Process",
      children: (
        <div className="detail-wrapper">
          <div className="w-auto bg-white px-6  mt-3">
            <DetailAssessmentActivity
              detailData={data}
              fetchAsessment={fetchDetailPersonalAssessment}
            />
          </div>
        </div>
      ),
      disabled:
        ["Pending", "Rejected", "Hold", "Cancel"].includes(data?.status) &&
        RoleaccessPriviledgeProcess
          ? true
          : false,
    },
  ];

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Modal
          open={openReasonReject}
          title={
            <div>
              Reason <span style={{ color: "#FF0000" }}> *</span>
            </div>
          }
          onOk={formik.handleSubmit}
          onCancel={handleCancelReason}
          footer={[
            <Button key="back" className="mt-6" onClick={handleCancelReason}>
              Cancel
            </Button>,
            <Button
              key="submit"
              className="mt-6"
              type="primary"
              loading={loading}
              onClick={formik.handleSubmit}>
              Ok
            </Button>,
          ]}>
          <TextArea
            showCount
            maxLength={100}
            name="cancel_reason"
            value={formik.values.cancel_reason}
            onChange={formik.handleChange}
          />
          {formik.touched.cancel_reason && formik.errors.cancel_reason ? (
            <div className="text-red-500">{formik.errors.cancel_reason}</div>
          ) : null}

          <div className="mt-4 w-full">
            <div>Attachment</div>
            <label className="file-upload">
              <input
                type="file"
                name="cancel_attachment"
                id="cancel_attachment"
                onChange={handleFileUpload}
                accept=".jpeg, .jpg, .png, .pdf"
                ref={inputRef}
              />
              <CloudUploadOutlined /> Upload Attachment Assessment
            </label>
            <div>
              {fileName && (
                <div>
                  <p>
                    {fileName}{" "}
                    <Button
                      shape="circle"
                      onClick={handleFileDelete}
                      icon={<DeleteOutlined style={{ color: "red" }} />}
                      size={"small"}
                    />{" "}
                  </p>
                </div>
              )}
            </div>
          </div>
        </Modal>
      </form>
      <Modal
        title="Confirmation"
        visible={isModalVisible}
        onOk={handleConfirmAction}
        onCancel={handleCancel}
        okText="Ok"
        cancelText="Cancel">
        <p>
          Are you sure you want to {modalAction === "HOLD" ? "hold" : "approve"}{" "}
          this request?
        </p>
      </Modal>
      <div className="title-section">
        <Button
          className="btn-sh-p"
          type="primary"
          shape="circle"
          style={{ marginRight: "14px" }}
          onClick={() => navigate("/personal-assessment")}
          icon={<ArrowLeftOutlined />}
          size={"large"}
        />
        Detail Personal Assessment
      </div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <>
        <Tabs defaultActiveKey="1" className="tab-wrapper" items={items} />
      </>
    </>
  );
};

export default PersonalAssessmentDetail;