import { Button, Col, Form as FormAntd, Radio, Row, message,DatePicker } from "antd";
import React, { useEffect, useRef, useState } from "react";
import  { InputWithLabel, RadioGroupWithLabel, TextAreaWithLabel } from "../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { AddHoliday, EditHoliday, getHolidayDetail } from "../../api/holiday";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import dayjs from "dayjs";

const FormAddHoliday = () => {
    const navigate = useNavigate();
    const companyStore = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const [fetching, setFetching] = useState(false);
    const location = window.location.href;
    var n = location.split("/");
    var result =  n[5];
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [detail, setDetail] = useState([]);
    const [flag, setFlag] = useState(0);
    const BestpraticeUseEffect = useRef(false)
	

    const holidayListDetail = async () => {
		setFetching(true);
		try {
		  const res = await getHolidayDetail(id)
        //   console.log('detail test',res)
		  setDetail(res?.data?.data);
		} catch (err) {
		  console.log(err);
		} finally {
        setFetching(false);
		}
	  };

	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            holidayListDetail();
          }
	}, [flag]);

    const form = useFormik({
        initialValues: {
            companyCode: result  ? companyStore : companyStore,
            name: result ? detail.map((data)=>data.holiday_name)[0] : "",
            description: result ? detail.map((data)=>data.description)[0] : "",
            holiday_type: result ? detail.map((data)=>data.holiday_type)[0] : "",
            date_from: result ? detail.map((data)=> data.date_from)[0]  : "",
            date_to: result ? detail.map((data)=> data.date_to)[0]  : "",
            recurring: result ? detail.map((data)=>data.recurring)[0]  : "",
            cut_leave: result ?detail.map((data)=>data.cut_leave)[0]  : "",
        },
        enableReinitialize: true,
        // validationSchema: validationUser,
        onSubmit: async(values) => {
            setFetching(true)
            const res =  result ? await  EditHoliday(values, id) : await AddHoliday(values);
            if(!res?.data?.success){
                message.error({
                    content: res?.data?.error,
                });
            }else{
                if(result){
                    message.success('Edit Data Success')
                }else{
                    message.success("Add Data Success");
                }
                navigate("/holiday")
                form.resetForm();
                setFlag((prev) => prev + 1)
            }
        },
    });
    

    const dataBreadcrumb = [
		{
			title: "Master Data - Holiday",
			url: "/holiday"
		},
		{
			title: `${result ?  'Edit Holiday' : 'Add Holiday'}` ,
			url: `${ result ?  `/holiday/edit-holiday/${result}` : '/holiday/add-holiday'}`
		},
	]

    return (
       <>
        <div className="title-section">
				{result ? "Edit Holiday" : "Add Holiday"}
		</div>
        <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
        <div className="add-wrapper">
            <FormAntd
                onFinish={form.handleSubmit}
            >
                <FormAntd.Item>
                    <InputWithLabel
                        name="name"
                        label={<div>Holiday Name<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="Holiday Name"
                        value={form?.values?.name}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {/* {form.touched.name && form.errors.name ? (
                        <span className="text-error">{form.errors.name}</span>
                    ) : null} */}
                </FormAntd.Item>
                <FormAntd.Item>
                    <TextAreaWithLabel
                        name="description"
                        label={"Description"}
                        placeholder="Description"
                        rows={4} 
                        value={form?.values?.description}
                        onBlur={form.handleBlur}
                        maxLength={500} 
                        onChange={form.handleChange}
                    />
                  
                </FormAntd.Item>
                <FormAntd.Item>
                    <RadioGroupWithLabel
                        name="holiday_type"
                        label={<div>Type<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="Type"
                        rows={4} 
                        value={form?.values?.holiday_type}
                        onBlur={form.handleBlur}
                        maxLength={500} 
                        onChange={form.handleChange}
                    >
                         <Radio value={'National'}>National holiday</Radio>
                         <Radio value={'Company'}>Company holiday</Radio>
                    </RadioGroupWithLabel>
                </FormAntd.Item>
                <Row justify={"space-between"}>
                <Col span={11}>
                <FormAntd.Item>
                        <div className="flex flex-col">
                        <div className="text-slate-500">{"Start Date"}<span className="text-red-500">*</span></div>
                        <DatePicker
                            name="date_from"
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            
                            allowClear={true}
                            value={form.values.date_from ? dayjs(form.values.date_from, 'YYYY-MM-DD') : null}
                            onChange={date => {
                            const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
                            form.setFieldValue('date_from', formattedDate);
                            }}
                            className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                        />
                        </div>
                </FormAntd.Item>
                </Col>
                    <Col span={11}>
                <FormAntd.Item>
                    <div className="flex flex-col">
                        <div className="text-slate-500">{"End Date"}<span className="text-red-500">*</span></div>
                        <DatePicker
                            name="date_to"
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            
                            allowClear={true}
                            value={form.values.date_to ? dayjs(form.values.date_to, 'YYYY-MM-DD') : null}
                            onChange={date => {
                            const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
                            form.setFieldValue('date_to', formattedDate);
                            }}
                            className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                        />
                        </div>
                </FormAntd.Item>
            </Col>
                </Row>
                <FormAntd.Item>
                    <RadioGroupWithLabel
                        name="recurring"
                        label={<div>Recurring<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="Recurring"
                        rows={4} 
                        onBlur={form.handleBlur}
                        maxLength={500} 
                        onChange={form.handleChange}
                        value={form.values?.recurring}
                    >
                          <Radio value={'Yes'}>Yes</Radio>
                            <Radio value={'No'}>No</Radio>
                    </RadioGroupWithLabel>
                </FormAntd.Item>
          
                <FormAntd.Item>
                    <RadioGroupWithLabel
                        name="cut_leave"
                        label={<div>Cut leave<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="Cut Leave"
                        rows={4} 
                        onBlur={form.handleBlur}
                        maxLength={500} 
                        onChange={form.handleChange}
                        value={form.values?.cut_leave}
                    >
                    <Radio value={'Yes'}>Yes</Radio>
                            <Radio value={'No'}>No</Radio>
                    </RadioGroupWithLabel>
                </FormAntd.Item>
          
            </FormAntd>
            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/holiday')}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p"  onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </div>
       </>
    )
}

export default FormAddHoliday;