export default function send({ size = 41, fill = "#0057FF" }) {
  return (
    <div>
      <svg
        width={size}
        height={size}
        viewBox="0 0 41 41"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M20.4993 3C22.7428 3 24.9642 3.44187 27.0369 4.30039C29.1095 5.15891 30.9928 6.41726 32.5791 8.00359C34.1654 9.58993 35.4238 11.4732 36.2823 13.5458C37.1408 15.6185 37.5827 17.8399 37.5827 20.0833C37.5827 24.6141 35.7828 28.9593 32.5791 32.1631C29.3753 35.3668 25.0301 37.1667 20.4993 37.1667C18.2559 37.1667 16.0345 36.7248 13.9618 35.8663C11.8892 35.0078 10.0059 33.7494 8.41961 32.1631C5.21586 28.9593 3.41602 24.6141 3.41602 20.0833C3.41602 15.5526 5.21586 11.2073 8.41961 8.00359C11.6234 4.79985 15.9686 3 20.4993 3ZM13.666 12.7546V18.4604L25.8635 20.0833L13.666 21.7062V27.4121L30.7493 20.0833L13.666 12.7546Z"
          fill={fill}
        />
      </svg>
    </div>
  );
}
