/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Modal,
  Row,
  Table,
  Tabs,
  message,
  Tooltip,
  DatePicker,
} from "antd";
import { useEffect, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { ArrowLeftOutlined } from "@ant-design/icons";
import TabPane from "antd/es/tabs/TabPane";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  UpdateLoanManagementId,
  getDetailLoanManagement,
} from "../../../api/loan";
import { formatCurrency } from "../../../helper/formatCurrency";
import {
  CheckOutlined,
  StopOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import dayjs from "dayjs";

const LoanDetail = () => {
  const location = window.location.href;
  let n = location.lastIndexOf("/");
  let result = location.substring(n + 1);
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const navigate = useNavigate();
  const RoleAccessLoanUpdate = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeLoanUpdate")
  );
  const [detailId, setDetailId] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateId, setUpdateId] = useState();
  const [listTable, setListTable] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paid, setPaid] = useState(dayjs().format("DD-MM-YYYY"));

  const handleDateChange = (date, dateString) => {
    // Validate date format
    if (dateString && dayjs(dateString, "DD-MM-YYYY", true).isValid()) {
      setPaid(dateString);
    }
  };

  const detailLoanManagementList = async () => {
    try {
      const res = await getDetailLoanManagement(id);
      setDetailId(res?.data?.data);
      setListTable(res?.data?.data?.loan_installment);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    detailLoanManagementList();
  }, []);

  const dataBreadcrumb = [
    {
      title: "Loan Management - Loan",
      url: "/loan",
    },
    {
      title: "Loan Detail",
      url: ``
    },
  ];

  const columnLoan = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Installment Date",
      dataIndex: "installment_date",
      key: "installment_date",
      render: (text) => {
        return (
          <div className="detail-btn-wp">
            {text ? moment(text).format("DD MMMM YYYY") : "-"}
          </div>
        );
      },
    },
    {
      title: "Installment",
      dataIndex: "name",
      key: "name",
      render: (text) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Installment Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "Payment Date",
      dataIndex: "paid_date",
      key: "paid_date",
      render: (text) => {
        return (
          <div className="detail-btn-wp">
            {" "}
            {text ? moment(text).format("DD MMMM YYYY") : "-"}
          </div>
        );
      },
    },
    {
      title: "Remaining Amount",
      dataIndex: "remaining",
      key: "remaining",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => {
        return (
          <div className="detail-btn-wp">
            {text ? text.charAt(0).toUpperCase() + text.slice(1) : ""}
          </div>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      width: "50px",
      render: (_, record) => {
        const isUnpaid = record?.status === "unpaid";
        const currentIndex = dataTableWithNumber().findIndex(
          (item) => item.no === record.no
        );
        const isUnpaidFirst =
          isUnpaid &&
          RoleAccessLoanUpdate &&
          (currentIndex === 0 ||
            dataTableWithNumber()[currentIndex - 1]?.status !== "unpaid");

        return (
          <Row justify="space-between">
            <div className="icon-action mx-auto">
              {isUnpaidFirst ? (
                <CheckCircleOutlined
                  style={{ fontSize: "24px", color: "#34D399" }}
                  onClick={() => showModal(record)}
                />
              ) : (
                <Tooltip title={isUnpaid ? "No Access" : ""}>
                  {isUnpaid ? (
                    <StopOutlined
                      style={{ fontSize: "24px", color: "#ff4d4f" }}
                    />
                  ) : (
                    <CheckOutlined style={{ fontSize: "16px" }} />
                  )}
                </Tooltip>
              )}
            </div>
          </Row>
        );
      },
    },
  ];

  const handleUpdateId = async (id) => {
    setLoading(true);

    try {
      const payload = {
        loanId: id?.loan_id,
        loanInstallmentId: id?.loan_installment_id,
        paid_date: paid,
      };

      const res = await UpdateLoanManagementId(payload);
      if (!res?.data?.success) {
        message.error({
          content: res?.data?.error,
        });
        setLoading(false);
      } else {
        message.success({
          content: "Data successfully updated",
        });
        detailLoanManagementList();
        setLoading(false);
      }
    } catch (error) {
      message.error({ content: "Data failed to update" });
      setLoading(false);
    }
  };

  function dataTableWithNumber() {
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: index + 1,
      };
    });
  }

  const showModal = (id) => {
    setIsModalOpen(true);
    setUpdateId(id);
  };

  const handleOk = () => {
    setIsModalOpen(false);
    handleUpdateId(updateId);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setPaid(dayjs().format("DD-MM-YYYY"));
  };

  const statusMap = {
    "on process": "On Process",
    paid: "Paid Off",
    active: "Active",
  };

  return (
    <>
      <Modal
        title="Update Installment Status"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}>
        <div className="flex flex-col">
          <div className="text-slate-800 font-semibold">{"Payment Date"}</div>
          <div>
            <DatePicker
              format="DD-MM-YYYY"
              placeholder="DD-MM-YYYY"
              
              allowClear={false}
              value={paid ? dayjs(paid, "DD-MM-YYYY") : dayjs() || dayjs()}
              onChange={handleDateChange}
              className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
            />
          </div>
        </div>
      </Modal>
      <div className="title-section">
        <Button
          className="btn-sh-p"
          type="primary"
          shape="circle"
          style={{ marginRight: "14px" }}
          onClick={() => navigate("/loan")}
          icon={<ArrowLeftOutlined />}
          size={"large"}
        />
        Loan Detail
      </div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="detail-wrapper">
        <Row>
          <Col span={12}>
            <div className="detail-card">
              <div className="title"> Employee Name</div>
              <div className="subtitle">{detailId?.employee_name || "-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Position</div>
              <div className="subtitle">{detailId?.position_name || "-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Department</div>
              <div className="subtitle">{detailId?.department_name || "-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Division</div>
              <div className="subtitle">{detailId?.division_name || "-"}</div>
            </div>
          </Col>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">Loan Number</div>
              <div className="subtitle">{detailId?.loan_number || "-"}</div>
            </div>
          </Col>

          <Col span={12}>
            <div className="detail-card">
              <div className="title">Status</div>
              <div className="subtitle">
                {detailId?.active_status
                  ? statusMap[detailId.active_status.toLowerCase()] ||
                    detailId.active_status
                  : "-"}
              </div>
            </div>
          </Col>
          <div className="mt-7 border-dashed border-2 border-sky-500 w-full bg-white p-6 rounded-lg shadow-lg">
            <Tabs defaultActiveKey="1" className="tab-wrapper" type="card">
              <TabPane tab="Loan Detail" key="1">
                <div className="detail-wrapper">
                  <div className="border-dashed border-2 border-sky-500 w-auto bg-white p-6 rounded-lg shadow-lg mt-3">
                    <Row>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">Loan Type</div>
                          <div className="subtitle">
                            {detailId?.loan_type || "-"}
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">Loan Date</div>
                          <div className="subtitle">
                            {detailId?.loan_date
                              ? moment(detailId?.loan_date).format(
                                  "DD MMMM YYYY"
                                )
                              : "-"}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">Loan Amount</div>
                          <div className="subtitle">
                            {isNaN(detailId?.loan_amount)
                              ? "-"
                              : formatCurrency(detailId?.loan_amount)}
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">Disburse Date </div>
                          <div className="subtitle">
                            {detailId?.disburse_date
                              ? moment(detailId?.disburse_date).format(
                                  "DD MMMM YYYY"
                                )
                              : "-"}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Col span={12}>
                      <div className="detail-card">
                        <div className="title">Calculation Reference</div>
                        <div className="title mt-[-10px] text-[#323232] text-[10px] font-bold">
                          {detailId?.cal_ref === "period"
                            ? "Installment Period"
                            : "Installment Amount"}
                        </div>
                        <div className="subtitle">
                          {detailId?.cal_ref == "period" ? (
                            <div className="flex gap-2">
                              <div>{detailId?.tenor || "-"}</div>
                              <div>Months</div>
                            </div>
                          ) : isNaN(detailId?.installment) ? (
                            "-"
                          ) : (
                            formatCurrency(detailId?.installment)
                          )}
                        </div>
                      </div>
                    </Col>

                    <Row>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">First Installment Custom</div>
                          <div className="subtitle">
                            {!isNaN(detailId?.first_installment_custom)
                              ? formatCurrency(
                                  detailId?.first_installment_custom
                                )
                              : "-"}
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">
                            {detailId?.cal_ref == "period"
                              ? "Total Installment"
                              : "Total Period"}
                          </div>
                          <div className="subtitle">
                            {detailId?.cal_ref == "period" ? (
                              isNaN(detailId?.installment) ? (
                                "-"
                              ) : (
                                formatCurrency(detailId?.installment)
                              )
                            ) : (
                              <div className="flex gap-2">
                                <div>{detailId?.tenor || "-"}</div>
                                <div>Months</div>
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">First Installment Period</div>
                          <div className="subtitle">
                            {detailId?.first_installment_period == "same"
                              ? "Same period as the loan disbursement date"
                              : "1 Period after the loan disbursement date"}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">Installments Start Date</div>
                          <div className="subtitle">
                            {detailId?.start_date
                              ? moment(detailId?.start_date).format(
                                  "DD MMMM YYYY"
                                )
                              : "-"}
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">Estimated End Date</div>
                          <div className="subtitle">
                            {detailId?.end_date
                              ? moment(detailId?.end_date).format(
                                  "DD MMMM YYYY"
                                )
                              : "-"}
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <div className="detail-card">
                          <div className="title">Description</div>
                          <div className="subtitle">
                            {detailId?.description}
                          </div>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="detail-card overflow-x-auto">
                          <div className="title">Attachment</div>
                          <div className="text-sm text-gray-500 flex items-center">
                            <div>
                              <svg
                                className="w-4 h-4 mr-1 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20">
                                <path d="M4 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1H2V6zM9 12v5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z"></path>
                              </svg>
                            </div>

                            <>
                              {detailId?.attachment_url ? (
                                <Link
                                  to={detailId?.attachment_url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="block overflow-x-auto text-gray-500"
                                  style={{
                                    wordWrap: "break-word",
                                    width: "100%",
                                  }}>
                                  {detailId?.attachment_url
                                    .replace(
                                      `${process.env.REACT_APP_HRIS_URL}attendance-request/file/attendancerequest/Phase02/`,
                                      ""
                                    )
                                    .replace(
                                      `${process.env.REACT_APP_HRIS_URL}attendance-request/file/attendancerequest/`,
                                      ""
                                    )
                                    .replace(
                                      `${process.env.REACT_APP_HRIS_URL}attendance-request/file/storage/attendancerequest/Phase 02/`,
                                      ""
                                    )}
                                </Link>
                              ) : (
                                <span
                                  key={detailId?.id}
                                  className="text-gray-500">
                                  No File
                                </span>
                              )}
                            </>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </TabPane>
              <TabPane tab="Installment" key="2">
                <div className="mt-7 border-dashed border-2  border-sky-500 w-auto bg-white p-6 rounded-lg shadow-lg">
                  <div className="text-slate-500 text-[16px]">
                    Installment Detail
                  </div>
                  <div className="border-solid border-1 w-[125px] mb-5"></div>
                  <div className="table-wrapper">
                    {loading ? (
                      <>
                        <Table
                          id={"my-table"}
                          columns={columnLoan}
                          loading={loading}
                          pagination={true}
                        />
                      </>
                    ) : (
                      <>
                        <Table
                          id={"my-table"}
                          dataSource={dataTableWithNumber()}
                          columns={columnLoan}
                          loading={loading}
                          pagination={true}
                        />
                      </>
                    )}
                  </div>
                </div>
              </TabPane>
            </Tabs>
          </div>
        </Row>
      </div>
    </>
  );
};

export default LoanDetail;
