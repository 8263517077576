/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Pagination, Row, Select, message } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { decryptData, encryptData } from "../../helper/cryptojs";
import { getLoanMangement } from "../../api/loan";
export default function LoanTransaction() {
  const navigate = useNavigate();
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const branch = decryptData(sessionStorage.getItem("selectBranch"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"));
  const branchCode = branchSession || branch !== null ? branch : branchDefault;
  const RoleAccessLoanCreate = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeLoanCreate")
  );
  const RoleAccessLoanDownload = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeLoanDownload")
  );
  const sessionSearch = sessionStorage.getItem("sessionSearchLoan");
  const limitSession = sessionStorage.getItem("sessionPageLoan");
  const filterSession = decryptData(sessionStorage.getItem("filterStatusLoan"));
  const offsetSession = sessionStorage.getItem("sessionOffsetLoan");
  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(limitSession ? limitSession : 1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(offsetSession ? offsetSession : 0);
  const [search, setSearch] = useState(sessionSearch ? sessionSearch : "");
  const [status, setStatus] = useState(
    filterSession ? filterSession : "active"
  );

  const previousPage = useRef(1);
  const BestpraticeUseEffect = useRef(false);

  const LoanManagement = async () => {
    setLoading(true);
    try {
      const res = await getLoanMangement(
        company,
        branch,
        1,
        page,
        offset,
        limit,
        search,
        status
      );
      setListTable(res?.data?.data);
      setTotalData(res?.data?.recordsTotal);
    } catch (err) {
      message.error({
        content: "Request Not Found",
      });
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("sessionPageLoan", page);
  }, [page]);

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      LoanManagement();
      const beforeUnloadHandler = () => {
        sessionStorage.removeItem("sessionPageLoan");
        sessionStorage.removeItem("sessionOffsetLoan");
        sessionStorage.removeItem("filterStatusLoan");
        sessionStorage.removeItem("sessionSearchLoan");
        setPage(1);
        setOffset(0);
        setSearch("");
      };
      window.addEventListener("beforeunload", beforeUnloadHandler);
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, [company, search, offset, limit, page, status]);

  useEffect(() => {
    const limitSession = sessionStorage.getItem("sessionPageLoan");
    if (limitSession) {
      setPage(parseInt(limitSession));
    }
  }, []);

  useEffect(() => {
    let offsetSession = 0;
    if (page > 1) {
      offsetSession = page * limit - 10;
    }
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPageLoan", page);
    sessionStorage.setItem("sessionOffsetLoan", offset);
  }, [page]);

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/loan/detail/${encodeURIComponent("id?" + record.loan_id)}`
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "Loan Number",
      dataIndex: "loan_number",
      key: "loan_number",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/loan/detail/${encodeURIComponent("id?" + record.loan_id)}`
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "NIK",
      dataIndex: "employee_number",
      key: "employee_number",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/loan/detail/${encodeURIComponent("id?" + record.loan_id)}`
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "Employee Name",
      dataIndex: "full_name",
      key: "full_name",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/loan/detail/${encodeURIComponent("id?" + record.loan_id)}`
              )
            }>
            {text}
          </div>
        );
      },
    },

    {
      title: "Branch",
      dataIndex: "branch_name",
      key: "branch_name",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/loan/detail/${encodeURIComponent("id?" + record.loan_id)}`
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "Total Loan Amount",
      dataIndex: "loan_amount",
      key: "loan_amount",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/loan/detail/${encodeURIComponent("id?" + record.loan_id)}`
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: "Tenor",
      dataIndex: "tenor",
      key: "tenor",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/loan/detail/${encodeURIComponent("id?" + record.loan_id)}`
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: "Amount Paid",
      dataIndex: "amount_paid",
      key: "amount_paid",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/loan/detail/${encodeURIComponent("id?" + record.loan_id)}`
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: "Remaining Amount",
      dataIndex: "remaining_amount",
      key: "remaining_amount",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/loan/detail/${encodeURIComponent("id?" + record.loan_id)}`
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },

    {
      title: "Status",
      dataIndex: "active_status",
      key: "active_status",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/loan/detail/${encodeURIComponent("id?" + record.loan_id)}`
              )
            }>
            {text ? text.charAt(0).toUpperCase() + text.slice(1) : "-"}
          </div>
        );
      },
    },
  ];
  const dataBreadcrumb = [
    {
      title: "Loan Management",
      url: "/loan",
    },
    {
      title: "Loan",
      url: ``
    },
  ];

  useEffect(() => {
    previousPage.current = page;
  }, [page]);

  const onChangeTable = (pageNumber, type) => {
    const offsetSession = pageNumber * limit - 10;
    setPage(pageNumber);
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPageLoan", pageNumber);
    sessionStorage.setItem("sessionOffsetLoan", offsetSession);
    //
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };
  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setLimit(10);
    setPage(1);
    sessionStorage.setItem("sessionSearchLoan", e.target.value);
  };

  const handleDownload = () => {
    window.location.href = `${process.env.REACT_APP_HRIS_URL}loan-report?companyCode=${company}&branchCode=${branchCode}&status=${status}`;
  };

  const dataFilter = [
    {
      value: "active",
      title: "Active",
    },
    {
      value: "paid",
      title: "Paid Off",
    },
    {
      value: "on process",
      title: "On Process",
    },
  ];

  return (
    <>
      <div className="title-section">Loan</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
        {" "}
        <Row
          style={{ marginBottom: "24px", justifyContent: "end" }}
          align={"middle"}>
          <Col md={{ top: 20 }} className="mt-5 md:mt-0">
            <Row justify={"end"} style={{ gap: 15 }} md={14}>
              <Select
                allowClear={false}
                placeholder="By Status"
                value={status}
                onChange={(val) => {
                  setStatus(val);
                  sessionStorage.setItem("filterStatusLoan", encryptData(val));
                  setPage(1);
                }}
                className="select-company w-24 md:w-36 text-xs ">
                {dataFilter.map((data, index) => {
                  return (
                    <Select.Option
                      style={{ height: "42px" }}
                      key={index}
                      value={data.value}>
                      {data.title}
                    </Select.Option>
                  );
                })}
              </Select>
              <InputWithIcon
                type="text"
                placeholder="Search Employee Name"
                img={iconSearch}
                className="search-input btn-sh-sec"
                classImg="op-icon"
                onChange={onChangeSearch}
                value={search}
              />
              {RoleAccessLoanDownload && (
                <Button size={"large"} type="primary" onClick={handleDownload}>
                  Download
                </Button>
              )}

              {RoleAccessLoanCreate && (
                <Button
                  className="btn-sh-p"
                  type="primary"
                  shape="circle"
                  onClick={() => navigate("/loan/add-loan")}
                  icon={<PlusOutlined />}
                  size={"large"}
                />
              )}
            </Row>
          </Col>
        </Row>
        <div className="table-wrapper">
          {loading ? (
            <>
              <Table id={"my-table"} columns={columns} loading={loading} />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          ) : (
            <>
              <Table
                id={"my-table"}
                dataSource={dataTableWithNumber()}
                columns={columns}
                loading={loading}
              />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
