import { Button, Col, Form as FormAntd, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { getRole } from "../../api/role";
import { InputWithLabel, SelectOptionWithLabel } from "../../component/Form/Input";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { register, updateUser, getUserDetail } from "../../api/login";
import { getEmployeeDetail } from "../../api/employee";
import { getListEmployeeActive } from "../../api/timeRequest";
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";

const FormAddUser = () => {
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);
    const [roles, setRoles] = useState([]);
    const [detail, setDetail] = useState([]);
    const [flag, setFlag] = useState(0);
    const branch = decryptData(sessionStorage.getItem("selectBranch") )? decryptData(sessionStorage.getItem("selectBranch") ): decryptData(localStorage.getItem("DefaultBranchCode"));
    const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const BestpraticeUseEffect = useRef(false)
    const BestpraticeUseEffect1 = useRef(false)
    const validationUser = yup.object().shape({
        email: yup.string().email("must be a valid email").required("Field is required"),
        // password: yup.string().matches(
        //     /^(?=.*[a-z])(?=.*[0-9])(?=.{8,})/,
        //     "Must Contain 8 Characters with Number"
        //   ),
        role: yup.string().required("Field is required"),
        // employee_number: yup.string().required("Field is required"),
        // name: yup.string().required("Field is required"),
        status: yup.number().required("Field is required"),
        // phoneNumber: yup.number().min(10, "Min 10 character").required("Field is required"),
    });
    const location = window.location.href;
    var n = location.split("/");
    var result = n[5];
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    // const [employeeNumbers,setEmployeeNumbers] = useState([]);


    const userList = async () => {
        setFetching(true);
        try {
            const res = await getUserDetail(id)
            setDetail(res?.data?.data);
        } catch (err) {
            console.log(err);
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            if (result) {
                userList();
            }
          }
      
    }, [flag]);
    const form = useFormik({
        initialValues: {
            employee_number: result ? detail.employee_number : "",
            employee_name: result ? detail.employee_name : "",
            // name: result? detail.username :"",
            status: result ? detail.is_active : 1,
            email: result ? detail.email : "",
            // password: result ? detail.password : "",
            role: result ? detail.role_name : "",
            // phoneNumber: result ? detail.phone_number : "",
            company_code: result ? detail.company_code != undefined ? detail.company_code : company  : company,
            branch_code: result ? detail.branch_code : branch,
        },
        enableReinitialize: true,
        validationSchema: validationUser,
        onSubmit: async (values) => {
            setFetching(true)
            try {
                const res = result ? await updateUser(values, id) : await register(values);
                message.success({
                    content: result ? "User successfully updated" : "User successfully saved",
                });
                setFetching(false)
                form.resetForm();
                navigate('/user')
            } catch (error) {
                console.log(error.response.data)
                message.error({
                    content: error.response.data.error,
                });
                setFetching(false)
            }
        },
    });
    const roleList = async () => {
        const res = await getRole(company, "", 100, 0)
        const data = res?.data?.data;
        setRoles(data.map((item) => {
            return {
                value: item.name,
                title: item.name
            }
        }))
    };
    useEffect(() => {
        if (!BestpraticeUseEffect1.current) {
            BestpraticeUseEffect1.current = true;
          } else {
            roleList();
            form.resetForm();
          }
       
    }, []);

    const dataBreadcrumb = [
		{
			title: "Master Data - User",
			url: "/user"
		},
		{
			title: `${result ?  'Edit User' : 'Add User'}` ,
			url: `${ result ?  `/user/edit-user/${result}` : '/company/add-user'}`
		},
	]

    const checkNik = async (nik) => {
        const res = await getListEmployeeActive(company, nik)
        if(res?.data?.success) {
            const data = res.data.data
            if(data.length > 0) {
                form.setValues({
                    ...form.values,
                    employee_number: nik,
                    employee_name: data[0].full_name,
                    email: data[0].official_email
                })
            }
        }
    }

    return (
        <>
            <div className="title-section">
                {result ? "Edit User" : "Add User"}
            </div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
         <div className="add-wrapper">
            <FormAntd
                onFinish={form.handleSubmit}
            >
                <FormAntd.Item>
                    <InputWithLabel
                        name="employee_number"
                        label={<div>NIK</div>}
                        placeholder="NIK"
                        value={form?.values?.employee_number}
                        // onChange={form.handleChange}
                        onChange={(e) => {
                            const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                            if (!regex.test(e.target.value)) {
                                form.handleChange(e);
                            }

                            if(e.target.value.length >= 10) {
                                checkNik(e.target.value)
                            }
                        }}
                        onBlur={form.handleBlur}
                        disabled={result ? true : false}
                    />
                </FormAntd.Item>
                <FormAntd.Item>
                    <InputWithLabel
                        name="employee_name"
                        label={<div>Employee Name</div>}
                        placeholder="Employee Name"
                        value={form?.values?.employee_name}
                        // onChange={form.handleChange}
                        onChange={(e) => {
                            const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                            if (!regex.test(e.target.value)) {
                                form.handleChange(e);
                            }
                        }}
                        onBlur={form.handleBlur}
                        disabled={result ? true : false}
                    />
                    {form.touched.employee_name && form.errors.employee_name ? (
                        <span className="text-error">{form.errors.employee_name}</span>
                    ) : null}
                </FormAntd.Item>
                <FormAntd.Item>
                    <InputWithLabel
                        name="email"
                        label={<div>Email<span style={{ color: "#FF0000" }}> *</span></div>}
                        placeholder="Email"
                        value={form?.values?.email}
                        // onChange={form.handleChange}
                        onChange={(e) => {
                            const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                            if (!regex.test(e.target.value)) {
                                form.handleChange(e);
                            }
                        }}
                        onBlur={form.handleBlur}
                        disabled={result ? true : false}
                    />
                    {form.touched.email && form.errors.email ? (
                        <span className="text-error">{form.errors.email}</span>
                    ) : null}
                </FormAntd.Item>
                <FormAntd.Item>
                    <SelectOptionWithLabel
                        name="role"
                        label={<div>Role<span style={{ color: "#FF0000" }}> *</span></div>}
                        items={roles}
                        value={form?.values?.role ? form?.values?.role :null}
                        onChange={(val) =>
                            form.setValues({
                                ...form.values,
                                role: val,
                            })
                        }
                        onBlur={form.handleBlur}
                    />
                    {form.touched.role && form.errors.role ? (
                        <span className="text-error">{form.errors.role}</span>
                    ) : null}
                </FormAntd.Item>
                {result && (
                    <FormAntd.Item>
                        <SelectOptionWithLabel
                            name="Status"
                            label={<div>Status<span style={{ color: "#FF0000" }}>*</span></div>}
                            items={[{ value: 1, title: "Active" }, { value: 0, title: "Inactive" }]}
                            value={form?.values?.status}
                            onChange={(val) =>
                                form.setValues({
                                    ...form.values,
                                    status: val,
                                })
                            }
                            onBlur={form.handleBlur}
                        />
                        {form.touched.status && form.errors.status ? (
                            <span className="text-error">{form.errors.status}</span>
                        ) : null}
                    </FormAntd.Item>
                )}
            </FormAntd>
            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{ marginRight: "14px" }} onClick={() => navigate('/user')}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </div>
        </>
       
    )
}

export default FormAddUser;