export default function listIcon() {
  return (
    <div>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.9882 12.9999L19.8902 9.09792C21.3272 7.66092 21.3752 5.37992 19.9972 4.00292C18.6202 2.62492 16.3392 2.67293 14.9022 4.10993L11.0002 8.01193M13.0002 15.9619L9.10824 19.8419C7.67624 21.2719 5.46824 21.4569 4.02624 19.9489C2.58424 18.4419 2.70024 16.3099 4.13324 14.8809L8.02524 10.9999M9.00025 14.9999L15.0002 8.99993"
          stroke="black"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}
