export default function result({ size = 22 }) {
  return (
    <div>
      <svg
        width={size}
        height={size}
        viewBox="0 0 27 27"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M21.9375 25.3125C23.8015 25.3125 25.3125 23.8015 25.3125 21.9375C25.3125 20.0735 23.8015 18.5625 21.9375 18.5625C20.0735 18.5625 18.5625 20.0735 18.5625 21.9375C18.5625 23.8015 20.0735 25.3125 21.9375 25.3125Z"
          fill="black"
          fill-opacity="0.7"
        />
        <path
          d="M8.4375 10.9688H10.125V12.6562H8.4375V10.9688ZM8.4375 15.1875H10.125V16.875H8.4375V15.1875ZM8.4375 19.4062H10.125V21.0938H8.4375V19.4062ZM11.8125 10.9688H18.5625V12.6562H11.8125V10.9688ZM11.8125 15.1875H18.5625V16.875H11.8125V15.1875ZM11.8125 19.4062H15.1875V21.0938H11.8125V19.4062Z"
          fill="black"
          fill-opacity="0.7"
        />
        <path
          d="M5.90625 23.625V5.90625H8.4375V8.4375H18.5625V5.90625H21.0938V15.1875H22.7812V5.90625C22.7812 5.4587 22.6035 5.02947 22.287 4.71301C21.9705 4.39654 21.5413 4.21875 21.0938 4.21875H18.5625V3.375C18.5625 2.92745 18.3847 2.49822 18.0682 2.18176C17.7518 1.86529 17.3226 1.6875 16.875 1.6875H10.125C9.67745 1.6875 9.24823 1.86529 8.93176 2.18176C8.61529 2.49822 8.4375 2.92745 8.4375 3.375V4.21875H5.90625C5.4587 4.21875 5.02947 4.39654 4.71301 4.71301C4.39654 5.02947 4.21875 5.4587 4.21875 5.90625V23.625C4.21875 24.0726 4.39654 24.5018 4.71301 24.8182C5.02947 25.1347 5.4587 25.3125 5.90625 25.3125H15.1875V23.625H5.90625ZM10.125 3.375H16.875V6.75H10.125V3.375Z"
          fill="black"
          fill-opacity="0.7"
        />
      </svg>
    </div>
  );
}
