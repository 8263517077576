import { Button, Col, Input, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getSetScheduleDetailShow } from "../../../api/setSchedule";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import moment from "moment";
const SetSchduleShiftDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [loading, setLoading] = useState(false);
    const BestpraticeUseEffect = useRef(false)
    const [detail, setDetail] = useState([]);
    const [activeShifts, setActiveShifts] = useState({});

   const SetScheduleDetail = async () => {
      try {
        const res = await getSetScheduleDetailShow(id);
        const data = res?.data?.data;
        setDetail(data);

        const shiftNames = data?.schedule?.reduce(
          (acc, item) => ({ ...acc, [moment(item.date).format('YYYY-MM-DD')]: item.name }),
          {}
        );
        setActiveShifts(shiftNames || {});
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
        } else{
            SetScheduleDetail();
        } 
        
	},[]);

    const dataBreadcrumb = [
        {
			title: "Master Data - Set Schedule",
			url: "/shifting/set-schedule"
		},
        {
			title: "Schedule Detail",
			url: `/shifting/set-schedule/detail-set-schedule/${result}`
		},
	];

        
    const tileContent = ({ date }) => {
      return (
        <div className="tile-content-wrapper">
          <div>
             <Input
                className="text-center text-lg font-bold"
                disabled
                style={{ color: 'black'}}
                value={activeShifts[moment(date).format('YYYY-MM-DD')] || '-'}
                />
          </div>
        </div>
      );
    };


    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/shifting/set-schedule')} icon={<ArrowLeftOutlined />} size={"large"} />
                Schedule Detail
			</div>
           <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">NIK</div>
                            <div className="subtitle">{detail?.employee_number}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Employee Name</div>
                            <div className="subtitle">{detail?.employee_name}</div>
                        </div>
                    </Col>
                    <Col span={24}>
                    <div className="detail-card">
                    <div className="title">Schedule</div>
                     <div>
                        <Calendar
                          tileContent={tileContent}
                          className="custom-calendar"
                          minDate={moment().subtract(2, 'months').toDate()} // Tanggal minimum  2 bulan sebelumnya
                          maxDate={moment().add(2, 'months').toDate()} // Tanggal minimum adalah 2 bulan  kedepan
                        //   tileDisabled={({ date }) => true} 
                        />
                    </div>
                    </div>
                </Col>
                </Row>
            </div>
        </>
    )
}

export default SetSchduleShiftDetail