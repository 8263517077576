export default function date({ size = 28, fill = "#111111" }) {
  return (
    <div>
      <svg
        width={size}
        height={size}
        viewBox="0 0 28 29"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.1667 4.83301H5.83333C4.54467 4.83301 3.5 5.91499 3.5 7.24967V24.1663C3.5 25.501 4.54467 26.583 5.83333 26.583H22.1667C23.4553 26.583 24.5 25.501 24.5 24.1663V7.24967C24.5 5.91499 23.4553 4.83301 22.1667 4.83301Z"
          stroke="#111111"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M18.667 2.41699V7.25033"
          stroke={fill}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.33301 2.41699V7.25033"
          stroke={fill}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M3.5 12.083H24.5"
          stroke={fill}
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
  );
}
