import { master } from "./hris";
import { decryptData } from "../helper/cryptojs";

const getGradeList = async (companyCode, page, offset, limit, level_id) => {
  return master.post(`/grade/list-data`, {
    companyCode,
    draw: page,
    start: offset,
    length: limit,
    level_id,
  });
};

const getGradeByLevel = async (levelCode) => {
  return master.get(`/grade/list-grade`, {
    params: {
      levelCode,
    }
  })
}

const getLevelList = async (companyCode) => {
  return master.post(`/grade/list-input-level`, {
    companyCode,
  });
};

const getGradeDetail = async (id) => {
  return master.post(`/grade/detail`, {
    id,
  });
};

const addGrade = async (values) => {
  const user = decryptData(localStorage.getItem("Username"));

  let payload = {
    created_by: user,
    level_id: values.level,
    grade: values.grade,
  };
  return master.post(`/grade/create`, payload);
};

const editGrade = async (values, id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    id,
    modified_by: user,
    level_id: values.level,
    grade: values.grade,
  };
  return master.post(`/grade/update`, payload);
};

const deleteGrade = async (id) => {
  const user = decryptData(localStorage.getItem("Username"));
  return master.post(`/grade/delete`, {
    id,
    deleted_by: user
  });
};

export { 
  getGradeList, 
  getLevelList, 
  addGrade, 
  editGrade, 
  getGradeDetail,
  deleteGrade,
  getGradeByLevel
};
