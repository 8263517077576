import { Button, Col, Form as FormAntd, message } from "antd";
import React, { useEffect, useState } from "react";
import  { InputWithLabel, SelectOptionWithLabel, SelectSearchWithLabelSwitch, TextAreaWithLabel } from "../../component/Form/Input";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { getDivision} from "../../api/division";
import { addPosition, editPosition, getCompanyByList, getPositionDetail, getPositionDropdown } from "../../api/position";
import { decryptData } from "../../helper/cryptojs";
import {decode} from 'html-entities';
import Breadcrumb from "../../component/Breadcrumb";


const FormAddPosition = () => {
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);
    const location = window.location.href;
    var n = location.split("/");
    var result =  n[5];;
    const decoder = decodeURIComponent(result)
    var dec = decoder.lastIndexOf('?');
    var id = decoder.substring(dec + 1);
    const [detail, setDetail] = useState([]);
    const [flag, setFlag] = useState(0);
    const [listDivision, setListDivision] = useState([]);
    const [listPosition, setListPosition] = useState([]);
    const [toggleSwitch, setToggleSwitch] = useState(false)
    const [loadingSwitch, setLoadingSwitch] = useState(false);
    const [listCompany, setCompanyList] = useState([])
    const company = sessionStorage.getItem("selectCompany") ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const multiCompanyAccess = decryptData(localStorage.getItem('MultiCompanyAccess'))
    const multiCompanyLoad = decryptData(localStorage.getItem('MultiCompany'))
    
    const positionList = async () => {
		setFetching(true);
		try {
		  const res = await getPositionDetail(id)
		  setDetail(res?.data?.data[0]);
		} catch (err) {
		  console.log(err);
		} finally {
		  setFetching(false);
		}
	  };

    const positionArr = async (companyPayload) => {
		setFetching(true);
		try {
		  const res = await getPositionDropdown(companyPayload)
          const data = res?.data?.data
          // console.log(data)
		  setListPosition(data.map((item) => {
			return {
				value : item.position_id,
				title : decode(item.position_name)
			}
		}));
		} catch (err) {
		  console.log(err);
		} finally {
		  setFetching(false);
		}
	  };
	
    const divisionList = async () => {
		setFetching(true);
		try {
		  const res = await getDivision("", 100, 0, 1, "",company)
		  const data = res?.data?.data
		  setListDivision(data.map((item) => {
			return {
				value : item.code,
				title : decode(item.division_name)
			}
		}))
		} catch (err) {
		  console.log(err);
		} finally {
		  setFetching(false);
		}
	};

	 const getCompanyList = async () => {
    setFetching(true)
    try {
      console.log(company)
      const res = await getCompanyByList(multiCompanyAccess,multiCompanyLoad)
      const {data} = res?.data
      console.log(data)
      setCompanyList(data)
    } catch (error) {
      
    }
   }


	useEffect(() => {
    if(result){
      positionList()
    }
    divisionList();
    getCompanyList();
	}, []);

  useEffect(() => {
    positionArr(form?.values?.company || company);
  }, [flag])



  const changeMode = async (bool) => {
    setLoadingSwitch(true);
    setToggleSwitch(bool)
    setLoadingSwitch(false)
}

    const validationUser = yup.object().shape({
        name: yup.string().required("Field is required"),
        divisionCode: yup.string().required("Field is required"),
    });
    const form = useFormik({
        initialValues: {
            name: result ? detail?.name : "",
            divisionCode: result ? detail?.division_code : undefined,
            company: result ? detail?.company : company,
            parent_id : result ? detail?.parent_id : null
        },
        enableReinitialize: true,
        validationSchema: validationUser,
        onSubmit: async(values) => {
            setFetching(true)
            try {
                const res = result ? await editPosition(values, id) : await addPosition(values);
                if(!res?.data?.success){
                    message.error({
                        content: res?.data?.error,
                    });
                } else {
                    message.success({
                        content: result ? "Position successfully updated" : "Position successfully saved",
                    });
                    navigate('/position')
                    form.resetForm();
                }
                setFetching(false)
            } catch (error) {
                message.error({
                    content: "Request not found",
                });
                setFetching(false)
            }
        },
    });

    const dataBreadcrumb = [
      {
        title: "Master Data - Position",
        url: "/position"
      },
      {
        title: `${result ?  'Edit Position' : 'Add Position'}` ,
        url: `${ result ?  `/position/edit-position/${result}` : '/position/add-position'}`
      },
    ]

    return (
      <>
        <div className="title-section">
           {result ? "Edit Position" : "Add Position"}
        </div>
        <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
          <Col span={24}>
            <Breadcrumb items={dataBreadcrumb}/>
          </Col>
        </div>
        <div className="add-wrapper">

            <FormAntd
                onFinish={form.handleSubmit}
            >
                <FormAntd.Item>
                    <InputWithLabel
                        name="name"
                        label={<div>Name<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="Name"
                        value={form?.values?.name}
                        onChange={(e) => {
                          const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                          if (!regex.test(e.target.value)) {
                              form.handleChange(e);
                          }
                      }}
                        onBlur={form.handleBlur}
                    />
                    {form.touched.name && form.errors.name ? (
                        <span className="text-error">{form.errors.name}</span>
                    ) : null}
                </FormAntd.Item>
                <FormAntd.Item>
                    <SelectOptionWithLabel 
                        name="divisionCode"
                        label={<div>Division<span style={{color:"#FF0000"}}> *</span></div>}
                        items={listDivision}
                        value={form?.values?.divisionCode}
                        onChange={(val) =>
                            form.setValues({
                            ...form.values,
                            divisionCode: val,
                            })
                        }
                        onBlur={form.handleBlur}
                    />
                    {form.touched.divisionCode && form.errors.divisionCode ? (
                        <span className="text-error">{form.errors.divisionCode}</span>
                    ) : null}
                </FormAntd.Item>
                <FormAntd.Item>
                <SelectSearchWithLabelSwitch 
                        defaultValue={company}
                        name="company"
                        labelname={<div>Company</div>}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.name.toLowerCase().includes(input.toLowerCase())
                          }
                        options={listCompany}
                        disabled={!toggleSwitch}
                        fieldNames={{label: 'name', value: 'code'}}
                        value={form?.values?.company}
                        onSelect={(val) =>{
                            setFlag((prev) => prev+1)
                            form.setValues({
                            ...form.values,
                            company: val,
                            parent_id: null
                            })}
                        }
                        onBlur={form.handleBlur}
                        switchProps={{
                          checked : toggleSwitch,
                                onChange : (c, e) => {
                                    changeMode(c)
                                },
                                loading : loadingSwitch,
                                tooltipMessage : 'Switch to display more company options for Parent selection.',
                                size : 'small',
                                className :'mx-2',
                                multiCompanyAccess: multiCompanyAccess
                        }}
                    />
                    {form.touched.company && form.errors.company ? (
                        <span className="text-error">{form.errors.company}</span>
                    ) : null}
                </FormAntd.Item>
                <FormAntd.Item>
                    <SelectOptionWithLabel 
                        name="parentId"
                        label={listPosition.length > 0 ? <div>Parent<span style={{color:"#FF0000"}}> *</span></div> : <div>Parent</div>}
                        items={listPosition}
                        value={form?.values?.parent_id ? form?.values?.parent_id : null}
                        onChange={(val) =>
                            form.setValues({
                            ...form.values,
                            parent_id: val,
                            })
                        }
                        onBlur={form.handleBlur}
                    />
                </FormAntd.Item>
                <FormAntd.Item>
                    <TextAreaWithLabel
                        name="job_desc"
                        label={<div>Job Description<span style={{color:"#FF0000"}}> *</span></div>}
                        placeholder="Add Job Description.."
                        showCount
                        maxLength={700}
                        value={form?.values?.job_desc}
                        onChange={form.handleChange}
                        onBlur={form.handleBlur}
                    />
                    {form.touched.job_desc && form.errors.job_desc ? (
                        <span className="text-error">{form.errors.job_desc}</span>
                    ) : null}
                </FormAntd.Item>
            </FormAntd>
            <div className="footer-add">
                <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/position')}>
                    Cancel
                </Button>
                <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                    Submit
                </Button>
            </div>
        </div>
      </>
      
    )
}

export default FormAddPosition;