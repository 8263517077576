/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Input, message, Modal, Spin, Flex, Timeline } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { Link, useNavigate } from "react-router-dom";
import { ArrowLeftOutlined,CloseCircleOutlined ,CheckCircleOutlined,CaretRightOutlined, ClockCircleFilled, EyeOutlined} from "@ant-design/icons";
import { getApprovalListDetail,Singlecreate} from "../../../api/approvallist";
import { getEmployeeDetail } from "../../../api/employee";

import { decryptData } from "../../../helper/cryptojs";
import * as yup from "yup";
import { useFormik } from "formik";
import moment from "moment";

const { TextArea } = Input;
const ApprovalListDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    let n = location.lastIndexOf('/');
    let result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    let parameters = decode.split('?');
    let id = parameters[0].split('=')[1];
    let row_type = parameters[1].split('=')[1];
    const [detail, setDetail] = useState([]);
    const [detailStatus, setDetailStatus] = useState([]);
    const [showButton, setShowButton] = useState(true);
    const [loading, setLoading] = useState(false);
    const BestpraticeUseEffect = useRef(false)
    const [open, setOpen] = useState(false);
    const [openReasonReject, setOpenopenReasonReject] = useState(false);
    const userNIK = decryptData(localStorage.getItem("EmployeeNumber"));
    const userCompany = decryptData(localStorage.getItem("DefaultCompanyCode"));

    const ApprovalDetail = async () => {
        try {
            setLoading(true);
            const res = await getApprovalListDetail(id,row_type)
            const resEmpl = await getEmployeeDetail(userNIK)
            const {employee_id} = resEmpl?.data?.data?.personal_data
            const {data, detail_status} = res?.data;
            setDetail(data[0])
            setDetailStatus(detail_status)
            detail_status?.tracking_data.forEach(e => {
             if(e?.employee_id === employee_id && e?.is_done){
              setShowButton(false)
              navigate("/approval-list");
             }
            });
          } catch (err) {
            console.log(err);
          }finally {
            setLoading(false);
            
          };
	  };


    const Approve = async () => {
        try {
            Modal.confirm({
                title: "Confirmation",
                content: "Are you sure want to approve this request?",
                onOk: async () => {
                  try {
                    detail.companyCode = userCompany;
                    detail.employeeNumber = userNIK;
                    detail.submit_type = 'APPROVED';
                    detail.row_id = id;
                    const res = await Singlecreate(detail);
                    if (!res?.data?.success) {
                      message.error({
                        content: res?.data?.error,
                      });
                    } else {
                      message.success({
                        content: "Request successfully approved",
                      });
                      navigate("/approval-list");
                    }
                  } catch (err) {
                    console.log(err);
                  } finally {
                    setLoading(false);
                  }
                },
              });
          } catch (err) {
            console.log(err);
          } finally {
            setLoading(false);
        }
    };


      const validationSchema = yup.object().shape({
        remark: yup.string().required("Field is required"),
      });

      const formik = useFormik({
        initialValues: {
          remark: '',
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {
          setLoading(true);
          await Reject(values.remark);
          setLoading(false);
        },
      });
      
    const Reject = async (remark) => {
        try {
            detail.companyCode = userCompany;
            detail.employeeNumber = userNIK;
            detail.submit_type = 'REJECTED';
            detail.remark = remark;
            detail.row_id = id;
            const res = await Singlecreate(detail)
            if(!res?.data?.success){
                message.error({
                    content: res?.data?.error,
                });
            } else {
                message.success({
                    content: "Request successfully rejected",
                });
                navigate("/approval-list")
            }
          } catch (err) {
            console.log(err);
          } finally {
            setLoading(false);
        }
    };


      const handleApprove = () => {
        setLoading(true);
        Approve();
        setTimeout(() => {
          setLoading(false);
          setOpen(false);
        }, 3000);
      };

      const handleCancelReason = () => {
        setOpenopenReasonReject(false);
        setOpen(false);
      };

	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          }else{
            ApprovalDetail();
          }
	}, []);

     const handlerejects = () => {
        Modal.confirm({
            title: "Confirmation",
            content: "Are you sure want to reject this request?",
            onOk: async () => {setOpenopenReasonReject(true)},
          });
     }

    const dataBreadcrumb = [
		{
			title: "Approval List",
			url: "/approval-list"
		},
        {
			title: "Approval List Detail",
			// url: "/approval-list/approval-list-detail"
		},
	];

    const RoleAccessApprovalListApprove = JSON.parse(localStorage.getItem('RoleaccessPriviledgeApprovalListApprove'));
    const RoleAccessApprovalListReject = JSON.parse(localStorage.getItem('RoleaccessPriviledgeApprovalListReject'));

    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/approval-list')} icon={<ArrowLeftOutlined />} size={"large"} />
				Approval List Detail
			</div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
          {loading?
            <div className="detail-wrapper">
              <Flex justify="center" align="center" gap="middle">
                <Spin size="large"/>
              </Flex>
            </div>
            :
            <div className="detail-wrapper">

            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'start',
              backgroundColor: '#0081FF1A',
              paddingLeft: '1rem',
              borderRadius: 10,
              gap: '1rem',
              font: 'Roboto',
              fontSize: 20,
              }}>
                <ClockCircleFilled
                style={{
                  color: "#0057FF",
                  fontSize: '35px',
                }}
                />
                <p style={{fontWeight: 700}}>{detail ? detail?.full_name : ''}</p>
                <p style={{fontWeight: 300}}>{detail ? 'would like to request' : ''} </p>
                <p style={{fontWeight: 700}}>{detail? detail?.request_type : ''}</p>
            </div>
            <div className="border border-gray-300 p-4 rounded-md mb-4">
              <h3 className="font-semibold text-gray-700 mb-2">Employee Information</h3>
              <div className="grid grid-cols-2 gap-4 mt-6">
                < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                  <div className="col-span-5 flex justify-between mr-2"><span>Company</span><span>:</span></div>
                  <div className="col-span-7">{detail?.company_name}</div>
                </div>

                < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                  <div className="col-span-5 flex justify-between mr-2"><span>Status</span><span>:</span></div>
                  <div className="col-span-7" style={{
                    color: detail?.status === 'PENDING' || detail?.status === 'WAITING' ? 'rgba(233, 136, 2, 1)':
                    detail?.status === 'APPROVED' ? 'rgba(82, 196, 26, 1)' : 'rgba(255, 0, 0, 1)'
                  }}>{detail?.status === "WAITING" ? 'PENDING' : detail?.status }</div>
                </div>

                < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                  <div className="col-span-5 flex justify-between mr-2"><span>Branch</span><span>:</span></div>
                  <div className="col-span-7">{detail?.branch_name}</div>
                </div>

                < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                  <div className="col-span-5 flex justify-between mr-2"><span>Position</span><span>:</span></div>
                  <div className="col-span-7">{detail?.position_name}</div>
                </div>
              </div>
            </div>

            <hr width="100%" size="2" />

                
                {/*  START UNTUK OVERTIME */}
                { detail?.request_type  === 'Overtime' &&
                <>
                  <div className="border border-gray-300 p-4 rounded-md mb-4" style={{
                    backgroundColor: 'rgba(229, 242, 255, 0.4)'
                  }}>
                    <h3 className="font-semibold text-gray-700 mb-2 mt-0">Request Detail</h3>
                    <div className="grid grid-cols-2 gap-4 mt-6">
                    < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                        <div className="col-span-5 flex justify-between mr-2"><span>Date</span><span>:</span></div>
                        <div className="col-span-7">{detail?.request_date}</div>
                      </div>

                      < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                        <div className="col-span-5 flex justify-between mr-2"><span>Schedule</span><span>:</span></div>
                        <div className="col-span-7">{detail?.scheduled_time}</div>
                      </div>

                      < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                        <div className="col-span-5 flex justify-between mr-2"><span>Start Time</span><span>:</span></div>
                        <div className="col-span-7">{moment(detail?.start_time,"HH:mm").format("HH:mm") || '-'}</div>
                      </div>

                      < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                        <div className="col-span-5 flex justify-between mr-2"><span>End Time</span><span>:</span></div>
                        <div className="col-span-7">{moment(detail?.end_time,"HH:mm").format("HH:mm") || '-'}</div>
                      </div>

                      < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                        <div className="col-span-5 flex justify-between mr-2"><span>Break Duration</span><span>:</span></div>
                        <div className="col-span-7">{moment(detail?.break_duration,"HH:mm").format("HH:mm") || '-'}</div>
                      </div>

                      < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                        <div className="col-span-5 flex justify-between mr-2"><span>Overtime Duration</span><span>:</span></div>
                        <div className="col-span-7">{moment(detail?.duration,"HH:mm").format("HH:mm") || '-'}</div>
                      </div>

                      < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                        <div className="col-span-5 flex justify-between mr-2"><span>Compensation Type</span><span>:</span></div>
                        <div className="col-span-7">{detail?.compensation_type}</div>
                      </div>

                     
                      < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                        <div className="col-span-5 flex justify-between mr-2"><span>Attachment</span><span>:</span></div>
                        <div className="col-span-7">
                          {detail?.attachment_url ? (
                             <Button size="small" type="primary" loading={loading} onClick={()=>{
                              window.open(detail?.attachment_url, '_blank').focus();
                            }}>
                                <EyeOutlined />
                                Preview
                            </Button>
                          ) : (
                              <span key={detail?.id} className="text-gray-500">
                              No File
                              </span>
                          )}</div>  
                      </div>

                      < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                        <div className="col-span-5 flex justify-between mr-2"><span>Notes</span><span>:</span></div>
                        <div className="col-span-7">{detail?.description || '-'}</div>
                      </div>
                    </div>
                  </div>

                  <hr width="100%" size="2" />
                </>
                }
            
               {/*  END UNTUK OVERTIME */}

                   {/*  START UNTUK LEAVE */}
                   {detail?.request_type === "Leave" && (
                    <>
                      <div className="border border-gray-300 p-4 rounded-md mb-4" style={{
                        backgroundColor: 'rgba(229, 242, 255, 0.4)'
                      }}>
                        <h3 className="font-semibold text-gray-700 mb-2 mt-0">Request Detail</h3>
                        <div className="grid grid-cols-2 gap-4 mt-6">
                          < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                            <div className="col-span-5 flex justify-between mr-2"><span>Start Date</span><span>:</span></div>
                            <div className="col-span-7">{detail?.date_from}</div>
                          </div>

                          < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                            <div className="col-span-5 flex justify-between mr-2"><span>End Date</span><span>:</span></div>
                            <div className="col-span-7">{detail?.date_to}</div>
                          </div>

                          < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                            <div className="col-span-5 flex justify-between mr-2"><span>Duration</span><span>:</span></div>
                            <div className="col-span-7">{detail?.duration} {detail?.duration === "1" ? 'Day':'Days'}</div>
                          </div>

                          < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                            <div className="col-span-5 flex justify-between mr-2"><span>Leave Type</span><span>:</span></div>
                            <div className="col-span-7">{detail?.leave_type}</div>
                          </div>

                          < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                            <div className="col-span-5 flex justify-between mr-2"><span>Attachment</span><span>:</span></div>
                            <div className="col-span-7">
                            {detail?.attachment_url ? (
                                  <Button size="small" type="primary" loading={loading} onClick={()=>{
                                    window.open(detail?.attachment_url, '_blank').focus();
                                  }}>
                                      <EyeOutlined />
                                      Preview
                                  </Button>
                                ) : (
                                    <span key={detail?.id} className="text-gray-500">
                                    No File
                                    </span>
                                )}</div>  
                            </div>

                          < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                            <div className="col-span-5 flex justify-between mr-2"><span>Notes</span><span>:</span></div>
                            <div className="col-span-3">{detail?.reason || '-'}</div>
                          </div>
                        </div>
                      </div>

                      <hr width="100%" size="2" />
                    </>
                  )}
                  {/*  END UNTUK LEAVE */}
                  
                 {/*  START UNTUK SHIFT */}
                 {detail?.request_type === "Shifting" && (
                  <>
                    <div className="border border-gray-300 p-4 rounded-md mb-4" style={{
                      backgroundColor: 'rgba(229, 242, 255, 0.4)'
                    }}>
                      <h3 className="font-semibold text-gray-700 mb-2 mt-0">Original Schedule</h3>
                      <div className="grid grid-cols-2 gap-4 mt-6">
                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Request Date</span><span>:</span></div>
                          <div className="col-span-7">{detail?.prev_shift_date}</div>
                        </div>

                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Schedule</span><span>:</span></div>
                          <div className="col-span-7">{detail?.prev_shift_name}</div>
                        </div>

                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Current Check In</span><span>:</span></div>
                          <div className="col-span-7">{moment(detail?.prev_schedule_in, 'HH:mm:ss').format('HH:mm')}</div>
                        </div>

                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Current Check Out</span><span>:</span></div>
                          <div className="col-span-7">{moment(detail?.prev_schedule_out, 'HH:mm:ss').format('HH:mm')}</div>
                        </div>
                      </div>
                    </div>

                    <hr width="100%" size="2" />

                    <div className="border border-gray-300 p-4 rounded-md mb-4" style={{
                      backgroundColor: 'rgba(229, 242, 255, 0.4)'
                    }}>
                      <h3 className="font-semibold text-gray-700 mb-2 mt-0">Request Detail</h3>
                      <div className="grid grid-cols-2 gap-4 mt-6">
                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Date</span><span>:</span></div>
                          <div className="col-span-7">{detail?.shift_date}</div>
                        </div>

                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Schedule</span><span>:</span></div>
                          <div className="col-span-7">{detail?.shift_name}</div>
                        </div>

                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Schedule In</span><span>:</span></div>
                          <div className="col-span-7">{moment(detail?.schedule_in, 'HH:mm:ss').format('HH:mm')}</div>
                        </div>

                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Schedule Out</span><span>:</span></div>
                          <div className="col-span-7">{moment(detail?.schedule_out, 'HH:mm:ss').format('HH:mm')}</div>
                        </div>

                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Attachment</span><span>:</span></div>
                          <div className="col-span-7">
                            {detail?.attachment_url ? (
                                <Button size="small" type="primary" loading={loading} onClick={()=>{
                                    window.open(detail?.attachment_url, '_blank').focus();
                                  }}>
                                      <EyeOutlined />
                                      Preview
                                </Button>
                            ) : (
                                <span key={detail?.id} className="text-gray-500">
                                No File
                                </span>
                            )}</div>  
                        </div>

                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Notes</span><span>:</span></div>
                          <div className="col-span-7">{detail?.description || '-'}</div>
                        </div>
                      </div>
                    </div>

                    <hr width="100%" size="2" />
                  </>
                    )}
                  {/*  END UNTUK SHIFT */}


                  {/*  START UNTUK ATTENDANCE */}
                  { detail?.request_type === 'Attendance' &&
                  <>
                    <div className="border border-gray-300 p-4 rounded-md mb-4" style={{
                      backgroundColor: 'rgba(229, 242, 255, 0.4)'
                    }}>
                      <h3 className="font-semibold text-gray-700 mb-2 mt-0">Schedule Detail</h3>
                      <div className="grid grid-cols-2 gap-4 mt-6">
                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Request Date</span><span>:</span></div>
                          <div className="col-span-7">{detail?.request_date}</div>
                        </div>

                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Schedule</span><span>:</span></div>
                          <div className="col-span-7">{detail?.schedule_name}</div>
                        </div>

                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Current Check In</span><span>:</span></div>
                          <div className="col-span-7">{detail?.schedule_in.slice(0,detail?.schedule_in.length-3)}</div>
                        </div>

                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Current Check Out</span><span>:</span></div>
                          <div className="col-span-7">{detail?.schedule_out.slice(0,detail?.schedule_out.length-3)}</div>
                        </div>
                      </div>
                    </div>

                    <hr width="100%" size="2" />

                    <div className="border border-gray-300 p-4 rounded-md mb-4" style={{
                      backgroundColor: 'rgba(229, 242, 255, 0.4)'
                    }}>
                      <h3 className="font-semibold text-gray-700 mb-2 mt-0">Request Detail</h3>
                      <div className="grid grid-cols-2 gap-4 mt-6">
                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Request Check In</span><span>:</span></div>
                          <div className="col-span-7">{moment(detail?.req_check_in, 'HH:mm:ss').format('HH:mm')}</div>
                        </div>

                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Request Check Out</span><span>:</span></div>
                          <div className="col-span-7">{moment(detail?.req_check_out, 'HH:mm:ss').format('HH:mm')}</div>
                        </div>

                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                          <div className="col-span-5 flex justify-between mr-2"><span>Attachment</span><span>:</span></div>
                          <div className="col-span-7">
                            {detail?.attachment_url ? (
                              <Button size="small" type="primary" loading={loading} onClick={()=>{
                                window.open(detail?.attachment_url, '_blank').focus();
                              }}>
                                  <EyeOutlined />
                                  Preview
                              </Button>
                            ) : (
                                <span key={detail?.id} className="text-gray-500">
                                No File
                                </span>
                            )}</div>  
                        </div>

                        < div className="grid grid-flow-row-dense grid-cols-12 grid-rows-1">
                        <div className="col-span-5 flex justify-between mr-2"><span>Notes</span><span>:</span></div>
                          <div className="col-span-7">{detail?.description || '-'}</div>
                        </div>
                      </div>
                    </div>

                    <hr width="100%" size="2" />
                  </>
                  }
                  {/*  END UNTUK ATTENDANCE */}


                <div className="border border-gray-300 p-4 rounded-md mb-4" >
                  <h3 className="font-semibold text-gray-700 mb-2 mt-0">Status Detail</h3>
                  <div className="subtitle mt-5">
                  <Timeline
                        items={detailStatus?.tracking_data?.map((item) => ({
                          children: (
                            <div
                            dangerouslySetInnerHTML={{
                              __html: ` ${item.web_wording} <br /> ${item.submitted_date ? item.submitted_date : ''}`,
                            }}
                            />
                          ),
                        })).concat([
                          {
                            children: (
                              <div>
                                {detail?.request_datetime ? (
                                  <div>
                                    Time of requested
                                    <br />
                                    {moment(detail.request_datetime).format('DD MMMM YYYY HH:mm')}
                                  </div>
                                ) : (
                                  "-"
                                )}
                              </div>
                            ),
                          },
                        ])}
                      />
                
                  </div>
                </div>
              <div className="flex justify-end mt-8">
                {RoleAccessApprovalListReject && showButton && (
                    <Button key="back"   size="large" icon={<CloseCircleOutlined/>} type="primary" danger style={{marginRight: "14px"}} onClick={handlerejects} >
                        Reject
                    </Button>
                )}
                {RoleAccessApprovalListApprove && showButton && (
                    <Button size="large" icon={<CheckCircleOutlined />}  style={{ backgroundColor: "#34D399", color: "white",borderColor:"#34D399", marginRight: "14px" }} onClick={handleApprove}>
                        Approve
                    </Button>
                )}
              </div>
                <form onSubmit={formik.handleSubmit}>
                    <Modal
                        open={openReasonReject}
                        title="Reason"
                        onOk={formik.handleSubmit}
                        onCancel={handleCancelReason}
                        footer={[
                        <Button key="back" className="mt-6" onClick={handleCancelReason}>
                            Cancel
                        </Button>,
                        <Button key="submit" className="mt-6" type="primary" loading={loading} onClick={formik.handleSubmit}>
                            Ok
                        </Button>,
                        ]}
                    >
                        <TextArea
                        showCount
                        maxLength={100}
                        name="remark"
                        value={formik.values.remark}
                        onChange={formik.handleChange}
                        />
                        {formik.touched.remark && formik.errors.remark ? (
                        <div className="text-red-500">{formik.errors.remark}</div>
                        ) : null}
                    </Modal>
                </form>
            </div>
          }
        </>
        
    )
}

export default ApprovalListDetail