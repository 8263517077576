import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

const PersonalAssessmentMaster = async (
  is_create,
  access_all,
  company,
  branch,
  employeeNumber,
  type,
  status,
  period,
  page,
  offset,
  limit,
  search,
  result
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    is_create: is_create,
    access_all: access_all,
    companyCode: companyStore || company !== null ? company : "",
    branchCode: branch,
    employeeNumber: employeeNumber,
    type: type,
    status: status,
    period: period,
    draw: page,
    start: offset,
    length: limit,
    search: search?.length > 0 ? search : undefined,
    result: result,
  };

  return master.post("/pa/list-data", payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

const AddBPersonalAssessmentMaster = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    employeeNumber: data.employeeNumber,
    request_date: data.requestDate,
    period: data.period,
    type: data.type,
    recommended_branch: data.recommendedBranch,
    recommended_department: data.recommendedDepartament,
    recommended_division: data.recommendedDivision,
    recommended_position: data.recommendedPosition,
    recommended_level: data.recommendedLevel,
    recommended_grade: data.recommendedGrade,
    recommended_direct_upline: data.recommendedDirectSPV,
    created_by: user,
  };

  return master.post("/pa/create", payload);
};
const AddPersonalAssessmentProcessActivity = async (data) => {
  const values = JSON.parse(sessionStorage.getItem("schedule"));
  const valuesUrl = JSON.parse(sessionStorage.getItem("url"));

  let payload = {
    employeeNumber: data?.employeeNumber,
    activities: data?.activities,
    activity:
      values?.activity === "FGD"
        ? "FGD"
        : values?.activity === "Psikotest"
        ? "Psikotest"
        : values?.activity === "Interview"
        ? "Interview"
        : values?.activity === "Presentasi"
        ? "Presentasi"
        : values?.activity === "Role Play"
        ? "Role Play"
        : data.activity,
    process_id: data.process_id,
    request_id: data.request_id,
    date:
      values?.activity === "FGD"
        ? data.date
        : values?.activity === "Psikotest"
        ? data.date_psikotest
        : values?.activity === "Interview"
        ? data.date_interview
        : values?.activity === "Presentasi"
        ? data.date_presentasi
        : values?.activity === "Role Play"
        ? data.date_role_play
        : data.date,
    start_time:
      values?.activity === "FGD"
        ? data.start_time
        : values?.activity === "Psikotest"
        ? data.start_time_psikotest
        : values?.activity === "Interview"
        ? data.start_time_interview
        : values?.activity === "Presentasi"
        ? data.start_time_presentasi
        : values?.activity === "Role Play"
        ? data.start_time_role_play
        : data.start_time,
    end_time:
      values?.activity === "FGD"
        ? data.end_time
        : values?.activity === "Psikotest"
        ? data.end_time_psikotest
        : values?.activity === "Interview"
        ? data.end_time_interview
        : values?.activity === "Presentasi"
        ? data.end_time_presentasi
        : values?.activity === "Role Play"
        ? data.end_time_role_play
        : data.end_time,
    url:
      valuesUrl != null
        ? valuesUrl
        : values?.activity === "FGD"
        ? data.url
        : values?.activity === "Psikotest"
        ? data.url_psikotest
        : values?.activity === "Interview"
        ? data.url_interview
        : values?.activity === "Presentasi"
        ? data.url_presentasi
        : values?.activity === "Role Play"
        ? data.url_role_play
        : data.url,
  };

  return master.post("/pa/send-schedule", payload);
};

const DeletePersonalAssessmentProcessActivity = async (data) => {
  let payload = {
    request_id: data?.request_id,
    activity: data?.activity,
  };

  return master.post("/pa/delete-schedule", payload);
};
const AddResultPA = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    request_id: data?.request_id,
    result_status: data?.result_status,
    result_attachment: data?.result_attachment,
    result_effective_date: data?.result_effective_date,
    result_remarks: data?.result_remarks,
    created_by: user,
  };

  return master.post("/pa/create-result", payload);
};

const getDetailPersonalAssessment = async (id, employeeNumber) => {
  let payload = {
    request_id: id,
    employeeNumber: employeeNumber,
  };
  return master.post("/pa/detail-assessment", payload);
};
const getDetailPersonalAssessmentProcess = async (id) => {
  let payload = {
    request_id: id,
  };
  return master.post("/pa/detail-process", payload);
};
const getListPersonalAssessmentEmployeeSelect = async (company, branch) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    branchCode: branch,
  };
  return master.post("/pa/list-employee-select", payload, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};
const getListPersonalAssessmentBranchSelect = async (company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
  };
  return master.post("/pa/list-branch-select", payload);
};

const getListPersonalAssessmentDepartmentSelect = async (company) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
  };
  return master.post("/pa/list-department-select", payload);
};

const getListPersonalAssessmentDivisionSelect = async (id) => {
  let payload = {
    departmentId: id,
  };
  return master.post("/pa/list-division-select", payload);
};
const getListPersonalAssessmentPositionSelect = async (id) => {
  let payload = {
    divisionId: id,
  };
  return master.post("/pa/list-position-select", payload);
};
const getListPersonalAssessmentLevelSelect = async (
  company,
  employeeNumber
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    employeeNumber: employeeNumber,
  };
  return master.post("/pa/list-level-select", payload);
};
const getListPersonalAssessmentGradeSelect = async (id) => {
  let payload = {
    levelId: id,
  };
  return master.post("/pa/list-grade-select", payload);
};
const getListPersonalAssessmentSpvSelect = async (company, id) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    levelId: id,
  };
  return master.post("/pa/list-upline-select", payload);
};
const ReasonPACancel = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    request_id: data?.request_id,
    cancel_reason: data?.cancel_reason,
    cancel_attachment: data?.cancel_attachment,
    modified_by: user,
  };
  return master.post("/pa/cancel", payload);
};
const ApprovalPAVP = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    request_id: data?.request_id,
    employeeNumber: data?.employeeNumber,
    status: data?.status,
    cancel_reason: data?.cancel_reason,
    cancel_attachment: data?.cancel_attachment,
    modified_by: user,
  };
  return master.post("/pa/approve", payload);
};
const ResubmitPA = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    request_id: data?.request_id,
    employeeNumber: data?.employeeNumber,
    request_date: data?.requestDate,
    period: data?.period,
    type: data?.type,
    recommended_branch: data?.recommendedBranch,
    recommended_department: data?.recommendedDepartament,
    recommended_division: data?.recommendedDivision,
    recommended_position: data?.recommendedPosition,
    recommended_level: data?.recommendedLevel,
    recommended_grade: data?.recommendedGrade,
    recommended_direct_upline: data?.recommendedDirectSPV,
    modified_by: user,
  };
  return master.post("/pa/resubmit", payload);
};

export {
  PersonalAssessmentMaster,
  AddBPersonalAssessmentMaster,
  getDetailPersonalAssessment,
  getListPersonalAssessmentEmployeeSelect,
  getListPersonalAssessmentBranchSelect,
  getListPersonalAssessmentDepartmentSelect,
  getListPersonalAssessmentDivisionSelect,
  getListPersonalAssessmentPositionSelect,
  getListPersonalAssessmentLevelSelect,
  getListPersonalAssessmentGradeSelect,
  getListPersonalAssessmentSpvSelect,
  ReasonPACancel,
  ResubmitPA,
  ApprovalPAVP,
  getDetailPersonalAssessmentProcess,
  AddResultPA,
  AddPersonalAssessmentProcessActivity,
  DeletePersonalAssessmentProcessActivity,
};