import { Pagination, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import Table from "../../Table";
import { formatCurrency } from "../../../helper/formatCurrency";
import "./styles.scss";
import moment from "moment";

export default function ContentReview({
  form,
  IsViewtotalData,
  isTotalPreview,
  isPreview,
  page,
  setPage,
  setOffset,
  limit,
  loading,
  setLimit,
}) {
  const previousPage = useRef(1);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    sessionStorage.setItem("sessionPageRunPayroll", page);
  }, [page]);

  useEffect(() => {
    const limitSession = sessionStorage.getItem("sessionPageRunPayroll");
    if (limitSession) {
      setPage(parseInt(limitSession));
    }
  }, []);

  useEffect(() => {
    if (page === 1) {
      setOffset(0);
    } else {
      setOffset(page * limit - 10);
    }
  }, [page]);

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (index) => {
        return <div className="detail-btn-wp">{index}</div>;
      },
    },
    {
      title: "Employee",
      dataIndex: "employee_name",
      key: "employee_name",
      render: (text) => {
        return <div className="detail-btn-wp">{text}</div>;
      },
    },
    {
      title: "Basic Salary",
      dataIndex: "basic_salary",
      key: "basic_salary",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "Allowance",
      dataIndex: "allowance",
      key: "allowance",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },

    {
      title: "Deduction",
      dataIndex: "deduction",
      key: "deduction",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "Additional Earning",
      dataIndex: "additional_earning",
      key: "additional_earning",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
  ];

  useEffect(() => {
    previousPage.current = page;
  }, [page]);

  const onChangeTable = (pageNumber, _) => {
    const offsetSession = pageNumber * limit - 10;
    setPage(pageNumber);
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPageReview", pageNumber);
    sessionStorage.setItem("sessionOffsetReview", offsetSession);
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };
  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return isPreview?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Modal
        title="Delete"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}>
        <p>Are you sure want to delete this data?</p>
      </Modal>

      <div className="flex flex-col gap-4 mb-10">
        <div className="flex flex-col">
          <p className="font-semibold text-xl text-[#505050] mb-[10px]">
            Review Payroll
          </p>
          <div className="text-sm text-[#505050] leading-[17px]">
            Please make sure employee payroll component are correct before run
            payroll
          </div>
        </div>

        <div className="flex gap-10 items-center">
          <div className="payroll flex items-center">
            <div className="flex flex-col">
              <p className="text-[#505050]">Payroll Period</p>
              <div className="mt-[-5px]">
                {IsViewtotalData?.cut_off_type === "weekly"
                  ? form.values.period
                    ? moment(form.values.end_date).format("D MMM YYYY")
                    : null
                  : form.values.period}
              </div>
            </div>
          </div>
          <div className="cut-off-period flex items-center ">
            <div className="flex flex-col">
              <p className="text-[#505050]">Cut Off Period</p>
              <div className="mt-[-5px]">
                {`${moment(form.values.start_date).format("D MMM")} -
                ${moment(form.values.end_date).format("D MMM YYYY")}`}
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <div className="flex flex-col">
              <p className="text-[#505050]">Employee</p>
              <div className="mt-[-5px]">
                {`
                ${
                  form.values.all_employee == 1
                    ? "All Employees"
                    : `${form.values?.select_employees.length}  Employees`
                }
            `}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg  mb-10">
        <div className="table-wrapper">
          {loading ? (
            <>
              <Table id={"my-table"} columns={columns} loading={loading} />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={isTotalPreview}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          ) : (
            <>
              <Table
                id={"my-table"}
                dataSource={dataTableWithNumber()}
                columns={columns}
                loading={loading}
              />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={isTotalPreview}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
