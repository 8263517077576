/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Pagination, Row, DatePicker, message } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import Table from "../../component/Table";
import { decryptData } from "../../helper/cryptojs";
import { formatCurrency } from "../../helper/formatCurrency";
import dayjs from "dayjs";
import "./styles.scss";
import { getListReportProgressive } from "../../api/reportPph21";
export default function ReportProgressive() {
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const branch = decryptData(sessionStorage.getItem("selectBranch"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"));
  const branchCode = branchSession || branch !== null ? branch : branchDefault;
  const RoleAccessReportTerDownload = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeReportTerDownload")
  );

  const limitSession = sessionStorage.getItem("sessionPageReportProgressive");
  const offsetSession = sessionStorage.getItem(
    "sessionOffsetReportProgressive"
  );

  const sessionDate = decryptData(
    sessionStorage.getItem("sessionDateReportProgressive")
  );
  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(limitSession ? limitSession : 1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(offsetSession ? offsetSession : 0);
  const [selectedDate, setSelectedDate] = useState(
    sessionDate ? sessionDate : dayjs().format("YYYY")
  );

  const previousPage = useRef(1);
  const BestpraticeUseEffect = useRef(false);

  const ReportTER = async () => {
    setLoading(true);
    try {
      const res = await getListReportProgressive(
        company,
        branch,
        page,
        offset,
        limit,
        selectedDate
      );
      setListTable(res?.data?.data);
      setTotalData(res?.data?.recordsTotal);
    } catch (err) {
      message.error({
        content: "Request Not Found",
      });
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    sessionStorage.setItem("sessionPageReportProgressive", page);
  }, [page]);

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      ReportTER();
      const beforeUnloadHandler = () => {
        sessionStorage.removeItem("sessionPageReportProgressive");
        sessionStorage.removeItem("sessionOffsetReportProgressive");
        sessionStorage.removeItem("sessionDateReportProgressive");
        setPage(1);
        setOffset(0);
      };
      window.addEventListener("beforeunload", beforeUnloadHandler);
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
    }
  }, [company, offset, limit, page, selectedDate]);

  useEffect(() => {
    const limitSession = sessionStorage.getItem("sessionPageReportProgressive");
    if (limitSession) {
      setPage(parseInt(limitSession));
    }
  }, []);

  useEffect(() => {
    let offsetSession = 0;
    if (page > 1) {
      offsetSession = page * limit - 10;
    }
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPageReportProgressive", page);
    sessionStorage.setItem("sessionOffsetReportProgressive", offset);
  }, [page]);

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text) => {
        return <div className="detail-btn-wp ">{text || "-"}</div>;
      },
    },
    {
      title: "NIK",
      dataIndex: "employee_number",
      key: "employee_number",
      render: (text) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Employee Name",
      dataIndex: "full_name",
      key: "full_name",
      render: (text) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "PTKP Status",
      dataIndex: "ptkp_status",
      key: "ptkp_status",
      render: (text) => {
        return <div className="detail-btn-wp">{text || "-"}</div>;
      },
    },
    {
      title: "Net Income (Yearly)",
      dataIndex: "net_income_value",
      key: "net_income_value",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "PKP",
      dataIndex: "pkp_value",
      key: "pkp_value",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
    {
      title: "Progressive Rate",
      dataIndex: "progressive_rate",
      key: "progressive_rate",
      render: (text) => {
        return <div className="detail-btn-wp">{`${text || "0"}%` || "-"}</div>;
      },
    },
    {
      title: "PPh 21 (Yearly)",
      dataIndex: "pph21_december",
      key: "pph21_december",
      render: (text) => {
        return (
          <div className="detail-btn-wp">{formatCurrency(text) || "-"}</div>
        );
      },
    },
  ];

  const dataBreadcrumb = [
    {
      title: "Report - PPh21",
      url: "/report-progressive",
    },
    {
      title: "Progressive Report",
      url: ``,
    },
  ];

  useEffect(() => {
    previousPage.current = page;
  }, [page]);

  const onChangeTable = (pageNumber, type) => {
    const offsetSession = pageNumber * limit - 10;
    setPage(pageNumber);
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPageReportProgressive", pageNumber);
    sessionStorage.setItem("sessionOffsetReportProgressive", offsetSession);
    //
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };
  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }

  const handleDatePickerChange = (date, dateString) => {
    setSelectedDate(dateString);
    setPage(1);
    setOffset(0);
    sessionStorage.setItem("sessionDateReportProgressive", dateString);
  };
  const disabledDate = (current) => {
    const currentYear = dayjs().year();
    const threeYearsAgo = dayjs().subtract(1, "year").year();
    const selectedYear = current.year();
    return selectedYear < threeYearsAgo || selectedYear > currentYear;
  };
  const handleDownload = () => {
    window.location.href = `${process.env.REACT_APP_HRIS_URL}report-progressive?companyCode=${company}&branchCode=${branchCode}&month=${selectedDate}`;
  };

  return (
    <>
      <div className="title-section">Progressive Report</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
        <Row
          style={{ marginBottom: "24px", justifyContent: "end" }}
          align={"middle"}>
          <Col md={{ top: 20 }} className="mt-5 md:mt-0">
            <Row justify={"end"} style={{ gap: 15 }} md={14}>
              {RoleAccessReportTerDownload && (
                <Button size={"large"} type="primary" onClick={handleDownload}>
                  Download
                </Button>
              )}
              <DatePicker
                placeholder="Select Year"
                allowClear={false}
                picker="year"
                className="search-input btn-sh-sec"
                defaultValue={dayjs(selectedDate, "YYYY")}
                onChange={handleDatePickerChange}
                disabledDate={disabledDate}
              />
            </Row>
          </Col>
        </Row>
        {loading ? (
          <>
            <div className="table-wrapper-bpjs">
              <Table id={"my-table"} columns={columns} loading={loading} />
            </div>
            <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
              <Pagination
                defaultCurrent={page}
                current={page}
                total={totalData}
                onChange={onChangeTable}
                showSizeChanger={false}
                onShowSizeChange={onShowSizeChange}
              />
            </div>
          </>
        ) : (
          <>
            <div className="table-wrapper-bpjs">
              <Table
                id={"my-table"}
                dataSource={dataTableWithNumber()}
                columns={columns}
                loading={loading}
              />
            </div>
            <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
              <Pagination
                defaultCurrent={page}
                current={page}
                total={totalData}
                onChange={onChangeTable}
                onShowSizeChange={onShowSizeChange}
                showSizeChanger={false}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
}
