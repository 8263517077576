import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

const getBpjsKesehatan = async (
  company,
  active,
  page,
  offset,
  limit,
  search
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    isActive: active,
    draw: page,
    start: offset,
    length: limit,
    search_by: "item",
    search_value: search?.length > 0 ? search : undefined,
  };
  return master.post("/bpjs-kesehatan", payload);
};

const AddBpjsKesehatan = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  const code = decryptData(sessionStorage.getItem("code"));
  let payload = {
    companyCode: data.companyCode,
    code: code,
    item: data.item,
    type: data.type,
    presentase_default: data.presentase_default,
    min_basic_premium: parseInt(data.min_basic_premium.replace(/,/g, "")),
    max_basic_premium: parseInt(data.max_basic_premium.replace(/,/g, "")),
    created_by: user,
  };

  return master.post("/bpjs-kesehatan-create", payload);
};

const EditBpjsKesehatan = async (data, id) => {
  const user = decryptData(localStorage.getItem("Username"));
  const code = decryptData(sessionStorage.getItem("code"));
  let payload = {
    companyCode: data.companyCode,
    m_bpjs_kesehatan_id: id,
    code: code,
    item: data.item,
    type: data.type,
    presentase_default: data.presentase_default,
    min_basic_premium: data.min_basic_premium.replace(/,/g, ""),
    max_basic_premium: data.max_basic_premium.replace(/,/g, ""),
    modified_by: user,
  };

  return master.post("/bpjs-kesehatan-update", payload);
};

let detailRequested = false;

const getLBpjsKesehatanDetail = async (id) => {
  if (detailRequested) {
    return;
  }

  detailRequested = true;

  let payload = {
    m_bpjs_kesehatan_id: id,
  };

  try {
    const res = await master.post("/bpjs-kesehatan-detail", payload);
    return res;
  } catch (err) {
    console.log(err);
  } finally {
    detailRequested = false;
  }
};

const DeleteBpjsMaster = async (id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    m_bpjs_kesehatan_id: id,
    deleted_by: user,
  };
  return master.post("/bpjs-kesehatan-delete", payload);
};

const getBpjsKetenagakerjaan = async (
  company,
  active,
  page,
  offset,
  limit,
  search
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    isActive: active,
    draw: page,
    start: offset,
    length: limit,
    search_by: "item",
    search_value: search?.length > 0 ? search : undefined,
  };
  return master.post("/bpjs-ketenagakerjaan", payload);
};

const AddBpjsKetenagakerjaan = async (data) => {
  const user = decryptData(localStorage.getItem("Username"));
  const code = decryptData(sessionStorage.getItem("codeKetenagakerjaan"));
  let payload = {
    companyCode: data.companyCode,
    code: code,
    item: data.item,
    type: data.type,
    presentase_default: data.presentase_default,
    min_basic_premium: parseInt(data.min_basic_premium.replace(/,/g, "")),
    max_basic_premium: parseInt(data.max_basic_premium.replace(/,/g, "")),
    created_by: user,
  };

  return master.post("/bpjs-ketenagakerjaan-create", payload);
};

const EditBpjsKetenagakerjaan = async (data, id) => {
  const user = decryptData(localStorage.getItem("Username"));
  const code = decryptData(sessionStorage.getItem("codeKetenagakerjaan"));
  let payload = {
    companyCode: data.companyCode,
    m_bpjs_ketenagakerjaan_id: id,
    code: code,
    item: data.item,
    type: data.type,
    presentase_default: data.presentase_default,
    min_basic_premium: data.min_basic_premium.replace(/,/g, ""),
    max_basic_premium: data.max_basic_premium.replace(/,/g, ""),
    modified_by: user,
  };

  return master.post("/bpjs-ketenagakerjaan-update", payload);
};

const getLBpjsKetenagakerjaanDetail = async (id) => {
  if (detailRequested) {
    return;
  }

  detailRequested = true;

  let payload = {
    m_bpjs_ketenagakerjaan_id: id,
  };

  try {
    const res = await master.post("/bpjs-ketenagakerjaan-detail", payload);
    return res;
  } catch (err) {
    console.log(err);
  } finally {
    detailRequested = false;
  }
};

const DeleteBpjsKetenagakerjaanMaster = async (id) => {
  const user = decryptData(localStorage.getItem("Username"));
  let payload = {
    m_bpjs_ketenagakerjaan_id: id,
    deleted_by: user,
  };
  return master.post("/bpjs-ketenagakerjaan-delete", payload);
};

const getListReportBpjsKesehatan = async (
  company,
  branch,
  page,
  offset,
  limit,
  startDate
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    branchCode: branchSession || branch !== null ? branch : branchDefault,
    draw: page,
    start: offset,
    length: limit,
    month: startDate,
  };

  return master.post("/report-list-bpjs-kesehatan", payload);
};
const getListReportBpjsKetenagaKerjaan = async (
  company,
  branch,
  pageBpjs,
  offsetBpjs,
  limitBpjs,
  startDate,
  type
) => {
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"));
  let payload = {
    companyCode: companyStore || company !== null ? company : "",
    branchCode: branchSession || branch !== null ? branch : branchDefault,
    draw: pageBpjs,
    start: offsetBpjs,
    length: limitBpjs,
    month: startDate,
    type
  };

  return master.post("/report-list-bpjs-ketenagakerjaan", payload);
};

export {
  getBpjsKesehatan,
  DeleteBpjsMaster,
  AddBpjsKesehatan,
  EditBpjsKesehatan,
  getLBpjsKesehatanDetail,
  getBpjsKetenagakerjaan,
  AddBpjsKetenagakerjaan,
  EditBpjsKetenagakerjaan,
  getLBpjsKetenagakerjaanDetail,
  DeleteBpjsKetenagakerjaanMaster,
  getListReportBpjsKesehatan,
  getListReportBpjsKetenagaKerjaan,
};
