/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Form as FormAntd,
  Row,
  Select,
  message,
  DatePicker,
  Radio,
} from "antd";
import { useEffect, useRef, useState } from "react";
import {
  CheckboxOptionWithLabel,
  InputWithLabel,
  NumberFormat,
  RadioGroupWithLabel,
  SelectOptionWithLabelPeriode,
  SelectSearchWithLabel,
  TextAreaWithLabel,
} from "../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { DeleteOutlined, CloudUploadOutlined } from "@ant-design/icons";
import "./styles.scss";
import { decryptData, encryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import { getListEmployeeActive } from "../../api/timeRequest";
import dayjs from "dayjs";
import { AddLoanManagement, DropdownMaterLoan } from "../../api/loan";
const FormAddLoan = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  let n = location.split("/");
  let result = n[5];
  const inputRef = useRef(null);
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const subtractRequest = sessionStorage.getItem("tenorPeriod");
  const valueChace = sessionStorage.getItem("cal_ref");
  const { Option } = Select;
  const [fetching, setFetching] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [options, setOptions] = useState([]);
  const [optionLoan, setOptionsMLeave] = useState([]);
  const [firstInstallmentPeriod, setFirstInstallmentPeriod] = useState(false);
  const BestpraticeUseEffect = useRef(false);
  const employeeNumberActive = async () => {
    try {
      const res = await getListEmployeeActive(companyStore);
      const data = res?.data?.data;
      setOptions(data);
    } catch (err) {
      console.log(err);
    }
  };
  const dropdownLoanManagement = async () => {
    try {
      const idEmployee = sessionStorage.getItem("selectedIdShift");
      const res = await DropdownMaterLoan(idEmployee);
      const data = res?.data?.data;
      setOptionsMLeave(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectLoan = (value) => {
    const selectedOption = optionLoan.find(
      (option) => option.m_loan_id === value
    );
    const selectedMaxLoan = selectedOption ? selectedOption.max_loan : null;
    form.setValues({
      ...form.values,
      m_loan_id: value,
    });
    if (selectedMaxLoan && form.values.loan_amount > selectedMaxLoan) {
      form.setValues({
        ...form.values,
        loan_amount: "",
      });
    }
  };

  const handleSelectChange = (value) => {
    form.setValues({
      ...form.values,
      employeeNumber: value,
    });
    // Mendapatkan nilai full_name terkait dari data API
    const selectedData = options.find((option) => option.employee_id === value);
    if (selectedData) {
      form.setFieldValue(
        "DepartmentName",
        selectedData ? selectedData.department_name : ""
      );
      form.setFieldValue(
        "DivisionName",
        selectedData ? selectedData.division_name : ""
      );
      form.setFieldValue("m_loan_id", selectedData ? "" : "");
      form.setFieldValue(
        "PositionName",
        selectedData ? selectedData.position_name : ""
      );
      sessionStorage.setItem(
        "selectedIdShift",
        selectedData ? selectedData.employee_number : ""
      );
    } else {
      form.setFieldValue("EmployeeName");
      form.setFieldValue("DepartmentName");
      form.setFieldValue("DivisionName");
      form.setFieldValue("PositionName");
      form.setFieldValue("m_loan_id");
      sessionStorage.removeItem("selectedIdShift");
    }
    form.setFieldValue("m_loan_id", "");
    dropdownLoanManagement();
  };

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      employeeNumberActive();
      dropdownLoanManagement();
      sessionStorage.removeItem("selectedIdShift");
      sessionStorage.removeItem("cal_ref");
      form.resetForm();
    }
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024) {
      const newFileName = file.name.replace(/['"]+/g, "");
      const renamedFile = new File([file], newFileName, { type: file.type });
      setFileName(renamedFile.name);
      form.setValues({
        ...form.values,
        attachment: renamedFile,
      });
    } else {
      setFileName(null);
      message.error(
        "Please upload a PDF or JPG file with a maximum size of 2 MB"
      );
    }
  };

  const handleFileDelete = () => {
    inputRef.current.value = ""; // Clear the input field
    setFileName(null);
    form.setValues({
      ...form.values,
      attachment: null,
    });
  };

  const handleRadioChangePeriod = (e) => {
    setFirstInstallmentPeriod(e === 1);
  };

  const validationUser = yup.object().shape({
    employeeNumber: yup.string().required("Field is required"),
    m_loan_id: yup.string().required("Field is required"),
    loan_date: yup.string().required("Field is required"),
    loan_amount: yup.string().required("Field is required"),
    disburse_date: yup.string().required("Field is required"),
    cal_ref: yup.string().required("Field is required"),
    ...(valueChace === "amount"
      ? {
          installment: yup.string().required("Field is required"),
        }
      : {}),
    ...(valueChace === "period"
      ? {
          tenor: yup.string().required("Field is required"),
        }
      : {}),
    description: yup.string().required("Field is required"),
    attachment: yup.string().required("Field is required"),
    first_installment_period: yup.string().required("Field is required"),
  });

  const form = useFormik({
    initialValues: {
      employeeNumber: "",
      m_loan_id: "",
      loan_date: "",
      loan_amount: "",
      disburse_date: "",
      start_date: "",
      end_date: "",
      cal_ref: "",
      installment: "",
      tenor: "",
      first_installment_custom: "",
      first_installment_period: "",
      description: "",
      attachment: null,
    },
    enableReinitialize: true,
    validationSchema: validationUser,

    validate: (values) => {
      const errors = {};

      if (
        values.custom &&
        (!values.first_installment_custom ||
          values.first_installment_custom.length === 0)
      ) {
        errors.first_installment_custom = "Please input first installment.";
      }

      return errors;
    },
    onSubmit: async (values) => {
      setFetching(true);
      try {
        const res = await AddLoanManagement(values);
        if (!res?.data?.success) {
          message.error({
            content: res?.data?.error,
          });
        } else {
          message.success({
            content: "Success to add loan",
          });
          navigate("/loan");
          sessionStorage.removeItem("selectedIdShift");
          sessionStorage.removeItem("tenor");
          sessionStorage.removeItem("tenorPeriod");
          sessionStorage.removeItem("custom");
          sessionStorage.removeItem("first_installment_custom");
          form.resetForm();
        }
        setFetching(false);
      } catch (error) {
        message.error({
          content: "Failed to add loan, please try again",
        });
        setFetching(false);
      }
    },
  });

  function calculateCurrencyValueFromSessionStorage(
    loanAmount,
    firstInstallmentCustom
  ) {
    const tenor = sessionStorage.getItem("tenor");
    if (!loanAmount || !firstInstallmentCustom || !tenor) {
      return "Rp.0";
    }

    const parsedLoanAmount = parseFloat(loanAmount.replace(/,/g, "")) || 0;
    const parsedFirstInstallmentCustom =
      parseFloat(firstInstallmentCustom.replace(/,/g, "")) || 0;
    const parsedTenor = parseInt(tenor, 10) || 0;

    if (parsedTenor > 1) {
      return (
        Math.ceil(parsedLoanAmount - parsedFirstInstallmentCustom) /
        (parsedTenor - 1)
      )
        .toLocaleString("id-ID", {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\./g, ",");
    } else {
      return "Rp.0";
    }
  }

  function calculateCurrencyValue(loanAmount, firstInstallmentCustom, tenor) {
    return (
      parseFloat(
        Math.ceil(loanAmount.replace(/,/g, "") || "Rp.0") -
          parseFloat(firstInstallmentCustom.replace(/,/g, "") || "Rp.0")
      ) /
      (tenor - 1)
    )
      .toLocaleString("id-ID", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\./g, ",");
  }

  const dataBreadcrumb = [
    {
      title: "Loan Management - Loan",
      url: "/loan",
    },
    {
      title: result ? "Edit Loan" : "Add Loan",
      url: ``
    },
  ];

  return (
    <>
      <div className="title-section">{result ? "Edit Loan" : "Add Loan"}</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="add-wrapper">
        <FormAntd onFinish={form.handleSubmit}>
          <Col>
            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <SelectSearchWithLabel
                    name="employeeNumber"
                    labelname={
                      <div>
                        Employee<span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    showSearch
                    placeholder="Select Employee"
                    optionFilterProp="children"
                    onChange={handleSelectChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }>
                    {options.map((option) => (
                      <Option
                        key={option.employee_id}
                        value={option.employee_id}>
                        {`${option.employee_number} - ${option.full_name}`}
                      </Option>
                    ))}
                  </SelectSearchWithLabel>
                  {form.touched.employeeNumber && form.errors.employeeNumber ? (
                    <span className="text-error">
                      {form.errors.employeeNumber}
                    </span>
                  ) : null}
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <InputWithLabel
                    disabled
                    name="PositionName"
                    label={"Position"}
                    value={form.values.PositionName}
                    placeholder="Position"
                    onChange={form.handleChange}
                  />
                  {form.touched.Position && form.errors.Position ? (
                    <span className="text-error">{form.errors.Position}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
            </Row>

            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <InputWithLabel
                    disabled
                    name="DepartmentName"
                    label={"Department"}
                    placeholder="Department"
                    value={form.values.DepartmentName}
                    onChange={form.handleChange}
                  />
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <InputWithLabel
                    disabled
                    name="DivisionName"
                    label={"Division"}
                    placeholder="Division"
                    value={
                      form.values.DivisionName ? form.values.DivisionName : ""
                    }
                    onChange={form.handleChange}
                  />
                  {form.touched.DivisionName && form.errors.DivisionName ? (
                    <span className="text-error">
                      {form.errors.DivisionName}
                    </span>
                  ) : null}
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <div className="text-slate-500 text-[16px]">Loan Detail</div>
                <div className="border-solid border-1 w-[80px] mb-5"></div>
              </Col>
            </Row>
            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <SelectSearchWithLabel
                    name="m_loan_id"
                    labelname={
                      <div>
                        Loan Type<span className="text-red-500">*</span>
                      </div>
                    }
                    showSearch
                    allowClear
                    placeholder="Loan Type"
                    optionFilterProp="children"
                    onChange={handleSelectLoan}
                    filterOption={(input, option) =>
                      option?.children
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    }
                    value={
                      optionLoan?.length > 0 && form.values.m_loan_id
                        ? form.values.m_loan_id
                        : null
                    }>
                    {optionLoan?.map((option) => (
                      <Option key={option?.m_loan_id} value={option?.m_loan_id}>
                        {option?.name}
                      </Option>
                    ))}
                  </SelectSearchWithLabel>

                  {form.touched.m_loan_id && form.errors.m_loan_id ? (
                    <span className="text-error">{form.errors.m_loan_id}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <div className="flex flex-col">
                    <div className="text-[#8697a8] text-base">
                      {"Loan Date"}
                      <span className="text-red-500 label-input">*</span>
                    </div>
                    <DatePicker
                      name="loan_date"
                      format="DD-MM-YYYY"
                      placeholder="DD-MM-YYYY"
                      
                      allowClear
                      value={
                        form.values.loan_date
                          ? dayjs(form.values.loan_date, "YYYY-MM-DD")
                          : null
                      }
                      onChange={(date) => {
                        const formattedDate = date
                          ? dayjs(date).format("YYYY-MM-DD")
                          : null;
                        form.setFieldValue("loan_date", formattedDate);
                      }}
                      className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                    />

                    {form.touched.loan_date && form.errors.loan_date && (
                      <span className="text-error">
                        {form.errors.loan_date}
                      </span>
                    )}
                  </div>
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <NumberFormat
                    label={
                      <div>
                        Loan Amount<span className="text-red-500 ml-1">*</span>
                      </div>
                    }
                    name="loan_amount"
                    type="text"
                    allowNegative={false}
                    placeholder="Rp."
                    thousandSeparator={true}
                    value={
                      form?.values?.loan_amount ? form?.values?.loan_amount : ""
                    }
                    onValueChange={(values) => {
                      const { formattedValue } = values;
                      form.setValues({
                        ...form.values,
                        loan_amount: formattedValue, // Menetapkan nilai yang sudah diformat
                      });
                    }}
                  />
                  <div
                    id="validationMessage"
                    className="text-red-500 text-[12px]"></div>

                  {form.touched.loan_amount && form.errors.loan_amount ? (
                    <span className="text-error">
                      {form.errors.loan_amount}
                    </span>
                  ) : null}
                </FormAntd.Item>
              </Col>

              <Col span={11}>
                <FormAntd.Item>
                  <div className="flex flex-col">
                    <div className="text-[#8697a8] text-base">
                      {"Disburse Date"}
                      <span className="text-red-500 label-input">*</span>
                    </div>
                    <DatePicker
                      name="disburse_date"
                      format="DD-MM-YYYY"
                      placeholder="DD-MM-YYYY"
                      
                      allowClear={true}
                      value={
                        form.values.disburse_date
                          ? dayjs(form.values.disburse_date, "YYYY-MM-DD")
                          : null
                      }
                      disabledDate={(current) => {
                        const loanDate = form.values.loan_date
                          ? dayjs(form.values.loan_date, "YYYY-MM-DD")
                          : null;
                        return (
                          (loanDate &&
                            current?.isBefore(loanDate?.startOf("month"))) ||
                          (current?.date() < loanDate?.date() &&
                            current?.month() === loanDate?.month() &&
                            current?.year() === loanDate?.year())
                        );
                      }}
                      onChange={(date) => {
                        const formattedDate = date
                          ? dayjs(date).format("YYYY-MM-DD")
                          : null;
                        form.setFieldValue("disburse_date", formattedDate);
                      }}
                      className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                    />
                    {form.touched.disburse_date &&
                      form.errors.disburse_date && (
                        <span className="text-error">
                          {form.errors.disburse_date}
                        </span>
                      )}
                  </div>
                </FormAntd.Item>
              </Col>
            </Row>
            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <SelectOptionWithLabelPeriode
                    className=""
                    placeholder="Installment Period / Amount "
                    name="cal_ref"
                    label={
                      <div>
                        Calculation Reference
                        <span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    items={[
                      {
                        title: "Installment Period",
                        value: "period",
                      },
                      {
                        title: "Installment Amount",
                        value: "amount",
                      },
                    ]}
                    value={form?.values?.cal_ref ? form?.values?.cal_ref : null}
                    onChange={(val) =>
                      form.setValues(
                        {
                          ...form.values,
                          cal_ref: val,
                        },
                        sessionStorage.setItem("cal_ref", val)
                      )
                    }
                    onBlur={form.handleBlur}
                  />
                  {form.touched.cal_ref && form.errors.cal_ref ? (
                    <span className="text-error">{form.errors.cal_ref}</span>
                  ) : null}
                  {form.values.cal_ref === "period" ? (
                    <div>
                      <InputWithLabel
                        className="w-full md:w-[200px] mt-3"
                        name="tenor"
                        placeholder="Months"
                        value={form.values.tenor ? form.values.tenor : null}
                        onChange={(e) => {
                          const regex = e.target.value;
                          const reg = /^[0-9\b]+$/;
                          if (
                            regex === "" ||
                            (reg.test(regex) &&
                              parseInt(regex) >= 1 &&
                              parseInt(regex) <= 60)
                          ) {
                            form.handleChange(e);
                          }
                          sessionStorage.setItem("tenorPeriod", e.target.value);
                        }}
                        maxLength={2}
                        minLength={0}
                        addonAfter="Months"
                      />

                      {form.values.custom === false || !form.values.custom ? (
                        <>
                          {form.values.tenor ? (
                            <div className="text-[#9CA3AF] font-medium mt-2">
                              Total installments for each period:
                              <br />
                              {isNaN(
                                parseFloat(
                                  form?.values?.loan_amount?.replace(/,/g, "")
                                )
                              ) || !form.values.tenor
                                ? "Rp.0"
                                : "Rp." +
                                  Math.ceil(
                                    parseFloat(
                                      form?.values?.loan_amount?.replace(
                                        /,/g,
                                        ""
                                      ) || "0"
                                    ) / form.values.tenor
                                  )
                                    .toLocaleString("id-ID", {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0,
                                    })
                                    .replace(/\./g, ",")}
                            </div>
                          ) : null}
                        </>
                      ) : null}

                      {form.touched.tenor && form.errors.tenor ? (
                        <span className="text-error">{form.errors.tenor}</span>
                      ) : null}
                    </div>
                  ) : null}
                  {form.values.cal_ref === "amount" ? (
                    <div>
                      <NumberFormat
                        name="installment"
                        type="text"
                        placeholder="Rp."
                        allowNegative={false}
                        thousandSeparator={true}
                        value={
                          form?.values?.installment
                            ? form?.values?.installment
                            : null
                        }
                        onChange={(e) => {
                          form.setFieldValue("installment", e.target.value);
                        }}
                        onBlur={form.handleBlur}
                      />
                      {form.values.installment !== "" ? (
                        <div className="text-[#9CA3AF] font-medium mb-5">
                          The loan must be repaid in{" "}
                          {Math.floor(
                            parseFloat(
                              form?.values?.loan_amount?.replace(/,/g, "") ||
                                "0"
                            ) /
                              parseFloat(
                                form?.values?.installment?.replace(/,/g, "") ||
                                  "1"
                              )
                          )}{" "}
                          installment periods.
                          <br />
                          Amount of installments for period 1-
                          {Math.floor(
                            parseFloat(
                              form?.values?.loan_amount?.replace(/,/g, "") ||
                                "0"
                            ) /
                              parseFloat(
                                form?.values?.installment?.replace(/,/g, "") ||
                                  "1"
                              )
                          )}{" "}
                          {sessionStorage.setItem(
                            "tenor",
                            Math.floor(
                              parseFloat(
                                form?.values?.loan_amount?.replace(/,/g, "") ||
                                  "0"
                              ) /
                                parseFloat(
                                  form?.values?.installment?.replace(
                                    /,/g,
                                    ""
                                  ) || "1"
                                )
                            )
                          )}
                          :
                          <br />
                          <div>
                            Rp.
                            {form?.values?.installment?.toLocaleString(
                              "id-ID",
                              {
                                style: "currency",
                                currency: "IDR",
                              }
                            )}
                            {Math.floor(
                              parseFloat(
                                form?.values?.loan_amount?.replace(/,/g, "") ||
                                  "0"
                              ) /
                                parseFloat(
                                  form?.values?.installment?.replace(
                                    /,/g,
                                    ""
                                  ) || "1"
                                )
                            ) <= 1 &&
                              parseInt(
                                form?.values?.installment?.replace(/,/g, "") ||
                                  "0"
                              ) !== 0}
                          </div>
                        </div>
                      ) : null}

                      {form.touched.installment && form.errors.installment ? (
                        <span className="text-error">
                          {form.errors.installment}
                        </span>
                      ) : null}
                    </div>
                  ) : null}
                  <div className="input-wrapper flex gap-3">
                    <CheckboxOptionWithLabel
                      name="custom"
                      checked={
                        form?.values?.custom
                          ? form?.values?.custom
                          : false || false
                      }
                      value={
                        form?.values?.custom
                          ? form?.values?.custom
                          : false || false
                      }
                      onChange={(e) => {
                        form.setFieldValue("custom", e.target.checked);
                        encryptData(
                          sessionStorage.setItem("custom", e.target.checked)
                        );
                      }}
                    />
                    <span className="mt-[7px] label-input">
                      First Installment Custom
                    </span>
                  </div>
                  <Row justify={"space-between"}>
                    <Col span={11}>
                      <NumberFormat
                        disabled={form?.values?.custom ? false : true}
                        name="first_installment_custom"
                        type="text"
                        placeholder="Rp."
                        thousandSeparator={true}
                        value={
                          form?.values.first_installment_custom
                            ? form?.values.first_installment_custom
                            : ""
                        }
                        onChange={(e) => {
                          form.setFieldValue(
                            "first_installment_custom",
                            e.target.value
                          );
                          encryptData(
                            sessionStorage.setItem(
                              "first_installment_custom",
                              e.target.value
                            )
                          );
                        }}
                        onBlur={form.handleBlur}
                      />

                      {form.touched.first_installment_custom &&
                      form.errors.first_installment_custom ? (
                        <span className="text-error">
                          {form.errors.first_installment_custom}
                        </span>
                      ) : null}
                    </Col>
                    <Col span={11}>
                      <div className="mt-2 text-[#9CA3AF] font-medium">
                        Total installments for next period :
                        <br />
                        {(form?.values.custom === true &&
                          form?.values?.loan_amount &&
                          form?.values?.first_installment_custom &&
                          (valueChace === "period"
                            ? form.values.tenor
                              ? (() => {
                                  const result = calculateCurrencyValue(
                                    form.values.loan_amount,
                                    form.values.first_installment_custom,
                                    form.values.tenor
                                  );
                                  return result ? "Rp." + result : "Rp.0";
                                })()
                              : "Rp.0"
                            : valueChace === "amount"
                            ? "Rp." +
                              calculateCurrencyValueFromSessionStorage(
                                form.values.loan_amount,
                                form.values.first_installment_custom
                              )
                            : "Rp.0")) ||
                          "Rp.0"}
                      </div>
                    </Col>
                  </Row>
                </FormAntd.Item>
              </Col>
            </Row>
            <div>
              <RadioGroupWithLabel
                name="first_installment_period"
                label={
                  <div>
                    First Installment Period
                    <span style={{ color: "#FF0000" }}> *</span>
                  </div>
                }
                rows={4}
                onBlur={form.handleBlur}
                maxLength={500}
                onChange={(val) => {
                  form.setValues({
                    ...form.values,
                    first_installment_period: val.target.value,
                  });
                  handleRadioChangePeriod(val.target.value);
                }}
                value={form?.values?.first_installment_period}>
                <div>
                  <Radio value={1}>
                    1 Period after the loan disbursement date
                  </Radio>
                </div>
                <div>
                  <Radio value={0}>
                    The same period as the loan disbursement date
                  </Radio>
                </div>
              </RadioGroupWithLabel>
            </div>
            {form.touched.first_installment_period &&
            form.errors.first_installment_period ? (
              <span className="text-error">
                {form.errors.first_installment_period}
              </span>
            ) : null}

            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  {(form.values.loan_amount &&
                    form.values.loan_date &&
                    form.values.disburse_date &&
                    form.values.tenor &&
                    form.values.m_loan_id) ||
                  form.values.installment ? (
                    <div className="flex flex-col mt-5">
                      <div className="text-[#8697a8] text-base">
                        {"Installment Start Date"}
                        <span className="text-red-500 label-input">*</span>
                      </div>
                      <DatePicker
                        disabled
                        name="start_date"
                        format="DD-MM-YYYY"
                        placeholder="DD-MM-YYYY"
                        
                        allowClear={true}
                        value={
                          form.values.cal_ref === "period"
                            ? form.values.start_date ||
                              form.values.disburse_date
                              ? (sessionStorage.setItem(
                                  "start_date",
                                  dayjs(
                                    form.values.start_date ||
                                      form.values.disburse_date,
                                    "YYYY-MM-DD"
                                  )
                                    .add(
                                      form.values.first_installment_period === 1
                                        ? 1
                                        : form.values
                                            .first_installment_period === 0
                                        ? 0
                                        : 0,
                                      "month"
                                    )
                                    .endOf("month")
                                    .format("YYYY-MM-DD")
                                ),
                                dayjs(
                                  form.values.start_date ||
                                    form.values.disburse_date,
                                  "YYYY-MM-DD"
                                )
                                  .add(
                                    form.values.first_installment_period === 1
                                      ? 1
                                      : form.values.first_installment_period ===
                                        0
                                      ? 0
                                      : 0,
                                    "month"
                                  )
                                  .isValid()
                                  ? dayjs(
                                      form.values.start_date ||
                                        form.values.disburse_date,
                                      "YYYY-MM-DD"
                                    )
                                      .add(
                                        form.values.first_installment_period ===
                                          1
                                          ? 1
                                          : form.values
                                              .first_installment_period === 0
                                          ? 0
                                          : 0,
                                        "month"
                                      )
                                      .endOf("month")
                                  : null)
                              : (sessionStorage.removeItem("start_date"),
                                form.values.start_date ||
                                form.values.disburse_date
                                  ? (sessionStorage.setItem(
                                      "start_date",
                                      form.values.start_date ||
                                        form.values.disburse_date
                                    ),
                                    dayjs(
                                      form.values.start_date ||
                                        form.values.disburse_date,
                                      "YYYY-MM-DD"
                                    ))
                                  : (sessionStorage.removeItem("start_date"),
                                    null) ||
                                    form.values.start_date ||
                                    form.values.disburse_date
                                  ? (sessionStorage.setItem(
                                      "start_date",
                                      form.values.start_date ||
                                        form.values.disburse_date
                                    ),
                                    dayjs(
                                      form.values.start_date ||
                                        form.values.disburse_date,
                                      "YYYY-MM-DD"
                                    ))
                                  : (sessionStorage.removeItem("start_date"),
                                    null))
                            : form.values.start_date ||
                              form.values.disburse_date
                            ? (sessionStorage.setItem(
                                "start_date",
                                dayjs(
                                  form.values.start_date ||
                                    form.values.disburse_date,
                                  "YYYY-MM-DD"
                                )
                                  .add(
                                    form.values.first_installment_period === 1
                                      ? 1
                                      : form.values.first_installment_period ===
                                        0
                                      ? 0
                                      : 0,
                                    "month"
                                  )
                                  .endOf("month")
                                  .format("YYYY-MM-DD")
                              ),
                              dayjs(
                                form.values.start_date ||
                                  form.values.disburse_date,
                                "YYYY-MM-DD"
                              )
                                .add(
                                  form.values.first_installment_period ===
                                    "after"
                                    ? 1
                                    : form.values.first_installment_period ===
                                      "same"
                                    ? 0
                                    : 0,
                                  "month"
                                )
                                .isValid()
                                ? dayjs(
                                    form.values.start_date ||
                                      form.values.disburse_date,
                                    "YYYY-MM-DD"
                                  ).add(
                                    form.values.first_installment_period === 1
                                      ? 1
                                      : form.values.first_installment_period ===
                                        0
                                      ? 0
                                      : 0,
                                    "month"
                                  )
                                : null).endOf("month")
                            : (sessionStorage.removeItem("start_date"), null)
                        }
                        onChange={(date) => {
                          const formattedDate = date
                            ? dayjs(date).format("YYYY-MM-DD")
                            : null;
                          form.setFieldValue("start_date", formattedDate);
                        }}
                        className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                      />
                      {form.touched.disburse_date &&
                        form.errors.disburse_date && (
                          <span className="text-error">
                            {form.errors.disburse_date}
                          </span>
                        )}
                    </div>
                  ) : (
                    <div className="flex flex-col mt-5">
                      <div className="text-[#8697a8] text-base">
                        {"Installment Start Date"}
                        <span className="text-red-500 label-input">*</span>
                      </div>
                      <DatePicker
                        disabled
                        name="start_date"
                        format="DD-MM-YYYY"
                        placeholder="DD-MM-YYYY"
                        
                        allowClear={true}
                        value={form.values.start_date}
                        onChange={(date) => {
                          const formattedDate = date
                            ? dayjs(date).format("YYYY-MM-DD")
                            : null;
                          form.setFieldValue("start_date", formattedDate);
                        }}
                        className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                      />
                      {form.touched.disburse_date &&
                        form.errors.disburse_date && (
                          <span className="text-error">
                            {form.errors.disburse_date}
                          </span>
                        )}
                    </div>
                  )}
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  {(form.values.loan_amount &&
                    form.values.loan_date &&
                    form.values.disburse_date &&
                    form.values.tenor &&
                    form.values.m_loan_id) ||
                  form.values.installment ? (
                    <div className="flex flex-col mt-5">
                      <div className="text-[#8697a8] text-base">
                        {"Estimated End Date"}
                        <span className="text-red-500 text-base label-input">
                          *
                        </span>
                      </div>
                      <DatePicker
                        disabled
                        name="end_date"
                        format="DD-MM-YYYY"
                        placeholder="DD-MM-YYYY"
                        
                        allowClear={true}
                        value={
                          form.values.cal_ref === "period"
                            ? form.values.start_date ||
                              form.values.disburse_date
                              ? (sessionStorage.setItem(
                                  "end_date",
                                  dayjs(
                                    form.values.start_date ||
                                      form.values.disburse_date,
                                    "YYYY-MM-DD"
                                  )
                                    .add(form.values.tenor, "month")
                                    .add(
                                      form.values.first_installment_period === 1
                                        ? 1
                                        : form.values
                                            .first_installment_period === 0
                                        ? 0
                                        : 0,
                                      "month"
                                    )
                                    .subtract(
                                      valueChace === "period" ? 1 : 0,
                                      "month"
                                    )
                                    .endOf("month")
                                    .format("YYYY-MM-DD")
                                ),
                                dayjs(
                                  form.values.start_date ||
                                    form.values.disburse_date,
                                  "YYYY-MM-DD"
                                )
                                  .add(form.values.tenor, "month")
                                  .add(
                                    form.values.first_installment_period === 1
                                      ? 1
                                      : form.values.first_installment_period ===
                                        0
                                      ? 0
                                      : 0,
                                    "month"
                                  )
                                  .subtract(subtractRequest ? 1 : 0, "month")
                                  .endOf("month"))
                              : (sessionStorage.removeItem("end_date"), null)
                            : form.values.cal_ref === "amount"
                            ? form.values.start_date ||
                              form.values.disburse_date
                              ? (sessionStorage.setItem(
                                  "end_date",
                                  dayjs(
                                    form.values.start_date ||
                                      form.values.disburse_date,
                                    "YYYY-MM-DD"
                                  )
                                    .add(
                                      sessionStorage.getItem("tenor"),
                                      "month"
                                    )
                                    .add(
                                      form.values.first_installment_period ==
                                        "after"
                                        ? 1
                                        : form.values
                                            .first_installment_period == "same"
                                        ? 0
                                        : 0,
                                      "month"
                                    )
                                    .subtract(
                                      valueChace === "period" ? 0 : 1,
                                      "month"
                                    )
                                    .endOf("month")
                                    .format("YYYY-MM-DD")
                                ),
                                dayjs(
                                  form.values.start_date ||
                                    form.values.disburse_date,
                                  "YYYY-MM-DD"
                                )
                                  .add(sessionStorage.getItem("tenor"), "month")
                                  .add(
                                    form.values.first_installment_period ==
                                      "after"
                                      ? 1
                                      : form.values.first_installment_period ==
                                        "same"
                                      ? 0
                                      : 0,
                                    "month"
                                  )
                                  .add(
                                    Math.floor(
                                      parseFloat(
                                        form.values.loan_amount?.replace(
                                          /,/g,
                                          ""
                                        ) || 0
                                      ) /
                                        parseFloat(
                                          form.values.installment?.replace(
                                            /,/g,
                                            ""
                                          ) || 1
                                        )
                                    ),
                                    "month"
                                  )
                                  .isValid()
                                  ? dayjs(
                                      form.values.start_date ||
                                        form.values.disburse_date,
                                      "YYYY-MM-DD"
                                    )
                                      .add(
                                        form.values.first_installment_period ===
                                          1
                                          ? 1
                                          : form.values
                                              .first_installment_period === 0
                                          ? 0
                                          : 0,
                                        "month"
                                      )
                                      .add(
                                        Math.floor(
                                          parseFloat(
                                            form.values.loan_amount?.replace(
                                              /,/g,
                                              ""
                                            ) || 0
                                          ) /
                                            parseFloat(
                                              form.values.installment?.replace(
                                                /,/g,
                                                ""
                                              ) || 1
                                            )
                                        ),
                                        "month"
                                      )
                                      .subtract(
                                        sessionStorage.getItem("tenor") ? 1 : 0,
                                        "month"
                                      )
                                      .endOf("month")
                                  : form.values.start_date ||
                                    form.values.disburse_date
                                  ? (sessionStorage.setItem(
                                      "start_date",
                                      form.values.start_date ||
                                        form.values.disburse_date
                                    ),
                                    dayjs(
                                      form.values.start_date ||
                                        form.values.disburse_date,
                                      "YYYY-MM-DD"
                                    ))
                                  : (sessionStorage.removeItem("start_date"),
                                    null) ||
                                    form.values.start_date ||
                                    form.values.disburse_date
                                  ? (sessionStorage.setItem(
                                      "start_date",
                                      form.values.start_date ||
                                        form.values.disburse_date
                                    ),
                                    dayjs(
                                      form.values.start_date ||
                                        form.values.disburse_date,
                                      "YYYY-MM-DD"
                                    ))
                                  : (sessionStorage.removeItem("end_date"),
                                    null))
                              : (sessionStorage.removeItem("end_date"), null)
                            : form.values.start_date ||
                              form.values.disburse_date
                            ? (sessionStorage.setItem(
                                "end_date",
                                dayjs(
                                  form.values.start_date ||
                                    form.values.disburse_date,
                                  "YYYY-MM-DD"
                                )
                                  .add(sessionStorage.getItem("tenor"), "month")
                                  .add(
                                    form.values.first_installment_period ==
                                      "after"
                                      ? 1
                                      : form.values.first_installment_period ==
                                        "same"
                                      ? 0
                                      : 0,
                                    "month"
                                  )
                                  .endOf("month")
                                  .format("YYYY-MM-DD")
                              ),
                              dayjs(
                                form.values.start_date ||
                                  form.values.disburse_date,
                                "YYYY-MM-DD"
                              ))
                            : (sessionStorage.removeItem("end_date"), null) ||
                              form.values.start_date ||
                              form.values.disburse_date
                            ? (sessionStorage.setItem(
                                "start_date",
                                form.values.start_date ||
                                  form.values.disburse_date
                              ),
                              dayjs(
                                form.values.start_date ||
                                  form.values.disburse_date,
                                "YYYY-MM-DD"
                              ))
                            : (sessionStorage.removeItem("end_date"), null)
                        }
                        onChange={(date) => {
                          const formattedDate = date
                            ? dayjs(date).format("YYYY-MM-DD")
                            : null;
                          form.setFieldValue("end_date", formattedDate);
                        }}
                        className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                      />
                    </div>
                  ) : (
                    <div className="flex flex-col mt-5">
                      <div className="text-[#8697a8] text-base">
                        {"Estimated End Date"}
                        <span className="text-red-500 text-base label-input">
                          *
                        </span>
                      </div>
                      <DatePicker
                        disabled
                        name="end_date"
                        format="DD-MM-YYYY"
                        placeholder="DD-MM-YYYY"
                        
                        allowClear={true}
                        value={form.values.end_date}
                        onChange={(date) => {
                          const formattedDate = date
                            ? dayjs(date).format("YYYY-MM-DD")
                            : null;
                          form.setFieldValue("end_date", formattedDate);
                        }}
                        className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                      />
                    </div>
                  )}
                  {form.touched.disburse_date && form.errors.disburse_date && (
                    <span className="text-error">
                      {form.errors.disburse_date}
                    </span>
                  )}
                </FormAntd.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <FormAntd.Item>
                  <div className="">
                    <div>
                      <div className="text-[#8697a8] text-base">
                        {"Attachment"}
                        <span className="text-red-500 label-input">*</span>
                      </div>
                      <label className="file-upload">
                        <input
                          type="file"
                          name="attachment"
                          id="attachment"
                          onChange={handleFileUpload}
                          accept=".jpg, .pdf"
                          ref={inputRef}
                        />
                        <CloudUploadOutlined /> Upload File
                      </label>
                    </div>
                    <div>
                      {fileName && (
                        <div>
                          <p>
                            {fileName}{" "}
                            <Button
                              shape="circle"
                              onClick={handleFileDelete}
                              icon={<DeleteOutlined style={{ color: "red" }} />}
                              size={"small"}
                            />{" "}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                  {form.touched.attachment && form.errors.attachment && (
                    <span className="text-error">{form.errors.attachment}</span>
                  )}
                </FormAntd.Item>
              </Col>
            </Row>
            <Row>
              <Col span={11}>
                <FormAntd.Item>
                  <div>
                    <TextAreaWithLabel
                      name="description"
                      label={
                        <div className="text-[#8697a8] text-base">
                          {"Description"}
                          <span className="text-red-500">*</span>
                        </div>
                      }
                      placeholder="......."
                      maxLength={200}
                      showCount
                      onChange={form.handleChange}
                      value={form.values.description}
                    />
                  </div>
                  {form.touched.description && form.errors.description && (
                    <span className="text-error">
                      {form.errors.description}
                    </span>
                  )}
                </FormAntd.Item>
              </Col>
            </Row>
          </Col>
        </FormAntd>

        <div className="footer-add">
          <Button
            key="back"
            className="btn btn-sec"
            style={{ marginRight: "14px" }}
            onClick={() => navigate("/loan")}>
            Cancel
          </Button>

          <Button
            key="submit"
            type="primary"
            className="btn btn-primary btn-sh-p"
            loading={fetching}
            onClick={form.handleSubmit}
            disabled={form.isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormAddLoan;
