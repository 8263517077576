import {
  SET_CUSTOM_SALARIES,
  ADD_CUSTOM_SALARY,
  REMOVE_CUSTOM_SALARY,
  UPDATE_CUSTOM_SALARY,
} from "../actionTypes";

const initialState = [{ id: null, file: null }];

const customSalariesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CUSTOM_SALARIES:
      return action.payload;

    case ADD_CUSTOM_SALARY:
      if (state.length < 5) {
        return [...state, action.payload];
      }
      return state;

    case REMOVE_CUSTOM_SALARY:
      return state.filter((_, index) => index !== action.payload);

    case UPDATE_CUSTOM_SALARY:
      return state.map((salary, index) =>
        index === action.payload.index ? action.payload.newSalary : salary
      );

    default:
      return state;
  }
};

export default customSalariesReducer;
