/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  DatePicker,
  Pagination,
  Row,
  Select,
} from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { PlusOutlined } from "@ant-design/icons";
import { decryptData, encryptData } from "../../helper/cryptojs";
import dayjs from "dayjs";
import { getLeaveTransaction } from "../../api/leaveTransaction";
import { getLeaveDropdown } from "../../api/leave";
import { decode } from "html-entities";
const { RangePicker } = DatePicker;
export default function LeaveTransaction() {
  const navigate = useNavigate();
  const limitSession = sessionStorage.getItem("sessionPageLeavetransaction");
	const offsetSession = sessionStorage.getItem("sessionOffsetLeavetransaction");
  const sessionSearch = sessionStorage.getItem("sessionSearchLeavetransaction");
	const startDateSession = decryptData(sessionStorage.getItem("sessionStartDateLeaveTrs"));
	const endDateSession = decryptData(sessionStorage.getItem("sessionEndDateLeaveTrs"));
  const leaveTypeSession = decryptData(sessionStorage.getItem("sessionLeaveTypeLeaveTrs"));
  const statusSession = decryptData(sessionStorage.getItem("sessionStatusLeaveTrs"));
  const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const branch = decryptData(sessionStorage.getItem("selectBranch"));
  const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
  const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"))
  const branchCode = branchSession || branch !== null ? branch  : branchDefault     
  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(limitSession ? parseInt(limitSession) : 1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(offsetSession ? offsetSession : 0)
  const [search, setSearch] = useState(sessionSearch ?? "");
  const [leaveType, setleaveType] = useState(leaveTypeSession ? leaveTypeSession : null);
  const [status, setStatus] = useState(statusSession ? statusSession : null);
  const [startDate, setStartDate] = useState(startDateSession ? startDateSession : null)
	const [endDate, setEndDate] = useState(endDateSession ? endDateSession : null)
  const [selectedDateRange, setSelectedDateRange] = useState([]);
  const [optionsMLeave, setOptionsMLeave] = useState([]);
  const previousPage = useRef(1);
  const BestpraticeUseEffect = useRef(false);
 

  const leaveListTransaction = async () => {
    setLoading(true);
    try {
      const res = await getLeaveTransaction(
        startDate, endDate, company, branch,search, offset, limit, page,leaveType,status
      );
      setListTable(res?.data?.data?.data);
      setTotalData(res?.data?.data?.recordsTotal);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const dropdownMLeave = async () => {
    try {
      const res = await getLeaveDropdown(company);
      const data = res?.data?.data;
      setOptionsMLeave(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    dropdownMLeave();
  }, []);

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      leaveListTransaction();
      const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageLeavetransaction");
        sessionStorage.removeItem("sessionOffsetLeavetransaction");
        sessionStorage.removeItem('sessionStartDateLeaveTrs');
        sessionStorage.removeItem('sessionEndDateLeaveTrs');
        sessionStorage.removeItem('sessionLeaveTypeLeaveTrs');
        sessionStorage.removeItem('sessionStatusLeaveTrs');
        sessionStorage.removeItem('picker_selected_leave_transaction');
        sessionStorage.removeItem("sessionSearchLeavetransaction");
        setSearch("")
			  };
			  window.addEventListener("beforeunload", beforeUnloadHandler);
			  return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			  };
    }
  }, [startDate, endDate, company, branch,search, offset, limit, page,leaveType,status]);

  useEffect(() => {
		let offset = 0
		if(page > 1){
			offset = page*limit - 10
		}
		
		setOffset(offset)
		sessionStorage.setItem("sessionPageLeavetransaction", page);
		sessionStorage.setItem("sessionOffsetLeavetransaction", offset);
	}, [page]);

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text,record, index) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/leave-transaction/detail/${encodeURIComponent(
                  "id?" + record.leave_id
                )}`
              )
            }>
           {text}
          </div>
        );
      },
    },
    {
      title: "Branch",
      dataIndex: "branch_name",
      key: "branch_name",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/leave-transaction/detail/${encodeURIComponent(
                  "id?" + record.leave_id
                )}`
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "NIK",
      dataIndex: "employee_number",
      key: "employee_number",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/leave-transaction/detail/${encodeURIComponent(
                  "id?" + record.leave_id
                )}`
              )
            }>
            {text}
          </div>
        );
      },
    },
    {
      title: "Employee Name",
      dataIndex: "full_name",
      key: "full_name",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/leave-transaction/detail/${encodeURIComponent(
                  "id?" + record.leave_id
                )}`
              )
            }>
            {decode(text)}
          </div>
        );
      },
    },

    {
      title: "Request Date",
      dataIndex: "requested_date",
      key: "requested_date",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/leave-transaction/detail/${encodeURIComponent(
                  "id?" + record.leave_id
                )}`
              )
            }>
           {text || '-'}
          </div>
        );
      },
    },
    {
      title: "Start Date",
      dataIndex: "date_from",
      key: "date_from",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/leave-transaction/detail/${encodeURIComponent(
                  "id?" + record.leave_id
                )}`
              )
            }>
         {text || '-'}
          </div>
        );
      },
    },
    {
      title: "End Date",
      dataIndex: "date_to",
      key: "date_to",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/leave-transaction/detail/${encodeURIComponent(
                  "id?" + record.leave_id
                )}`
              )
            }>
            {text || '-'}
          </div>
        );
      },
    },
    {
      title: "Leave Type",
      dataIndex: "leave_type",
      key: "leave_type",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/leave-transaction/detail/${encodeURIComponent(
                  "id?" + record.leave_id
                )}`
              )
            }>
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: "Allowance",
      dataIndex: "allowance",
      key: "allowance",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/leave-transaction/detail/${encodeURIComponent(
                  "id?" + record.leave_id
                )}`
              )
            }>
            {text ? "Yes" : "No" || "-"}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={() =>
              navigate(
                `/leave-transaction/detail/${encodeURIComponent(
                  "id?" + record.leave_id
                )}`
              )
            }>
            {text === "WAITING" ? "PENDING" : text}
          </div>
        );
      },
    },

   
  ];
  const dataBreadcrumb = [
    {
      title: "Attendance Management",
      url: "/leave-transaction",
    },
    {
      title: "Leave Transaction",
      url: "/leave-transaction",
    },
  ];

  useEffect(() => {
    previousPage.current = page;
  }, [page]);

  const onChangeTable = (pageNumber, type) => {
    const offsetSession = pageNumber*limit- 10
		setPage(pageNumber);
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPageLeavetransaction", pageNumber);
		sessionStorage.setItem("sessionOffsetLeavetransaction", offsetSession);
    //
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };
  function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

  const onChangeSearch = (e) => {
    setSearch(e.target.value);
    setLimit(10);
    setPage(1);
    sessionStorage.setItem("sessionSearchLeavetransaction", e.target.value);
  };
  useEffect(() => {
    const startDate = decryptData(sessionStorage.getItem("sessionStartDateLeaveTrs"));
    const endDate = decryptData(sessionStorage.getItem("sessionEndDateLeaveTrs"));
    if (startDate && endDate) {
      setStartDate(startDate);
      setEndDate(endDate);
      setSelectedDateRange([dayjs(startDate), dayjs(endDate)]);
    }
  }, []);

  const handleDateChange = (dates) => {
    if (dates && dates.length === 2) {
      const start = dates[0].startOf("day").format("YYYY-MM-DD");
      const end = dates[1].endOf("day").format("YYYY-MM-DD");
      sessionStorage.setItem("sessionStartDateLeaveTrs", encryptData(start));
      sessionStorage.setItem("sessionEndDateLeaveTrs", encryptData(end));
      sessionStorage.setItem("picker_selected_leave_transaction", "true");
      setStartDate(start);
      setEndDate(end);
      setPage(1)
    } else {
      sessionStorage.removeItem("sessionStartDateLeaveTrs");
      sessionStorage.removeItem("sessionEndDateLeaveTrs");
      sessionStorage.removeItem("picker_selected_leave_transaction");
      setStartDate(null);
      setEndDate(null);
      setPage(1);
    }
    setSelectedDateRange(dates);
  };

  const handleDownload = () => {
		let startdate = moment(startDate,"YYYY-MM-DD").format("YYYY-MM-DD") 
		let enddate = moment(endDate, "YYYY-MM-DD").format("YYYY-MM-DD")
		if (startdate === "Invalid date" || enddate === "Invalid date") {
			startdate = ""; // Nilai default
			enddate = ""; // Nilai default
		  }
     fetch(`${process.env.REACT_APP_HRIS_URL}leave-request/export-to-excel?startDate=${startdate}&endDate=${enddate}&search=${search}&leaveType=${leaveType ? encodeURIComponent(leaveType) : ""}&status=${status ? encodeURIComponent(status) : ""}&companyCode=${encodeURIComponent(company)}&branchCode=${encodeURIComponent(branchCode)}`, {
       method: 'GET',
       headers: {
         'Content-Type': 'application/json',
       }
     })
       .then(response => response.blob())
       .then(blob => {
         const url = window.URL.createObjectURL(blob);
         const link = document.createElement('a');
         link.href = url;
         link.download = 'leave-requests.xlsx';
         link.click();
         window.URL.revokeObjectURL(url);
       })
       .catch(error => {
         console.error('Error downloading file:', error);
       });
	  };


  const RoleAccessLeaveTransactionCreate = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeLeaveTransactionCreate")
  );
  const RoleAccessLeaveTransactionDelete = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeLeaveTransactionDownload")
  );
  return (
    <>
      <div className="title-section">Leave Transaction</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
        {" "}
        <Row
          style={{ marginBottom: "24px", justifyContent: "end" }}
          align={"middle"}>
          <Col md={{ top: 20 }} className="mt-5 md:mt-0">
            <Row justify={"end"} style={{ gap: 15 }} md={14}>
              <RangePicker
                size={"large"}
                onChange={handleDateChange}
                value={selectedDateRange}
                allowClear={true}
                format="YYYY-MM-DD"
              />
           <Select
             allowClear
             placeholder="Leave Type"
             className="select-company w-14 md:w-32 text-xs"
             onChange={(value) => {
              if(value) {
                sessionStorage.setItem("sessionLeaveTypeLeaveTrs", encryptData(value));
              } else {
                sessionStorage.removeItem("sessionLeaveTypeLeaveTrs");
              }
              setleaveType(value);
              setPage(1)
              setOffset(0)
             }}
             value={leaveType ? leaveType : null}
           >
             {optionsMLeave.map((option) => (
               <Select.Option key={option.m_leave_id} value={option.m_leave_id}>
                 {option.leave_name}
               </Select.Option>
             ))}
           </Select>

             <Select
               allowClear
               placeholder="By Status"
               className="select-company w-14 md:w-32 text-xs"
               onChange={(value) => {
                if(value) {
                  sessionStorage.setItem("sessionStatusLeaveTrs", encryptData(value));
                } else {
                  sessionStorage.removeItem("sessionStatusLeaveTrs");
                }
                setStatus(value);
                setPage(1)
                setOffset(0)
               }}
               value={status ? status : null}
             >
               <Select.Option value="WAITING">PENDING</Select.Option>
               <Select.Option value="APPROVED">APPROVED</Select.Option>
               <Select.Option value="REJECTED">REJECTED</Select.Option>
             </Select>
              <InputWithIcon
                type="text"
                placeholder="Search Leave Transaction"
                img={iconSearch}
                className="search-input btn-sh-sec"
                classImg="op-icon"
                onChange={onChangeSearch}
                value={search}
              />
              {RoleAccessLeaveTransactionDelete && (
                <Button
                  size={"large"}
                  type="primary"
                    onClick={handleDownload}
                >
                  Download
                </Button>
              )}

              {RoleAccessLeaveTransactionCreate && (
                <Button
                  className="btn-sh-p"
                  type="primary"
                  shape="circle"
                  onClick={() =>
                    navigate("/leave-transaction/add-leave-transaction")
                  }
                  icon={<PlusOutlined />}
                  size={"large"}
                />
              )}
            </Row>
          </Col>
        </Row>
        <div className="table-wrapper">
          {loading ? (
            <>
              <Table id={"my-table"} columns={columns} loading={loading} />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          ) : (
            <>
              <Table
                id={"my-table"}
                dataSource={dataTableWithNumber()}
                columns={columns}
                loading={loading}
              />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

