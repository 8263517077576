import { Col, Row } from "antd";
import { useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import Navbar from "../component/Navbar";
import Sidebar from "../component/Sidebar";
import Attendance from "./Attendance";
import AttendanceSummary from "./Attendance/Summary";
import AttendanceDetail from "./Attendance/Detail";
import Dashboard from "./Dashboard";
import Employee from "./Employee";
import DetailEmployee from "./Employee/Detail";
import Role from "./Role";
import FormAddRole from "./Role/FormAdd";
import User from "./User";
import FormAddUser from "./User/FormAdd";
import Department from "./Department";
import FormAddDepartment from "./Department/FormAdd";
import DepartmentDetail from "./Department/Detail";
import Division from "./Division";
import FormAddDivision from "./Division/FormAdd";
import DivisionDetail from "./Division/Detail";
import Postion from "./Position";
import FormAddPosition from "./Position/FormAdd";
import PositionDetail from "./Position/Detail";
import Branch from "./Branch";
import FormAddEmployee from "./Employee/FormAdd";
import RoleDetail from "./Role/Detail";
import Company from "./Company";
import Level from "./Level";
import FormAddBranch from "./Branch/FormAdd";
import BranchDetail from "./Branch/Detail";
import FormAddLevel from "./Level/FormAdd";
import LevelDetail from "./Level/Detail";
import Grade from "./Grade";
import FormAddGrade from "./Grade/FormAdd";
import GradeDetail from "./Grade/Detail";
import UserDetail from "./User/Detail";
import FormAddCompany from "./Company/FormAdd";
import AbsenceSetting from "./Company/AbsenceSetting";
import CompanyDetail from "./Company/Detail";
import OrganizationStructure from "./Position/Detail/organization-v2";
import FormAddAttendance from "./Attendance/FormAdd";
import NotFound from "./404";
import Overtime from "./Overtime";
import FormAddOvertime from "./Overtime/FormAdd";
import OvertimeDetail from "./Overtime/Detail";
import Leave from "./Leave";
import FormAddLeave from "./Leave/FormAdd";
import LeaveDetail from "./Leave/Detail";
import Holiday from "./Holiday";
import FormAddHoliday from "./Holiday/FormAdd";
import HolidayDetail from "./Holiday/Detail";
import ApprovalSetting from "./ApprovalSetting";
import FormAddApprovalSetting from "./ApprovalSetting/FormAdd";
import ApprovalSettingDetail from "./ApprovalSetting/Detail";
import OvertimeTransaction from "./OvertimeTransaction";
import FormAddOvertimeTransaction from "./OvertimeTransaction/FormAdd";
import OvertimeTransactionDetail from "./OvertimeTransaction/Detail";
import FormImportOvertimTransaction from "./OvertimeTransaction/FormImport";
import LeaveTransaction from "./LeaveTransaction";
import FormAddLeaveTransaction from "./LeaveTransaction/FormAdd";
import LeaveTransactionDetail from "./LeaveTransaction/Detail";
import LogUser from "./LogUser";
import Announcement from "./Announcement";
import FormAddAnnouncement from "./Announcement/FormAdd";
import AnnouncementDetail from "./Announcement/Detail";
import ApprovalList from "./ApprovalList";
import ApprovalListDetail from "./ApprovalList/Detail";
import { decryptData } from "../helper/cryptojs";
import FormAddSetSceduleShift from "./SetSceduleShift/Form";
import SetSchedule from "./SetSceduleShift";
import FormAddShiftingShift from "./ShiftingShift/Form";
import ShiftingShift from "./ShiftingShift";
import ShiftingShiftDetail from "./ShiftingShift/Detail";
import SetSchduleShiftDetail from "./SetSceduleShift/Detail";
import DefaultApprovalSetting from "./ApprovalSetting/DefaultSetting";
import FormImportEmployee from "./Employee/FormImport";
import Area from "./Area";
import FormAddArea from "./Area/FormAdd";
import AreaDetail from "./Area/Detail";
import TimeRequest from "./TimeRequest";
import FormAddTimeRequest from "./TimeRequest/FormAdd";
import TimeRequestDetail from "./TimeRequest/Detail";
import GeneratePayslipIndex from "./GeneratePayslip";
import FormAddGeneratePayslip from "./GeneratePayslip/FormAdd";
import EmployeeManagement from "./EmployeeManagement";
import EmployeeManagementDetail from "./EmployeeManagement/Detail";
import AddChangeShiftRequest from "./TimeRequest/ChangeShiftRequest/AddChangeShiftRequest";
import LoanTransaction from "./Loan";
import LoanSetting from "./LoanSetting";
import FormAddLoanSetting from "./LoanSetting/FormAdd";
import LoanSettingDetail from "./LoanSetting/Detail";
import FormAddLoan from "./Loan/FormAdd";
import LoanDetail from "./Loan/Detail";
import LoanDetailEmployee from "./EmployeeManagement/Detail/Detail";
import AssessmentDetailEmployee from "./EmployeeManagement/Detail/Detail/Assessment";
import Welcome from "./Welcome";
import BpjsKesehatan from "./BpjsKesehatan";
import FormAddBpjsMaster from "./BpjsKesehatan/FormAdd";
import BpjsMasterDetail from "./BpjsKesehatan/Detail";
import BpjsKetenagakerjaan from "./BpjsKetenagakerjaan";
import BpjsMasterKetenagakerjaanDetail from "./BpjsKetenagakerjaan/Detail";
import FormAddBpjsKetenagakerjaanMaster from "./BpjsKetenagakerjaan/FormAdd";
import Insurance from "./Insurance";
import InsuranceDetail from "./Insurance/Detail";
import FormAddInsurance from "./Insurance/FormAdd";
import ReportInsurance from "./ReportInsurance";
import ReportBpjs from "./ReportBpjs";
import DefaultLayout from "../component/Organisms/Layouts/DefaultLayout";
import EmployeeTransfer from "./EmployeeTransfer";
import FormCreateEmployeeTransfer from "./EmployeeTransfer/FormAdd";
import EmployeeTransferDetail from "./EmployeeTransfer/Detail";
import FormImportEmployeeTransfer from "./EmployeeTransfer/FormImport";
import PtkpStatus from "./PtkpStatus";
import FormAddPtkpStatus from "./PtkpStatus/FormAdd";
import PtkpStatusDetail from "./PtkpStatus/Detail";
import Pph21Rate from "./Pph21_Rate";
import FormAddPph21Rate from "./Pph21_Rate/FormAdd";
import Pph21RateDetail from "./Pph21_Rate/Detail";
import TaxMethod from "./TaxMethod";
import FormAddTaxMethod from "./TaxMethod/FormAdd";
import TaxMethodDetail from "./TaxMethod/Detail";
import HistoryFakeGpsReport from "./HistoryFakeGpsReport";
import ImportEmployee from "./Employee/Import";
import ReportTER from "./ReportTER";
import ReportProgressive from "./ReportProgressive";
// import ImportEmployee from "./Employee/Import";
// import HistoryFakeGpsReport from "./HistoryFakeGpsReport";
import PayrollReport from "./ReportPayroll";
import PayrollReportDetail from "./ReportPayroll/Detail";
import PayrollComponent from "./PayrollComponent";
import PayrollComponentDetail from "./PayrollComponent/Detail";
import WagesSetting from "./PayrollComponent/WagesSetting";
import FormAddMasterComponent from "./PayrollComponent/MasterComponent";
import RunPayroll from "./RunPayroll";
import ApprovalSequence from "./PerformanceManagement/ApproverSequence";
import PersonalAssessment from "./PersonalAssessment";
import FormAddPersonalAssessmentMaster from "./PersonalAssessment/FormAdd";
import PersonalAssessmentDetail from "./PersonalAssessment/Detail";
import PersonalAssessmentReport from "./PersonalAssessmentReport";
import PersonalAssessmentDetailReport from "./PersonalAssessmentReport/Detail";


function Router() {
  const user = decryptData(localStorage.getItem("Username"));
  const role = decryptData(localStorage.getItem("Role"));
  // const authRoleBased = JSON.parse(localStorage.getItem('SubMenuDashbord'));  
  const mastermenu = role
  const Master = mastermenu !== null ? mastermenu : '';
  // const rolevalidation = mastermenu != null || mastermenu != '' ? true : false
  const [showMobile, setShowMobile] = useState(false)
  const [collapsed, setCollapsed] = useState(false);
  const location = window.location.href;
  var n = location.split("/");
  var result = n[3];
  //var navbar = <Navbar collapse={handleCollapsed} collapsed={collapsed} onShowMobile={handleShowMobile} showMobile={showMobile}/>;
  const handleCollapsed = () => {
    if (collapsed) {
      setCollapsed(false)
    } else {
      setCollapsed(true)
    }
  }
  const handleShowMobile = () => {
    if (showMobile) {
      setShowMobile(false)
    } else {
      setShowMobile(true)
    }
  }
  const navigate = useNavigate();
  const backLogin = () => {
    navigate('/')
  }
  useEffect(() => {
    if (user === null && result !== "reset-password" && result !== "privacy-policy" && result !== "health" && result !== "maintenance") {
      backLogin()
    }
  }, [user]);

  // const contoh decrypt = decryptData(JSON.parse(localStorage.getItem('contoh decrypt')));
  //previlage dashboard
  const RoleAccessDashbord = localStorage.getItem('RoleaccessPriviledgeDashbord');
  //previlage Attandance
  const RoleAccessAttandanceIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeAttandanceIndex'));
  const RoleAccessAttandanceCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeAttandanceCreate'));
  const RoleAccessAttandanceUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeAttandanceUpdate'));
  //previlage Branch
  const RoleAccessBranchIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeBranchIndex'));
  const RoleAccessBranchCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeBranchCreate'));
  const RoleAccessBranchUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeBranchUpdate'));
  //previlage Company
  const RoleAccessCompanyIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeCompanyIndex'));
  const RoleAccessCompanyCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeCompanyCreate'));
  const RoleAccessCompanyUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeCompanyUpdate'));
  //previlage Departmen
  const RoleAccessDepartmentIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeDepartmentIndex'));
  const RoleAccessDepartmentCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeDepartmentCreate'));
  const RoleAccessDepartmentUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeDepartmentUpdate'));
  //previlage Division
  const RoleAccessDivisionIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeDivisionIndex'));
  const RoleAccessDivisionCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeDivisionCreate'));
  const RoleAccessDivisionUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeDivisionUpdate'));
  //previlage Employee
  const RoleAccessEmployeeIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeEmployeeIndex'));
  const RoleAccessEmployeeCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeEmployeeCreate'));
  const RoleAccessEmployeeUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeEmployeeUpdate'));
  //previlage Employee Management
  const RoleAccessEmployeeManagementIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeEmployeeManagementIndex'));
  //previlage Level
  const RoleAccessLevelIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLevelIndex'));
  const RoleAccessLevelCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLevelCreate'));
  const RoleAccessLevelUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLevelUpdate'));
  //previlage Grade
  const RoleAccessGradeIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeGradeIndex'));
  const RoleAccessGradeCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeGradeCreate'));
  const RoleAccessGradeUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeGradeUpdate'));
   //previlage Position
  const RoleAccessPositionIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgePositionIndex'));
  const RoleAccessPositionCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgePositionCreate'));
  const RoleAccessPositionUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgePositionUpdate'));
  const RoleAccessPositionViewChart = JSON.parse(localStorage.getItem('RoleaccessPriviledgePositionViewChart'));
  //previlage Role
  const RoleAccessRoleIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeRoleIndex'));
  const RoleAccessRoleCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeRoleCreate'));
  const RoleAccessRoleUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeRoleUpdate'));
  //previlage User
  const RoleAccessUserIndex = localStorage.getItem('RoleaccessPriviledgeUserIndex');
  const RoleAccessUserCreate = localStorage.getItem('RoleaccessPriviledgeRoleCreate');
  const RoleAccessUserUpdate = localStorage.getItem('RoleaccessPriviledgeRoleUpdate');
  //previlage Announcement
  const RoleAccessnnouncementIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeAnnouncementIndex'));
  const RoleAccessnnouncementCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeAnnouncementCreate'));
  const RoleAccessnnouncementUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeAnnouncementUpdate'));
  //previlage Shift
  const RoleAccessShiftIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeShiftIndex'));
  const RoleAccessShiftCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeShiftCreate'));
  const RoleAccessShiftUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeShiftUpdate'));
  //previlage Set Schedule
  const RoleAccessSetScheduleIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeSetScheduleIndex'));
  const RoleAccessSetScheduleCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeSetScheduleCreate'));
  const RoleAccessSetScheduleUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeSetScheduleUpdate'));
  //previlage Overtime
  const RoleAccessOvertimeIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeOvertimeIndex'));
  const RoleAccessOvertimeCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeOvertimeCreate'));
  const RoleAccessOvertimeUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeOvertimeUpdate'));
  //previlage Overtime Transaction
  const RoleAccessOvertimeTransactionIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeOvertimeTransactionIndex'));
  const RoleAccessOvertimeTransactionCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeOvertimeTransactionCreate'));
  //previlage Leave
  const RoleAccessLeaveIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLeaveIndex'));
  const RoleAccessLeaveCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLeaveCreate'));
  const RoleAccessLeaveUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLeaveUpdate'));
  //previlage Leave Transaction
  const RoleAccessLeaveTransactionIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLeaveTransactionIndex'));
  const RoleAccessLeaveTransactionCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLeaveTransactionCreate'));
  const RoleAccessLeaveTransactionUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLeaveTransactionUpdate'));
  //previlage Loan Setting
  const RoleAccessLoanSettingIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLoanSettingIndex'));
  const RoleAccessLoanSettingCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLoanSettingCreate'));
  const RoleAccessLoanSettingUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLoanSettingUpdate'));
  //previlage Loan Management
  const RoleAccessLoanIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLoanIndex'));
  const RoleAccessLoanCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeLoanCreate'));
  //previlage Holiday
  const RoleAccessHolidayIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeHolidayIndex'));
  const RoleAccessHolidayCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeHolidayCreate'));
  const RoleAccessHolidayUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeHolidayUpdate'));
  //previlage Approval Setting
  const RoleAccessApprovalSettingIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeApprovalSettingIndex'));
  const RoleAccessApprovalSettingCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeApprovalSettingCreate'));
  const RoleAccessApprovalSettingUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeApprovalSettingUpdate'));
  //previlage Approval List
  const RoleAccessApprovalListIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeApprovalListIndex'));
  //previlage Time  Request
  const RoleAccessTimeRequestIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeTimeRequestIndex'));
  const RoleAccessTimeRequestCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeTimeRequestCreate'));
  //previlage Bpjs Kesehatan
  const RoleAccessBpjsKesehatanIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeBpjsKesehatanIndex'));
  const RoleAccessBpjsKesehatanCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeBpjsKesehatanCreate'));
  const RoleAccessBpjsKesehatanUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeBpjsKesehatanUpdate'));
  //previlage Bpjs Ketenagakerjaan
  const RoleAccessBpjsKetenagakerjaanIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeBpjsKetenagakerjaanIndex'));
  const RoleAccessBpjsKetenagakerjaanCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeBpjsKetenagakerjaanCreate'));
  const RoleAccessBpjsKetenagakerjaanUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeBpjsKetenagakerjaanUpdate'));
  //previlage Bpjs Report
  const RoleAccessBpjsReportIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeBpjsReportIndex'));
  //previlage Insurance Report
  const RoleAccessInsuranceReportIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeInsuranceReportIndex'));
  //previlage Insurance
  const RoleAccessInsuranceIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeInsuranceIndex'));
  const RoleAccessInsuranceCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeInsuranceCreate'));
  const RoleAccessInsuranceUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeInsuranceUpdate'));
  //previlage Bpjs Kesehatan
  const RoleAccessPtkpStatusIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgePtkpStatusIndex'));
  const RoleAccessPtkpStatusCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgePtkpStatusCreate'));
  const RoleAccessPtkpStatusUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgePtkpStatusUpdate'));
  //previlage pph21 rate
  const RoleAccessPph21RateIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgePph21RateIndex'));
  const RoleAccessPph21RateCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgePph21RateCreate'));
  const RoleAccessPph21RateUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgePph21RateUpdate'));
  //previlage Tax Method
  const RoleAccessTaxMethodIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeTaxMethodIndex'));
  const RoleAccessTaxMethodCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeTaxMethodCreate'));
  const RoleAccessTaxMethodUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeTaxMethodUpdate'));
  //previlage Tax Method
  const RoleAccessReportTerIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeReportTerIndex'));
   //previlage Payroll Component - Master
   const RoleAccessPayrollComponentIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgePayrollComponentIndex'));
   const RoleAccessPayrollComponentMasterCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgePayrollComponentMasterCreate'));
   const RoleAccessPayrollComponentMasterUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgePayrollComponentMasterUpdate'));
   //previlage Report Payroll
  const RoleAccessReportPayrollIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeReportPayrollIndex'));
  //previlage Report Progressive
  const RoleAccessReportProgressiveIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeReportProgressiveIndex'));
  //previlage Report Progressive
  const RoleAccessHistoryFakeGpsReportIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeReportHistoryFakeGpsIndex'));
  //previlage Run Payroll
  const RoleAccessRunPayrollIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeRunPayrollIndex'));
  //previlage Personal Assessment
  const RoleAccessPersonalAssessmentIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgePersonalAssessmentIndex'));
  const RoleAccessPersonalAssessmentCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgePersonalAssessmentCreate'));
  //previlage Personal Assessment Report
  const RoleAccessPersonalAssessmentReportIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgePersonalAssessmentReportIndex'));
  //previlage Personal Assessment Approval Sequence
  const RoleAccessApprovalSequenceIndex = JSON.parse(localStorage.getItem('RoleaccessPriviledgeApprovalSequenceIndex'));
  

  return (
    <Row className="row-page">
      {/* <Col span={collapsed? 1 : 4} className={`mobile-col-sidebar ${showMobile ? 'active' : ''}`}>
        { user === null || result === "reset-password" || result === "privacy-policy" || result === "health" ? null : (
          <Sidebar collapsed={collapsed} showMobile={showMobile}/> 
          )}    
        </Col>
        <Col span={collapsed ? 23 : 20} className="mobile-col-main">
          { user === null || result === "reset-password" || result === "privacy-policy" || result === "health" ? null : (
             <Navbar collapse={handleCollapsed} collapsed={collapsed} onShowMobile={handleShowMobile} showMobile={showMobile} /> 
          )}           */}
      {user === null || result === "reset-password" || result === "privacy-policy" || result === "health" ? null : (
        <DefaultLayout content={(
          <div className="p-2 md:p-10">
            <Routes>
              <Route path="/welcome" element={Master && ( <Welcome />)} />
              <Route path="/dashboard" element={Master && ( RoleAccessDashbord  &&  <Dashboard />)} />
              <Route path="/employee" element={Master && ( RoleAccessEmployeeIndex  &&  <Employee />)} /> 
              <Route path="/employee/add-employee" element={Master && (RoleAccessEmployeeCreate  && <FormAddEmployee  /> )} />
              <Route path="/employee/edit-employee/:id" element={Master && ( RoleAccessEmployeeUpdate && <FormAddEmployee />)} />
              <Route path="/employee/detail/:id" element={Master && (RoleAccessEmployeeIndex  &&  <DetailEmployee />)} />
              <Route path="/employee/import-employee" element={Master && (RoleAccessEmployeeCreate && <ImportEmployee />)} />
              <Route path="/employee/add-import-employee" element={Master && ( RoleAccessEmployeeCreate && <FormImportEmployee />  )} />
              <Route path="/attendance" element={Master && (RoleAccessAttandanceIndex && <Attendance />)} />
              <Route path="/attendance/summary" element={Master && (RoleAccessAttandanceIndex && <AttendanceSummary />)} />
              <Route path="/attendance/add-attendance" element={Master && (RoleAccessAttandanceCreate && <FormAddAttendance />)} />
              <Route path="/attendance/edit-attendance/:id" element={Master && (RoleAccessAttandanceUpdate && <FormAddAttendance />)} />
              <Route path="/attendance/detail/:id" element={Master && (RoleAccessAttandanceIndex && <AttendanceDetail />)} />
              <Route path="/user" element={Master && (RoleAccessUserIndex && <User />)} />
              <Route path="/user/add-user" element={Master && (RoleAccessUserCreate && <FormAddUser />)} />
              <Route path="/user/edit-user/:id" element={Master && (RoleAccessUserUpdate && <FormAddUser />)} />
              <Route path="/user/detail/:id" element={Master && (RoleAccessUserIndex && <UserDetail />)} />
              <Route path="/role" element={Master && (RoleAccessRoleIndex && <Role />)} />
              <Route path="/role/detail/:id" element={Master && (RoleAccessRoleIndex && <RoleDetail />)} />
              <Route path="/role/edit-role/:id" element={Master && (RoleAccessRoleUpdate && <FormAddRole />)} />
              <Route path="/role/add-role" element={Master && (RoleAccessRoleCreate && <FormAddRole />)} />
              <Route path="/department" element={Master && (RoleAccessDepartmentIndex && <Department />)} />
              <Route path="/department/add-department" element={Master && (RoleAccessDepartmentCreate && <FormAddDepartment />)} />
              <Route path="/department/detail/:id" element={Master && (RoleAccessDepartmentIndex && <DepartmentDetail />)} />
              <Route path="/department/edit-department/:id" element={Master && (RoleAccessDepartmentUpdate && <FormAddDepartment />)} />
              <Route path="/division" element={Master && (RoleAccessDivisionIndex && <Division />)} />
              <Route path="/division/add-division" element={Master && (RoleAccessDivisionCreate && <FormAddDivision />)} />
              <Route path="/division/detail/:id" element={Master && (RoleAccessDivisionIndex && <DivisionDetail />)} />
              <Route path="/division/edit-division/:id" element={Master && (RoleAccessDivisionUpdate && <FormAddDivision />)} />
              <Route path="/position" element={Master && (RoleAccessPositionIndex && <Postion />)} />
              <Route path="/position/add-position" element={Master && (RoleAccessPositionCreate && <FormAddPosition />)} />
              <Route path="/position/detail/:id" element={Master && (RoleAccessPositionIndex && <PositionDetail />)} />
              <Route path="/position/edit-position/:id" element={Master && (RoleAccessPositionUpdate && <FormAddPosition />)} />
              <Route path="/position/organization/:id" element={Master && (RoleAccessPositionViewChart && <OrganizationStructure />)} />
              <Route path="/branch" element={Master && (RoleAccessBranchIndex && <Branch />)} />
              <Route path="/branch/add-branch" element={Master && (RoleAccessBranchCreate && <FormAddBranch />)} />
              <Route path="/branch/detail/:id" element={Master && (RoleAccessBranchIndex && <BranchDetail />)} />
              <Route path="/branch/edit-branch/:id" element={Master && (RoleAccessBranchUpdate && <FormAddBranch />)} />
              <Route path="/area" element={Master && (<Area />)} />
              <Route path="/area/add-area" element={Master && (<FormAddArea />)} />
              <Route path="/area/detail/:id" element={Master && (<AreaDetail />)} />
              <Route path="/area/edit-area/:id" element={Master && (<FormAddArea />)} />
              <Route path="/company" element={Master && (RoleAccessCompanyIndex && <Company />)} />
              <Route path="/company/add-company" element={Master && (RoleAccessCompanyCreate && <FormAddCompany />)} />
              <Route path="/company/edit-company/:id" element={Master && (RoleAccessCompanyUpdate && <FormAddCompany />)} />
              <Route path="/company/absence-setting/:id" element={Master && (RoleAccessCompanyUpdate && <AbsenceSetting />)} />
              <Route path="/company/detail/:id" element={Master && (RoleAccessCompanyIndex && <CompanyDetail />)} />
              <Route path="/level" element={Master && (RoleAccessLevelIndex && <Level />)} />
              <Route path="/level/add-level" element={Master && (RoleAccessLevelCreate && <FormAddLevel />)} />
              <Route path="/level/edit-level/:id" element={Master && (RoleAccessLevelUpdate && <FormAddLevel />)} />
              <Route path="/level/detail/:id" element={Master && (RoleAccessLevelIndex && <LevelDetail />)} />
              <Route path="/grade" element={Master && (RoleAccessGradeIndex && <Grade />)} />
              <Route path="/grade/add-grade" element={Master && (RoleAccessGradeCreate  &&<FormAddGrade />  )} />
              <Route path="/grade/edit-grade/:id" element={Master && (RoleAccessGradeUpdate  &&  <FormAddGrade />)} />
              <Route path="/grade/detail/:id" element={Master && (RoleAccessGradeIndex && <GradeDetail />)} />
              <Route path="/overtime" element={Master && (RoleAccessOvertimeIndex &&<Overtime />)} />
              <Route path="/overtime/add-overtime" element={Master && (RoleAccessOvertimeCreate && <FormAddOvertime />  )} />
              <Route path="/overtime/edit-overtime/:id" element={Master && (RoleAccessOvertimeUpdate &&  <FormAddOvertime />)} />
              <Route path="/overtime/detail/:id" element={Master && (RoleAccessOvertimeIndex && <OvertimeDetail />)} />
              <Route path="/leave" element={Master && (RoleAccessLeaveIndex && <Leave />)} />
              <Route path="/leave/add-leave" element={Master && (RoleAccessLeaveCreate && <FormAddLeave />)} />
              <Route path="/leave/edit-leave/:id" element={Master && (RoleAccessLeaveUpdate && <FormAddLeave />)} />
              <Route path="/leave/detail/:id" element={Master && (RoleAccessLeaveIndex && <LeaveDetail />)} />
              <Route path="/holiday" element={Master && (RoleAccessHolidayIndex && <Holiday />)} />
              <Route path="/holiday/add-holiday" element={Master && (RoleAccessHolidayCreate && <FormAddHoliday />)} />
              <Route path="/holiday/edit-holiday/:id" element={Master && (RoleAccessHolidayUpdate && <FormAddHoliday />)} />
              <Route path="/holiday/detail/:id" element={Master && (RoleAccessHolidayIndex && <HolidayDetail />)} />
              <Route path="/approval-setting" element={Master && (RoleAccessApprovalSettingIndex && <ApprovalSetting />)} />
              <Route path="/approval-setting/add-approval-setting" element={Master && (RoleAccessApprovalSettingCreate && <FormAddApprovalSetting />)} />
              <Route path="/approval-setting/edit-approval-setting/:id" element={Master && (RoleAccessApprovalSettingUpdate && <FormAddApprovalSetting />)} />
              <Route path="/approval-setting/detail/:id" element={Master && (RoleAccessApprovalSettingIndex && <ApprovalSettingDetail />)} />
              <Route path="/approval-setting/default-setting" element={Master && (RoleAccessApprovalSettingCreate && <DefaultApprovalSetting />)} />
              <Route path="/shifting/set-schedule" element={Master && (RoleAccessSetScheduleIndex && <SetSchedule />)} />
              <Route path="/shifting/set-schedule/add-set-schedule" element={Master && (RoleAccessSetScheduleCreate && <FormAddSetSceduleShift />)} />
              <Route path="/shifting/set-schedule/edit-set-schedule/:id" element={Master && (RoleAccessSetScheduleUpdate && <FormAddSetSceduleShift />)} />
              <Route path="/shifting/set-schedule/detail-set-schedule/:id" element={Master && (RoleAccessSetScheduleIndex && <SetSchduleShiftDetail />)} />
              <Route path="/shifting/shift" element={Master && (RoleAccessShiftIndex && <ShiftingShift />)} />
              <Route path="/shifting/shift/add-shift" element={Master && (RoleAccessShiftCreate && <FormAddShiftingShift />)} />
              <Route path="/shifting/shift/edit-shift/:id" element={Master && (RoleAccessShiftUpdate && <FormAddShiftingShift />)} />
              <Route path="/shifting/shift/detail-shift/:id" element={Master && (RoleAccessShiftIndex && <ShiftingShiftDetail />)} />
              <Route path="/overtime-transaction" element={Master && (RoleAccessOvertimeTransactionIndex && <OvertimeTransaction />)} />
              <Route path="/overtime-transaction/add-overtime-transaction" element={Master && (RoleAccessOvertimeTransactionCreate && <FormAddOvertimeTransaction />)} />
              <Route path="/overtime-transaction/add-import-overtime-transaction" element={Master && (RoleAccessOvertimeTransactionCreate && <FormImportOvertimTransaction />)} />
              <Route path="/overtime-transaction/detail/:id" element={Master && (RoleAccessOvertimeTransactionIndex && <OvertimeTransactionDetail />)} />
              <Route path="/leave-transaction" element={Master && (RoleAccessLeaveTransactionIndex && <LeaveTransaction />)} />
              <Route path="/leave-transaction/add-leave-transaction" element={Master && (RoleAccessLeaveTransactionCreate && <FormAddLeaveTransaction />)} />
              <Route path="/leave-transaction/edit-leave-transaction/:id" element={Master && (RoleAccessLeaveTransactionUpdate && <FormAddLeaveTransaction />)} />
              <Route path="/leave-transaction/detail/:id" element={Master && (RoleAccessLeaveTransactionIndex && <LeaveTransactionDetail />)} />
              <Route path="/log-user" element={Master && (<LogUser />)} />
              {/* <Route path="/announcement" element={Master && (RoleAccessnnouncementIndex && <Announcement />)} />
              <Route path="/announcement/add-announcement" element={Master &&  ( RoleAccessnnouncementCreate && <FormAddAnnouncement />  )} />
              <Route path="/announcement/edit-announcement/:id" element={Master && (RoleAccessnnouncementUpdate && <FormAddAnnouncement />)} />
              <Route path="/announcement/detail/:id" element={Master && (RoleAccessnnouncementIndex && <AnnouncementDetail />)} /> */}
              <Route path="/approval-list" element={Master && (RoleAccessApprovalListIndex && <ApprovalList />)} />
              <Route path="/approval-list/approval-list-detail/:id" element={Master && (RoleAccessApprovalListIndex && <ApprovalListDetail />)} />
              <Route path="/time-request" element={Master && (RoleAccessTimeRequestIndex && <TimeRequest />)} />
              <Route path="/time-request/add-attendance-request" element={Master && (RoleAccessTimeRequestCreate && <FormAddTimeRequest />)} />
              <Route path="/time-request/add-change-shift-request" element={Master && (RoleAccessTimeRequestCreate && <AddChangeShiftRequest />)} />
              <Route path="/time-request/detail/:id" element={Master && (RoleAccessTimeRequestIndex && <TimeRequestDetail />)} />
              <Route path="/generate-payslip" element={Master && (<GeneratePayslipIndex />)} />
              <Route path="/generate-payslip/execute-generate-payslip" element={Master && (<FormAddGeneratePayslip />)} />
              <Route path="/employee-management" element={Master && (RoleAccessEmployeeManagementIndex && <EmployeeManagement />)} />
              <Route path="/employee-management/detail/:id" element={Master && (RoleAccessEmployeeManagementIndex && <EmployeeManagementDetail />)} />
              <Route path="/employee-management/detail/detail-loan/:id" element={Master && (RoleAccessEmployeeManagementIndex && <LoanDetailEmployee />)} />
              <Route path="/employee-management/detail/detail-assessment" element={Master && (RoleAccessEmployeeManagementIndex && <AssessmentDetailEmployee />)} />
              <Route path="/loan" element={Master && (RoleAccessLoanIndex && <LoanTransaction />)} />
              <Route path="/loan/detail/:id" element={Master && (RoleAccessLoanSettingIndex && <LoanDetail />)} />
              <Route path="/loan/add-loan" element={Master && (RoleAccessLoanCreate && <FormAddLoan />)} />
              <Route path="/loan-setting" element={Master && (RoleAccessLoanSettingIndex && <LoanSetting />)} />
              <Route path="/loan-setting/add-loan-setting" element={Master && (RoleAccessLoanSettingCreate && <FormAddLoanSetting />)} />
              <Route path="/loan-setting/edit-loan-setting/:id" element={Master && (RoleAccessLoanSettingUpdate && <FormAddLoanSetting />)} />
              <Route path="/loan-setting/detail/:id" element={Master && (RoleAccessLoanSettingIndex && <LoanSettingDetail />)} />
              <Route path="/bpjs-kesehatan" element={Master && (RoleAccessBpjsKesehatanIndex && <BpjsKesehatan />)} />
              <Route path="/bpjs-kesehatan/add-bpjs-kesehatan" element={Master && (RoleAccessBpjsKesehatanCreate && <FormAddBpjsMaster />)} />
              <Route path="/bpjs-kesehatan/edit-bpjs-kesehatan/:id" element={Master && (RoleAccessBpjsKesehatanUpdate && <FormAddBpjsMaster />)} />
              <Route path="/bpjs-kesehatan/detail/:id" element={Master && (RoleAccessBpjsKesehatanIndex && <BpjsMasterDetail />)} />
              <Route path="/bpjs-ketenagakerjaan" element={Master && (RoleAccessBpjsKetenagakerjaanIndex && <BpjsKetenagakerjaan />)} />
              <Route path="/bpjs-ketenagakerjaan/add-bpjs-ketenagakerjaan" element={Master && (RoleAccessBpjsKetenagakerjaanCreate && <FormAddBpjsKetenagakerjaanMaster />)} />
              <Route path="/bpjs-ketenagakerjaan/edit-bpjs-ketenagakerjaan/:id" element={Master && (RoleAccessBpjsKetenagakerjaanUpdate && <FormAddBpjsKetenagakerjaanMaster />)} />
              <Route path="/bpjs-ketenagakerjaan/detail/:id" element={Master && (RoleAccessBpjsKetenagakerjaanIndex && <BpjsMasterKetenagakerjaanDetail />)} />
              <Route path="/insurance" element={Master && (RoleAccessInsuranceIndex && <Insurance />)} />
              <Route path="/insurance/add-insurance" element={Master && (RoleAccessInsuranceCreate && <FormAddInsurance />)} />
              <Route path="/insurance/edit-insurance/:id" element={Master && (RoleAccessInsuranceUpdate && <FormAddInsurance />)} />
              <Route path="/insurance/detail/:id" element={Master && (RoleAccessInsuranceIndex && <InsuranceDetail />)} />
              <Route path="/insurance-report" element={Master && (RoleAccessInsuranceReportIndex && <ReportInsurance />)} />
              <Route path="/bpjs-report" element={Master && (RoleAccessBpjsReportIndex && <ReportBpjs />)} />
              {/* <Route path="/employee/employee-transfer" element={Master &&  <EmployeeTransfer />} />
              <Route path="/employee/employee-transfer/create-employee-transfer" element={Master &&  <FormCreateEmployeeTransfer />} />
              <Route path="/employee/employee-transfer/import-employee-transfer" element={Master &&  <FormImportEmployeeTransfer />} />
              <Route path="/employee/employee-transfer/employee-transfer-detail/:id" element={Master &&  <EmployeeTransferDetail />} /> */}
              <Route path="/ptkp-status" element={Master && (RoleAccessPtkpStatusIndex && <PtkpStatus />)} />
              <Route path="/ptkp-status/add-ptkp-status" element={Master && (RoleAccessPtkpStatusCreate && <FormAddPtkpStatus />)} />
              <Route path="/ptkp-status/edit-ptkp-status/:id" element={Master && (RoleAccessPtkpStatusUpdate && <FormAddPtkpStatus />)} />
              <Route path="/ptkp-status/detail/:id" element={Master && (RoleAccessPtkpStatusIndex && <PtkpStatusDetail />)} />
              <Route path="/pph21-rate" element={Master && (RoleAccessPph21RateIndex && <Pph21Rate />)} />
              <Route path="/pph21-rate/add-pph21-rate" element={Master && (RoleAccessPph21RateCreate && <FormAddPph21Rate />)} />
              <Route path="/pph21-rate/edit-pph21-rate/:id" element={Master && (RoleAccessPph21RateUpdate && <FormAddPph21Rate />)} />
              <Route path="/pph21-rate/detail/:id" element={Master && (RoleAccessPph21RateIndex && <Pph21RateDetail />)} />
              <Route path="/tax-methods" element={Master && (RoleAccessTaxMethodIndex && <TaxMethod />)} />
              <Route path="/tax-methods/add-tax-methods" element={Master && (RoleAccessTaxMethodCreate && <FormAddTaxMethod />)} />
              <Route path="/tax-methods/edit-tax-methods/:id" element={Master && (RoleAccessTaxMethodUpdate && <FormAddTaxMethod />)} />
              <Route path="/tax-methods/detail/:id" element={Master && (RoleAccessTaxMethodIndex && <TaxMethodDetail />)} />
              <Route path="/payroll-component" element={Master && (RoleAccessPayrollComponentIndex && <PayrollComponent />)} />
              <Route path="/payroll-component/wages-setting" element={Master && (RoleAccessPayrollComponentIndex && <WagesSetting />)} />
              <Route path="/payroll-component/add-master-component" element={Master && (RoleAccessPayrollComponentMasterCreate && <FormAddMasterComponent />)} />
              <Route path="/payroll-component/edit-master-component/:id" element={Master && (RoleAccessPayrollComponentMasterUpdate && <FormAddMasterComponent />)} />
              <Route path="/payroll-component/detail/:id" element={Master && (RoleAccessPayrollComponentIndex && <PayrollComponentDetail />)} />
              <Route path="/payroll-report" element={Master && (RoleAccessReportPayrollIndex && <PayrollReport />)} />
              <Route path="/payroll-report/detail/:id" element={Master && (RoleAccessReportPayrollIndex && <PayrollReportDetail />)} />
              <Route path="/report-ter" element={Master && (RoleAccessReportTerIndex && <ReportTER />)} />
              <Route path="/fake-gps-history-report" element={Master && (RoleAccessHistoryFakeGpsReportIndex && <HistoryFakeGpsReport />)} />
              <Route path="/report-progressive" element={Master && (RoleAccessReportProgressiveIndex && <ReportProgressive />)} />
              <Route path="/run-payroll" element={Master && (RoleAccessRunPayrollIndex && <RunPayroll />)} />
              <Route path="/approval-sequence" element={Master && (RoleAccessApprovalSequenceIndex && <ApprovalSequence />)} />
              <Route path="/personal-assessment" element={Master && (RoleAccessPersonalAssessmentIndex && <PersonalAssessment />)} />
              <Route path="/personal-assessment/add-personal-assessment" element={Master && (RoleAccessPersonalAssessmentCreate && <FormAddPersonalAssessmentMaster />)} />
              <Route path="/personal-assessment/detail/:id" element={Master && (RoleAccessPersonalAssessmentIndex && <PersonalAssessmentDetail />)} />
              <Route path="/personal-assessment-report" element={Master && (RoleAccessPersonalAssessmentReportIndex && <PersonalAssessmentReport />)} />
              <Route path="/personal-assessment-report/detail/:id" element={Master && (RoleAccessPersonalAssessmentReportIndex && <PersonalAssessmentDetailReport />)} />
              {result !== "privacy-policy" && result !== "health" && result !== "maintenance" && result !== "reset-password" ? (
                <Route path='*' element={<NotFound />} />
              ) : ""}

            </Routes>
          </div>
        )} />
      )}
    </Row>
  );
}

export default Router;
