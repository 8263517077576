/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form as FormAntd, message, Modal } from "antd";
import { useEffect, useState } from "react";
import * as yup from "yup";
import {
  InputWithLabel,
  NumberFormat,
  SelectOptionWithLabelApplies,
} from "../../component/Form/Input";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import "./styles.scss";
import { decryptData, encryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import {
  AddBpjsKetenagakerjaan,
  EditBpjsKetenagakerjaan,
  getLBpjsKetenagakerjaanDetail,
} from "../../api/bpjs";
const FormAddBpjsKetenagakerjaanMaster = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  const locationPath = useLocation();
  let n = location.split("/");
  let result = n[5];
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const [fetching, setFetching] = useState(false);
  const [detail, setDetail] = useState([]);

  const getDetailBpjsMaster = async () => {
    try {
      const res =
        locationPath.pathname ===
        "/bpjs-ketenagakerjaan/add-bpjs-ketenagakerjaan"
          ? null
          : await getLBpjsKetenagakerjaanDetail(id);
      setDetail(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getDetailBpjsMaster();
    sessionStorage.removeItem("codeKetenagakerjaan");
    form.resetForm();
  }, []);

  const validationUser = yup.object().shape({
    item: yup.string().required("Field is required"),
    type: yup.string().required("Field is required"),
    presentase_default: yup.string().required("Field is required"),
    min_basic_premium: yup.string().when('item', {
      is: (val) => val !== 'BPU',
      then: (schema)=> schema.required("Field is required"),
      otherwise: (schema) => schema.nullable()
   }),
    max_basic_premium: yup.string().when('item', {
      is: (val) => val !== 'BPU',
      then: (schema)=> schema.required("Field is required"),
      otherwise: (schema) => schema.nullable()
   }),
    premium_value: yup.string().when('item', {
      is: (val) => val === 'BPU',
      then: (schema)=> schema.required("Field is required"),
      otherwise: (schema) => schema.nullable()
   }),
  }, ['premium_value', 'max_basic_premium', 'min_basic_premium']);

  const form = useFormik({
    initialValues: {
      companyCode: companyStore,
      code: result ? detail?.code : "",
      item: result ? detail?.item : "",
      type: result ? detail?.type : "",
      presentase_default: result ? detail?.presentase_default : "",
      min_basic_premium: result ? detail?.min_basic_premium : "",
      max_basic_premium: result ? detail?.max_basic_premium : "",
      premium_value: result ? detail?.min_basic_premium : "",
    },
    enableReinitialize: true,
    validationSchema: validationUser,

    onSubmit: async (values) => {
      setFetching(true);
      try {
        const res = result
          ? await EditBpjsKetenagakerjaan(values, id)
          : await AddBpjsKetenagakerjaan(values);
        if (!res?.data?.success) {
          message.error({
            content: res?.data?.error,
          });
        } else {
          message.success({
            content: result
              ? "BPJS Ketenagakerjaan item has been successfully updated"
              : "BPJS Ketenagakerjaan item has been successfully saved",
          });
          navigate("/bpjs-ketenagakerjaan");
          sessionStorage.removeItem("codeKetenagakerjaan");
          form.resetForm();
        }
        setFetching(false);
      } catch (error) {
        message.error({
          content: "BPJS Ketenagakerjaan code already exist",
        });
        setFetching(false);
      }
    },
  });

  const dataBreadcrumb = [
    {
      title: "Payroll Management - BPJS Ketenagakerjaan",
      url: "/bpjs-ketenagakerjaan",
    },
    {
      title: `${
        result
          ? "Edit BPJS Ketenagakerjaan Item"
          : "Add BPJS Ketenagakerjaan Item"
      }`,
      url: ``
    },
  ];

  return (
    <>
      <div className="title-section">
        {result
          ? "Edit BPJS Ketenagakerjaan Item"
          : "Add BPJS Ketenagakerjaan Item"}
      </div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="add-wrapper">
        <FormAntd onFinish={form.handleSubmit}>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-12 md:gap-[119px] mb-1">
                <div className="flex-grow">
                  <InputWithLabel
                    label={
                      <div>
                        Code<span className="text-red-500 ml-1">*</span>
                      </div>
                    }
                    disabled
                    maxWidth={10}
                    name="code"
                    placeholder="Code"
                    value={
                      form.values.type
                        ? `${form.values.item}${
                              form.values.item === "BPU"?
                                ""
                              : form.values.type === "Company"
                              ? "C"
                              : form.values.type === "Employee"
                              ? "E"
                              : ""
                          }`
                        : form.values.code
                    }
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    maxLength={20}
                  />
                  {sessionStorage.setItem(
                    "codeKetenagakerjaan",
                    encryptData(
                      form.values.type
                        ? `${form.values.item}${
                              form.values.item === "BPU"?
                                ""
                              : form.values.type === "Company"
                              ? "C"
                              : form.values.type === "Employee"
                              ? "E"
                              : ""
                          }`
                        : form.values.code
                    )
                  )}
                  {form.touched.type && form.errors.type ? (
                    <span className="text-error">{form.errors.type}</span>
                  ) : null}
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-6 md:gap-[130px] mb-1">
                <div className="flex-grow">
                  <Col>
                    <SelectOptionWithLabelApplies
                      name="item"
                      label={
                        <div>
                          BPJS Ketenagakerjaan Item
                          <span style={{ color: "#FF0000" }}> *</span>
                        </div>
                      }
                      items={[
                        {
                          value: "JHT",
                          title: "Jaminan Hari Tua",
                        },
                        {
                          value: "JKK",
                          title: "Jaminan Kecelakaan Kerja",
                        },
                        {
                          value: "JKM",
                          title: "Jaminan Kematian",
                        },
                        {
                          value: "JP",
                          title: "Jaminan Pensiun",
                        },
                        {
                          value: "BPU",
                          title: "Bukan Penerima Upah (BPU)",
                        },
                      ]}
                      value={form?.values?.item ? form?.values?.item : null}
                      onChange={(val) => {
                        form.setValues({
                          ...form.values,
                          item: val,
                          presentase_default: val === 'BPU' ? '0': form.values.presentase_default,
                          type: val === 'BPU' ? 'Company' : form.values.type,
                        });
                      }}
                      placeholder="BPJS Kesehatan Item"
                      onBlur={form.handleBlur}
                    />
                    {form.touched.item && form.errors.item ? (
                      <span className="text-error">{form.errors.item}</span>
                    ) : null}
                  </Col>
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-6 md:gap-[130px] mb-1">
                <div className="flex-grow">
                  <Col>
                    <SelectOptionWithLabelApplies
                      name="type"
                      disabled={form.values.item === 'BPU'}
                      label={
                        <div>
                          Type<span style={{ color: "#FF0000" }}> *</span>
                        </div>
                      }
                      items={[
                        {
                          value: "Company",
                          title: "Company",
                        },
                        {
                          value: "Employee",
                          title: "Employee",
                        },
                      ]}
                      value={form?.values?.type ? form?.values?.type : null}
                      onChange={(val) => {
                        form.setValues({
                          ...form.values,
                          type: val,
                        });
                      }}
                      placeholder="Type"
                      onBlur={form.handleBlur}
                    />
                    {form.touched.type && form.errors.type ? (
                      <span className="text-error">{form.errors.type}</span>
                    ) : null}
                  </Col>
                </div>
              </div>
            </div>
          </FormAntd.Item>
          
          {form?.values?.item === 'BPU' ?
          <FormAntd.Item>
          <div className="flex flex-col">
            <div className="flex items-center gap-12 md:gap-[119px] mb-1">
              <div className="flex-grow">
                <NumberFormat
                  label={
                    <div>
                      Premium Value
                      <span className="text-red-500 ml-1">*</span>
                    </div>
                  }
                  name="premium_value"
                  type="text"
                  allowNegative={false}
                  placeholder="Rp."
                  thousandSeparator={true}
                  value={
                    form?.values?.premium_value
                      ? form?.values?.premium_value
                      : ""
                  }
                  isAllowed={(values) => {
                    const { floatValue, value} = values;
                    return (value.length <=7 && floatValue <= 9999999 && floatValue>= 0) || value === '';
                  }}
                  onValueChange={(values) => {
                    const { formattedValue } = values;
                    form.setValues({
                      ...form.values,
                      premium_value: formattedValue,
                      min_basic_premium: formattedValue,
                      max_basic_premium: formattedValue,
                    });
                  }}
                />
                <div
                  className={
                    form.touched.min_basic_premium &&
                    form.errors.min_basic_premium === "Field is required"
                      ? "mb-[-22px] mt-0"
                      : "mt-3 mb-[-22px]"
                  }>
                  {form.touched.premium_value &&
                  form.errors.premium_value ? (
                    <span className="text-error">
                      {form.errors.premium_value}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          </FormAntd.Item>
          :
          <>
            <FormAntd.Item>
              <div className="flex flex-col">
                <div className="flex items-center gap-12 md:gap-[119px] mb-1">
                  <div className="flex-grow">
                    <InputWithLabel
                      label={
                        <div>
                          Percentage Default
                          <span style={{ color: "#FF0000" }}> *</span>
                        </div>
                      }
                      addonAfter="%"
                      name="presentase_default"
                      type="text"
                      placeholder="Percentage Default"
                      value={form?.values?.presentase_default}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                    />
                    <div
                      className={
                        form.touched.presentase_default &&
                        form.errors.presentase_default === "Field is required"
                          ? "mb-[-22px] mt-0"
                          : "mt-3 mb-[-22px]"
                      }>
                      {form.touched.presentase_default &&
                      form.errors.presentase_default ? (
                        <span className="text-error">
                          {form.errors.presentase_default}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </FormAntd.Item>
            <FormAntd.Item>
              <div className="flex flex-col">
                <div className="flex items-center gap-12 md:gap-[119px] mb-1">
                  <div className="flex-grow">
                    <NumberFormat
                      label={
                        <div>
                          Minimun Basic Premium Value
                          <span className="text-red-500 ml-1">*</span>
                        </div>
                      }
                      name="min_basic_premium"
                      type="text"
                      allowNegative={false}
                      placeholder="Rp."
                      thousandSeparator={true}
                      value={
                        form?.values?.min_basic_premium
                          ? form?.values?.min_basic_premium
                          : ""
                      }
                      onValueChange={(values) => {
                        const { formattedValue } = values;
                        form.setValues({
                          ...form.values,
                          min_basic_premium: formattedValue,
                        });
                      }}
                    />
                    <div
                      className={
                        form.touched.min_basic_premium &&
                        form.errors.min_basic_premium === "Field is required"
                          ? "mb-[-22px] mt-0"
                          : "mt-3 mb-[-22px]"
                      }>
                      {form.touched.min_basic_premium &&
                      form.errors.min_basic_premium ? (
                        <span className="text-error">
                          {form.errors.min_basic_premium}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </FormAntd.Item>
            <FormAntd.Item>
              <div className="flex flex-col">
                <div className="flex items-center gap-12 md:gap-[119px] mb-1">
                  <div className="flex-grow">
                    <NumberFormat
                      label={
                        <div>
                          Maximum Basic Premium Value
                          <span className="text-red-500 ml-1">*</span>
                        </div>
                      }
                      name="max_basic_premium"
                      type="text"
                      allowNegative={false}
                      placeholder="Rp."
                      thousandSeparator={true}
                      value={
                        form?.values?.max_basic_premium
                          ? form?.values?.max_basic_premium
                          : ""
                      }
                      onValueChange={(values) => {
                        const { formattedValue } = values;
                        form.setValues({
                          ...form.values,
                          max_basic_premium: formattedValue,
                        });
                      }}
                    />
                    <div
                      className={
                        form.touched.max_basic_premium &&
                        form.errors.max_basic_premium === "Field is required"
                          ? "mb-[-22px] mt-0"
                          : "mt-3 mb-[-22px]"
                      }>
                      {form.touched.max_basic_premium &&
                      form.errors.max_basic_premium ? (
                        <span className="text-error">
                          {form.errors.max_basic_premium}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </FormAntd.Item>
          </>}
        </FormAntd>

        <div className="footer-add">
          <Button
            key="back"
            className="btn btn-sec"
            style={{ marginRight: "14px" }}
            onClick={() => navigate("/bpjs-ketenagakerjaan")}>
            Cancel
          </Button>

          <Button
            key="submit"
            type="primary"
            className="btn btn-primary btn-sh-p"
            loading={fetching}
            onClick={form.handleSubmit}
            disabled={form.isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormAddBpjsKetenagakerjaanMaster;
