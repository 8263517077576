import { Button } from "antd";
import "./styles.scss";
import { Success } from "../../../assets";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { decryptData } from "../../../helper/cryptojs";

export default function RunPayrollSuccess({ form, IsViewtotalData }) {
  const RoleaccessPriviledgeRunPayrollDownload = localStorage.getItem(
    "RoleaccessPriviledgeRunPayrollDownload"
  );
  const storeValues =  decryptData(sessionStorage.getItem("storeValues"));
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const handleDownload = () => {
    window.location.href = `${process.env.REACT_APP_HRIS_URL}run-payroll/download-result/${id}`;
  };
  return (
    <div className="py-10">
      <div className="text-[20px] font-semibold leading-6">
        Run payroll success
      </div>
      <div className="py-5">
        <img src={Success} alt="Icon" />
      </div>
      <p className="text-sm leading-6 mt-[-10px]">
        Your payroll data has been successfully set with the details below
      </p>
      <div className="flex gap-10 items-center ">
        <div className="payroll flex items-center">
          <div className="flex flex-col">
            <p className="text-[#505050]">Payroll Period</p>
            <div className="mt-[-5px] font-semibold text-[18px]">
              {" "}
              {IsViewtotalData?.cut_off_type === "weekly"
                ? form.values.period
                  ? moment(form.values.end_date).format("D MMM YYYY")
                  : null
                : form.values.period || "-" || IsViewtotalData?.cut_off_type === "weekly"
                ? storeValues?.period
                  ? moment(storeValues?.end_date).format("D MMM YYYY")
                  : null
                : storeValues?.period || "-"}
            </div>
          </div>
        </div>
        <div className="cut-off-period flex items-center ">
          <div className="flex flex-col">
            <p className="text-[#505050]">Cut Off Period</p>
         <div className="mt-[-5px] font-semibold text-[18px]">
           {form.values.start_date && form.values.end_date ? 
             `${moment(form.values.start_date).format("D MMM")} - ${moment(form.values.end_date).format("D MMM YYYY")}` 
             : "-" || storeValues?.start_date && storeValues?.end_date ? 
             `${moment(storeValues?.start_date).format("D MMM")} - ${moment(storeValues?.end_date).format("D MMM YYYY")}` 
             : "-" }
         </div>
          </div>
        </div>
        <div className="flex items-center">
          <div className="flex flex-col">
            <p className="text-[#505050]">Employee</p>
            <div className="mt-[-5px] font-semibold text-[18px]">
              {form.values.all_employee === 1 || form.values?.select_employees?.length > 0 ? form.values.all_employee === 1 ? "All Employees" : `${form.values?.select_employees?.length} Employees` : "-" || storeValues?.all_employee === 1 || storeValues?.select_employees?.length > 0 ? storeValues?.all_employee === 1 ? "All Employees" : `${storeValues?.select_employees?.length} Employees` : "-" }
            </div>
          </div>
        </div>
      </div>
      <p className="text-sm leading-6 pt-5">
        Your run payroll history is available in the report menu.
        <br />
        you can download the result here
      </p>
      {RoleaccessPriviledgeRunPayrollDownload && (
        <div className="pb-5">
          <Button size={"large"} type="primary" onClick={handleDownload}>
            Download
          </Button>
        </div>
      )}
    </div>
  );
}
