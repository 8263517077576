import React, { useState } from "react";
import { Button, Card, Col, Form as FormAntd, Row, message } from "antd";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import {
  HomeOutlined,
  CloudUploadOutlined,
  FileExcelOutlined,
} from "@ant-design/icons";
import IconExel from "../../../assets/IconExel";
import { addOImport0vertimeTransaction } from "../../../api/overtimeTransaction";
import { decryptData } from "../../../helper/cryptojs";
import Dragger from "antd/es/upload/Dragger";
import Breadcrumb from "../../../component/Breadcrumb";
const FormImportEmployeeTransfer = ({ classNames }) => {
  const location = window.location.href;
  var n = location.split("/");
  var result = n[5];
  const decode = decodeURIComponent(result);
  var dec = decode?.lastIndexOf("?");
  var id = decode?.substring(dec + 1);
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(false);
  const [fileName, setFileName] = useState(null);
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024) {
      setFileName(file.name);
      form.setValues({
        ...form.values,
        Overtime: e.currentTarget.files[0],
      });
    } else {
      setFileName(null);
      let alesrt = message.error(
        "Please upload a XLSX file with a maximum size of 2 MB"
      );
      setTimeout(() => {
        if (alesrt) {
          window.location.reload(true);
        }
      }, 3000);
    }
  };

  const handleFileDelete = () => {
    setFileName(null);
  };

  const form = useFormik({
    initialValues: {
      company: companyStore,
      Overtime: null,
    },
    enableReinitialize: true,
    // validationSchema: validationUser,
    onSubmit: async (values) => {
      // setFetching(true)
      try {
        const res = await addOImport0vertimeTransaction(values);
        if (!res?.data?.success) {
          message.error({
            content: res?.data?.error,
          });
        } else {
          window.location.href = `${res.data.data}`;
          message.success({
            content: "Import data success",
          });
          navigate("/overtime-transaction");
          form.resetForm();
          // window.location.reload()
        }
        setFetching(false);
      } catch (error) {
        message.error({
          content: "Request Not Found",
        });
        setFetching(false);
      }
    },
  });

  const handleDownload = () => {
    window.location.href = `${process.env.REACT_APP_HRIS_URL}template-import-employee/template_employee.xlsx`;
    // window.location.href = `http://127.0.0.1:8000/template-import-employee/template_employee.xlsx`;
  };

  const dataBreadcrumb = [
    {
        title: "Master Data - Employee",
        url: "/employee"
    },
    {
        title: "Employee Transfer",
        url: "/employee/employee-transfer"
    },
    {
        title: "Import Employee Transfer",
        url: "/employee/employee-transfer/import-employee-transfer"
    },
]

  return (
    <div className="add-wrapper">
      <div className="title-section">Import Employee Transfer</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <FormAntd onFinish={form.handleSubmit}>
        <Col>
          <div>
          <Row className="grid mt-5  grid-cols-1 md:grid-cols-2 gap-8"></Row>
          <Row className="grid  grid-cols-1 md:grid-cols-2 gap-8">
            <div>
              <Col>
                <p className="font-semibold text-lg">Download Template</p>
              </Col>
              <div className="flex flex-row gap-7 items-center mt-2">
              <Col span={6} className="text-center">
                        <FileExcelOutlined style={{ fontSize: "4em" }} />
                     </Col>
                <div className="flex flex-col justify-start">
                  <p className="ml-4">Employee Transfer Template</p>
                  <Button type="link" onClick={handleDownload}>
                    Download the following template and please fill in the data
                  </Button>
                </div>
              </div>
            </div>
          </Row>
          </div>
          <div className="mt-11">
            <Col>
              <p className="font-semibold text-lg">
                Upload Attachment <span className="text-error">*</span>
              </p>
            </Col>
            <Dragger
              beforeUpload={() => false}
              onChange={handleFileUpload}
              accept=".xlsx, .xls"
              maxCount={1}
            >
              <p className="ant-upload-drag-icon">
                <CloudUploadOutlined />
              </p>
              <p className="ant-upload-text">
                Select a file or drag and drop here{" "}
              </p>
              <p className="ant-upload-hint">XLSX, file size no more 12MB </p>
            </Dragger>
            {form.touched.doc_file && form.errors.doc_file ? (
              <span className="text-error">{form.errors.doc_file}</span>
            ) : null}
          </div>
          <div className="flex justify-end mt-20">
            <Button
              key="back"
              className="btn btn-sec"
              style={{ marginRight: "14px" }}
              onClick={() => navigate("/employee/employee-transfer")}
            >
              Cancel
            </Button>
            ,
            <Button
              key="submit"
              type="primary"
              className="btn btn-primary btn-sh-p"
              onClick={form.handleSubmit}
              disabled={form.isSubmitting}
            >
              Submit
            </Button>
          </div>
        </Col>
      </FormAntd>
    </div>
  );
};

export default FormImportEmployeeTransfer;
