import React, {useState} from 'react';
import {
  Panel,
  useReactFlow,
  getNodesBounds,
  getViewportForBounds,
} from '@xyflow/react';
import { toPng } from 'html-to-image';
import { Button, Spin } from 'antd';

const imageWidth = 2560;
const imageHeight = 1642;

function DownloadButton() {
    const { getNodes } = useReactFlow();
    const [isDownloading, setIsDownloading] = useState(false)
    
    function downloadImage(dataUrl) {
      const a = document.createElement('a');
    
      a.setAttribute('download', 'Struktur Organisasi.png');
      a.setAttribute('href', dataUrl);
      a.click();
      setIsDownloading(false)
    }

  const onClick = () => {
    setIsDownloading(true)
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    const nodesBounds = getNodesBounds(getNodes());
    const viewport = getViewportForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      0.5,
      2,
    );

    toPng(document.querySelector('.react-flow__viewport'), {
      backgroundColor: '#fff',
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${viewport.x}px, ${viewport.y}px) scale(${viewport.zoom})`,
      },
    }).then(downloadImage);
  };

  return (
    <Panel position="top-right">
        {isDownloading ? 
        <Button disabled className="btn btn-sh-p">
            <Spin />
        </Button>
        :
        <Button type='primary' className="btn btn-primary btn-sh-p" onClick={onClick}>
            Download
        </Button>}
    </Panel>
  );
}

export default DownloadButton;
