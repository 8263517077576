/* eslint-disable react-hooks/exhaustive-deps */
import {  Col, Pagination, Row} from "antd";
import  { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import "./styles.scss"
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../helper/cryptojs";
import {decode} from 'html-entities';
import { getEmployee } from "../../api/employee";

export default function EmployeeManagement() {
	const navigate = useNavigate();
	const sessionPage = sessionStorage.getItem("sessionPageBranch");
	const sessionOffset = sessionStorage.getItem("sessionOffsetBranch");
	const sessionSearch = sessionStorage.getItem("sessionSearchBranch");
	const [listTable, setListTable] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(sessionPage ? parseInt(sessionPage) : 1)
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(sessionOffset ? sessionOffset : 0)
	const [search, setSearch] = useState(sessionSearch ? sessionSearch : "");
	const [active, setActive] = useState("active");
	const BestpraticeUseEffect = useRef(false)
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
	const employeeList = async () => {
		setLoading(true);
		try {
		  const res = await getEmployee(search, limit, offset, page,company,active)
		  setListTable(res?.data?.data);
		  setTotalData(res?.data?.recordsTotal)
		//   console.log(res?.data)
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	  useEffect(() => {
		if (!BestpraticeUseEffect.current) {
			BestpraticeUseEffect.current = true;
		  } else {
			employeeList();
			const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageBranch");
				sessionStorage.removeItem("sessionSearchBranch");
				sessionStorage.removeItem("sessionOffsetBranch");
				setPage(1)
				setOffset(0)
				setSearch("")
			};
			window.addEventListener("beforeunload", beforeUnloadHandler);
			return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			};
		  }
	}, [search, limit, offset, page, company]);


	useEffect(() => {
		let offsetSession = 0;
		if(page > 1){
			offsetSession = page*limit - 10
		}
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPageBranch", page);
		sessionStorage.setItem("sessionOffsetBranch", offsetSession);
	},[page])

	

	const columns = [
	{
		title: 'No',
		dataIndex: 'no',
		key: 'no',
		render: (text,record) =>{
			return (
				<div className="detail-btn-wp"   onClick={() => navigate(`/employee-management/detail/${encodeURIComponent("id?" + record?.employee_id)}`)}>
					{text}
				</div>
			)
		}
	},
    {
		title: 'Branch',
		dataIndex: 'branch_name',
		key: 'branch_name',
		render: (text,record) =>{
			return (
				<div className="detail-btn-wp"  onClick={() => navigate(`/employee-management/detail/${encodeURIComponent("id?" + record?.employee_id)}`)}>
					{text || "-"}
				</div>
			)
		}
	},
	{
		title: 'NIK',
		dataIndex: 'employee_number',
		key: 'employee_number',
		render: (text,record) =>{
			return (
				<div className="detail-btn-wp"  onClick={() => navigate(`/employee-management/detail/${encodeURIComponent("id?" + record?.employee_id)}`)}>
					{text}
				</div>
			)
		}
	},
	{
		title: 'Employee Name',
		dataIndex: 'full_name',
		key: 'full_name',
		render: (text,record) =>{
			return (
				<div className="detail-btn-wp"  onClick={() => navigate(`/employee-management/detail/${encodeURIComponent("id?" + record?.employee_id)}`)}>
					{decode(text)}
				</div>
			)
		}
	},
	{
		title: 'Department',
		dataIndex: 'department_name',
		key: 'department_name',
		render: (text,record) =>{
			return (
				<div className="detail-btn-wp"   onClick={() => navigate(`/employee-management/detail/${encodeURIComponent("id?" + record?.employee_id)}`)}>
					{decode(text)}
				</div>
			)
		}
	},
	{
		title: 'Division',
		dataIndex: 'division_name',
		key: 'division_name',
		render: (text,record) =>{
			return (
				<div className="detail-btn-wp"   onClick={() => navigate(`/employee-management/detail/${encodeURIComponent("id?" + record?.employee_id)}`)}>
					{decode(text)}
				</div>
			)
		}
	},
	{
		title: 'Position',
		dataIndex: 'position_name',
		key: 'position_name',
		render: (text,record) =>{
			return (
				<div className="detail-btn-wp"  onClick={() => navigate(`/employee-management/detail/${encodeURIComponent("id?" + record?.employee_id)}`)}>
					{decode(text)}
				</div>
			)
		}
	},
	];
	const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/employee-management"
		},
		{
			title: "Employee Management",
			url: "/employee-management"
		},
	]

	const onChangeTable = (pageNumber, type) => {
		setPage(pageNumber)
		setOffset(pageNumber*limit- 10)
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		setLimit(10)
		setPage(1)
		sessionStorage.setItem("sessionSearchBranch", e.target.value);
	}

	
    return (
        <>
		<>
		<div className="title-section">
				Employee Management
			</div>
			<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
			<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
				<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"} >
					<Col md={{top:20}}>
						<Row justify={"end"} style={{gap:12}} md={14}>
								<InputWithIcon
									type="text"
									placeholder="Search Employee Management"
									img={iconSearch}
									className="search-input btn-sh-sec"
									classImg="op-icon"
									value={search}
									onChange={onChangeSearch}
								/>
						</Row>
					</Col>
				
				</Row>

				<div className="table-wrapper">
					<Table dataSource={dataTableWithNumber()} columns={columns} loading={loading}/>
					<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
						<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
					</div>

				</div>
			</div>
		</>		
            
        </>
    )
}