import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getEmployee = async (search, limit, offset, page,company, active) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
    const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"))
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        branchCode: branchSession ? branchSession : branchDefault,
        is_active : active == 'active' ? 1 : 0,
        startDate : "",
        endDate : "",
        filterColumn : "full_name",
        filter : search.length > 0 ? search : undefined,
        start : offset,
        length : limit,
        draw : page
    }
    return master.post("/employee", payload);
}

export const getEmployeeManagementId = async (id,month,offset,limit,page) => {
    let payload = {
        employeeId : id,
        period: month,
        start : offset,
        length : limit,
        draw : page
    }
    return master.post('/leave-request', payload);
}

export const getEmployeeManagementOvertime = async (id,month,offset,limit,page) => {
    let payload = {
        employeeId : id,
        period: month,
        start : offset,
        length : limit,
        draw : page
    }
    return master.post('/overtime-employee-management', payload);
}

export const getEmployeeManagementDetail = async (id) => {
    return master.get(`/employee-leave/detail/employee/${id}`);
}
export const getEmployeeManagementDetailId = async (id) => {
    let payload = {
        EmployeeId: id
    }
    return master.post('/employee-management-detail',payload,{
      headers :{
        "Content-Type" : "application/json"    
      }
    });
}
export const getEmployeeManagementDetailLoanId = async (id,company,status,limit, offset, page) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
    companyCode : companyStore || company !== null ? company : "",
    status: status,
    start : offset,
    length : limit,
    draw : page
    }
    return master.post(`/loan/detail/employee/${id}`,payload,{
      headers :{
        "Content-Type" : "application/json"    
      }
    });
}

export const getManagementDetailLoanId = async (id) => {
    return master.get(`/loan/${id}`);
}
export const getManagementDetailLoanIdInstallment = async (id) => {
    return master.get(`/loan/installment/${id}`);
}

export const UpdateEmployeeManagement = async (id,data,company) => {
    const user = decryptData(localStorage.getItem("Username"));
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    const payload = { 
        companyCode : companyStore || company !== null ? company : "",
        employeeId:data.employeeId,
        leaveBalance:data.leaveBalance,
        modifiedBy: user,
    }
    return master.post(`/employee-leave/update-balance/${id}`,payload);
}

export const addPersonalDataEmployee = async (data,company) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        full_name: data.name,
        email_office: data.emailOffice,
        email_personal: data.emailPersonal,
        date_of_birth: data.dateOfBirth,
        age : data.Age,
        husband_wife_name : data.HusbandWifeName,
        husband_wife_mobile: data.HusbandWifeMobile,
        husband_wife_email: data.HusbandWifeEmail,
        husband_wife_occupation: data.HusbandWifeOccupation,
        child: data.Child,
        child_1: data.Child1,
        child_2: data.Child2,
        child_3: data.Child3,
        place_of_birth: data.placeOfBirth,
        nationality: data.nationaly,
        religion: data.religion,
        marital_status: data.maritalStatus,
        gender: data.gender,
        education: data.education,
        education_institution: data.EducationInstitution,
        education_field: data.EducationField,
        gpa: data.Gpa,
        phone_number: data.phoneNumber,
        family_certificate_number: data.familyCertificateNumber,
        identity_card_number: data.identityNumber,
        driver_license_expiry: data.driverLicenseExpired,
        driver_license_number: data.driverLicenseNumber,
        permanent_address: data.addressPermanent,
        permanent_postal_code: data.postalCodePermanent,
        permanent_city: data.cityPermanent,
        permanent_province: data.provincePermanent,
        current_address: data.addressCurrent,
        current_postal_code: data.postalCodeCurrent,
        current_city: data.cityCurrent,
        current_province: data.provinceCurrent,
        created_by:user,
        emergency_contact_relation: data.emergency_contact_relation,
        emergency_contact_name: data.emergency_contact_name,
        emergency_contact_number: data. emergency_contact_number,
        employee_photo: data.employee_photo ? data.employee_photo : null,
    }
    return master.post("/personal-data-create", payload);
}
export const EditPersonalDataEmployee = async (data,id,company) => {
    const user = decryptData(localStorage.getItem("Username"));
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        employee_id: id,
        full_name: data.name,
        email_office: data.emailOffice,
        email_personal: data.emailPersonal,
        date_of_birth: data.dateOfBirth,
        age : data.Age,
        husband_wife_name : data.HusbandWifeName,
        husband_wife_mobile: data.HusbandWifeMobile,
        husband_wife_email: data.HusbandWifeEmail,
        husband_wife_occupation: data.HusbandWifeOccupation,
        child: data.Child,
        child_1: data.Child1,
        child_2: data.Child2,
        child_3: data.Child3,
        place_of_birth: data.placeOfBirth,
        nationality: data.nationaly,
        religion: data.religion,
        marital_status: data.maritalStatus,
        gender: data.gender,
        education: data.education,
        education_institution: data.EducationInstitution,
        education_field: data.EducationField,
        gpa: data.Gpa,
        phone_number: data.phoneNumber,
        family_certificate_number: data.familyCertificateNumber,
        identity_card_number: data.identityNumber,
        driver_license_expiry: data.driverLicenseExpired,
        driver_license_number: data.driverLicenseNumber,
        permanent_address: data.addressPermanent,
        permanent_postal_code: data.postalCodePermanent,
        permanent_city: data.cityPermanent,
        permanent_province: data.provincePermanent,
        current_address: data.addressCurrent,
        current_postal_code: data.postalCodeCurrent,
        current_city: data.cityCurrent,
        current_province: data.provinceCurrent,
        is_active: data.Status,
        in_active_reason: data.in_active_reason,
        in_active_description: data.in_active_description,
        in_active_endate: data.in_active_endate,
        in_active_backlist: data.in_active_backlist,
        created_by:user,
        modified_by: user,
        emergency_contact_relation: data.emergency_contact_relation,
        emergency_contact_name: data.emergency_contact_name,
        emergency_contact_number: data.emergency_contact_number,
        employee_photo: data.employee_photo ? data.employee_photo : null,
    }
    return master.post("/personal-data-modify", payload);
}

export const addEmployeeData = async (data, id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        employee_id: id,
        employee_number:data.employee_number,
        employee_status: data.employeeStatus,
        join_date: data.joinDate,
        start_date: data.startDate,
        end_date: data.endDate,
        sign_date: data.signDate,
        branchCode: data.branch,
        departmentCode: data.department,
        divisionCode: data.CodeDivision,
        supervisorCode: data.SupervisorCode,
        levelCode: data.LevelCode,
        positionCode: data.CodePosition,
        gradeId: data.GradeId,
        created_by:user,
        modified_by: user,
        update_approval: data.updateApproval
    }
    return master.post("/employement-data-create", payload);
}
export const EditEmployeeData = async (data, id,company) => {
    const user = decryptData(localStorage.getItem("Username"));
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        employee_id: id,
        employee_status: data.employeeStatus,
        join_date: data.joinDate,
        start_date: data.startDate,
        end_date: data.endDate,
        sign_date: data.signDate,
        branchCode: data.branch,
        departmentCode: data.department,
        divisionCode: data.CodeDivision,
        supervisorCode: data.SupervisorCode,
        levelCode: data.LevelCode,
        gradeId: data.GradeId,
        positionCode: data.CodePosition,
        created_by:user,
        modified_by: user,
        update_approval: data.updateApproval
    }
    return master.post("/employement-data-modify", payload);
}

export const addPayrollData = async (data, id) => {
    const user = localStorage.getItem("Username");
    let payload = {
        employee_id: id,
        basic_salary: data?.basicSalary?.replace(/,/g, ""),
        salary_type: data.salaryType,
        payment_schedule: data.paymentSchedule,
        bank_name: data.bankName,
        account_name: data.accountName,
        account_number: data.accountNumber,
        prorate_setting: data.prorateSetting,
        allowed_overtime: data.allowedForOvertime === "Yes" ? 1 : 0,
        overtime_working_day: data.overtimeWorkingDay,
        overtime_dayoff: data.overtimeDayOff,
        overtime_national_holiday: data.overtimeNationalHoliday,
        ptkp_status: data.ptkpStatus,
        npwp: data.npwp,
        taxable_date: data.taxableDate,
        tax_method: data.taxMethode,
        begining_netto: data?.beginingNeto?.replace(/,/g, ""),
        tax_salary:data.taxSalary,
        tax_status: data.employeeTaxStatus,
        bpjstk_number: data.bpjsKetenagakerjaanType === "PU" || data.bpjsKetenagakerjaanType === "BPU" ? data.bpjsKetenagakerjaan : null,
        pph21_paid: data.pph21,
        npp_bpjstk_name: data.nppBpjsKetenagakerjaan,
        bpjstk_date: data.bpjsKetenagakerjaanDate,
        bpjstk_type: data.bpjsKetenagakerjaanType,
        bpjskes_number: data.bpjsKesehatanNumber,
        bpjsKesehatan: data.bpjsKesehatan,
        bpjskes_date: data.bpjsKesehatanDate,
        bpjskes_cost: data.bpjsKesehatanCost,
        jht_cost: data.bpjsKetenagakerjaanType === "PU" ? data.jhtCostName : null,
        pension_cost: data.bpjsKetenagakerjaanType === "PU" ? data.jaminanPensiunCost : null,
        pension_plan_cost: data.jhtCost,
        pension_date: data.jaminanPensiunDate,
        component_overtime: data.componentOvertime,
        tunjangan_jabatan: data.TunjanganJabatan,
        tunjangan_kehadiran : data.TunjanganKehadiran,
        tunjangan_profesi: data.TunjanganProfesi,
        tunjangan_makan: data.TunjanganMakan,
        insurance_number: data.Insurance === 1 ? data.InsuranceNumber : null,
        insurance_date: data.Insurance === 1 ? data.InsuranceDate : null,
        insurance: data.bpjsKesehatan === 2 ? data.Insurance : null,
        insuranceCode: data.Insurance === 1 ? data.InsuranceCode : null,
        bpu_tk: data.BpuTk,
        bpu_tk_probation: data.BpuTkProbation,
        bpjskes_family: data.bpjsKesehatanFamily,
        allowance_data: data.allowanceData,
        created_by:user
    }
    return master.post("/employee-payroll-create", payload);
}
export const EditPayrollData = async (data, id,company) => {
    const user = decryptData(localStorage.getItem("Username"));
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        employee_id: id,
        basic_salary: data?.basicSalary?.replace(/,/g, ""),
        salary_type: data.salaryType,
        payment_schedule: data.paymentSchedule,
        bank_name: data.bankName,
        account_name: data.accountName,
        account_number: data.accountNumber,
        prorate_setting: data.prorateSetting,
        allowed_overtime: data.allowedForOvertime === "Yes" ? 1 : 0,
        overtime_working_day: data.overtimeWorkingDay,
        overtime_dayoff: data.overtimeDayOff,
        overtime_national_holiday: data.overtimeNationalHoliday,
        ptkp_status: data.ptkpStatus,
        npwp: data.npwp,
        taxable_date: data.taxableDate,
        tax_method: data.taxMethode,
        begining_netto: data?.beginingNeto?.replace(/,/g, ""),
        tax_status: data.employeeTaxStatus,
        bpjstk_number: data.bpjsKetenagakerjaanType === "PU" || data.bpjsKetenagakerjaanType === "BPU" ? data.bpjsKetenagakerjaan : null,
        pph21_paid: data.pph21,
        npp_bpjstk_name: data.nppBpjsKetenagakerjaan,
        bpjstk_date: data.bpjsKetenagakerjaanDate,
        insuranceCode: data.Insurance === 1 ? data.InsuranceCode : null,
        bpjstk_type: data.bpjsKetenagakerjaanType,
        bpjskes_number: data.bpjsKesehatan === 1 ? data.bpjsKesehatanNumber : null,
        bpjsKesehatan: data.bpjsKesehatan,
        bpjskes_date: data.bpjsKesehatanDate,
        bpjskes_cost: data.bpjsKesehatanCost,
        jht_cost: data.bpjsKetenagakerjaanType === "PU" ? data.jhtCostName : null,
        pension_cost: data.bpjsKetenagakerjaanType === "PU" ? data.jaminanPensiunCost : null,
        pension_plan_cost: data.jhtCost,
        pension_date: data.jaminanPensiunDate,
        component_overtime: data.componentOvertime,
        tunjangan_jabatan: data.TunjanganJabatan,
        tunjangan_kehadiran : data.TunjanganKehadiran,
        tunjangan_profesi: data.TunjanganProfesi,
        tunjangan_makan: data.TunjanganMakan,
        insurance_number: data.Insurance === 1 ? data.InsuranceNumber : null,
        insurance_date: data.Insurance === 1 ? data.InsuranceDate : null,
        insurance: data.bpjsKesehatan === 2 ? data.Insurance : null,
        bpu_tk: data.BpuTk,
        bpu_tk_probation: data.BpuTkProbation,
        bpjskes_family: data.bpjsKesehatanFamily,
        tax_salary:data.taxSalary,
        allowance_data: data.allowanceData,
        created_by:user,
        modified_by: user
    }
    return master.post("/employee-payroll-modify", payload);
}

export const insuranceInputList = async (company) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
    }
    return master.post("/insurance-input-list", payload);
}
export const ptkpTypeInputList = async () => {
    return master.get("/ptkp-type-input");
}

export const addOnBoadingData =  async (data,id) => {
    const user = localStorage.getItem("Username");
    let payload = {
        employee_id: id,
        main_docs: data?.supportingDocument ? 1 : 0,
        vission_mission: data?.visiMisi ? 1 : 0,
        orchart_company: data?.organization ? 1 : 0,
        job_desc: data?.jobDescription ? 1 : 0,
        role_access: data?.roleAccess ? 1 : 0,
        assets: data?.assets,
        created_by: user
    }
    return master.post("/employee-onboard-create", payload);
}
export const EditOnBoadingData =  async (data, id,company) => {
    const user = decryptData(localStorage.getItem("Username"));
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        employee_id: id,
        main_docs: data?.supportingDocument ? 1 : 0,
        vission_mission: data?.visiMisi ? 1 : 0,
        orchart_company: data?.organization ? 1 : 0,
        job_desc: data?.jobDescription ? 1 : 0,
        role_access: data?.roleAccess ? 1 : 0,
        assets: data?.assets,
        created_by: user,
        modified_by: user
    }
    return master.post("/employee-onboard-modify", payload);
}

export const editEmployee = async (data) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
            employeeNumber: data.employeeNumber,
            identity_card_number: data.identity_card_number,
            taxpayer_identification_number: data.taxpayer_identification_number,
            first_name: data.first_name,
            middle_name: data.middle_name,
            last_name: data.last_name,
            email: data.email,
            phone_number: data.phone_number,
            nationality: data.nationality,
            marital_status: data.marital_status,
            date_of_birth: data.date_of_birth,
            place_of_birth: data.place_of_birth,
            gender: data.gender,
            religion: data.religion,
            complete_address: data.complete_address,
            employee_type: data.employee_type,
            join_date: data.join_date,
            end_date: data.end_date,
            is_active: "1",
            levelCode: data.levelCode,
            branchCode: data.branchCode,
            positionCode: data.positionCode,
            organizationCode: data.organizationCode,
            shiftCode: data.shiftCode,
            modified_by:user
    }
    return master.post("/employee-update", payload);
}

export const getEmployeeDetail = async (id) => {
    let payload = {
        employeeNumber: id,
    }
   // console.log(payload);
    return master.post("/employee-detail", payload);
}

export const getSupervisor = async (company, id, level_code, multi_company, company_list) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        employeeNumber : id,
        multi_company,
        company_list,
        level_code
    }
    return master.post("/supervisor-by-division", payload, {
        headers : {
            "Content-Type" : "application/json"    
          }
    });
}


export const deleteEmployee = async (id) => {
    const user = localStorage.getItem("Username");
    let payload = {
        employeeNumber: id,
        is_active: "0",
        modified_by: user
    }
    return master.post("/employee-delete", payload);
}

export const getEmployeeNumber = async (company,search) => {
     const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        search : search
    }
    return master.post("/employee-by-company", payload);
}

export const getEmployeeActive = async () => {
    return master.get("/employee-active");
}

export const getEmployeeShift = async (limit, offset, page,company,divisionCode, departmentCode, positionSearch) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    const branch = decryptData(sessionStorage.getItem("selectBranch"));
    const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"))
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        branchCode: branch || branch !== null ? branch  : branchDefault ,
        is_active : 1,
        start : offset,
        length : limit,
        draw : page
        // divisionCode: divisionCode ,
        // departmentCode: departmentCode ,
        // positionSearch: positionSearch 
    }
    //console.log(payload)
    return master.post("/employee", payload);
}

export const getHistoryEmployee = async (search, limit, offset, page,company,employeeNumber) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        employeeNumber : employeeNumber,
        filterColumn : "description",
        filter : search.length > 0 ? search : undefined,
        start : offset,
        length : limit,
        draw : page
    }
    return master.post("/history-employee", payload);
}
export const editExpiredEmployee = async (data) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        employee_number: data.employeeNumber,
        employee_type: data.employeeStatus,
        period: data.period,
        sign_date: data.signDate,
        modified_by:user
    }
    return master.post("/update-expired-employee", payload);
}
export const uploadBulkEditExpiredEmployee = async (data) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        file_update : data.doc_file,
        created_by :user
    }
    return master.post("/update-expired-employee-bulk", payload);
}

export const getImportEmployeeList = async (limit, offset, page, company) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    const branchSession = decryptData(sessionStorage.getItem("selectBranch"));
    const branchDefault = decryptData(localStorage.getItem("DefaultBranchCode"))
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        branchCode: branchSession ? branchSession : branchDefault,
        start : offset,
        length : limit,
        draw : page
    }
    return master.post("/employee-import-list", payload);
}

export const uploadImportEmployee = async (data) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        file: data.doc_file,
        company_id: data.company_id,
        created_by: user
    }
    return master.post("/employee-import", payload);
}

export const getListAllowance = async (data) => {
    return master.get(`/employee-payroll-component/${data}`);
}

export const getPAHistoryListData = async (employeeNumber, period) => {
    let payload = {
        employeeNumber,
        period,
    }
    return master.post("/pa/history", payload, {
        headers : {
            "Content-Type" : "application/json"    
          }
    });
}

export const getPAHistoryDetailAssessment = async (employeeNumber, request_id) => {
    let payload = {
        employeeNumber,
        request_id,
    }
    return master.post("/pa/detail-assessment", payload, {
        headers : {
            "Content-Type" : "application/json"    
          }
    });
}

export const getPAHistoryDetailProcess = async (request_id) => {
    let payload = {
        request_id,
    }
    return master.post("/pa/detail-process", payload, {
        headers : {
            "Content-Type" : "application/json"    
          }
    });
}

export const setEmployeeInactive = async (data, employee_id) => {
    const user = decryptData(localStorage.getItem("Username"));
    const {
        in_active_reason,
        in_active_description,
        in_active_endate,
        in_active_backlist,
    } = data
    let payload = {
        employee_id,
        in_active_reason,
        in_active_description,
        in_active_endate,
        in_active_backlist,
        modified_by: user
    }
    return master.post("/employee-set-inactive", payload, {
        headers : {
            "Content-Type" : "application/json"    
          }
    });
}

export const setEmployeeActive = async (employee_id) => {
    const user = decryptData(localStorage.getItem("Username"));

    let payload = {
        employee_id,
        modified_by: user
    }
    return master.post("/employee-set-active", payload, {
        headers : {
            "Content-Type" : "application/json"    
          }
    });
}