import { DatePicker, Form as FormAntd } from "antd";
import dayjs from "dayjs";
import "./styles.scss";
import {
  CheckboxOptionWithLabel,
  RangeDatePickerWithLabel,
} from "../../Form/Input";
import { Info, UserIcon } from "../../../assets/icons";
import { useEffect, useState } from "react";

const SetUpPeriodRunPayroll = (props) => {
  const {
    setIsModalOpen,
    form,
    setSelectedDateRange,
    totalData,
    setIsSelectedEmployee,
    loading,
  } = props;

  const [isChecked, setIsChecked] = useState(false);
  const handleClick = () => {
    if (form?.values?.period !== "" && form?.values?.all_employee !== 1) {
      setIsChecked(!isChecked);
      setIsModalOpen(!isChecked);
    }
  };
  useEffect(() => {
    if (totalData?.cut_off_type === "weekly" && form.values.period) {
      const startDate = form.values.start_date
        ? dayjs(form.values.start_date)
        : dayjs(form.values.period, "DD-MM-YYYY").subtract(6, "day");
      const endDate = form.values.end_date
        ? dayjs(form.values.end_date)
        : dayjs(form.values.period, "DD-MM-YYYY");

      form.setFieldValue("start_date", startDate.format("YYYY-MM-DD"));
      form.setFieldValue("end_date", endDate.format("YYYY-MM-DD"));
    }
  }, [
    form.values.start_date,
    form.values.end_date,
    form.values.period,
    totalData?.cut_off_type,
  ]);

  return (
    <>
      <div>
        <p className="font-semibold text-xl text-[#505050] mb-[10px]">
          Setup period
        </p>
        <div className="text-sm text-[#505050] leading-[17px]">
          You can choose the period for run payroll
        </div>
      </div>
      <div className="py-5">
        <FormAntd onFinish={form.handleSubmit}>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="text-[#8697a8] text-base">
                Select Period{" "}
                <span className="text-red-500 label-input">*</span>
              </div>
              <DatePicker
                name="period"
                format={
                  totalData?.cut_off_type === "weekly" ? "DD MMMM YYYY" : "MMMM"
                }
                placeholder="Select Period"
                allowClear
                picker={totalData?.cut_off_type === "weekly" ? "date" : "month"}
                value={
                  totalData?.cut_off_type === "weekly"
                    ? form.values.period
                      ? dayjs(form.values.period, "DD-MM-YYYY")
                      : null
                    : form.values.period
                    ? dayjs(form.values.period, "MMMM")
                    : null
                }
                onChange={(date) => {
                  if (date && dayjs(date).isValid()) {
                    const formattedDate =
                      totalData?.cut_off_type === "weekly"
                        ? dayjs(date).format("DD-MM-YYYY")
                        : dayjs(date).format("MMMM");

                    form.setFieldValue("period", formattedDate);
                  } else if (date === null) {
                    form.setFieldValue("period", "");
                    form.setFieldValue("start_date", null);
                    form.setFieldValue("end_date", null);
                    setSelectedDateRange(null);
                  }
                }}
                disabled={loading}
                className="w-full h-10 bg-[#F8FAFB] border-none mt-2 customDataPicker"
              />

              {form.touched.period && form.errors.period && (
                <div className="relative">
                  <span className="text-error absolute">
                    {form.errors.period}
                  </span>
                </div>
              )}
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="text-[#8697a8] text-base">
                {form.values.period === "05-2024"
                  ? "Cut Off Period"
                  : form.values.period
                  ? "Select Cut Off Period"
                  : "Cut Off Period"}{" "}
                <span className="text-red-500 label-input">*</span>
              </div>

              <RangeDatePickerWithLabel
                name="select_cut_off"
                placeholder="Cut Off Period"
                format="DD MMM"
                onChange={(dates) => {
                  if (dates && dates.length === 2) {
                    form.setFieldValue(
                      "start_date",
                      dayjs(dates[0]).format("YYYY-MM-DD")
                    );
                    form.setFieldValue(
                      "end_date",
                      dayjs(dates[1]).format("YYYY-MM-DD")
                    );
                    setSelectedDateRange(dates);
                  } else if (!dates || dates.length === 0) {
                    form.setFieldValue("start_date", null);
                    form.setFieldValue("end_date", null);
                    setSelectedDateRange(null);
                  }
                }}
                value={
                  totalData?.cut_off_type === "weekly" && form.values.period
                    ? [
                        form.values.start_date
                          ? dayjs(form.values.start_date)
                          : dayjs(form.values.period, "DD-MM-YYYY").subtract(
                              6,
                              "day"
                            ),
                        form.values.end_date
                          ? dayjs(form.values.end_date)
                          : dayjs(form.values.period, "DD-MM-YYYY"),
                      ]
                    : totalData?.cut_off_type === "monthly" &&
                      form.values.period
                    ? [
                        dayjs()
                          .month(dayjs(form.values.period, "MMMM").month() - 1)
                          .date(totalData?.cut_off_date + 1),
                        dayjs()
                          .month(dayjs(form.values.period, "MMMM").month())
                          .date(totalData?.cut_off_date),
                      ]
                    : [
                        form.values.start_date
                          ? dayjs(form.values.start_date)
                          : null,
                        form.values.end_date
                          ? dayjs(form.values.end_date)
                          : null,
                      ] || []
                }
                allowClear={true}
                disabled={
                  totalData?.cut_off_type === "monthly" && form.values.period
                    ? true
                    : false || loading
                }
                disabledDate={(current) => {
                  const selectedDate = dayjs(form.values.period, "DD-MM-YYYY");
                  const startDateRange = selectedDate.subtract(6, "day");
                  const endDateRange = selectedDate;
                  return current < startDateRange || current > endDateRange;
                }}
              />
            </div>

            {form.touched.start_date && form.errors.start_date && (
              <div className="relative">
                <span className="text-error absolute">
                  {totalData?.cut_off_type === "monthly"
                    ? form.errors.period
                    : form.errors.start_date}
                </span>
              </div>
            )}
          </FormAntd.Item>

          <FormAntd.Item>
            <div className="">
              <div className="flex flex-col gap-2">
                <div className="font-semibold text-xl text-[#505050]">
                  Choose employees
                </div>
                <div className="text-sm text-[#505050]">
                  Which employees will be processed in this payroll run?{" "}
                  <span className="text-red-600">*</span>
                </div>
              </div>
              <div className="flex flex-row gap-4 items-center">
                <div
                  className={`mt-2 flex items-center gap-2 section-button py-2 px-2 ${
                    form?.values?.period === "" ||
                    form?.values?.all_employee === 1 ||
                    loading
                      ? "cursor-not-allowed bg-[#F5F5F5]"
                      : "cursor-pointer"
                  } hover:bg-[#F0F0F0] rounded`}
                  onClick={
                    form?.values?.period === "" ||
                    form?.values?.all_employee === 1 ||
                    loading
                      ? null
                      : handleClick
                  }>
                  <UserIcon />
                  <div className="mt-[-3px]">{"Select employee"}</div>
                </div>
                <div className="flex flex-row gap-2 items-center">
                  <CheckboxOptionWithLabel
                    name="all_employee"
                    checked={
                      form.values.select_employees?.length > 0
                        ? false
                        : form?.values?.all_employee
                    }
                    value={
                      form.values.select_employees
                        ? false
                        : form?.values?.all_employee || false
                    }
                    onChange={(e) => {
                      const isChecked = e.target.checked;
                      form.setFieldValue("all_employee", isChecked ? 1 : "");
                      setIsSelectedEmployee(null);
                      sessionStorage.removeItem("selectedEmployees");
                    }}
                    disabled={loading}
                  />

                  <div className="text-md mt-2">Select all employees</div>
                </div>
              </div>
              {form.touched.period &&
                !form?.values?.all_employee &&
                (!form.values.select_employees ||
                  form.values.select_employees?.length === 0) && (
                  <div className="relative">
                    <span className="text-error absolute">
                      {"Field is required"}
                    </span>
                  </div>
                )}

              {form.values.select_employees?.length > 0 && (
                <div className="flex gap-2 section-notif py-2 rounded border-[#B2B2B2] px-3 mt-4 items-center">
                  <div className="mt-1">
                    <Info />
                  </div>
                  <div className="text-[15px] text-[#4D4D4D]">
                    {form.values.select_employees.length} employee has been
                    selected and will be included in this payroll.
                  </div>
                </div>
              )}
            </div>
          </FormAntd.Item>
        </FormAntd>
      </div>
    </>
  );
};

export default SetUpPeriodRunPayroll;
