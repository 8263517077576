import { Button, Card } from "antd";
import React, { useEffect, useRef, useState, memo } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { Tree, TreeNode } from "react-organizational-chart";
import "../styles.scss";
import DownloadPage from "../../../helper/downloadPage";
import { getOrChart } from "../../../api/position";
import { decryptData } from "../../../helper/cryptojs";
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  Controls,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import Loader from "../../../component/common/Loader";
import CardOrganization from "../../../component/Organisms/CardOrganization"
import DownloadButton from "../../../component/Organisms/CardOrganization/DownloadButton";
const OrganizationStructure = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const company = decryptData(sessionStorage.getItem("selectCompanyName"))
    ? decryptData(sessionStorage.getItem("selectCompanyName"))
    : decryptData(localStorage.getItem("DefaultCompanyName"));
  const companyCode = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const branch = decryptData(sessionStorage.getItem("selectBranch"))
    ? decryptData(sessionStorage.getItem("selectBranch"))
    : decryptData(localStorage.getItem("DefaultBranchCode"));

  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const nodeTypes = {
    selectorNode: CardOrganization,
  };

  function buildHierarchyFromChildren(data) {
    const nodes = [];
    const links = [];
    const nodeMap = {};
    const positions = {};
    const linkIds = new Set();
    const levelNodes = {};
    const levelRightmostNodes = {};
    let mainTreeRightmostX = 0;   // Keep track of the rightmost X of the main tree
    let separatedTreeMargin = 1000;         // Track the maximum width of the main tree

    const baseLevelSpacing = 150; // Base vertical distance between levels

    // Helper function to calculate horizontal spacing dynamically based on level
    function calculateSiblingSpacing(level) {
        const baseSiblingSpacing = 1000;
        const reductionFactor = 0.7; // Reduced spacing quicker for deeper levels to tighten the triangle
        return baseSiblingSpacing * Math.pow(reductionFactor, level);
    }

    // Helper function to calculate vertical spacing based on the parent's full_name length
    function calculateVerticalSpacing(fullName) {
        const lengthFactor = 0.25; // Adjust this value to control how much space longer names add
        return baseLevelSpacing + (fullName.length * lengthFactor);
    }

    // Helper function to add a node
    function addNode(node, x, y, level) {
        const nodeId = `${node.position_id}`;
        if (!nodeMap[nodeId]) {
            positions[nodeId] = { x, y };
            nodes.push({
                id: nodeId,
                position: { x, y },
                type: 'selectorNode',
                selectable: false,
                data: { ...node, label: node.position_name || 'No Label' }
            });
            nodeMap[nodeId] = true;
            if (!levelNodes[level]) {
                levelNodes[level] = [];
            }
            levelNodes[level].push(node);

            // Track the rightmost X position of the main tree
            if (x > mainTreeRightmostX && node.company_code === data[0].company_code) {
                mainTreeRightmostX = x;
            }
        }
    }

    // Helper function to add a link (always creating a link now)
    function addLink(sourceId, targetId) {
        const linkId = `${sourceId}-${targetId}`;
        if (!linkIds.has(linkId)) {
            links.push({
                source: sourceId,
                target: targetId,
                id: linkId,
                type: 'smoothstep',
                style: {
                    strokeWidth: '3px',
                    stroke: '#44566c'
                },
                selectable: false,
            });
            linkIds.add(linkId);
        }
    }

    // Recursive function to traverse the tree and calculate positions
    function traverse(node, x, y, level = 0) {
        try {
            // Add the node
            addNode(node, x, y, level);

            // If the node has children, position them symmetrically
            if (node.children && Array.isArray(node.children) && node.children.length > 0) {
                const siblingSpacing = calculateSiblingSpacing(level); // Calculate spacing based on depth
                const sameCompanyChildren = node.children.filter(child => child.company_code === node.company_code);
                const differentCompanyChildren = node.children.filter(child => child.company_code !== node.company_code);

                const totalWidth = (sameCompanyChildren.length - 1) * siblingSpacing;

                // Process same-company children first
                sameCompanyChildren.forEach((child, index) => {
                    let childX = x - totalWidth / 2 + index * siblingSpacing; // Center the children horizontally
                    let childY = y + calculateVerticalSpacing(node.full_name); // Calculate Y position for the child

                    // Add a link from the current node to the child (always creating a link now)
                    if(node.company_code === companyCode){
                      addLink(`${node.position_id}`, `${child.position_id}`);
                    }

                    // Recursively traverse the child
                    traverse(child, childX, childY, level + 1);
                });

                // Now process different-company children and move them to the rightmost side
                if (differentCompanyChildren.length > 0) {
                    let separatedTreeX;

                    if (!levelRightmostNodes[level]) {
                        levelRightmostNodes[level] = mainTreeRightmostX + separatedTreeMargin; // Start the separated tree to the right of the main tree
                    }

                    separatedTreeX = levelRightmostNodes[level];
                    let totalChildrenWidth = (differentCompanyChildren.length - 1) * calculateSiblingSpacing(level);
                    let startX = separatedTreeX - totalChildrenWidth / 2; // Center the separated tree children

                    differentCompanyChildren.forEach((child, index) => {
                        let childX = startX + index * calculateSiblingSpacing(level); // Distribute children symmetrically
                        let childY = y + calculateVerticalSpacing(node.full_name); // Calculate Y position for the child

                        if (differentCompanyChildren.length === 1) {
                            // If only one child, position directly below the parent
                            childX = separatedTreeX;
                        }

                        // Add a link from the current node to the child (always creating a link now)
                        if(child.company_code === companyCode){
                          addLink(`${node.position_id}`, `${child.position_id}`);
                        }

                        // Recursively traverse the child
                        traverse(child, childX, childY, level + 1);
                    });

                    levelRightmostNodes[level] += calculateSiblingSpacing(level); // Ensure spacing for multiple rightmost nodes
                }
            }
        } catch (error) {
            console.error('Error processing node:', node, error);
        }
    }

    // Start traversal from the root
    if (data && Array.isArray(data) && data.length > 0) {
        // Use a queue to manage large datasets
        const queue = [{ node: data[0], x: 0, y: 0, level: 0 }];
        while (queue.length > 0) {
            const { node, x, y, level } = queue.shift();
            traverse(node, x, y, level);

            // Enqueue children for processing
            if (node.children && Array.isArray(node.children)) {
                node.children.forEach((child) => {
                    let childX;
                    if (child.company_code === node.company_code) {
                        // Position child normally if in the same company
                        const sameCompanyChildren = node.children.filter(c => c.company_code === node.company_code);
                        const adjustedIndex = sameCompanyChildren.indexOf(child);
                        childX = x - (sameCompanyChildren.length - 1) * calculateSiblingSpacing(level) / 2 + adjustedIndex * calculateSiblingSpacing(level);
                    } else {
                        // Move child to the rightmost side if company_code is different
                        if (!levelRightmostNodes[level]) {
                            levelRightmostNodes[level] = mainTreeRightmostX + separatedTreeMargin; // Start the separated tree to the right of the main tree
                        }
                        childX = levelRightmostNodes[level];
                        levelRightmostNodes[level] += calculateSiblingSpacing(level); // Ensure spacing for multiple rightmost nodes
                    }

                    queue.push({
                        node: child,
                        x: childX,
                        y: y + calculateVerticalSpacing(node.full_name), // Calculate y based on parent's full_name
                        level: level + 1
                    });
                });
            }
        }

        // Adjust positions to spread nodes at the same level evenly and symmetrically
        Object.keys(levelNodes).forEach(level => {
            const nodesAtLevel = levelNodes[level];
            const numberOfNodes = nodesAtLevel.length;
            if (numberOfNodes > 0) {
                const siblingSpacing = calculateSiblingSpacing(level);
                const levelWidth = (numberOfNodes - 1) * siblingSpacing;

                nodesAtLevel.forEach((node, index) => {
                    const nodeId = `${node.position_id}`;
                    // Center nodes horizontally based on the number of nodes
                    const x = (index * siblingSpacing) - (levelWidth / 2);
                    positions[nodeId].x = x;
                });
            }
        });
    } else {
        console.warn('No valid data provided for hierarchy.');
    }

    return { nodes, links };
}


  const populateDiagram = (nodes = [], links = []) => {
    setNodes(nodes);
    setEdges(links);
  };
  const getOrChartList = async () => {
    setLoading(true);
    try {
      const res = await getOrChart(companyCode);
      if (res?.data != null && res?.data.length > 0) {
        const d = orgChartFunc(res?.data)
        const result = buildHierarchyFromChildren(d);
        const { nodes, links } = result;
        populateDiagram(nodes, links);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getOrChartList();
  }, [companyCode]);

  const dataBreadcrumb = [
    {
      title: "Master Data - Position",
      url: "/position",
    },
    {
      title: "Organization",
      url: "#",
    },
  ];

  const RoleAccessPositionDownload = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgePositionDownload")
  );

  const orgChartFunc = (arrayEmployee)=>{
        orgChart.build_tree(arrayEmployee, "employee_id", "parent_id");
        return [orgChart.tree.children[0]];
    }
    var orgChart = {
        // The Tree
        "tree": {
            "children": []
        },

        // Main function to build the tree
        "build_tree": function (ary, id_field, parent_field) {
            this.tree[id_field] = "root";
            this._build(ary, id_field, parent_field);
            return true;
        },

        // Function to push children in arrays
        "_build": function (ary, id_field, parent_field) {
            var x,
                i;

            this.map = {};

            // Step 1. Create a "hash map" of all objects
            for (i = 0; i < ary.length; i++) {
                this.map[ary[i][id_field]] = ary[i];
            }

            // Step 2. Iterate over the "hash map"
            for (x in this.map) {
                // 2a. Does the current object have a parent on the map?
                if (this.map[this.map[x][parent_field]]) {
                    // This object has a parent. Hop in their kiddie pool (children array)!
                    if (this.map[this.map[x][parent_field]].children) {
                        this.map[this.map[x][parent_field]].children.push(this.map[x]);
                    } else {
                        this.map[this.map[x][parent_field]].children = [this.map[x]];
                    }
                } else {
                    // Object has no parent. It's a Node. Add to root's children.
                    this.tree.children.push(this.map[x]);
                }
            }
        },

        // Find a node by id in the map, and apply a function to it,
        // and all of its ancestors
        "_back_trace": function (id, parent_field, fx) {
            if (this.map[this.map[id][parent_field]]) {
                // Apply the function
                fx(this.map[id]);
                this._back_trace(this.map[id][parent_field], parent_field, fx);
            }
        }
    };

  return (
    <>
      <div className="title-section">
        <Button
          className="btn-sh-p"
          type="primary"
          shape="circle"
          style={{ marginRight: "14px" }}
          onClick={() => navigate("/position")}
          icon={<ArrowLeftOutlined />}
          size={"large"}
        />
        Organization Structure {company}
      </div>
      <div style={{ marginBottom: "24px" }}>
        <Breadcrumb items={dataBreadcrumb} />
      </div>
      <div className="detail-wrapper">
          {loading ? (
            <Loader />
          ) : (
            <div
              id="pagedownload"
              style={{
                padding: "24px",
                height: '80vh',
              }}
            >
                <ReactFlow
                    nodes={nodes}
                    edges={edges}
                    onNodesChange={null}
                    onEdgesChange={null}
                    // fitView
                    nodeTypes={nodeTypes}
                    connectionLineStyle={{ stroke: '#000000' }}
                    attributionPosition="bottom-left"
                >
                    <Controls />
                    {RoleAccessPositionDownload && (
                      <DownloadButton />  
                    )}
                </ReactFlow>
            </div>
           )} 
      </div>
    </>
  );
};

export default OrganizationStructure;
