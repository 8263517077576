import { Button, Col, Form as FormAntd, Radio, Row, message, Input as InputAntd, Tooltip, Space, Spin } from "antd";
import {PercentageOutlined } from '@ant-design/icons';
import React, { useEffect, useRef, useState } from "react";
import  { InputWithLabel, SelectOptionWithLabel, SelectOptionWithLabelAppliesAllvalue, InputFormula } from "../../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { decryptData } from "../../../helper/cryptojs";
import Breadcrumb from "../../../component/Breadcrumb";
import { addPayrollComponent, editPayrollComponent, getBaseCalc, getComponentCode, getListLevel, getListPosition, getPayrollComponentMasterDetail, getWagesSetting } from "../../../api/payrollComponent";

const FormAddMasterComponent = () => {
    const navigate = useNavigate();
    const companyCode = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
    const companyId = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompanyId"))
    : decryptData(localStorage.getItem("DefaultCompanyId"));

    const [fetching, setFetching] = useState(false);
    const location = window.location.href;
    var n = location.split("/");
    var result =  n[5];
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [detail, setDetail] = useState([]);
    const [flag, setFlag] = useState(0);
    const [optionLevel, setOptionLevel] = useState([])
    const [optionPosition, setOptionPosition] = useState([])

    const [percentMode, setPercentMode] = useState(false);
    const [baseCalc, setBaseCalc] = useState([])
    const [isDeduction, setIsDeduction] = useState(false)
    const [isFormula, setIsFormula] = useState(false)
    const [isCustom, setIsCustom] = useState(false)
    const [isValid, setIsValid] = useState(true)

	const options = [
        {
          label: '+',
          value: '+',
        },
        {
          label: '-',
          value: '-',
        },
        {
          label: '*',
          value: '*',
        },
        {
            label: '/',
            value: '/',
          },
      ];

    const checkWagesAvail = async () => {
        try {
            const populate = await getWagesSetting(companyId)
            const {data} = populate?.data
            if(data.length !== 0){
                return true
            }else{
                return false
            }
        } catch (err) {
            console.log(err);
		} finally {
            // setFetching(false);
		}
    }
      

    const MasterComponent = async () => {
		setFetching(true);
		try {
        //
        const resDetail = await getPayrollComponentMasterDetail(id)
        const {data} = resDetail?.data
        data?.applies_to === 'Custom' ? setIsCustom(true) : setIsCustom(false)
        data?.type === 'Deduction' ? setIsDeduction(true) : setIsDeduction(false)
        data?.type === 'Deduction'&& data?.formula ? setIsFormula(true) : setIsFormula(false)
        data?.type === 'Deduction' && data?.is_ratio_percentage ? setPercentMode(true) : setPercentMode(false)
        //
        const validity = await checkWagesAvail()
        const resBasecal = await getBaseCalc(companyId , validity ? 1: 0)
        const resPosition = await getListPosition(companyCode)
        const resLevel = await getListLevel(companyCode)
        
        setDetail(data)
        setIsValid(validity)
        setBaseCalc(resBasecal?.data?.data)
        setOptionLevel(resLevel?.data?.data)
        setOptionPosition(resPosition?.data?.data)

		} catch (err) {
		  console.log(err);
		} finally {
        setFetching(false);
		}
	  };

    const getCompCode = async (val) => {
        if(result && val === detail?.type) {
            form.setValues({
                code: detail?.code,
                type: val,
                name: null,
                taxable: null,
                applies_to: null,
                custom_level_ids: [],
                custom_position_ids: [],
                formula: null,
                ratio: null,
                operation: null,
                base_calculation_id: null,
            })
        }else{
            const params = {
                type: val,
                company_id: companyId
            }
            const res = await getComponentCode(params)
            form.setValues({
                code: res?.data?.data?.code,
                type: val,
                name: null,
                taxable: null,
                applies_to: null,
                custom_level_ids: [],
                custom_position_ids: [],
                formula: null,
                ratio: null,
                operation: null,
                base_calculation_id: null,
            })
        }
    }
    

	useEffect(() => {
        MasterComponent();
	}, []);

    const validationForm = yup.object().shape({
        code: yup.string().required("Field is required"),
        type: yup.string().required("Field is required"),
        name: yup.string().required("Field is required"),
        taxable: yup.string().required("Field is required"),
        applies_to: yup.string().required("Field is required"),
        formula: isDeduction ? yup.string().required('Field is required') : '',
        ratio: isFormula ? yup.string().required("Field is required") : '',
        base_calculation_id: isFormula ? yup.string().required("Field is required") : '',
    })

    const form = useFormik({
        initialValues: {
            code: result  ? detail.code : '',
            type: result ? detail?.type : null,
            name: result ? detail.name : "",
            taxable: result ? (detail.taxable ? 'yes' : 'no') : null,
            applies_to: result ? detail.applies_to : null,
            custom_level_ids: result ? detail?.levels?.map(e => e.level_id) : null,
            custom_position_ids: result ? detail?.positions?.map(e => e.position_id) : null,
            formula: result ? (detail.formula ? 'yes' : 'no') : null,
            ratio: result ? detail.ratio : '2.50',
            operation: result ? detail.operation : '+',
            base_calculation_id: result && isValid ? detail.base_calculation_id : null,
        },
        enableReinitialize: true,
        validationSchema: validationForm,
        onSubmit: async(values) => {
            setFetching(true)
            let manualStop = false
            const user = decryptData(localStorage.getItem("Username"));
            let payload = {
                company_id: companyId,
                type: values.type,
                code: values.code,
                name: values.name,
                taxable: values.taxable,
                applies_to: values.applies_to || "All Employees",
                formula: values.formula || 'no',
            }
            if(values.formula === 'yes'){
                payload.ratio = parseFloat(values.ratio).toFixed(2)
                payload.is_ratio_percentage = percentMode ? 'yes' : 'no'
                payload.operation = values.operation || '+'
                payload.base_calculation_id = values.base_calculation_id
            }else{
                payload.formula = 'no'
            }
            if(values.applies_to === 'Custom'){
                if(values.custom_level_ids.length === 0 && values.custom_position_ids.length === 0){
                    manualStop = true
                }
                if(values.custom_level_ids){
                    payload.custom_level_ids = values.custom_level_ids
                }
                if(values.custom_position_ids){
                    payload.custom_position_ids = values.custom_position_ids
                }
            }
            if(!manualStop){
                try {
                    result ? payload.modified_by = user : payload.created_by = user
                    const res = result ? await editPayrollComponent(payload, id) : await addPayrollComponent(payload);
                    if(!res?.data?.success){
                        message.error({
                            content: res?.data?.error,
                        });
                    } else {
                        message.success({
                            content: result ? "Edit Data Success" : "Add Data Success",
                        });
                        navigate("/payroll-component")
                        form.resetForm();
                        setFlag((prev) => prev + 1)
                        // window.location.reload()
    
                    }
                    setFetching(false)
                } catch (error) {
                    message.error({
                        content: error.response.data.error,
                    });
                    setFetching(false)
                }
            }else{
                setFetching(false)
            }
        },
    });
    

    const dataBreadcrumb = [
		{
			title: "Payroll Component - Master Component",
			url: "/payroll-component"
		},
		{
			title: `${result ?  'Edit Payroll Component' : 'Add Payroll Component'}` ,
			url: `${ result ?  `/payroll-component/edit-master-component/${result}` : '/payroll-component/add-master-component'}`
		},
	]

    return (
       <>
        <div className="title-section">
                {result ? "Edit Payroll Component" : "Add Payroll Component"}
        </div>
        <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
                <Col span={24}>
                    <Breadcrumb items={dataBreadcrumb}/>
                </Col>
            </div>
        <div className="add-wrapper">
            {fetching ?
                <Row justify="center" align="center">
                    <Spin />
                </Row>
                :
                <>
                <FormAntd
                    onFinish={form.handleSubmit}
                >   
                    <FormAntd.Item>
                        <SelectOptionWithLabel
                            name="type"
                            label={<div>Type<span style={{color:"#FF0000"}}> *</span></div>}
                            items={[
                                {
                                    value: 'Allowance',
                                    title: "Allowance"
                                },
                                {
                                    value: 'Deduction',
                                    title: "Deduction"
                                },
                                {
                                    value: 'Basic Salary',
                                    title: "Basic Salary"
                                },
                                {
                                    value: 'Benefit',
                                    title: "Benefit"
                                },
                                {
                                    value: 'Additional Earning',
                                    title: "Additional Earning"
                                },
                            ]}
                            value={form?.values?.type}
                            onChange={(val) =>{
                                if(val === 'Deduction'){
                                    setIsDeduction(true)
                                }else{
                                    setIsDeduction(false)
                                }
                            getCompCode(val)
                            }}
                            onBlur={form.handleBlur}
                            />
                            <div style={{marginTop: '1rem'}}>
                            {form.touched.type && form.errors.type ? (
                                <span className="text-error">{form.errors.type}</span>
                            ) : null}
                            </div>
                    </FormAntd.Item>
                    <FormAntd.Item>
                        <InputWithLabel
                            name="code"
                            label={<div>Component Code</div>}
                            placeholder="Component Code"
                            value={form?.values?.code}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                            disabled
                        />
                        {form.touched.code && form.errors.code ? (
                            <span className="text-error">{form.errors.code}</span>
                        ) : null}
                    </FormAntd.Item>
                    <FormAntd.Item>
                        <InputWithLabel
                            name="name"
                            label={<div>Component Name<span style={{color:"#FF0000"}}> *</span></div>}
                            placeholder="Component Name"
                            value={form?.values?.name}
                            onChange={form.handleChange}
                            onBlur={form.handleBlur}
                        />
                        {form.touched.name && form.errors.name ? (
                            <span className="text-error">{form.errors.name}</span>
                        ) : null}
                    </FormAntd.Item>
                    <FormAntd.Item>
                        <SelectOptionWithLabel
                            name="taxable"
                            label={<div>Taxable<span style={{color:"#FF0000"}}> *</span></div>}
                            items={[
                                {
                                    value: 'yes',
                                    title: "Yes"
                                },
                                {
                                    value: 'no',
                                    title: "No"
                                },
                            ]}
                            value={form?.values?.taxable}
                            onChange={(val) =>
                                form.setValues({
                                ...form.values,
                                taxable: val,
                                })
                            }
                            onBlur={form.handleBlur}
                            />
                            <div style={{marginTop: '1rem'}}>
                            {form.touched.taxable && form.errors.taxable ? (
                                <span className="text-error">{form.errors.taxable}</span>
                            ) : null}
                            </div>
                    </FormAntd.Item>
                    <FormAntd.Item>
                        <SelectOptionWithLabel
                            name="applies_to"
                            label={<div>Applies To<span style={{color:"#FF0000"}}> *</span></div>}
                            items={[
                                {
                                    value: "All Employees",
                                    title: "All Employees"
                                },
                                {
                                    value: 'Custom',
                                    title: "Custom"
                                },
                            ]}
                            value={form?.values?.applies_to}
                            onChange={(val) =>{
                                if(val === 'All Employees'){
                                    setIsCustom(false)
                                    form.setValues({
                                    ...form.values,
                                    applies_to: val,
                                    custom_level_ids: null,
                                    custom_position_ids: null,
                                    })
                                } else if (val === 'Custom'){
                                    setIsCustom(true)
                                    form.setValues({
                                        ...form.values,
                                        applies_to: val,
                                        custom_level_ids: [],
                                        custom_position_ids: [],
                                        })
                                }
                            }}
                            onBlur={form.handleBlur}
                            />
                            <div style={{marginTop: '1rem'}}>
                            {form.touched.applies_to && form.errors.applies_to ? (
                                <span className="text-error">{form.errors.applies_to}</span>
                            ) : null}
                            </div>
                    </FormAntd.Item>
                    { isCustom && (
                    <FormAntd.Item>
                        <div className="flex flex-row gap-10">
                        <Col span={11}>
                        <FormAntd.Item>
                            <SelectOptionWithLabelAppliesAllvalue
                                className='w-auto h-auto' 
                                name="custom_level_ids"
                                mode="multiple"
                                fieldNames={{label: 'name', value: 'level_id'}}
                                options={optionLevel}
                                value={form?.values?.custom_level_ids}
                                onChange={(val) => {
                                    form.setValues({
                                        ...form.values,
                                        custom_level_ids: val,
                                    });
                                }}
                                placeholder="Level"
                                onBlur={form.handleBlur}
                            />
                            <div style={{marginTop: '1rem'}}>
                            {form?.values?.applies_to === 'Custom' && form?.values?.custom_level_ids.length <1 && form?.values?.custom_position_ids.length <1 ? (
                                <span className="text-error">Must at least select 1 Level or 1 Position</span>
                            ) : null}
                            </div>
                            </FormAntd.Item>
                        </Col>
                        <Col span={11}>
                            <FormAntd.Item>
                            <SelectOptionWithLabelAppliesAllvalue
                                className='w-auto h-auto' 
                                name="custom_position_ids"
                                mode="multiple"
                                options={optionPosition}
                                fieldNames={{label: 'name', value: 'position_id'}}
                                value={form?.values?.custom_position_ids}
                                onChange={(val) => {
                                    form.setValues({
                                        ...form.values,
                                        custom_position_ids: val,
                                    });
                                }}
                                placeholder="Position"
                                onBlur={form.handleBlur}
                            />
                            <div style={{marginTop: '1rem'}}>
                            {form?.values?.applies_to === 'Custom' && form?.values?.custom_level_ids.length <1 && form?.values?.custom_position_ids.length <1 ? (
                                <span className="text-error">Must at least select 1 Level or 1 Position</span>
                            ) : null}
                            </div>
                            </FormAntd.Item>
                            </Col>
                        </div>
                    </FormAntd.Item>
                    )}
                    {isDeduction ?
                    <FormAntd.Item>
                        <div className="label-input">Formula<span style={{color:"#FF0000"}}> *</span></div>
                        <Radio.Group 
                        name="formula"
                        onChange={({target: {value}})=>{
                            if(value === 'yes'){
                                setIsFormula(true)
                                form.setValues(
                                    {...form.values,
                                    formula: value}
                                )
                            }else{
                                setIsFormula(false)
                                setPercentMode(false)
                                form.setValues(
                                    {...form.values,
                                    formula: value,
                                    ratio: null,
                                    operation: null,
                                    base_calculation_id: null
                                })
                            }
                            }}
                        value={form?.values?.formula}>
                            <Radio value={'yes'}>Yes</Radio>
                            <Radio value={'no'}>No</Radio>
                        </Radio.Group>
                        <div style={{marginTop: '1rem'}}>
                            {form.touched.formula && form.errors.formula ? (
                                <span className="text-error">{form.errors.formula}</span>
                            ) : null}
                        </div>
                        {isFormula ?
                            <div style={{marginTop: '1rem'}}>
                                <Row 
                                gutter={{ xs: 8, sm: 16, md: 24, lg: 4, xl: 12, xxl: 6}}
                                >
                                    <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={2}>
                                        <div className="input-wrapper" style={{marginTop: '1rem'}}>
                                        <Space.Compact>
                                            <InputAntd 
                                                name={"ratio"}
                                                placeholder={"Number input"}
                                                value={form?.values?.ratio}
                                                onChange={(e) => {
                                                    const numInput = e.target.value;
                                                    const re = /^\d*\.?\d*$/;
                                                    if (numInput === '' || re.test(numInput)) {
                                                        form.handleChange(e)
                                                    }
                                                }}
                                                onBlur={form.handleBlur} 
                                            />
                                            {percentMode ?
                                            <Button 
                                                type="primary" 
                                                className="percent-btn"
                                                onClick={()=>setPercentMode(!percentMode)}>
                                                    <PercentageOutlined style={{
                                                        color: '#FFFFFF', 
                                                        fontSize: '24px'
                                                        }}/>
                                            </Button> 
                                            : 
                                            <Tooltip title="click me to change into percentile" color="#FFFFFF">
                                                <Button 
                                                    type="default"
                                                    className="percent-btn"
                                                    onClick={()=>setPercentMode(!percentMode)}>
                                                        <PercentageOutlined style={{
                                                            color: '#0081FF', 
                                                            fontSize: '24px'
                                                        }}/>
                                                </ Button>
                                            </Tooltip>}
                                        </Space.Compact>
                                        <div style={{marginTop: '1rem'}}>
                                        {form.touched.ratio && form.errors.ratio ? (
                                            <span className="text-error">{form.errors.ratio}</span>
                                        ) : null}
                                        </div>
                                        </div>
                                    </Col>
                                    <InputFormula 
                                    radioProps={{
                                        size:"large",
                                        buttonStyle:"solid",
                                        optionType:"button",
                                        options: options,
                                        name: 'operation',
                                        value: form?.values?.operation || '+',
                                        onChange: ({target: {value}})=>{
                                            form.setValues({
                                            ...form.values,
                                            operation: value,
                                            })}
                                    }}

                                    selectProps={{
                                        name: "base_calculation_id",
                                        loading: fetching,
                                        options: baseCalc,
                                        fieldNames: {label: 'name', value: 'id'},
                                        value: form?.values?.base_calculation_id,
                                        onSelect: (val) =>
                                            form.setValues({
                                            ...form.values,
                                            base_calculation_id: val,
                                            }),
                                        onBlur: form.handleBlur
                                    }}
                                    errorFlag={form.touched.base_calculation_id && form.errors.base_calculation_id ? true : false}
                                    errorMes={form.errors.base_calculation_id}
                                    />
                                </Row>
                            </div>
                            :
                            null
                        }
                        
                    </FormAntd.Item>
                    :
                        null
                    }
                </FormAntd>
                <div className="footer-add">
                    <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/payroll-component')}>
                        Cancel
                    </Button>
                    <Button key="submit" type="primary" className="btn btn-primary btn-sh-p"  onClick={form.handleSubmit} disabled={form.isSubmitting}>
                        Submit
                    </Button>
                </div>
                </>
            }
        </div>
       </>
    )
}

export default FormAddMasterComponent;