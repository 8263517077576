import { Button, Col, message, Modal, Pagination, Row, Tooltip, Select } from "antd";
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import Table from "../../component/Table";
import { PlusOutlined, DeleteFilled, EditFilled,StopOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { deleteGrade, getGradeList, getLevelList } from "../../api/grade";
import { decryptData } from "../../helper/cryptojs";

export default function Grade() {
	const navigate = useNavigate();
	const sessionPage = sessionStorage.getItem("sessionPageGrade");
	const sessionOffset = sessionStorage.getItem("sessionOffsetGrade");
	const sessionFilter = sessionStorage.getItem("sessionFilterGrade") ? JSON.parse(sessionStorage.getItem("sessionFilterGrade")) : sessionStorage.getItem("sessionFilterGrade");
	const [listTable, setListTable] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(sessionPage ? parseInt(sessionPage) : 1)
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(sessionOffset ? sessionOffset : 0)
	const [search, setSearch] = useState(sessionFilter ? sessionFilter : null)
	const [flag, setFlag] = useState(0)
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [deleteId, setDeleteId] = useState()
	const [dataFilter, setDataFilter] = useState([])
	const [selectedDataFilter, setSelectedDataFilter] = useState(sessionFilter ? sessionFilter?.label : null)
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));

	const gradeList = async () => {
		setLoading(true);
		try {
		  const res = await getGradeList(company, page, offset, limit, search?.value)
		  setListTable(res?.data?.data);
		  setTotalData(res?.data?.recordsTotal)
		setLoading(false);
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	};

	useEffect(()=> {
		const filterList = async () => {
			setLoading(true);
			try {
			  const res = await getLevelList(company)
			  setDataFilter(res?.data?.data);
			setLoading(false);
			} catch (err) {
			  console.log(err);
			} finally {
			  setLoading(false);
			}
		};
		filterList()
	},[])
	useEffect(() => {
			gradeList();
			const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageGrade");
				sessionStorage.removeItem("sessionFilterGrade");
				sessionStorage.removeItem("sessionOffsetGrade");
				setPage(1)
				setOffset(0)
				setSearch(null)
			};
			window.addEventListener("beforeunload", beforeUnloadHandler);
			return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			};
	}, [limit, offset, search,page,flag]);

	useEffect(() => {
		let offsetSession = 0;
		if(page > 1){
			offsetSession = page*limit - 10
		}
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPageGrade", page);
		sessionStorage.setItem("sessionOffsetGrade", offsetSession);
	},[page])

	const handleDelete = async (id) =>{
        setLoading(true)
        try {
            const res = await deleteGrade(id);
            message.success({
                content: "Data successfully deleted",
            });
            setLoading(false)
			setFlag((prev) => prev + 1)
        } catch (error) {
            message.error({
                content: "Request Not Found",
            });
            setLoading(false)
        }
    }

      
	
	const columns = [
	{
		title: 'No',
		dataIndex: 'no',
		key: 'no',
		width: '25px',
        render: (text,record) =>{
			return (
				<div className="detail-btn-wp" onClick={() => navigate(`/grade/detail/${encodeURIComponent("id?" + record.id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
	{
		
		title: 'Level',
		dataIndex: 'level_name',
		key: 'level_name',
		render: (text,record) =>{
			return (
				
				<div className="detail-btn-wp" onClick={() => navigate(`/grade/detail/${encodeURIComponent("id?" + record.id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
	{
		
		title: 'Grade',
		dataIndex: 'grade',
		key: 'grade',
		render: (text,record) =>{
			return (
				
				<div className="detail-btn-wp" onClick={() => navigate(`/grade/detail/${encodeURIComponent("id?" + record.id)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
	{
		title: 'Action',
		key: 'action',
		width: "50px",
		render: (_,record,index) => {
            return RoleAccessGradeUpdate && RoleAccessGradeDelete ? (
                <Row justify={"space-between"}>
					<div className="icon-action" >
					  <DeleteFilled onClick={() => showModal(record.id)}/>
				  	</div>
					
					 <div className="icon-action" onClick={() => navigate(`/grade/edit-grade/${encodeURIComponent("id?" + record.id)}`)}>
					 <EditFilled />
					 </div>
                </Row>
            ) : (
				<Row justify={"space-between"}>
						<Tooltip title="No Access">
							<StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
						</Tooltip>
				</Row>
			)
        }
	},
	];
	const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/grade"
		},
		{
			title: "Grade",
			url: "/grade"
		},
	];


	const onChangeTable = (pageNumber, type) => {
		setPage(pageNumber)
		setOffset(pageNumber*limit- 10)
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	const showModal = (id) => {
		setIsModalOpen(true);
		setDeleteId(id)
	  };

	const handleOk = () => {
		setIsModalOpen(false);
		handleDelete(deleteId)
	};
	
	const handleCancel = () => {
		setIsModalOpen(false);
	};

	const RoleAccessGradeCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeGradeCreate'));
	const RoleAccessGradeUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeGradeUpdate'));
	const RoleAccessGradeDelete = JSON.parse(localStorage.getItem('RoleaccessPriviledgeGradeDelete'));

    return (
        <>
		  <>
		  <div className="title-section">
					Grade
				</div>
				<Modal title="Delete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
						<p>Are you sure to delete this data?</p>
				</Modal>
				<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
					<Col span={24}>
						<Breadcrumb items={dataBreadcrumb}/>
					</Col>
				</div>
				<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
					<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"}>
						<Col md={{top:20}} className="mt-5 md:mt-0">
							<Row justify={"end"} style={{gap:15}} md={14}>
								<Select
									placeholder="Select Level"
									options={dataFilter}
									fieldNames={{label: 'name', value: 'level_id'}}
									labelInValue
									value={selectedDataFilter}
									allowClear
									onSelect={(e) => {
										setSelectedDataFilter(e.label)
										setSearch(e)
										sessionStorage.setItem("sessionFilterGrade", JSON.stringify(e));
										setPage(1)
									}}
									onClear={() => {
										setSelectedDataFilter('')
										setSearch(null)
										sessionStorage.removeItem("sessionFilterGrade");
										setPage(1)
									}}
									className="select-company w-24 md:w-36 text-xs "
									loading={loading}
								/>
									{RoleAccessGradeCreate && (
										<Col style={{ display: "flex", justifyContent: "flex-end" }}>
											<Button className="btn-sh-p" type="primary" shape="circle" onClick={() => navigate('/grade/add-grade')} icon={<PlusOutlined />} size={"large"} />
										</Col>
									)}
									
								
							</Row>
						</Col>
					</Row>
					<div className="table-wrapper">
					{loading ? (
						<>
						<Table id={'my-table'}  columns={columns}  loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					) : (
						<>
						<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
					)}
					</div>
				</div>
					</>
		
          
        </>
    )
}