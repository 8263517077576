import { Button, Col, message, Modal, Pagination, Row, Tooltip } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import InputWithIcon from "../../component/Form/InputWithIcon";
import Table from "../../component/Table";
import iconSearch from "../../assets/img/search-icon.png";
import { PlusOutlined, DeleteFilled, EditFilled, StopOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { decryptData } from "../../helper/cryptojs";
import { deleteSetSchedule, getSetSchedule } from "../../api/setSchedule";


export default function SetSchedule() {
	const navigate = useNavigate();
	const sessionPage = sessionStorage.getItem("sessionPageShift");
	const sessionOffset = sessionStorage.getItem("sessionOffsetShift");
	const sessionSearch = sessionStorage.getItem("sessionSearchShift");
	const [listTable, setListTable] = useState([]);
	const [loading, setLoading] = useState(false);
	const [totalData, setTotalData] = useState(0)
	const [page, setPage] = useState(sessionPage ? parseInt(sessionPage) : 1)
	const [limit, setLimit] = useState(10)
	const [offset, setOffset] = useState(sessionOffset ? sessionOffset : 0)
	const previousPage = useRef(1);
	const [search, setSearch] = useState(sessionSearch ? sessionSearch : "")
	const [flag, setFlag] = useState(0)
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [deleteId, setDeleteId] = useState()
	const [startDate, setStartDate] = useState(null)
	const [endDate, setEndDate] = useState(null)
	const BestpraticeUseEffect = useRef(false)
	const company = decryptData(sessionStorage.getItem("selectCompany")) ? decryptData(sessionStorage.getItem("selectCompany")) : decryptData(localStorage.getItem("DefaultCompanyCode"));
	const downloadbyCompany = decryptData(localStorage.getItem("DefaultCompanyCode"))

	const setScheduleList = async () => {
		setLoading(true);
		try {
		  const res = await getSetSchedule(company, search, limit, offset,page)
		  setListTable(res?.data?.data);
		  setTotalData(res?.data?.recordsTotal)
		} catch (err) {
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };

	useEffect(() => {
		if (!BestpraticeUseEffect.current) {
			BestpraticeUseEffect.current = true;
		  } else {
			setScheduleList()
			const beforeUnloadHandler = () => {
				sessionStorage.removeItem("sessionPageShift");
				sessionStorage.removeItem("sessionSearchShift");
				sessionStorage.removeItem("sessionOffsetShift");
				setPage(1)
				setOffset(0)
				setSearch("")
			};
			window.addEventListener("beforeunload", beforeUnloadHandler);
			return () => {
				window.removeEventListener("beforeunload", beforeUnloadHandler);
			};
		  }
	}, [search,startDate, endDate,  limit, offset, page,company]);


	useEffect(() => {
		let offsetSession = 0;
		if(page > 1){
			offsetSession = page*limit - 10
		}
		setOffset(offsetSession)
		sessionStorage.setItem("sessionPageShift", page);
		sessionStorage.setItem("sessionOffsetShift", offsetSession);
	},[page])

	const handleDelete = async (id) =>{
        setLoading(true)
        try {
            await deleteSetSchedule(id);
            message.success({
				content: "Data successfully deleted",
				onClose: () => {
					window.location.reload();
				}
			});
            setLoading(false)
			setFlag((prev) => prev + 1)
        } catch (error) {
            message.error({
				content:  "Response Failed" 
            });
            setLoading(false)
        }
    }


	const columns = [
	{
		title: 'No',
		dataIndex: 'no',
		key: 'no',
		width: '25px',
        render: (text,record) =>{
			return (
				<div className="detail-btn-wp" onClick={() => navigate(`/shifting/set-schedule/detail-set-schedule/${encodeURIComponent("id?" + record?.employee_number)}`)}>
					{text || '-'}
				</div>
			)
		}
	},
	{
		
		title: 'Employee Name',
		dataIndex: 'employee_name',
		key: 'employee_name',
		render: (text,record) =>{
			return (
				
				<div className="detail-btn-wp" onClick={() => navigate(`/shifting/set-schedule/detail-set-schedule/${encodeURIComponent("id?" + record?.employee_number)}`)}>
					{text || '-'}
				</div>
			)
		}
	},

	{
		title: 'Action',
		key: 'action',
		width: "50px",
		render: (_,record,index) => {
            return RoleAccessSetScheduleUpdate && RoleAccessSetScheduleDelete ? (
                <Row justify={"space-between"}>
					<div className="icon-action" >
					  <DeleteFilled onClick={() => showModal(record?.employee_number)}/>
				  	</div>
					 <div className="icon-action" onClick={() => navigate(`/shifting/set-schedule/edit-set-schedule/${encodeURIComponent("id?" + record?.employee_number)}`)}>
					 <EditFilled />
					 </div>
                </Row>
            ) : (
				<Row justify={"space-between"}>
						<Tooltip title="No Access">
							<StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
						</Tooltip>
				</Row>
			)
        }
	},
	];
	const dataBreadcrumb = [
		{
			title: "Master Data",
			url: "/shifting/set-schedule"
		},
		{
			title: "Set Schedule",
			url: "/shifting/set-schedule"
		},
	];


	useEffect(() => {
		previousPage.current = page;
	}, [page]);


	const onChangeTable = (pageNumber, type) => {
		setPage(pageNumber)
		setOffset(pageNumber*limit- 10)
		//
	};

	const onShowSizeChange = (_, pageSize) => {
		setLimit(pageSize)
	};

	function dataTableWithNumber() {
		const showNumber = limit * (page - 1);
		return listTable?.map((data, index) => {
			return {
				...data,
				key: index + 1,
				no: showNumber + (index + 1),
			};
		});
	}

	const onChangeSearch = (e) => {
		setSearch(e.target.value)
		setLimit(10)
		setPage(1)
		sessionStorage.setItem("sessionSearchShift", e.target.value);
	}


	const showModal = (id) => {
		setIsModalOpen(true);
		setDeleteId(id)
	  };

	const handleOk = () => {
		setIsModalOpen(false);
		handleDelete(deleteId)
	};
	
	const handleCancel = () => {
		setIsModalOpen(false);
	};


	  const handleDownload = () => {
		window.location.href = `${process.env.REACT_APP_HRIS_URL}mshifting-schedule-download?companyCode=${downloadbyCompany}`;
	  }

	  const RoleAccessSetScheduleCreate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeSetScheduleCreate'));
	  const RoleAccessSetScheduleUpdate = JSON.parse(localStorage.getItem('RoleaccessPriviledgeSetScheduleUpdate'));
	  const RoleAccessSetScheduleDelete = JSON.parse(localStorage.getItem('RoleaccessPriviledgeSetScheduleDelete'));
	  const RoleAccessSetScheduleDownload = JSON.parse(localStorage.getItem('RoleaccessPriviledgeSetScheduleDownload'));

    return (
        <>
		  <>
		  		<div className="title-section">
					Set Schedule
				</div>
					<Modal title="Delete" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
						<p>Are you sure to delete this data?</p>
					</Modal>
					<div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
						<Col span={24}>
							<Breadcrumb items={dataBreadcrumb}/>
						</Col>
					</div>
					<div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
						<Row style={{marginBottom: "24px", justifyContent:'end'}} align={"middle"} >

							<Col  md={{top:20}} className="mt-5 md:mt-0">
								<Row justify={"end"} style={{gap:15}} md={14}>	
										<InputWithIcon
											type="text"
											placeholder="Search Set Schedule"
											img={iconSearch}
											className="search-input btn-sh-sec"
											classImg="op-icon"
											value={search}
											onChange={onChangeSearch}
										/>

									{RoleAccessSetScheduleDownload && (
										<Button size={"large"} onClick={handleDownload} type="primary">Download </Button>
									)}
						
									{RoleAccessSetScheduleCreate && (
										<Button className="btn-sh-p" type="primary" shape="circle" onClick={() => navigate('/shifting/set-schedule/add-set-schedule')} icon={<PlusOutlined />} size={"large"} />
									)}
									
								</Row>
							</Col>
						
						</Row>
						<div className="table-wrapper">
						{loading ? (
						<>
						<Table id={'my-table'}  columns={columns}  loading={loading} />
						<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
							<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
						</div>
						</>
						) : (
							<>
							<Table id={'my-table'} dataSource={dataTableWithNumber()} columns={columns} loading={loading} />
							<div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
								<Pagination defaultCurrent={page} current={page} total={totalData} onChange={onChangeTable} showSizeChanger={false} onShowSizeChange={onShowSizeChange}/>
							</div>
							</>
						)}	
						</div>
					</div>
					</>
          
        </>
    )
}
