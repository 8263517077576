import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import { LoginPage } from "./views/Login";
import Router from "./views/Router";
import "./App.scss";
import { ResetPasswordPage } from "./views/ResetPassword";
import { PrivacyPolicyPage } from "./views/PrivacyPolicy";
import { HealthPage } from "./views/Health";
import Maintenance from "./views/maintenance";
import { Provider } from "react-redux";
import store from "./store";

function App() {
  // const user = localStorage.getItem("Username");
  const location = window.location.href;
  var n = location.lastIndexOf("/");
  var result = location.substring(n);
  var n2 = location.split("/");
  var result2 = n2[3];

  const maxHeightCondition =
    result.toString() === "/" ||
    result2 === "/reset-password" ||
    result2 === "/privacy-policy";
  return (
    <BrowserRouter>
      <Provider store={store}>
        <div
          className="app"
          style={
            maxHeightCondition ? { maxHeight: "100vh" } : { maxHeight: "none" }
          }>
          {/* {user === null ? ( */}

          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
          <link
            href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
            rel="stylesheet"
          />
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/maintenance" element={<Maintenance />} />
            <Route path="/reset-password/:id" element={<ResetPasswordPage />} />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/health" element={<HealthPage />} />
          </Routes>
          <Router />
          {/* ): <Router />    } */}
        </div>
      </Provider>
    </BrowserRouter>
  );
}

export default App;
