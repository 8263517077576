import { Button, Col, message, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getCompanyDetail } from "../../../api/company";
import { formatCurrency } from "../../../helper/formatCurrency";
import { Link } from "react-router-dom";

const CompanyDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [loading, setLoading] = useState(false);
	const [detail, setDetail] = useState([]);
    const [day, setDay] = useState('')
    const [divis, setDivis] = useState('')
    const [abs, setAbs] = useState('')
    const [is_none, setIs_None] = useState(false)
    const [is_custom, setIs_Custom] = useState(false)
    const BestpraticeUseEffect = useRef(false)
    const companyList = async () => {
		setLoading(true);
		try {
		  const res = await getCompanyDetail(id)
          const cutOffType = res?.data?.data.cut_off_type;
          const cutOffDate = res?.data?.data.cut_off_date;
          const divisor = res?.data?.data.divisor_proportional
          const absCalRef = res?.data?.data.absence_cal_ref
          absCalRef === 'none' || absCalRef === 'amount' ? setIs_None(true) : setIs_None(false)
          absCalRef === 'custom' || absCalRef === 'amount' ? setIs_Custom(true) : setIs_Custom(false)
          if (cutOffType === 'weekly') {
              const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
              setDay(daysOfWeek[cutOffDate]);
          } else if (cutOffType === 'monthly') {
              setDay(cutOffDate);
          }
          const divOpt = [
              {
                  value: 'calendar_day',
                  label: "Calendar Day"
              },
              {
                  value: 'working_day',
                  label: "Working Day"
              },
              {
                  value: 'custom_on_calendar_day',
                  label: "Custom on Calendar Day"
              },
              {
                  value: 'custom_on_working_day',
                  label: "Custom on Working Day"
              },
          ]
          const absOpt = [
            {
                value: 'none',
                label: "None"
            },
            {
              value: 'based_on_working_days',
              label: "Based on Working Day"
          },
          {
              value: 'based_on_calendar_days',
              label: "Based on Calendar Day"
          },
          {
              value: 'custom',
              label: "Custom"
          },
          {
              value: 'amount',
              label: "Amount"
          },
          ]
         setDivis(divOpt.find((e) => e.value === divisor))
         setAbs(absOpt.find((e) => e.value === absCalRef))
		  setDetail(res?.data?.data);
		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            companyList();
          }
	}, []);

    const dataBreadcrumb = [
        {
			title: "Master Data - Company",
			url: "/company"
		},
        {
			title: "Company Detail",
			url: `/company/detail/${result}`
		},
	];
    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/company')} icon={<ArrowLeftOutlined />} size={"large"} />
				Company Detail
			</div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <div className="title-divider">Company Logo</div>

                <img src={process.env.REACT_APP_HRIS_URL+`company/image/${detail?.company_logo_url}`} height="250px"/>
                 
                <div className="title-divider">Company Info</div>
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Company Name</div>
                            <div className="subtitle">{detail?.name != 'null' ? (detail?.name || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Company Phone Number</div>
                            <div className="subtitle">{detail?.phone_number !== 'null' ? (detail?.phone_number || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Email</div>
                            <div className="subtitle">{detail?.email !==  'null' ? (detail?.email || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Address</div>
                            <div className="subtitle">{detail?.address !== 'null' ? (detail?.address || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Postal Code</div>
                            <div className="subtitle">{detail?.postal_code !== 'null' ? (detail?.postal_code || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Province</div>
                            <div className="subtitle">{detail?.province !== 'null' ? (detail?.province || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">City</div>
                            <div className="subtitle">{detail?.city !== 'null' ? (detail?.city || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Industry</div>
                            <div className="subtitle">{ detail?.industry !== 'null' ? (detail?.industry || "-") : "-"}</div>
                        </div>
                    </Col>
                </Row>
                <div className="title-divider" style={{marginTop: "32px"}}>Tax Info</div>
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Company NPWP</div>
                            <div className="subtitle">{detail?.company_npwp !== 'null'  ? (detail?.company_npwp || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Company Taxable Date</div>
                            <div className="subtitle">{detail?.company_tax_date !== 'null' ? (detail?.company_tax_date || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Tax Person Name</div>
                            <div className="subtitle">{detail?.tax_person_name !== 'null'  ? (detail?.tax_person_name || "-") : "-" }</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Tax Person NPWP</div>
                            <div className="subtitle">{detail?.tax_person_npwp !== 'null' ? (detail?.tax_person_npwp || "-") : "-"}</div>
                        </div>
                    </Col>
                </Row>
                <div className="title-divider" style={{marginTop: "32px"}}>Cut Off Settings</div>
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Cut Off Type</div>
                            <div className="subtitle" style={{textTransform: "capitalize"}}>{detail?.cut_off_type !== 'null'  ? (detail?.cut_off_type || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Cut Off Date</div>
                            <div className="subtitle">{day || "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Divisor for Proportional Calculation</div>
                            <div className="subtitle">{divis?.label ? divis?.label : "-" }</div>
                        </div>
                    </Col>
                    {detail?.divisor_proportional === 'custom_on_calendar_day' || detail?.divisor_proportional === 'custom_on_working_day' ? 
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Determine Divisor Number</div>
                            <div className="subtitle">{detail?.divisor_custom_number !== 'null' ? detail?.divisor_custom_number : "-"}</div>
                        </div>
                    </Col>
                    :
                    detail?.divisor_proportional === 'working_day' ?
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Holiday Included</div>
                            <div className="subtitle">{detail?.divisor_include_holiday !== 'null' ? (detail?.divisor_include_holiday ? 'Yes': 'No') : "-"}</div>
                        </div>
                    </Col>
                    : 
                    null}
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Working Days</div>
                            <Col>
                                {detail?.working_day_arr ? detail?.working_day_arr.map((e, i) => {
                                    return (
                                        <div className="subtitle">{e !== 'null' ? (e || "-") : "-"}</div>
                                    )
                                })
                                :
                                '-'}
                            </Col>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">New Employee Proration</div>
                            <div className="subtitle" style={{textTransform: 'capitalize'}}>{detail?.proration === 'same' ? ('Same Cut Off Period' || "-") : "Next Cut Off Period"}</div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="detail-card">
                            <div className="title">Template Payroll</div>
                            <div className="text-sm text-gray-500 flex items-center">
                            <div>
                              <svg
                                className="w-4 h-4 mr-1 fill-current"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20">
                                <path d="M4 6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v2h2a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h2V6a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1H2V6zM9 12v5a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-5a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1z"></path>
                              </svg>
                            </div>
                            <>
                              {detail?.template_payroll ? (
                                <Link
                                    to={process.env.REACT_APP_HRIS_URL+`company/image/${detail?.template_payroll}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="block overflow-x-auto text-gray-500" style={{ wordWrap: 'break-word', width:'100%' }}
                                >
                                    {detail?.template_payroll}
                                </Link>
                              ) : (
                                <div className="subtitle">
                                  -
                                </div>
                              )}
                            </>
                          </div>
                        </div>
                    </Col>
                </Row>
                <div className="title-divider" style={{marginTop: "32px"}}>Absence Settings</div>
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Absence Calculation References</div>
                            <div className="subtitle">{abs ? abs.label : "-"}</div>
                        </div>
                    </Col> 
                    {!is_none ?
                        <Col span={12}>
                            <div className="detail-card">
                                <div className="title">Calculation Base</div>
                                <div className="subtitle">{detail?.calculation_base !== 'null' ? (detail?.calculation_base_name || "-") : "-"}</div>
                            </div>
                        </Col>  
                        :
                         null
                     }
                    {is_custom ?
                        <Col span={12}>
                            <div className="detail-card">
                                <div className="title">Custom Amount</div>
                                {detail?.absence_cal_ref_amount ?
                                <div className="subtitle">
                                    {formatCurrency(detail?.absence_cal_ref_amount)}
                                </div>
                                : 
                                detail?.absence_cal_ref_custom ?
                                <div className="subtitle">
                                    {detail?.absence_cal_ref_custom}
                                </div>
                                : 
                                <div className="subtitle">
                                    {'-'}
                                </div>}
                            </div>
                        </Col>  
                    :
                        null
                    }
                </Row>
                <div className="title-divider" style={{marginTop: "32px"}}>Other Info</div>
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Contact Person Name</div>
                            <div className="subtitle">{detail?.contact_person_name !== 'null' ? (detail?.contact_person_name || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Contact Person Number</div>
                            <div className="subtitle">{detail?.contact_person_number !== 'null' ? (detail?.contact_person_number || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Contact Person Email</div>
                            <div className="subtitle">{detail?.contact_person_email !== 'null' ? (detail?.contact_person_email || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Status</div>
                            <div className="subtitle">{detail?.status === 1 ? "Active" : "Inactive"}</div>
                        </div>
                    </Col>
                </Row>
                <div className="title-divider" style={{marginTop: "32px"}}>Social Media</div>
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Recruitment Email</div>
                            <div className="subtitle">{detail?.recruitmentEmail !== 'null' ? (detail?.recruitmentEmail || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Website</div>
                            <div className="subtitle">{detail?.website !== 'null' ? (detail?.website || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Instagram</div>
                            <div className="subtitle">{detail?.instagram !== 'null' ? (detail?.instagram || "-") : "-"}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Linkedin</div>
                            <div className="subtitle">{detail?.linkedin !== 'null' ? (detail?.linkedin || "-") : "-"}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default CompanyDetail