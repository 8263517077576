/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { decryptData } from "../../../helper/cryptojs";
import { getLoanSettingDetail } from "../../../api/loan";
import { formatCurrency } from "../../../helper/formatCurrency";

const LoanSettingDetail = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  let n = location.lastIndexOf("/");
  let result = location.substring(n + 1);
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const BestpraticeUseEffect = useRef(false);
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const [detail, setDetail] = useState([]);

  const getDetailLoanSetting = async () => {
    try {
      const res = await getLoanSettingDetail(id);
      setDetail(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      getDetailLoanSetting();
    }
  }, [company]);

  const dataBreadcrumb = [
    {
      title: "Loan Management - Loan Setting",
      url: "/loan-setting",
    },
    {
      title: "Loan Setting Detail",
      url: ``
    },
  ];

  return (
    <>
      <div className="title-section">
        <Button
          className="btn-sh-p"
          type="primary"
          shape="circle"
          style={{ marginRight: "14px" }}
          onClick={() => navigate(-1)}
          icon={<ArrowLeftOutlined />}
          size={"large"}
        />
        Loan Settings Detail
      </div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="detail-wrapper">
        <div className="border-dashed border-2 border-sky-500 w-auto bg-white p-6 rounded-lg shadow-lg mt-3">
          <Row>
            <Col span={12}>
              <div className="detail-card">
                <div className="title">
                  Loan Name <span className="text-red-500">*</span>
                </div>
                <div className="subtitle">{detail?.name || "-"}</div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <div className="detail-card">
                <div className="title">
                  Maximum Tenor <span className="text-red-500">*</span>
                </div>
                <div className="subtitle">{detail?.max_tenor || "-"}</div>
              </div>
            </Col>
            <Col span={12}>
              <div className="detail-card">
                <div className="title">
                  Maximum Loan <span className="text-red-500">*</span>
                </div>
                <div className="subtitle">
                  {isNaN(detail?.max_loan)
                    ? "-"
                    : formatCurrency(detail?.max_loan)}
                </div>
              </div>
            </Col>
          </Row>
          <Col span={12}>
            <div className="detail-card">
              <div className="title">
                Minimum working period <span className="text-red-500">*</span>
              </div>
              <span className="ml-0 relative top-[-8px]  text-[11px] text-slate-400">
                Starting from the joining date
              </span>
              <div className="subtitle">
                <div className="flex flex-grow">
                  {detail?.min_work_year || "0"}
                  <span className="mx-2"> Years</span>
                  {detail?.min_work_month?.toString() || "-"}
                  <span className="mx-2"> Months</span>
                </div>
              </div>
            </div>
          </Col>
          <Row>
            <Col span={12}>
              <div className="detail-card">
                <div className="title">
                  Applies to <span className="text-red-500">*</span>
                </div>
                <div className="subtitle">
                  {detail?.applies_to == "all"
                    ? "All Employees"
                    : "Based on Level"}
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            {detail?.applies_to != "all" ? (
              <Col span={12}>
                <div className="detail-card">
                  <div className="title">Level</div>
                  {detail?.level?.length > 0 ? (
                    detail?.level.map((level, index) => (
                      <div className="subtitle" key={index}>{`- ${level}`}</div>
                    ))
                  ) : (
                    <div className="subtitle">-</div>
                  )}
                </div>
              </Col>
            ) : null}
            <Col span={12}>
              <div className="detail-card">
                <div className="title">Status</div>
                <div className="subtitle">
                  {detail?.is_active === 1 ? "Active" : "Inactive"}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default LoanSettingDetail;
