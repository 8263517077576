/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Col,
  Form as FormAntd,
  Row,
  Select,
  message,
  DatePicker,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import {
  InputWithLabel,
  SelectOptionWithLabel,
  SelectSearchWithLabel,
  TextAreaWithLabel,
} from "../../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { useFormik } from "formik";
import { DeleteOutlined, CloudUploadOutlined } from "@ant-design/icons";

import dayjs from "dayjs";
import Breadcrumb from "../../../component/Breadcrumb";
import { decryptData } from "../../../helper/cryptojs";
import { getListEmployeeActive } from "../../../api/timeRequest";
const FormCreateEmployeeTransfer = () => {
  const navigate = useNavigate();
  const [fetching, setFetching] = useState(false);
  const location = window.location.href;
  let n = location.split("/");
  let result = n[5];
  const inputRef = useRef(null);
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const { Option } = Select;
  const [fileName, setFileName] = useState(null);
  const [options, setOptions] = useState([]);
  const [optionsMLeave, setOptionsMLeave] = useState([]);
  const BestpraticeUseEffect = useRef(false);
  const employeeNumberActive = async () => {
    try {
      const res = await getListEmployeeActive(companyStore);
      const data = res?.data?.data;
      setOptions(data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleSelectChange = (value) => {
    form.setValues({
      ...form.values,
      employeeId: value,
    });
    // Mendapatkan nilai full_name terkait dari data API
    const selectedData = options.find((option) => option.employee_id === value);
    if (selectedData) {
      form.setFieldValue(
        "DepartmentName",
        selectedData ? selectedData.department_name : ""
      );
      form.setFieldValue(
        "DivisionName",
        selectedData ? selectedData.division_name : ""
      );
      form.setFieldValue(
        "PositionName",
        selectedData ? selectedData.position_name : ""
      );
      sessionStorage.setItem(
        "selectedIdShift",
        selectedData ? selectedData.employee_number : ""
      );
    } else {
      form.setFieldValue("EmployeeName");
      form.setFieldValue("DepartmentName");
      form.setFieldValue("DivisionName");
      form.setFieldValue("PositionName");
    }
  };

  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      employeeNumberActive();
      form.resetForm();
    }
  }, []);

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 2 * 1024 * 1024) {
      const newFileName = file.name.replace(/['"]+/g, "");
      const renamedFile = new File([file], newFileName, { type: file.type });
      setFileName(renamedFile.name);
      form.setValues({
        ...form.values,
        attachment: renamedFile,
      });
    } else {
      setFileName(null);
      message.error(
        "Please upload a PDF,JPEG,JPG,PNG file with a maximum size of 2 MB"
      );
    }
  };

  const handleFileDelete = () => {
    inputRef.current.value = ""; // Clear the input field
    setFileName(null);
    form.setValues({
      ...form.values,
      attachment: null,
    });
  };

  const validationUser = yup.object().shape({
    startDate: yup.string().required("Field is required"),
    endDate: yup.string().required("Field is required"),
    mLeaveId: yup.string().required("Field is required"),
    requestType: yup.string().required("Field is required"),
    employeeId: yup.string().required("Field is required"),
  });

  const form = useFormik({
    initialValues: {
      companyCode: companyStore,
      mLeaveId: "",
      startDate: "",
      endDate: "",
      requestType: "",
      LeaveType: "",
      description: "",
      employeeId: "",
      attachment: "",
    },
    enableReinitialize: true,
    validationSchema: validationUser,

    onSubmit: async (values) => {
      try {
        const res = "";
        if (!res?.data?.success) {
          message.error({
            content: res?.data?.error,
          });
        } else {
          message.success({
            content: "Leave successfully saved",
          });
          navigate("/leave-transaction");
          form.resetForm();
        }
        setFetching(false);
      } catch (error) {
        message.error({
          content: "Leave failed to saved",
        });
        setFetching(false);
      }
    },
  });

  const dataBreadcrumb = [
    {
        title: "Master Data - Employee",
        url: "/employee"
    },
    {
        title: "Employee Transfer",
        url: "/employee/employee-transfer"
    },
    {
        title: "Create Employee Transfer",
        url: "/employee/employee-transfer/create-employee-transfer"
    },
]

  return (
    <>
      <div className="title-section">
        {"Create Employee Transfer"}
      </div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="add-wrapper">
         
        <FormAntd onFinish={form.handleSubmit}>
          <h3>Employee Transfer</h3>
          <Col>
            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <SelectSearchWithLabel
                    name="EmployeeNumber"
                    labelname={
                      <div>
                        Employee<span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    showSearch
                    allowClear
                    placeholder="Select Employee"
                    optionFilterProp="children"
                    onChange={handleSelectChange}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {options.map((option) => (
                      <Option
                        key={option.employee_id}
                        value={option.employee_id}
                      >
                        {`${option.employee_number} - ${option.full_name}`}
                      </Option>
                    ))}
                  </SelectSearchWithLabel>
                  {form.touched.employeeId && form.errors.employeeId ? (
                    <span className="text-error">{form.errors.employeeId}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <InputWithLabel
                    disabled
                    name="Branch"
                    label={"Branch"}
                    value={form.values.Branch}
                    placeholder="Branch"
                    onChange={form.handleChange}
                  />
                  {form.touched.Branch && form.errors.Branch ? (
                    <span className="text-error">{form.errors.Branch}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
            </Row>

            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <InputWithLabel
                    disabled
                    name="DepartmentName"
                    label={"Department"}
                    placeholder="Department"
                    value={form.values.DepartmentName}
                    onChange={form.handleChange}
                  />
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <InputWithLabel
                    disabled
                    name="DivisionName"
                    label={"Division"}
                    placeholder="Division"
                    value={
                      form.values.DivisionName ? form.values.DivisionName : ""
                    }
                    onChange={form.handleChange}
                  />
                  {form.touched.DivisionName && form.errors.DivisionName ? (
                    <span className="text-error">
                      {form.errors.DivisionName}
                    </span>
                  ) : null}
                </FormAntd.Item>
              </Col>
            </Row>
            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <InputWithLabel
                    disabled
                    name="Level"
                    label={"Level"}
                    placeholder="Level"
                    value={form.values.level}
                    onChange={form.handleChange}
                  />
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <InputWithLabel
                    disabled
                    name="PositionName"
                    label={"Position"}
                    value={form.values.PositionName}
                    placeholder="Position"
                    onChange={form.handleChange}
                  />
                  {form.touched.Position && form.errors.Position ? (
                    <span className="text-error">{form.errors.Position}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
            </Row>

            <h3>Manage Transfer</h3>
            <Row justify={"space-between"}>
            <Col span={11}>
                <FormAntd.Item>
                  <SelectOptionWithLabel
                    label={
                      <div>
                        Transfer Type<span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    placeholder={"Transfer Type"}
                    items={optionsMLeave.map((option) => ({
                      value: option.m_leave_id,
                      title: option.leave_name,
                    }))}
                    value={
                      form?.values?.mLeaveId ? form?.values?.mLeaveId : null
                    }
                    onChange={(val) =>
                      form.setValues({
                        ...form.values,
                        mLeaveId: val,
                      })
                    }
                  />

                  {form.touched.mLeaveId && form.errors.mLeaveId ? (
                    <span className="text-error">{form.errors.mLeaveId}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
            </Row>
            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <SelectOptionWithLabel
                    label={
                      <div>
                        Company<span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    placeholder={"Company"}
                    items={optionsMLeave.map((option) => ({
                      value: option.m_leave_id,
                      title: option.leave_name,
                    }))}
                    value={
                      form?.values?.mLeaveId ? form?.values?.mLeaveId : null
                    }
                    onChange={(val) =>
                      form.setValues({
                        ...form.values,
                        mLeaveId: val,
                      })
                    }
                  />

                  {form.touched.mLeaveId && form.errors.mLeaveId ? (
                    <span className="text-error">{form.errors.mLeaveId}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <SelectOptionWithLabel
                    label={
                      <div>
                        Branch<span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    placeholder={"Branch"}
                    items={optionsMLeave.map((option) => ({
                      value: option.m_leave_id,
                      title: option.leave_name,
                    }))}
                    value={
                      form?.values?.mLeaveId ? form?.values?.mLeaveId : null
                    }
                    onChange={(val) =>
                      form.setValues({
                        ...form.values,
                        mLeaveId: val,
                      })
                    }
                  />

                  {form.touched.mLeaveId && form.errors.mLeaveId ? (
                    <span className="text-error">{form.errors.mLeaveId}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>

            </Row>
            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <SelectOptionWithLabel
                    label={
                      <div>
                        Department<span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    placeholder={"Department"}
                    items={optionsMLeave.map((option) => ({
                      value: option.m_leave_id,
                      title: option.leave_name,
                    }))}
                    value={
                      form?.values?.mLeaveId ? form?.values?.mLeaveId : null
                    }
                    onChange={(val) =>
                      form.setValues({
                        ...form.values,
                        mLeaveId: val,
                      })
                    }
                  />

                  {form.touched.mLeaveId && form.errors.mLeaveId ? (
                    <span className="text-error">{form.errors.mLeaveId}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <SelectOptionWithLabel
                    label={
                      <div>
                        Division<span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    placeholder={"Division"}
                    items={optionsMLeave.map((option) => ({
                      value: option.m_leave_id,
                      title: option.leave_name,
                    }))}
                    value={
                      form?.values?.mLeaveId ? form?.values?.mLeaveId : null
                    }
                    onChange={(val) =>
                      form.setValues({
                        ...form.values,
                        mLeaveId: val,
                      })
                    }
                  />

                  {form.touched.mLeaveId && form.errors.mLeaveId ? (
                    <span className="text-error">{form.errors.mLeaveId}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>

            </Row>
            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <SelectOptionWithLabel
                    label={
                      <div>
                        Direct supervisor<span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    placeholder={"Direct supervisor"}
                    items={optionsMLeave.map((option) => ({
                      value: option.m_leave_id,
                      title: option.leave_name,
                    }))}
                    value={
                      form?.values?.mLeaveId ? form?.values?.mLeaveId : null
                    }
                    onChange={(val) =>
                      form.setValues({
                        ...form.values,
                        mLeaveId: val,
                      })
                    }
                  />

                  {form.touched.mLeaveId && form.errors.mLeaveId ? (
                    <span className="text-error">{form.errors.mLeaveId}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <SelectOptionWithLabel
                    label={
                      <div>
                        Level<span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    placeholder={"Level"}
                    items={optionsMLeave.map((option) => ({
                      value: option.m_leave_id,
                      title: option.leave_name,
                    }))}
                    value={
                      form?.values?.mLeaveId ? form?.values?.mLeaveId : null
                    }
                    onChange={(val) =>
                      form.setValues({
                        ...form.values,
                        mLeaveId: val,
                      })
                    }
                  />

                  {form.touched.mLeaveId && form.errors.mLeaveId ? (
                    <span className="text-error">{form.errors.mLeaveId}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>

            </Row>
            <Row justify={"space-between"}>
              <Col span={11}>
                <FormAntd.Item>
                  <SelectOptionWithLabel
                    label={
                      <div>
                        Position<span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    placeholder={"Position"}
                    items={optionsMLeave.map((option) => ({
                      value: option.m_leave_id,
                      title: option.leave_name,
                    }))}
                    value={
                      form?.values?.mLeaveId ? form?.values?.mLeaveId : null
                    }
                    onChange={(val) =>
                      form.setValues({
                        ...form.values,
                        mLeaveId: val,
                      })
                    }
                  />

                  {form.touched.mLeaveId && form.errors.mLeaveId ? (
                    <span className="text-error">{form.errors.mLeaveId}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <SelectOptionWithLabel
                    label={
                      <div>
                        Employee Status<span style={{ color: "#FF0000" }}> *</span>
                      </div>
                    }
                    placeholder={"Employee Status"}
                    items={optionsMLeave.map((option) => ({
                      value: option.m_leave_id,
                      title: option.leave_name,
                    }))}
                    value={
                      form?.values?.mLeaveId ? form?.values?.mLeaveId : null
                    }
                    onChange={(val) =>
                      form.setValues({
                        ...form.values,
                        mLeaveId: val,
                      })
                    }
                  />

                  {form.touched.mLeaveId && form.errors.mLeaveId ? (
                    <span className="text-error">{form.errors.mLeaveId}</span>
                  ) : null}
                </FormAntd.Item>
              </Col>

            </Row>
            <Row justify={"space-between"}>
            <Col span={11}>
                <FormAntd.Item>
                  <div className="flex flex-col">
                    <div className="text-slate-500">
                      {"Effective Date"}
                      <span className="text-red-500">*</span>
                    </div>
                    <DatePicker
                      name="startDate"
                      format="DD-MM-YYYY"
                      placeholder="DD-MM-YYYY"
                      allowClear={true}
                      value={
                        form.values.startDate
                          ? dayjs(form.values.startDate, "YYYY-MM-DD")
                          : null
                      }
                      onChange={(date) => {
                        const formattedDate = date
                          ? dayjs(date).format("YYYY-MM-DD")
                          : null;
                        form.setFieldValue("startDate", formattedDate);
                      }}
                      className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                    />
                    {form.touched.startDate && form.errors.startDate && (
                      <span className="text-error">
                        {form.errors.startDate}
                      </span>
                    )}
                  </div>
                </FormAntd.Item>
              </Col>
              <Col span={11}>
                <FormAntd.Item>
                  <div className="mt-8">
                    <div>
                      <label className="file-upload">
                        <input
                          type="file"
                          name="attachment"
                          id="attachment"
                          onChange={handleFileUpload}
                          accept=".jpeg, .jpg, .png, .pdf"
                          ref={inputRef}
                        />
                        <CloudUploadOutlined /> Attachment
                      </label>
                    </div>
                    <div>
                      {fileName && (
                        <div>
                          <p>
                            {fileName}{" "}
                            <Button
                              shape="circle"
                              onClick={handleFileDelete}
                              icon={<DeleteOutlined style={{ color: "red" }} />}
                              size={"small"}
                            />{" "}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </FormAntd.Item>
              </Col>
            </Row>
          </Col>
        </FormAntd>

        <div className="footer-add">
          <Button
            key="back"
            className="btn btn-sec"
            style={{ marginRight: "14px" }}
            onClick={() => navigate("/employee/employee-transfer")}
          >
            Cancel
          </Button>

          <Button
            key="submit"
            type="primary"
            className="btn btn-primary btn-sh-p"
            loading={fetching}
            onClick={form.handleSubmit}
            disabled={form.isSubmitting}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormCreateEmployeeTransfer;
