import { Button, Col, message, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getHolidayDetail } from "../../../api/holiday";

const HolidayDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [loading, setLoading] = useState(false);
    const [detail, setDetail] = useState([]);
    const BestpraticeUseEffect = useRef(false)
	
    const holidayListDetail = async () => {
		setLoading(true);
		try {
		  const res = await getHolidayDetail(id)
        //   console.log('detail test',res)
		  setDetail(res?.data?.data);
		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } 
        else{
            holidayListDetail();
          }
	}, []);

    const dataBreadcrumb = [
        {
			title: "Master Data - Holiday",
			url: "/holiday"
		},
        {
			title: "Holiday Detail",
			url: `/holiday/detail/${result}`
		},
	];
    const handleBack = () => {
        navigate('/holiday');
        window.location.reload()
      };
    
    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={handleBack} icon={<ArrowLeftOutlined />} size={"large"} />
				Holiday Detail
			</div>
           <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Holiday Name</div>
                            <div className="subtitle">{detail.map((data)=> data.holiday_name || '-')}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Description</div>
                            <div className="subtitle">{detail.map((data)=> data.description || '-')}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Type</div>
                            <div className="subtitle">{detail.map((data)=> data.holiday_type || '-')}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Date</div>
                            <div className="subtitle">{detail.map((data)=> data.holiday_date || '-')} </div>
                            {/* <div className="subtitle">{detail.map((data)=> moment(data.date_from).format('D') || '-')} - {detail.map((data)=> moment(data.date_to).format('D MMMM YYYY') || '-')}</div> */}
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Recurring</div>
                            <div className="subtitle">{detail.map((data)=> data.recurring || '-')}</div>
                        </div>
                    </Col>
                    <Col span={12}>
                        <div className="detail-card">
                            <div className="title">Cut leave </div>
                            <div className="subtitle">{detail.map((data)=> data.cut_leave || '-')}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default HolidayDetail