import { Button, Col, message, Row } from "antd";
import React, { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../../component/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { getShiftDetail } from "../../../api/shift";

const ShiftingShiftDetail = () => {
    const navigate = useNavigate();
    const location = window.location.href;
    var n = location.lastIndexOf('/');
    var result = location.substring(n + 1);
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [loading, setLoading] = useState(false);
    const BestpraticeUseEffect = useRef(false)
    const [detail, setDetail] = useState([]);

    const shiftList = async () => {
		setLoading(true);
		try {
		  const res = await getShiftDetail(id)
        //   console.log(res)
		  setDetail(res?.data?.data);
		} catch (err) {
			message.error({
				content: "Request Not Found",
			});
		  console.log(err);
		} finally {
		  setLoading(false);
		}
	  };
	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
        } else{
            shiftList();
        } 
	}, []);

    const dataBreadcrumb = [
        {
			title: "Master Data - Shift",
			url: "/shifting/shift"
		},
        {
			title: "Shift Detail",
			url: `/shifting/shift/detail-shift/${result}`
		},
	];
    return (
        <>
            <div className="title-section">
                <Button className="btn-sh-p" type="primary" shape="circle" style={{marginRight: "14px"}} onClick={() => navigate('/shifting/shift')} icon={<ArrowLeftOutlined />} size={"large"} />
				Shift Detail
			</div>
           <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="detail-wrapper">
                <Row>
                    <Col span={24}>
                        <div className="detail-card">
                            <div className="title">Shift Name</div>
                            <div className="subtitle">{detail?.name}</div>
                        </div>
                    </Col>
                    <Col span={3}>
                        <div className="detail-card">
                            <div className="title">Schedule In</div>
                            <div className="subtitle">{detail.schedule_in}</div>
                        </div>
                    </Col>
                    <Col span={3}>
                        <div className="detail-card">
                            <div className="title">Schedule Out</div>
                            <div className="subtitle">{detail.schedule_out}</div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="detail-card">
                            <div className="title">Tolerance</div>
                            <div className="subtitle">{detail.tolerance} Minutes</div>
                        </div>
                    </Col>
                    <Col span={3}>
                        <div className="detail-card">
                            <div className="title">Break Start</div>
                            <div className="subtitle">{detail.break_start}</div>
                        </div>
                    </Col>
                    <Col span={3}>
                        <div className="detail-card">
                            <div className="title">Break Period</div>
                            <div className="subtitle">{detail.break_period} Hours</div>
                        </div>
                    </Col>
                    <Col span={24}>
                        <div className="detail-card">
                            <div className="title">Workhour</div>
                            <div className="subtitle">{detail.workhour} Hours</div>
                        </div>
                    </Col>
                    <Col span={3}>
                        <div className="detail-card">
                            <div className="title">Is Default</div>
                            <div className="subtitle">{detail?.is_default ? 'Yes' : 'No'}</div>
                        </div>
                    </Col>
                    <Col span={3}>
                        <div className="detail-card">
                            {detail.default_type && (
                                <div className="title">Default Type</div>
                            )}                 
                            <div className="subtitle">{detail.default_type}</div>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default ShiftingShiftDetail