import { decryptData } from "../helper/cryptojs";
import { master } from "./hris";

export const getLevel = async (search, limit, offset, page, company) => {
    const companyStore = decryptData(sessionStorage.getItem("selectCompany"));
    let payload = {
        companyCode : companyStore || company !== null ? company : "",
        search_by : "name",
        search_value : search.length > 0 ? search : undefined,
        start :offset,
        length :limit,
        draw : page
    }
    
    return master.post("/level",payload);
}

export const addLevel = async (data) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        company_id : data.companyCode,
        name: data.name,
        sequence:data.sequence,
        created_by: user,
    }
    return master.post("/level-store", payload);
}

export const getLevelDetail = async (id) => {
    let payload = {
        level_id: id,
    }
    return master.post("/level-detail", payload);
}


export const getLevelSequence = async (company_id, code) => {
    let payload = {
        company_id: company_id,
        code: code
    }
    return master.post("/level-get-sequence", payload);
}



export const deleteLevel = async (id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        level_id: id,
        deleted_by: user
    }
    return master.post("/level-delete", payload);
}

export const editLevel = async (data, id) => {
    const user = decryptData(localStorage.getItem("Username"));
    let payload = {
        level_id: id,
        name: data.name,
        is_active: "1",
        sequence:data.sequence,
        modified_by: user
    }
    return master.post("/level-update", payload);
}