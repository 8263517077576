import { Button, Col, Form as FormAntd, message, Row, DatePicker, Checkbox } from "antd";
import React, { useEffect, useRef, useState } from "react";
import  { DatePicker2WithLabel, InputWithLabel, InputNumberWithLabel, SelectOptionWithLabel, NumberFormat, SelectOptionWithLabelAppliesAllvalue } from "../../component/Form/Input";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { addCompany, editCompany, getCompany, getCompanyDetail } from "../../api/company";
import { getBaseCalc } from "../../api/payrollComponent";
import moment from "moment";
import { DeleteOutlined,CloudUploadOutlined } from "@ant-design/icons";
import axios from "axios";
import './styles.scss'
import { decryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import dayjs from "dayjs";
const FormAddCompany = () => {
    const navigate = useNavigate();
    const [fetching, setFetching] = useState(false);
    const location = window.location.href;
    var n = location.split("/");
    var result =  n[5];;
    const decode = decodeURIComponent(result)
    var dec = decode.lastIndexOf('?');
    var id = decode.substring(dec + 1);
    const [detail, setDetail] = useState();
    const [flag, setFlag] = useState(0);
    const [listCompany, setListCompany] = useState([]);
    const inputRef = useRef(null);
    const [active, setActive] = useState(1)
    const deCompanyCode = decryptData(localStorage.getItem("DefaultCompanyCode"));
    const [fileName, setFileName] = useState(null);
    const [filePayroll, setFilePayroll] = useState(null);
    const inputRefPayroll = useRef(null);
    const BestpraticeUseEffect = useRef(false)
    const BestpraticeUseEffect1 = useRef(false)
    const [isCustomDivisor, setIsCustomDivisor] = useState(false)

    const companyDetail = async () => {
		setFetching(true);
		try {
		  const res = await getCompanyDetail(id)
          const {data} = res?.data
          data.divisor_proportional === 'custom_on_calendar_day' || data.divisor_proportional === 'custom_on_working_day' ? setIsCustomDivisor(true) : setIsCustomDivisor(false)
		  setDetail(data);
		} catch (err) {
		  console.log(err);
		} finally {
		  setFetching(false);
		}
	  };
    const checkValidity = (flag, arrayTarget = []) => {
        const found = arrayTarget.find((e) => e.id === flag)
        if(!found){
            return false
        }else{
            return true
        }
    }	
	useEffect(() => {
        if (!BestpraticeUseEffect.current) {
            BestpraticeUseEffect.current = true;
          } else {
            if(result){
                companyDetail();
            }
          }
     
	}, [flag]);

    const companyList = async () => {
		setFetching(true);
		try {
		  const res = await getCompany("", -1, 0, 0, active,deCompanyCode)
		  const data = res?.data?.data
		  setListCompany(data.map((item) => {
			return {
                idx : item.company_id,
				value : item.code,
				title : item.name
			}
		}))
		} catch (err) {
			message.error({
				content: err.response.data.error,
			});
		  console.log(err);
		} finally {
		  setFetching(false);
		}
	};
	
	useEffect(() => {
        if (!BestpraticeUseEffect1.current) {
            BestpraticeUseEffect1.current = true;
          } else {
            companyList();
          }

	}, []);

    const validationUser = yup.object().shape({
        companyName: yup.string().required("Field is required"),
        companyLogo: yup.string().required("Document Image is required"),
        companyPhoneNumber: yup.string().min(10, "Min 10 characters").required("Field is required"),
        email: yup.string().email("Must be a valid email").required("Field is required"),
        address: yup.string().required("Field is required"),
        province: yup.string().required("Field is required"),
        city: yup.string().required("Field is required"),
        contactPersonEmail: yup.string().email("Must be a valid email").nullable(),
        contactPersonNumber: yup.string().min(10, "Min 10 characters").nullable(),
        // postalCode: yup.string(),
        cut_off_type: yup.string().required("Field is required"),
        cut_off_date: yup.string().required("Field is required"),
        divisor_proportional: yup.string().required("Field is required"),
        divisor_custom_number: isCustomDivisor? yup.string().required("Field is required") : '',
        working_day: yup.array().min(1, 'Please select at least a day').required("Field is required"),
        proration: yup.string().required("Field is required"),
    });
    
    const form = useFormik({
        initialValues: {
            companyName: result ? detail?.name : null,
            companyPhoneNumber: result ? detail?.phone_number : null,
            email: result ? detail?.email : null,
            companyLogo: result ? false : null,
            address: result ? detail?.address : null,
            postalCode: result? (detail?.postal_code == null ? "" : detail?.postal_code) : "",
            province: result ? detail?.province : null,
            city: result ? detail?.city : null,
            industry: result ? (detail?.industry == 'null' ? "" : detail?.industry) : null,
            companyNpwp: result ? (detail?.company_npwp == 'null' ? "" : detail?.company_npwp) : null,
            companyTaxDate: result ? detail?.company_tax_date : "",
            taxPersonName: result ? (detail?.tax_person_name == 'null' ? "" : detail?.tax_person_name) : null,
            taxPersonNpwp: result ? (detail?.tax_person_npwp == 'null' ? "" : detail?.tax_person_npwp) : null,
            contactPersonName: result ? (detail?.contact_person_name == 'null' ? "" : detail?.contact_person_name) : null,
            contactPersonNumber: result ? (detail?.contact_person_number == 'null' ? "" : detail?.contact_person_number) : null,
            contactPersonEmail: result ? detail?.contact_person_email : "",
            status: result ? detail?.status : null,
            companyTemplatePayroll : result ? false : null,
            //added for cutoff & absence
            cut_off_type: result ? detail?.cut_off_type : null,
            cut_off_date: result ? detail?.cut_off_date : null,
            divisor_proportional: result ? detail?.divisor_proportional : null,
            divisor_include_holiday: result ? detail?.divisor_include_holiday : null,
            divisor_custom_number: result ? detail?.divisor_custom_number : null,
            working_day: result ? detail?.working_day : [],
            proration: result ? detail?.proration : null,
        },
        enableReinitialize: true,
        validationSchema: validationUser,
        onSubmit: async(values) => {
            if(result){
                setFetching(true)
                try {
                    const res = await editCompany(values, id)
                    if(!res?.data?.success){
                        message.error({
                            content: res?.data?.error,
                        });
                    } else {
                        message.success({
                            content: result ? "Company successfully updated" : "Company successfully saved",
                        });
                        setTimeout(() => {
                            // window.location.reload();
                        }, 1000);
                        navigate('/company')
                        form.resetForm();
                        setFlag((prev) => prev + 1)
                       
                    }
                    setFetching(false)
                } catch (error) {
                    message.error({
                        content: "Request Not Found",
                    });
                    setFetching(false)
                }
            } else {
                setFetching(true)
                try {
                    const res = await addCompany(values)
                    if(!res?.data?.success){
                        message.error({
                            content: res?.data?.error,
                        });
                    } else {
                        message.success({
                            content: result ? "Edit Data Success" : "Add Data Success",
                        });
                        navigate('/company')
                        form.resetForm();
                        setFlag((prev) => prev + 1)
                        window.location.reload()
                    }
                    setFetching(false)
                } catch (error) {
                    message.error({
                        content: "Request Not Found",
                    });
                    setFetching(false)
                }
            }
        },
    });



    const handleFileUpload = (e) => {
      const file = e.target.files[0];
      if (file && file.size <= 2 * 1024 * 1024) {
          setFileName(file.name);
        form.setValues({
            ...form.values,
            companyLogo: e.currentTarget.files[0],
        })
      }
      else{
        setFileName(null);
        let alesrt = message.error('Please upload a JPEG,JPG,PNG file with a maximum size of 2 MB');
        setTimeout(()=>{
            if(alesrt){
                window.location.reload(true)
            }
        },3000)
      
      }
    };

    const handleFilePayroll = (e) => {
        const file = e.target.files[0];
        setFilePayroll(file.name);
        form.setValues({
            ...form.values,
            companyTemplatePayroll: e.currentTarget.files[0],
        })
    };

    const handleFileDelete = () => {
        setFileName(null);
    };

    const dataBreadcrumb = [
		{
			title: "Master Data - Company",
			url: "/company"
		},
		{
			title: `${result ?  'Edit Company' : 'Add Company'}` ,
			url: `${ result ?  `/company/edit-company/${result}` : '/company/add-company'}`
		},
	]
  
    return (
        <>
          <div className="title-section">
				{result ? "Edit Company" : "Add Company"}
			</div>
            <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
				<Col span={24}>
					<Breadcrumb items={dataBreadcrumb}/>
				</Col>
			</div>
            <div className="add-wrapper">
           
          <FormAntd
              onFinish={form.handleSubmit}
          >
              <div className="title-divider">Company Logo <span style={{color:"#FF0000"}}> *</span></div>
              {detail?.company_logo_url && (
                  <div id={id}>
                    <img src={process.env.REACT_APP_HRIS_URL+`company/image/${detail?.company_logo_url}`} height="250px"/>
                </div>
              )}
              <Row>
                  <FormAntd.Item>
                      <div className="flex-container">
                          <div>
                              <label className="file-upload">
                                  <input type="file" onChange={handleFileUpload} accept=".jpeg, .jpg, .png"  ref={inputRef} />
                                  <CloudUploadOutlined /> Uploading Company Image
                              </label>
                          </div>
                          <div>
                              {fileName && (
                                  <div>
                                      <p>{fileName}   <Button  shape="circle" onClick={handleFileDelete} icon={<DeleteOutlined style={{color:'red'}}/>} size={"small"} /> </p>
                                  </div>
                              )}
                          </div>
                      </div>
                      {form.touched.companyLogo && form.errors.companyLogo ? (
                        <span className="text-error">{form.errors.companyLogo}</span>
                        ) : null}
                  </FormAntd.Item>
              </Row>
              <div className="title-divider">Company Info</div>
              <FormAntd.Item>
                  <InputWithLabel
                      name="companyName"
                      label={<div>Company Name<span style={{color:"#FF0000"}}> *</span></div>}
                      placeholder="Company Name"
                      value={form?.values?.companyName}
                      onChange={(e) => {
                          const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                          if (!regex.test(e.target.value)) {
                              form.handleChange(e);
                          }
                      }}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.companyName && form.errors.companyName ? (
                      <span className="text-error">{form.errors.companyName}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel 
                      name="companyPhoneNumber"
                      label={<div>Company Phone Number<span style={{color:"#FF0000"}}> *</span></div>}
                      placeholder="08**********"
                      value={form?.values?.companyPhoneNumber}
                      onChange={ (e) => {
                          const telNo = e.target.value;
                          const re = /^[0-9\b]+$/;
                          if (telNo === '' || re.test(telNo)) {
                              form.handleChange(e)
                          }
                      }}
                      onBlur={form.handleBlur}
                      maxLength={13}
                  />
                  {form.touched.companyPhoneNumber && form.errors.companyPhoneNumber ? (
                      <span className="text-error">{form.errors.companyPhoneNumber}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="email"
                      label={<div>Email<span style={{color:"#FF0000"}}> *</span></div>}
                      placeholder="Email"
                      value={form?.values?.email}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.email && form.errors.email ? (
                      <span className="text-error">{form.errors.email}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="address"
                      label={<div>Address<span style={{color:"#FF0000"}}> *</span></div>}
                      placeholder="Address"
                      value={form?.values?.address}
                      onChange={(e) => {
                          const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                          if (!regex.test(e.target.value)) {
                              form.handleChange(e);
                          }
                      }}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.address && form.errors.address ? (
                      <span className="text-error">{form.errors.address}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="postalCode"
                      label={"Postal Code"}
                      placeholder="Postal Code"
                      value={form?.values?.postalCode}
                      // onChange={form.handleChange}
                      onChange={ (e) => {
                          const telNo = e.target.value;
                          const re = /^[0-9\b]+$/;
                          if (telNo === '' || re.test(telNo)) {
                              form.handleChange(e)
                          }
                      }}
                      onBlur={form.handleBlur}
                      maxLength={6}
                  />
                  {form.touched.postalCode && form.errors.postalCode ? (
                      <span className="text-error">{form.errors.postalCode}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="province"
                      label={<div>Province<span style={{color:"#FF0000"}}> *</span></div>}
                      placeholder="Province"
                      value={form?.values?.province}
                      onChange={(e) => {
                          const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                          if (!regex.test(e.target.value)) {
                              form.handleChange(e);
                          }
                      }}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.province && form.errors.province ? (
                      <span className="text-error">{form.errors.province}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="city"
                      label={<div>City<span style={{color:"#FF0000"}}> *</span></div>}
                      placeholder="City"
                      value={form?.values?.city}
                      onChange={(e) => {
                          const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                          if (!regex.test(e.target.value)) {
                              form.handleChange(e);
                          }
                      }}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.city && form.errors.city ? (
                      <span className="text-error">{form.errors.city}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="industry"
                      label={"Industry"}
                      placeholder="Industry"
                      value={form?.values?.industry}
                      onChange={(e) => {
                          const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                          if (!regex.test(e.target.value)) {
                              form.handleChange(e);
                          }
                      }}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.industry && form.errors.industry ? (
                      <span className="text-error">{form.errors.industry}</span>
                  ) : null}
              </FormAntd.Item>
              <div className="title-divider">Tax Info</div>
              <FormAntd.Item>
                  <InputWithLabel
                      name="companyNpwp"
                      label={"Company NPWP"}
                      placeholder="Company Npwp"
                      value={form?.values?.companyNpwp}
                      // onChange={form.handleChange}
                      onChange={ (e) => {
                          const telNo = e.target.value;
                          const re = /^[0-9\b]+$/;
                          if (telNo === '' || re.test(telNo)) {
                              form.handleChange(e)
                          }
                      }}
                      maxLength={16}
                      onBlur={form.handleBlur}
                     
                  />
                  {form.touched.companyNpwp && form.errors.companyNpwp ? (
                      <span className="text-error">{form.errors.companyNpwp}</span>
                  ) : null}
              </FormAntd.Item>
                       <FormAntd.Item>
                          <div className="flex flex-col input-wrapper">
                          <div className="text-slate-500 label-input">{"Company Tax Date"}</div>
                          <DatePicker
                            name="companyTaxDate"
                            format="DD-MM-YYYY"
                            placeholder="DD-MM-YYYY"
                            
                            allowClear={true}
                            value={form.values.companyTaxDate ? dayjs(form.values.companyTaxDate, 'YYYY-MM-DD') : null}
                            onChange={date => {
                              const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
                              form.setFieldValue('companyTaxDate', formattedDate);
                      
                            }}
                            className="w-full h-10 bg-[#F8FAFB] border-none mt-2"
                          />
                          {form.touched.companyTaxDate && form.errors.companyTaxDate && (
                            <span className="text-error">{form.errors.companyTaxDate}</span>
                          )}
                        </div>
                      </FormAntd.Item>
                      <FormAntd.Item>
                          <InputWithLabel
                              name="taxPersonName"
                              label={"Tax Person Name"}
                              placeholder="Tax Person Name"
                              value={form?.values?.taxPersonName}
                              onChange={(e) => {
                                  const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                                  if (!regex.test(e.target.value)) {
                                      form.handleChange(e);
                                  }
                              }}
                              onBlur={form.handleBlur}
                          />
                          {form.touched.taxPersonName && form.errors.taxPersonName ? (
                              <span className="text-error">{form.errors.taxPersonName}</span>
                          ) : null}
                      </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="taxPersonNpwp"
                      label={"Tax Person NPWP"}
                      placeholder="Tax Person Npwp"
                      value={form?.values?.taxPersonNpwp}
                      onChange={ (e) => {
                          const telNo = e.target.value;
                          const re = /^[0-9\b]+$/;
                          if (telNo === '' || re.test(telNo)) {
                              form.handleChange(e)
                          }
                      }}
                      maxLength={16}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.taxPersonNpwp && form.errors.taxPersonNpwp ? (
                      <span className="text-error">{form.errors.taxPersonNpwp}</span>
                  ) : null}
              </FormAntd.Item>
              <div className="title-divider">Cut Off Settings</div>
              <FormAntd.Item>
                  <SelectOptionWithLabel
                      name="cut_off_type"
                      label={<div>Cut Off Type<span style={{color:"#FF0000"}}> *</span></div>}
                      items={[
                          {
                              value: 'monthly',
                              title: "Monthly"
                          },
                          {
                              value: 'weekly',
                              title: "Weekly"
                          },
                      ]}
                      value={form?.values?.cut_off_type}
                      onChange={(val) =>
                        form.setValues({
                        ...form.values,
                        cut_off_type: val,
                        cut_off_date: null,
                        })
                      }
                      onBlur={form.handleBlur}
                      />
                <div style={{marginTop: '1rem'}}>
                  {form.touched.cut_off_type && form.errors.cut_off_type ? (
                      <span className="text-error">{form.errors.cut_off_type}</span>
                    ) : null}
                </div>
              </FormAntd.Item>
              <FormAntd.Item>
                  {form?.values?.cut_off_type === 'weekly' ? 
                    ( <SelectOptionWithLabel
                      name="cut_off_date"
                      label={<div>Cut Off Date<span style={{color:"#FF0000"}}> *</span></div>}
                      items={[
                          {
                              value: 1,
                              title: "Monday"
                          },
                          {
                              value: 2,
                              title: "Tuesday"
                          },
                          {
                              value: 3,
                              title: "Wednesday"
                          },
                          {
                              value: 4,
                              title: "Thursday"
                          },
                          {
                              value: 5,
                              title: "Friday"
                          },
                          {
                              value: 6,
                              title: "Saturday"
                          },
                          {
                              value: 0,
                              title: "Sunday"
                          },
                      ]}
                      value={form?.values?.cut_off_date}
                      onChange={(val) =>
                        form.setValues({
                        ...form.values,
                        cut_off_date: val,
                        })
                      }
                      onBlur={form.handleBlur}
                  />)
                    : 
                    (<InputNumberWithLabel
                      name="cut_off_date"
                      label={<div>Cut Off Date<span style={{color:"#FF0000"}}> *</span></div>}
                      placeholder=""
                      value={form.values.cut_off_date ? form.values.cut_off_date : null }
                      onChange={(value) =>{
                        const re = /^[0-9]*$/;
                        if (value === '' || re.test(value)) {
                            form.setValues({
                                ...form.values,
                                cut_off_date: value
                            })
                        }
                      }}
                      stringMode
                      step={1}
                      max={31}
                      min={1}
                      style={{ width: "100%" }}
                  />)}
                <div style={{marginTop: '1rem'}}>
                  {form.touched.cut_off_date && form.errors.cut_off_date ? (
                      <span className="text-error">{form.errors.cut_off_date}</span>
                  ) : null}
                  </div>
              </FormAntd.Item>
              <FormAntd.Item>
                  <SelectOptionWithLabel
                      name="divisor_proportional"
                      label={<div>Divisor For Proportional Calculation<span style={{color:"#FF0000"}}> *</span></div>}
                      items={[
                          {
                              value: 'calendar_day',
                              title: "Calendar Day"
                          },
                          {
                              value: 'working_day',
                              title: "Working Day"
                          },
                          {
                              value: 'custom_on_calendar_day',
                              title: "Custom on Calendar Day"
                          },
                          {
                              value: 'custom_on_working_day',
                              title: "Custom on Working Day"
                          },
                      ]}
                      value={form?.values?.divisor_proportional}
                      onSelect={(val) =>{
                        if(val.includes('custom')){
                            setIsCustomDivisor(true)
                        }else{
                            setIsCustomDivisor(false)
                        }
                        form.setValues({
                        ...form.values,
                        divisor_proportional: val,
                        divisor_custom_number: null,
                        divisor_include_holiday: false, 
                        })
                      }}
                      onBlur={form.handleBlur}
                  />
                <div style={{marginTop: '1rem'}}>
                  {form.touched.divisor_proportional && form.errors.divisor_proportional ? (
                      <span className="text-error">{form.errors.divisor_proportional}</span>
                  ) : null}
                </div>
              </FormAntd.Item>
              {form?.values?.divisor_proportional === "working_day"?
              <FormAntd.Item>
                  <Checkbox
                    name="divisor_include_holiday"
                    checked={form?.values?.divisor_include_holiday}
                    onChange={(e)=>{
                        form.setValues({
                            ...form.values,
                            divisor_include_holiday: e.target.checked, 
                            })
                    }}
                  >
                    <div className="text-slate-500">
                  Include Holiday in Working Days? 
                    </div>
                  </Checkbox>
              </FormAntd.Item>
              : 
              form?.values?.divisor_proportional === "custom_on_calendar_day" || form?.values?.divisor_proportional === "custom_on_working_day" ?
              <FormAntd.Item>
                  <InputNumberWithLabel
                      name="divisor_custom_number"
                      label={<div>Determine Divisor Number<span style={{color:"#FF0000"}}> *</span></div>}
                      placeholder=""
                      value={form?.values?.divisor_custom_number ? form?.values?.divisor_custom_number : null }
                      onChange={(value) =>{
                        const re = /^[0-9]*$/;
                        if (value === '' || re.test(value)) {
                            form.setValues({
                                ...form.values,
                                divisor_custom_number: value
                            })
                        }
                      }}
                      stringMode
                      step={1}
                      max={31}
                      min={1}
                      style={{ width: "100%" }}
                  />
                <div style={{marginTop: '1rem'}}>
                  {form.touched.divisor_custom_number && form.errors.divisor_custom_number ? (
                      <span className="text-error">{form.errors.divisor_custom_number}</span>
                  ) : null}
                </div>
              </FormAntd.Item>
              :
              null
              }
              <FormAntd.Item>
                  <div className="label-input">Working Days<span style={{color:"#FF0000"}}> *</span></div>
                  <div className="label-input" style={{marginTop: '1rem'} }>Select the days your company usually work</div>
                  <Checkbox.Group style={{ width: '100%', marginLeft: '1.3rem' }} 
                  name="working_day"
                  value={form.values?.working_day}
                  onChange={(e)=>{
                    form.setValues({
                        ...form.values,
                        working_day: e
                    })
                  }}
                  >
                      <Col xs={24} sm={24} md={24} lg={8} >
                        <Row>
                          <Checkbox value={1}><div className="text-slate-500" style={{padding: 16}}>Monday</div></Checkbox>
                        </Row>
                        <Row>
                          <Checkbox value={2}><div className="text-slate-500" style={{padding: 16}}>Tuesday</div></Checkbox>
                        </Row>
                        <Row>
                          <Checkbox value={3}><div className="text-slate-500" style={{padding: 16}}>Wednesday</div></Checkbox>
                        </Row>
                        <Row>
                          <Checkbox value={4}><div className="text-slate-500" style={{padding: 16}}>Thursday</div></Checkbox>
                        </Row>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={8}>
                        <Row>
                          <Checkbox value={5}><div className="text-slate-500" style={{padding: 16}}>Friday</div></Checkbox>
                        </Row>
                        <Row>
                          <Checkbox value={6}><div className="text-slate-500" style={{padding: 16}}>Saturday</div></Checkbox>
                        </Row>
                        <Row>
                          <Checkbox value={0}><div className="text-slate-500" style={{padding: 16}}>Sunday</div></Checkbox>
                        </Row>
                      </Col>
                  </Checkbox.Group>
                  <div style={{marginTop: '1rem'}}>
                  {form.touched.working_day && form.errors.working_day ? (
                      <span className="text-error">{form.errors.working_day}</span>
                  ) : null}
                </div>
              </FormAntd.Item>
              <FormAntd.Item>
                  <SelectOptionWithLabel
                      name="proration"
                      label={<div>New Employee Proration<span style={{color:"#FF0000"}}> *</span></div>}
                      items={[
                          {
                              value: 'same',
                              title: "Same Cut off Period"
                          },
                          {
                              value: 'next',
                              title: "Next Cut off Period"
                          },
                      ]}
                      value={form?.values?.proration}
                      onChange={(val) =>
                        form.setValues({
                        ...form.values,
                        proration: val,
                        })
                      }
                      onBlur={form.handleBlur}
                  />
                  <div style={{marginTop: '1rem'}}>
                  {form.touched.proration && form.errors.proration ? (
                      <span className="text-error">{form.errors.proration}</span>
                  ) : null}
                  </div>
              </FormAntd.Item>
              <FormAntd.Item>
                  <div className="label-input">Template<span style={{color:"#FF0000"}}> *</span></div>
                  <Col>
                    <Row>
                        <label className="file-upload">
                            <input type="file" onChange={handleFilePayroll} accept=".xls, .xlsx"  ref={inputRefPayroll} />
                            <CloudUploadOutlined /> Uploading Template Payroll
                        </label>
                    </Row>
                    <Row>
                        {filePayroll && (
                              <div>
                                  <p>{filePayroll}   <Button  shape="circle" onClick={() => setFilePayroll(null)} icon={<DeleteOutlined style={{color:'red'}}/>} size={"small"} /> </p>
                              </div>
                          )}
                    </Row>
                  </Col>
                  {form.touched.companyTemplatePayroll && form.errors.companyTemplatePayroll ? (
                    <span className="text-error">{form.errors.companyTemplatePayroll}</span>
                    ) : null}
              </FormAntd.Item>
              <div className="title-divider">Other Info</div>
              <FormAntd.Item>
                  <InputWithLabel
                      name="contactPersonName"
                      label={"Contact Person Name"}
                      placeholder="Contact Person Name"
                      value={form?.values?.contactPersonName}
                      onChange={(e) => {
                          const regex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|\p{Emoji_Presentation}+/gu;
                          if (!regex.test(e.target.value)) {
                              form.handleChange(e);
                          }
                      }}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.contactPersonName && form.errors.contactPersonName ? (
                      <span className="text-error">{form.errors.contactPersonName}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel 
                      name="contactPersonNumber"
                      label={"Contact Person Number"}
                      placeholder="08**********"
                      value={form?.values?.contactPersonNumber}
                      onChange={ (e) => {
                          const telNo = e.target.value;
                          const re = /^[0-9\b]+$/;
                          if (telNo === '' || re.test(telNo)) {
                              form.handleChange(e)
                          }
                      }}
                      onBlur={form.handleBlur}
                      maxLength={13}
                  />
                  {form.touched.contactPersonNumber && form.errors.contactPersonNumber ? (
                      <span className="text-error">{form.errors.contactPersonNumber}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="contactPersonEmail"
                      label={"Contact Person Email"}
                      placeholder="Contact Person Email"
                      value={form?.values?.contactPersonEmail}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.contactPersonEmail && form.errors.contactPersonEmail ? (
                      <span className="text-error">{form.errors.contactPersonEmail}</span>
                  ) : null}
              </FormAntd.Item>
              <Row>
              {/* <FormAntd.Item>
                      <div className="flex-container">
                          <div>
                              <label className="file-upload">
                                  <input type="file" onChange={handleFilePayroll} accept=".xls, .xlsx"  ref={inputRefPayroll} />
                                  <CloudUploadOutlined /> Uploading Template Payroll
                              </label>
                          </div>
                          <div>
                              {filePayroll && (
                                  <div>
                                      <p>{filePayroll}   <Button  shape="circle" onClick={() => setFilePayroll(null)} icon={<DeleteOutlined style={{color:'red'}}/>} size={"small"} /> </p>
                                  </div>
                              )}
                          </div>
                      </div>
                      {form.touched.companyTemplatePayroll && form.errors.companyTemplatePayroll ? (
                        <span className="text-error">{form.errors.companyTemplatePayroll}</span>
                        ) : null}
                  </FormAntd.Item> */}
                  </Row>
              {result && (
                  <FormAntd.Item>
                      <SelectOptionWithLabel
                          name="status"
                          label={"Status"} 
                          items={[
                              {
                                  value: 1,
                                  title: "Active"
                              },
                              {
                                  value: 0,
                                  title: "Inactive"
                              },
                          ]}
                          value={form?.values?.status}
                          onChange={(val) =>
                              form.setValues({
                              ...form.values,
                              status: val,
                              })
                          }
                          onBlur={form.handleBlur}
                      />
                      {form.touched.status && form.errors.status ? (
                          <span className="text-error">{form.errors.status}</span>
                      ) : null}
                  </FormAntd.Item>
              )}
              <div className="title-divider">Social Media</div>
              <FormAntd.Item>
                  <InputWithLabel
                      name="recruitmentEmail"
                      label={"Recruitment Email"}
                      placeholder="Recruitment Email"
                      value={form?.values?.recruitmentEmail}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.recruitmentEmail && form.errors.recruitmentEmail ? (
                      <span className="text-error">{form.errors.recruitmentEmail}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="website"
                      label={"Website"}
                      placeholder="Website"
                      value={form?.values?.website}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.website && form.errors.website ? (
                      <span className="text-error">{form.errors.website}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="instagram"
                      label={"Instagram"}
                      placeholder="Instagram"
                      value={form?.values?.instagram}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.instagram && form.errors.instagram ? (
                      <span className="text-error">{form.errors.instagram}</span>
                  ) : null}
              </FormAntd.Item>
              <FormAntd.Item>
                  <InputWithLabel
                      name="linkedin"
                      label={"Linkedin"}
                      placeholder="Linkedin"
                      value={form?.values?.linkedin}
                      onChange={form.handleChange}
                      onBlur={form.handleBlur}
                  />
                  {form.touched.linkedin && form.errors.linkedin ? (
                      <span className="text-error">{form.errors.linkedin}</span>
                  ) : null}
              </FormAntd.Item>
          </FormAntd>
          <div className="footer-add">
              <Button key="back" className="btn btn-sec" style={{marginRight: "14px"}} onClick={() => navigate('/company')}>
                  Cancel
              </Button>
              <Button key="submit" type="primary" className="btn btn-primary btn-sh-p" loading={fetching} onClick={form.handleSubmit} disabled={form.isSubmitting}>
                  Submit
              </Button>
          </div>
      </div>
        </>
        
      
    )
}

export default FormAddCompany;