// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iconWrapper .ant-steps-item-icon {
  width: 50px;
  height: 50px;
  line-height: 50px;
}
.iconWrapper .ant-steps-item-tail {
  padding: 40px !important;
  position: absolute;
  top: -10px;
  left: 10px;
}
.iconWrapper .ant-steps-item-title {
  width: 140px;
}
.iconWrapper .ant-steps-item-finish .ant-steps-item-icon {
  background-color: #e6f4ff;
  border-color: #d4d7fe;
}
@media (max-width: 768px) {
  .iconWrapper .ant-steps-item-icon {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .iconWrapper .ant-steps-item-tail {
    padding: 30px !important;
    top: -10px;
    left: 5px;
  }
  .iconWrapper .ant-steps-item-title {
    width: 140px !important;
    font-size: 12px !important;
  }
}
@media (max-width: 480px) {
  .iconWrapper .ant-steps-item-icon {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }
  .iconWrapper .ant-steps-item-tail {
    padding: 20px !important;
    top: 15px !important;
    left: -5px !important;
  }
  .iconWrapper .ant-steps-item-title {
    width: 80px !important;
  }
}

.section-modal {
  border: #808080 1px solid;
}`, "",{"version":3,"sources":["webpack://./src/views/RunPayroll/styles.scss"],"names":[],"mappings":"AACE;EACE,WAAA;EACA,YAAA;EACA,iBAAA;AAAJ;AAGE;EACE,wBAAA;EACA,kBAAA;EACA,UAAA;EACA,UAAA;AADJ;AAIE;EACE,YAAA;AAFJ;AAKE;EACE,yBAAA;EACA,qBAAA;AAHJ;AAME;EACE;IACE,WAAA;IACA,YAAA;IACA,iBAAA;EAJJ;EAOE;IACE,wBAAA;IACA,UAAA;IACA,SAAA;EALJ;EAQE;IACE,uBAAA;IACA,0BAAA;EANJ;AACF;AASE;EACE;IACE,WAAA;IACA,YAAA;IACA,iBAAA;EAPJ;EAUE;IACE,wBAAA;IACA,oBAAA;IACA,qBAAA;EARJ;EAYE;IACE,sBAAA;EAVJ;AACF;;AAcA;EACE,yBAAA;AAXF","sourcesContent":[".iconWrapper {\n  .ant-steps-item-icon {\n    width: 50px;\n    height: 50px;\n    line-height: 50px;\n  }\n\n  .ant-steps-item-tail{\n    padding: 40px !important;\n    position: absolute;\n    top: -10px;\n    left: 10px;\n}\n\n  .ant-steps-item-title {\n    width: 140px;\n  }\n\n  .ant-steps-item-finish .ant-steps-item-icon {\n    background-color: #e6f4ff;\n    border-color: #d4d7fe;\n  }\n\n  @media (max-width: 768px) {\n    .ant-steps-item-icon {\n      width: 40px;\n      height: 40px;\n      line-height: 40px;\n    }\n\n    .ant-steps-item-tail {\n      padding: 30px !important;\n      top: -10px;\n      left: 5px;\n    }\n\n    .ant-steps-item-title {\n      width: 140px !important;\n      font-size: 12px !important;\n    }\n  }\n\n  @media (max-width: 480px) {\n    .ant-steps-item-icon {\n      width: 30px;\n      height: 30px;\n      line-height: 30px;\n    }\n\n    .ant-steps-item-tail {\n      padding: 20px !important;\n      top: 15px !important;\n      left: -5px !important;\n\n    }\n\n    .ant-steps-item-title {\n      width: 80px !important;\n    }\n  }\n}\n\n.section-modal {\n  border: #808080 1px solid;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
