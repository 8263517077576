/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Form as FormAntd, message, Modal } from "antd";
import { useEffect, useRef, useState } from "react";
import * as yup from "yup";
import {
  InputWithLabel,
  NumberFormat,
  SelectOptionWithLabelApplies,
  SelectOptionWithLabelAppliesAllvalue,
  SelectOptionWithLabelPeriode,
} from "../../component/Form/Input";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import "./styles.scss";
import { decryptData, encryptData } from "../../helper/cryptojs";
import Breadcrumb from "../../component/Breadcrumb";
import {
  AddLoanSetting,
  EditLoanSetting,
  getListDropdownListInputLoan,
  getLoanSettingDetail,
} from "../../api/loan";
const FormAddLoanSetting = () => {
  const navigate = useNavigate();
  const location = window.location.href;
  let n = location.split("/");
  let result = n[5];
  const decode = decodeURIComponent(result);
  let dec = decode.lastIndexOf("?");
  let id = decode.substring(dec + 1);
  const companyStore = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompany"))
    : decryptData(localStorage.getItem("DefaultCompanyCode"));
  const deAppiesTo = decryptData(sessionStorage.getItem("AppliesTo"));
  const [fetching, setFetching] = useState(false);
  const [detail, setDetail] = useState([]);
  const [optionsMLeave, setOptionsMLeave] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const BestpraticeUseEffect = useRef(false);

  const handleButtonClick = async () => {
    setIsModalVisible(true);
  };

  const handleModalSubmit = async () => {
    await form.handleSubmit();
    setIsModalVisible(false);
  };

  const handleModalNo = () => {
    setIsModalVisible(false);
  };

  const dropdownListLevel = async () => {
    try {
      const res = await getListDropdownListInputLoan(companyStore);
      const data = res?.data?.data;
      setOptionsMLeave(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getDetailLoanSetting = async () => {
    try {
      const res = await getLoanSettingDetail(id);
      setDetail(res?.data?.data);
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    if (!BestpraticeUseEffect.current) {
      BestpraticeUseEffect.current = true;
    } else {
      getDetailLoanSetting();
      dropdownListLevel();
      form.resetForm();
    }
  }, []);

  const validationUser = yup.object().shape({
    name: yup.string().required("Field is required"),
    max_tenor: yup.string().required("Field is required"),
    max_loan: yup.string().required("Field is required"),
    min_work_year: yup.string().required("Field is required"),
    min_work_month: yup.string().required("Field is required"),
    applies_to: yup.string().required("Field is required"),
    ...(deAppiesTo === "level"
      ? {
          applies_to_level_id: yup
            .number()
            .typeError("Field is required")
            .required("Field is required"),
        }
      : {}),
    ...(detail?.is_active
      ? {
          is_active: yup.string().required("Field is required"),
        }
      : {}),
  });

  const form = useFormik({
    initialValues: {
      name: result ? detail?.name : "",
      companyCode: companyStore,
      max_tenor: result ? detail?.max_tenor : "",
      max_loan: result ? detail?.max_loan : "",
      min_work_year: result ? detail?.min_work_year : "",
      min_work_month: result ? detail?.min_work_month : "",
      applies_to: result ? detail?.applies_to : "",
      applies_to_level_id:
        result && detail?.applies_to_level_id
          ? JSON.parse(detail.applies_to_level_id)
          : [],
      // ...(detail?.is_active
      //   ? {
      //       is_active: result ? detail?.is_active : "",
      //     }
      //   : {}),
      is_active: result ? detail?.is_active : "",
    },
    enableReinitialize: true,
    validationSchema: validationUser,

    validate: (values) => {
      const errors = {};

      if (values.applies_to === "level") {
        if (
          values.applies_to_level_id &&
          values.applies_to_level_id.length === 0
        ) {
          errors.applies_to_level_id = "Please input level at least 1";
        }
      }

      return errors;
    },
    onSubmit: async (values) => {
      const selectedLevels = form.values.applies_to_level_id || [];
      values["applies_to_level_id"] = selectedLevels;
      try {
        const res = result
          ? await EditLoanSetting(values, id)
          : await AddLoanSetting(values);
        if (!res?.data?.success) {
          message.error({
            content: res?.data?.error,
          });
        } else {
          message.success({
            content: result
              ? "Loan successfully updated saved"
              : "Loan successfully submitted saved",
          });
          navigate("/loan-setting");
          form.resetForm();
        }
        setFetching(false);
      } catch (error) {
        message.error({
          content: "Loan failed submitted to saved",
        });
        setFetching(false);
      }
    },
  });

  const dataBreadcrumb = [
    {
      title: "Loan Management - Loan Setting",
      url: "/loan-setting",
    },
    {
      title: `${result ? "Edit Loan Setting" : "Add Loan Setting"}`,
      url: ``
    },
  ];

  return (
    <>
      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={[
          <Button key="submit" type="primary" onClick={handleModalSubmit}>
            Submit
          </Button>,
          <Button key="no" onClick={handleModalNo}>
            No
          </Button>,
        ]}>
        <p>Are you sure to change loan setting?</p>
      </Modal>
      <div className="title-section">
        {result ? "Edit Loan Setting" : "Add Loan Setting"}
      </div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="add-wrapper">
        <FormAntd onFinish={form.handleSubmit}>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-12 md:gap-[119px] mb-1">
                <div className="flex-grow">
                  <InputWithLabel
                    label={
                      <div>
                        Loan Name<span className="text-red-500 ml-1">*</span>
                      </div>
                    }
                    maxWidth={10}
                    name="name"
                    placeholder="Loan Name"
                    value={form.values.name}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    maxLength={25}
                  />
                  <div
                    className={
                      form.touched.name &&
                      form.errors.name === "Field is required"
                        ? "mb-[-22px] mt-0"
                        : "mt-3 mb-[-22px]"
                    }>
                    {form.touched.name && form.errors.name ? (
                      <span className="text-error">{form.errors.name}</span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-[70px] md:gap-[55px] mb-1">
                <div className="flex-grow">
                  <div className="w-full md:w-[200px] ">
                    <InputWithLabel
                      className="w-full md:w-[200px] mt-3"
                      name="max_tenor"
                      label={
                        <div>
                          Maximum Tenor
                          <span className="text-red-500 ml-1">*</span>
                        </div>
                      }
                      placeholder="Maximum Tenor"
                      value={
                        form.values.max_tenor ? form.values.max_tenor : null
                      }
                      onChange={(e) => {
                        const regex = e.target.value;
                        const reg = /^[0-9\b]+$/;
                        if (
                          regex === "" ||
                          (reg.test(regex) &&
                            parseInt(regex) >= 1 &&
                            parseInt(regex) <= 36)
                        ) {
                          if (parseInt(regex) === 1) {
                            sessionStorage.setItem(
                              "validationMessageSetting",
                              "Input minimum tenor 2 months"
                            );
                          } else {
                            sessionStorage.removeItem(
                              "validationMessageSetting"
                            );
                          }
                          form.handleChange(e);
                        } else {
                          sessionStorage.removeItem("validationMessageSetting");
                        }
                        sessionStorage.setItem("tenorPeriod", e.target.value);
                      }}
                      maxLength={2}
                      minLength={0}
                      addonAfter="Months"
                    />
                  </div>
                  <div
                    className={form.touched.max_tenor && form.errors.max_tenor}>
                    {form.touched.max_tenor && form.errors.max_tenor ? (
                      <span className="text-error">
                        {form.errors.max_tenor}
                      </span>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <div className="input-wrapper">
            <p className="label-input">
              Minimum working period<span style={{ color: "#FF0000" }}> *</span>
              <br />
              <span className="ml-0 text-[12px] text-slate-400">
                Starting from the joining date
              </span>
            </p>
          </div>
          <FormAntd.Item>
            <div
              style={{ display: "flex", alignItems: "center", gap: 20 }}
              className="mb-4">
              <div className="w-full md:w-[200px] ">
                <InputWithLabel
                  name="min_work_year"
                  placeholder="Years"
                  min={1}
                  max={10}
                  onChange={(e) => {
                    const regex = e.target.value;
                    const reg = /^[0-9\b]+$/;
                    if (
                      regex === "" ||
                      (reg.test(regex) &&
                        parseInt(regex) >= 0 &&
                        parseInt(regex) <= 9)
                    ) {
                      form.handleChange(e);
                      form.setFieldValue("min_work_year", regex);
                    }
                  }}
                  maxLength={1}
                  value={form?.values?.min_work_year}
                  addonAfter="Years"
                />
                {form.touched.min_work_year && form.errors.min_work_year ? (
                  <span className="text-error absolute">
                    {form.errors.min_work_year}
                  </span>
                ) : null}
              </div>
              <div className="w-full md:w-[200px] ">
                <InputWithLabel
                  name="min_work_month"
                  placeholder="Months"
                  value={
                    form.values.min_work_month
                      ? form.values.min_work_month
                      : null
                  }
                  onChange={(e) => {
                    const regex = e.target.value;
                    const reg = /^[0-9\b]+$/;
                    if (
                      regex === "" ||
                      (reg.test(regex) &&
                        parseInt(regex) >= 0 &&
                        parseInt(regex) <= 11)
                    ) {
                      form.handleChange(e);
                      form.setFieldValue("min_work_month", regex);
                    }
                  }}
                  maxLength={2}
                  minLength={1}
                  addonAfter="Months"
                />

                {form.touched.min_work_month && form.errors.min_work_month ? (
                  <span className="text-error absolute">
                    {form.errors.min_work_month}
                  </span>
                ) : null}
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col mt-[-20px]">
              <div className="flex items-center gap-6 md:gap-[90px] mb-1">
                <div className="flex-grow">
                  <NumberFormat
                    label={
                      <div>
                        Maximum Loan<span className="text-red-500 ml-1">*</span>
                      </div>
                    }
                   
                    allowNegative={false}
                    name="max_loan"
                    type="text"
                    placeholder="Rp."
                    thousandSeparator={true}
                    value={form?.values?.max_loan}
                    onChange={form.handleChange}
                    onBlur={form.handleBlur}
                    maxLength={14}
                  />
                </div>
              </div>
            </div>
          </FormAntd.Item>
          <FormAntd.Item>
            <div className="flex flex-col">
              <div className="flex items-center gap-6 md:gap-[130px] mb-1">
                <div className="flex-grow">
                  <Col >
                    <SelectOptionWithLabelApplies
                      name="applies_to"
                      label={
                        <div>
                          Applies to<span style={{ color: "#FF0000" }}> *</span>
                        </div>
                      }
                      items={[
                        {
                          value: "all",
                          title: "All Employees",
                        },
                        {
                          value: "level",
                          title: "Based on Level",
                        },
                      ]}
                      value={
                        form?.values?.applies_to
                          ? form?.values?.applies_to
                          : null
                      }
                      onChange={(val) => {
                        form.setValues(
                          {
                            ...form.values,
                            applies_to: val,
                          },
                          sessionStorage.setItem("AppliesTo", encryptData(val))
                        );
                      }}
                      placeholder="Default by employee setting"
                      onBlur={form.handleBlur}
                    />
                    {form.touched.applies_to && form.errors.applies_to ? (
                      <span className="text-error">
                        {form.errors.applies_to}
                      </span>
                    ) : null}
                  </Col>
                  {form.values.applies_to === "level" ? (
                    <div className="mt-10">
                      <SelectOptionWithLabelAppliesAllvalue
                        style={{
                          maxWidth: 420,
                        }}
                        className="w-auto h-auto"
                        name="applies_to_level_id"
                        mode="multiple"
                        options={optionsMLeave.map((item) => ({
                          label: item.name,
                          value: item.level_id,
                        }))}
                        value={form?.values?.applies_to_level_id}
                        onChange={(val) => {
                          form.setValues({
                            ...form.values,
                            applies_to_level_id: val,
                          });
                        }}
                        placeholder="Level"
                        onBlur={form.handleBlur}
                        filterOption={(input, option) =>
                          option.label
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      />

                      <div
                        className={
                          form.touched.applies_to_level_id &&
                          form.errors.applies_to_level_id ===
                            "Field is required"
                            ? "mb-[-22px] mt-[-10px] ml-0"
                            : "mt-0 mb-[-22px]"
                        }>
                        {form.touched.applies_to_level_id &&
                        form.errors.applies_to_level_id ? (
                          <span className="text-error">
                            {form.errors.applies_to_level_id}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </FormAntd.Item>
          {detail?.is_active === 1 || detail?.is_active === 0 ? (
            <FormAntd.Item>
              <div className="flex flex-col">
                <div className="flex items-center gap-6 md:gap-[130px] mb-1">
                  <div className="flex-grow">
                    <SelectOptionWithLabelPeriode
                      label={
                        <span className="mr-1 label-input">
                          Status<span className="text-red-500 ml-1">*</span>
                        </span>
                      }
                      placeholder={"Active / Inactive"}
                      name="is_active"
                      items={[
                        {
                          value: "1",
                          title: "Active",
                        },
                        {
                          value: "0",
                          title: "Inactive",
                        },
                      ]}
                      value={
                        form?.values?.is_active == 1 ? "Active" : "Inactive"
                      }
                      onChange={(val) =>
                        form.setValues({
                          ...form.values,
                          is_active: val,
                        })
                      }
                      onBlur={form.handleBlur}
                    />

                    <div
                      className={
                        form.touched.is_active &&
                        form.errors.is_active === "Field is required"
                          ? "mb-[-22px] mt-0 ml-0"
                          : "mt-3 mb-[-22px]"
                      }>
                      {form.touched.is_active && form.errors.is_active ? (
                        <span className="text-error">
                          {form.errors.is_active}
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </FormAntd.Item>
          ) : null}
        </FormAntd>

        <div className="footer-add">
          <Button
            key="back"
            className="btn btn-sec"
            style={{ marginRight: "14px" }}
            onClick={() => navigate("/loan-setting")}>
            Cancel
          </Button>

          <Button
            key="submit"
            type="primary"
            className="btn btn-primary btn-sh-p"
            loading={fetching}
            onClick={detail?.is_active ? handleButtonClick : form.handleSubmit}
            disabled={form.isSubmitting}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default FormAddLoanSetting;
