/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Pagination, Row, DatePicker, message, Tooltip } from "antd";
import { StopOutlined, DownloadOutlined} from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import Breadcrumb from "../../component/Breadcrumb";
import Table from "../../component/Table";
import { decryptData } from "../../helper/cryptojs";
import { getListReportPayroll, downloadResult } from "../../api/reportPayroll";
import { formatCurrency } from "../../helper/formatCurrency";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";

export default function ReportInsurance() {
  const navigate = useNavigate();
  const company = decryptData(sessionStorage.getItem("selectCompany"))
    ? decryptData(sessionStorage.getItem("selectCompanyId"))
    : decryptData(localStorage.getItem("DefaultCompanyId"));
  const RoleAccessReportPayrollDownload = JSON.parse(
    localStorage.getItem("RoleaccessPriviledgeReportPayrollDownload")
  );
  const limitSession = sessionStorage.getItem("sessionPageInsurance");
  const offsetSession = sessionStorage.getItem("sessionOffsetInsurance");
  const sessionDate = sessionStorage.getItem("sessionDateInsurance");
  const [loading, setLoading] = useState(false);
  const [listTable, setListTable] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [page, setPage] = useState(limitSession ? limitSession : 1);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(offsetSession ? offsetSession : 0);
  const [selectedDate, setSelectedDate] = useState(sessionDate ?? dayjs().format('YYYY-MM'));
  const previousPage = useRef(1);
  const BestpraticeUseEffect = useRef(false);

  const ReportPayroll = async () => {
    setLoading(true);
    try {
      const res = await getListReportPayroll(company,page,offset,limit,selectedDate);
      setListTable(res.data.data);
      setTotalData(res.data.recordsTotal);
    } catch (err) {
      message.error({
        content: "Request Not Found",
      });
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const actDownloadResult = async (id) => {
    window.open(`${process.env.REACT_APP_HRIS_URL}run-payroll/download-result/${id}`, '_blank');
  }

  useEffect(() => {
    sessionStorage.setItem("sessionPagePayroll", page);
  }, [page]);

  useEffect(() => {
      ReportPayroll();
      const beforeUnloadHandler = () => {
        sessionStorage.removeItem("sessionPagePayroll");
        sessionStorage.removeItem("sessionOffsetPayroll");
        sessionStorage.removeItem("sessionDatePayroll");
        setPage(1)
				setOffset(0)
      };
      window.addEventListener("beforeunload", beforeUnloadHandler);
      return () => {
        window.removeEventListener("beforeunload", beforeUnloadHandler);
      };
  }, [company,selectedDate,page,offset,limit]);

  useEffect(() => {
    const limitSession = sessionStorage.getItem("sessionPagePayroll");
    if (limitSession) {
      setPage(parseInt(limitSession));
    }
  }, []);



  useEffect(() => {
		let offsetSession = 0;
		if(page > 1){
			offsetSession = page*limit - 10
		}
		setOffset(offsetSession)
	  sessionStorage.setItem("sessionPagePayroll", page);
    sessionStorage.setItem("sessionOffsetPayroll", offset);
	},[page])

  const columns = [
    {
      title: "No",
      dataIndex: "no",
      key: "no",
      render: (text, record, index) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={()=>navigate(`/payroll-report/detail/${encodeURIComponent('id?'+record.id)}`)}
            >
            {text}
          </div>
        );
      },
    },
    {
      title: "Period",
      dataIndex: "period",
      key: "period",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={()=>navigate(`/payroll-report/detail/${encodeURIComponent('id?'+record.id)}`)}
            >
            {text}
          </div>
        );
      },
    },
    {
      title: "Run Payroll Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={()=>navigate(`/payroll-report/detail/${encodeURIComponent('id?'+record.id)}`)}
            >
            {moment(text).format('DD-MM-YYYY')}
          </div>
        );
      },
    },
    {
      title: "Total Data",
      dataIndex: "total_data",
      key: "total_data",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={()=>navigate(`/payroll-report/detail/${encodeURIComponent('id?'+record.id)}`)}
            >
            {text || ""}
          </div>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <div
            className="detail-btn-wp"
            onClick={()=>navigate(`/payroll-report/detail/${encodeURIComponent('id?'+record.id)}`)}
            >
            {text || "-"}
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: "50px",
      render: (_, record, index) => {
        return RoleAccessReportPayrollDownload ?  (
        <div className="detail-btn-wp">
          <Button 
          type="link" 
          disabled={_.status !== "Completed"} 
          onClick={() => {
            actDownloadResult(record.id)
          }} 
          target="_blank">
            <DownloadOutlined />
          </Button>
        </div>
        ) : (
            <Row justify={"space-between"}>
              <Tooltip title="No Access">
                <StopOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
              </Tooltip>
            </Row>
        )
      }
      },
  ];
  const dataBreadcrumb = [
    {
      title: "Report",
      url: "/payroll-report",
    },
    {
      title: "Payroll",
      url: "/payroll-report",
    },
  ];

  useEffect(() => {
    previousPage.current = page;
  }, [page]);

  const onChangeTable = (pageNumber, type) => {
    const offsetSession = pageNumber * limit - 10;
    setPage(pageNumber);
    setOffset(offsetSession);
    sessionStorage.setItem("sessionPageInsurance", pageNumber);
    sessionStorage.setItem("sessionOffsetInsurance", offsetSession);
    //
  };

  const onShowSizeChange = (_, pageSize) => {
    setLimit(pageSize);
  };
  function dataTableWithNumber() {
    const showNumber = limit * (page - 1);
    return listTable?.map((data, index) => {
      return {
        ...data,
        key: index + 1,
        no: showNumber + (index + 1),
      };
    });
  }


  const handleDownload = () => {
    if (selectedDate !== null) {
      const url = `${process.env.REACT_APP_HRIS_URL}insurance-report?companyCode=${company}&month=${selectedDate}`;
      window.location.href = url;
    } else {
      window.location.href = `${process.env.REACT_APP_HRIS_URL}insurance-report?companyCode=${company}`;
    }
  };


  const handleDatePickerChange = (date, dateString) => {
    setSelectedDate(dateString);
    setPage(1);
    sessionStorage.setItem("sessionDateInsurance", dateString);
  };

  const disabledDate = (current) => {
    const currentYear = dayjs().year();
  
    const selectedYear = current.year();
  
    return selectedYear != currentYear 
  };

  return (
    <>
      <div className="title-section">Payroll Report</div>
      <div className="border bg-slate-50 mb-3 p-3 rounded-md shadow-lg">
        <Col span={24}>
          <Breadcrumb items={dataBreadcrumb} />
        </Col>
      </div>
      <div className="border bg-slate-50 p-6 rounded-lg mt-2 shadow-lg">
        {" "}
        <Row
          style={{ marginBottom: "24px", justifyContent: "end" }}
          align={"middle"}>
          <Col md={{ top: 20 }} className="mt-5 md:mt-0">
            <Row justify={"end"} style={{ gap: 15 }} md={14}>
              {/* {RoleAccessInsuranceReportDownload && (
                <Button size={"large"} type="primary" onClick={handleDownload}>
                  Download
                </Button>
              )} */}
               <DatePicker
                  allowClear={false}
                  picker="month"
                  className="search-input btn-sh-sec"
                  defaultValue={dayjs(selectedDate, 'YYYY-MM')}
                  onChange={handleDatePickerChange}
                  placeholder="Select Month"
                  disabledDate={disabledDate}
                />
            </Row>
          </Col>
        </Row>
        <div className="table-wrapper">
          {loading ? (
            <>
              <Table id={"my-table"} columns={columns} loading={loading} />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          ) : (
            <>
              <Table
                id={"my-table"}
                dataSource={dataTableWithNumber()}
                columns={columns}
                loading={loading}
              />
              <div className="mt-2 border rounded-lg p-3 bg-[#ffffff] flex justify-end">
                <Pagination
                  defaultCurrent={page}
                  current={page}
                  total={totalData}
                  onChange={onChangeTable}
                  showSizeChanger={false}
                  onShowSizeChange={onShowSizeChange}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}
